import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Active } from "src/shared/assets/svgs/green-tick-circle.svg";
import { ReactComponent as NotActive } from "src/shared/assets/svgs/close-red-icon.svg";
import { ReactComponent as Arrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { FieldName } from "src/shared/components/formCard/styles";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import { IBuilding } from "../types";
import { IBuildingsBody } from "./types";
import {
  ArrowWrapper,
  Divider,
  FieldInformationWrapper,
  FieldWrapper,
  IconWrapperWithArrow,
  FieldsWrapper,
  FieldValue,
  BuildingItemContainer,
} from "./styles";

export const BuildingBody = ({ buildingItems }: IBuildingsBody) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickSite = (id: string) => () => {
    navigate(`/building-information/${id}`);
  };

  return (
    <React.Fragment>
      {buildingItems?.map(
        ({ name, id, statusID }: IBuilding, index: number) => (
          <React.Fragment key={id}>
            <BuildingItemContainer key={id} onClick={handleClickSite(id)}>
              <FieldWrapper>
                <FieldInformationWrapper>
                  <FieldName>{t("name")}</FieldName>
                  <FieldsWrapper>
                    <FieldValue>{name}</FieldValue>
                  </FieldsWrapper>
                </FieldInformationWrapper>
                <IconWrapperWithArrow>
                  {statusID === STATUS_ID_VALUES.ACTIVE ? (
                    <Active />
                  ) : (
                    <NotActive />
                  )}
                  <ArrowWrapper>
                    <Arrow />
                  </ArrowWrapper>
                </IconWrapperWithArrow>
              </FieldWrapper>
            </BuildingItemContainer>
            {index !== buildingItems?.length - 1 && <Divider />}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};
