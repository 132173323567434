import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const FooterLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexGrow: 2,
  justifyContent: "space-evenly",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));
