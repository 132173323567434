import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const Divider = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#D9D9D9",
  height: 1,
}));

export const ArrowWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 20,
  cursor: "pointer",
  "& > svg > path": {
    fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 10,
  },
}));

export const IconWrapperWithArrow = styled(Box)(() => ({
  display: "flex",
  "& > svg": {
    width: 20,
    height: 20,
  },
}));

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

export const FieldInformationWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

export const FieldsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const RoomFieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  justifyContent: "space-between",
}));

export const FieldValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const LocationItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  borderRadius: 16,
  padding: 16,
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
  "&:hover": {
    background: isDarkMode(theme.palette.mode) ? "#3F4044" : "#F0F1F2",
  },
}));
