import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Input } from "src/shared/components/input/input";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { Button } from "src/shared/components/button/button";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { Select } from "src/shared/components/select/select";
import { useGetDescriptionWasteGeneratingProcessesQuery } from "src/shared/store/api/regulatory.api";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { SHIPMENT_OPTIONS } from "../customerInformation/constants";
import {
  ButtonWrapper,
  FieldWrapper,
  IndicatesRequiredField,
  SectionHeader,
  TextField,
  ErrorText,
  SectionDivider,
} from "../../pages/newProfile/styles";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import { WasteStreamDescriptionValidationSchema } from "./model";
import { IDescriptionWasteGenerating } from "./types";
import {
  WasteStreamDescriptionContainer,
  WasteStreamRadioGroup,
  WasteStreamFormControlLabel,
  WasteStreamRadio,
  WasteStreamDescriptionWrapper,
  WasteStreamDescriptionHeaderWrapper,
} from "./styles";

export const WasteStreamDescription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(WasteStreamDescriptionValidationSchema(t)),
    mode: "onSubmit",
  });

  const shipmentOption = watch("shipmentOption");

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const { data: descriptionWasteGenerating } =
    useGetDescriptionWasteGeneratingProcessesQuery({});

  const handleChangedData = () => {
    setChangedData(true);
  };

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContinue = useCallback(
    handleSubmit(
      ({ profileName, shipmentOption, wasteGeneratingProcessDescription }) => {
        upsertProfileRequest({
          id: profileID,
          profileName,
          shipmentOption,
          wasteGeneratingProcessDescription,
          sectionStep: SECTION.CHEMICAL_COMPOSITION,
        });
        setChangedData(false);
      }
    ),
    [handleSubmit, profileID, setChangedData, upsertProfileRequest]
  );

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToDashboard();
      });
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    setValue("profileName", wasteProfileData?.wasteName);
    if (wasteProfileData?.shipmentAs) {
      setValue("shipmentOption", wasteProfileData?.shipmentAs);
    } else {
      setValue("shipmentOption", SHIPMENT_OPTIONS.NON_BULK);
    }
    if (wasteProfileData?.wasteGeneratingProcessDescription) {
      setValue(
        "wasteGeneratingProcessDescription",
        wasteProfileData?.wasteGeneratingProcessDescription
      );
    }
  }, [setValue, wasteProfileData]);

  return (
    <WasteStreamDescriptionContainer>
      <WasteStreamDescriptionWrapper>
        <WasteStreamDescriptionHeaderWrapper>
          <SectionHeader>{t("waste-stream-description-section")}</SectionHeader>
          <IndicatesRequiredField>
            <span>*</span> {t("indicates-a-required-field")}
          </IndicatesRequiredField>
        </WasteStreamDescriptionHeaderWrapper>
        <FieldWrapper>
          <TextField>
            {`${t("profile-name")}`}
            <span> *</span>
          </TextField>
          <Input
            placeholder={t("type-a-name-for-your-waste")}
            errorMessage={errors?.profileName?.message}
            register={{
              ...register("profileName", { onChange: handleChangedData }),
            }}
            disabled={isActiveWasteProfile}
            id="waste-stream-description-profileName"
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField>{`${t("shipment-options")}`}</TextField>
          <WasteStreamRadioGroup
            value={shipmentOption || ""}
            onChange={(event) => {
              setValue("shipmentOption", event?.target?.value, {
                shouldValidate: true,
              });
              setChangedData(true);
            }}
          >
            <WasteStreamFormControlLabel
              value={SHIPMENT_OPTIONS.INNER_CONTAINERS}
              control={<WasteStreamRadio icon={<CheckedIcon />} />}
              label={t("inner-containers")}
              disabled={isActiveWasteProfile}
            />
            <WasteStreamFormControlLabel
              value={SHIPMENT_OPTIONS.NON_BULK}
              control={<WasteStreamRadio icon={<CheckedIcon />} />}
              label={t("non-bulk")}
              disabled={isActiveWasteProfile}
            />
            <WasteStreamFormControlLabel
              value={SHIPMENT_OPTIONS.BULK}
              control={<WasteStreamRadio icon={<CheckedIcon />} />}
              label={t("bulk-119")}
              disabled={isActiveWasteProfile}
            />
            <WasteStreamFormControlLabel
              value={SHIPMENT_OPTIONS.TANKER}
              control={<WasteStreamRadio icon={<CheckedIcon />} />}
              label={t("tanker")}
              disabled={isActiveWasteProfile}
            />
          </WasteStreamRadioGroup>
          {errors?.shipmentOption?.message && (
            <ErrorText>{errors?.shipmentOption?.message}</ErrorText>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <TextField>
            {`${t("description-of-waste-generating-process")}`}
            <span> *</span>
          </TextField>
          <Select
            value={watch("wasteGeneratingProcessDescription") || ""}
            errorMessage={errors?.wasteGeneratingProcessDescription?.message}
            options={
              descriptionWasteGenerating?.map(
                (description: IDescriptionWasteGenerating) => ({
                  id: description?.name,
                  val: description?.name,
                })
              ) || []
            }
            register={{
              ...register("wasteGeneratingProcessDescription", {
                onChange: handleChangedData,
              }),
            }}
            disabled={isActiveWasteProfile}
            id="waste-generating-process-description"
          />
        </FieldWrapper>
        <SectionDivider />
        <ButtonWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCancel}
            id="waste-stream-description-cancel"
          />
          {!isActiveWasteProfile && (
            <Button
              text={t("continue")}
              onClick={handleContinue}
              id="waste-stream-description-continue"
            />
          )}
        </ButtonWrapper>
      </WasteStreamDescriptionWrapper>
    </WasteStreamDescriptionContainer>
  );
};
