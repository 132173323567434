import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    gap: 16,
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const NavigationItem = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontFamily: "Roboto",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    color: "#F5F5F5",
  },
  "&:hover": {
    opacity: 0.7,
  },
}));

export const AdminPopoverItem = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  padding: "6px 16px",
  color: theme.palette.text.secondary,
  fontSize: 16,
  minWidth: 150,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
  "&:hover": {
    background: isDarkMode(theme.palette.mode)
      ? "rgba(37, 137, 92, 0.5);"
      : "rgba(37, 137, 92, 0.20)",
  },
}));
