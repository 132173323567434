import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { Column } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  SortChangedEvent,
  DragStoppedEvent,
  CellValueChangedEvent,
  FilterChangedEvent,
  SortModelItem,
  PaginationChangedEvent,
  IRowNode,
} from "ag-grid-community";
import { debounce } from "lodash/fp";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { Input } from "src/shared/components/input/input";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { IOption } from "../select/types";
import { PAGINATION_PAGE_SIZE_OPTIONS } from "./customPagination/constants";
import { CustomPagination } from "./customPagination/customPagination";
import { CustomLoadingOverlay } from "./customLoadingOverlay/customLoadingOverlay";
import { AG_GRID_ROW_HEIGHT, PAGE, PAGINATION, SEARCH } from "./constants";
import {
  AgGridContainer,
  AgGridTitleWrapper,
  AgGridTitle,
  AgGridWrapper,
  InputWrapper,
  PrintToExcelButton,
} from "./styled";
import { IAgGrid } from "./types";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export const AgGrid = ({
  rowData: rowDataProps,
  gridName,
  rowGroupPanelShow = "onlyWhenGrouping",
  onCellClicked,
  columnDefs,
  defaultColDef,
  onCellValueChanged,
  onGridReady,
  detailCellRendererParams,
  detailCellRenderer,
  masterDetail,
  detailRowAutoHeight,
  customFilters,
  customTitle,
  noRowsText,
  isLoadingData,
  title,
  columnKeys,
  getContextMenuItems,
  cellSelection,
  allowContextMenuWithControlKey,
  localeText,
  rowSelection,
  withSavingPage,
  withSavingSearch,
  onSelectionChanged,
  quickFilterMatcher,
}: IAgGrid) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [changedColumnState, setChangedColumnState] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [paginationPageSize, setPaginationPageSize] = useState<string>(
    getFromLocalStorage(`${gridName}-${PAGINATION}`) || "1"
  );
  const [sortModel, setSortModel] = useState<SortModelItem>();

  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const gridSearchValue = gridApi?.getQuickFilter();

  const handleUpdatePage = useCallback(
    (currentPage: number) => {
      setCurrentPage(currentPage);
      saveToLocalStorage(`${gridName}-${PAGE}`, currentPage);
      gridApi?.paginationGoToPage(currentPage);
    },
    [setCurrentPage, gridApi, gridName]
  );

  const handleChangeCurrentPage = (params: PaginationChangedEvent) => {
    if (params.newPage) {
      if (!isFirstRender) {
        const currentPage = gridApi?.paginationGetCurrentPage() || 0;
        handleUpdatePage(currentPage);
      }
    }
  };

  const handleGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);

    if (onGridReady) {
      onGridReady(params);
    }
  };

  const saveColumnState = () => {
    if (!gridApi || isFirstRender) return;

    const columnState = gridApi.getColumnState();
    const columnGroupState = gridApi.getColumnGroupState();
    saveToLocalStorage(`${gridName}_column`, columnState);
    saveToLocalStorage(`${gridName}_columnGroup`, columnGroupState);
  };

  const delayedSaveGridColumnState = debounce(100, saveColumnState);

  const handleSaveGridColumnState = () => {
    delayedSaveGridColumnState();
  };

  const handleRowGroupChanged = () => {
    delayedSaveGridColumnState();
    setChangedColumnState(true);
  };

  const selectStoredRow = useCallback(() => {
    const selectedRow = getFromLocalStorage(`selected_${gridName}`) || "";
    if (selectedRow?.id) {
      const selected: IRowNode<unknown>[] = [];
      const deselected: IRowNode<unknown>[] = [];
      gridApi?.forEachNode((node) => {
        if (node?.data?.id === selectedRow?.id) {
          selected.push(node);
        }
      });
      gridApi?.setNodesSelected({ nodes: selected, newValue: true });
      gridApi?.setNodesSelected({
        nodes: deselected,
        newValue: false,
      });
    }
  }, [gridApi, gridName]);

  const handleFirstDataRendered = (params: FirstDataRenderedEvent) => {
    const filterData = getFromLocalStorage(`${gridName}_filter`) || null;
    const columnState = getFromLocalStorage(`${gridName}_column`) || null;
    const columnGroupState =
      getFromLocalStorage(`${gridName}_columnGroup`) || null;
    selectStoredRow();

    if (filterData) {
      params?.api?.setFilterModel(filterData);
    }

    if (columnState) {
      params?.api?.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }

    if (columnGroupState) {
      params?.api.setColumnGroupState(columnGroupState);
    }

    if (withSavingPage) {
      setCurrentPage(getFromLocalStorage(`${gridName}-${PAGE}`));
      params?.api?.paginationGoToPage(
        getFromLocalStorage(`${gridName}-${PAGE}`)
      );
    }
    if (withSavingSearch) {
      setSearchValue(getFromLocalStorage(`${gridName}-${SEARCH}`));
      params?.api?.setGridOption(
        "quickFilterText",
        getFromLocalStorage(`${gridName}-${SEARCH}`)
      );
    }
    setIsFirstRender(false);
  };

  const handleSortChanged = (params: SortChangedEvent) => {
    const sortModel = params.api.getColumnState();
    const keyName = `${gridName}_column`;

    saveToLocalStorage(keyName, sortModel);
  };

  const handleFilterChanged = (params: FilterChangedEvent) => {
    const sortModel = params.api.getFilterModel();
    const keyName = `${gridName}_filter`;
    setSortModel(sortModel as SortModelItem);

    saveToLocalStorage(keyName, sortModel);
  };

  const onFilterTextBoxChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      gridApi?.setGridOption("quickFilterText", e.target.value);
      saveToLocalStorage(`${gridName}-${SEARCH}`, e.target.value);
      handleUpdatePage(0);
    },
    [gridApi, gridName, handleUpdatePage]
  );

  const autoSizeAll = useCallback(() => {
    if (lessThanLarge) {
      const allColumnIds = [] as string[];
      gridApi?.getColumns()?.forEach((column: Column) => {
        allColumnIds.push(column.getId());
      });

      gridApi?.autoSizeColumns(allColumnIds, false);
    }
  }, [gridApi, lessThanLarge]);

  const handleDragStopped = (e: DragStoppedEvent) => {
    const orderedData = e.api.getRenderedNodes().map((item) => item.data);

    if (rowDataProps && rowDataProps?.[0]?.type_id) {
      const keyName = `${gridName}_order|${rowDataProps[0].type_id}`;

      saveToLocalStorage(keyName, orderedData);
    }
  };

  const handleCellValueChanged = (event: CellValueChangedEvent) => {
    if (rowDataProps?.length && rowDataProps[0]?.type_id) {
      const keyName = `${gridName}_order|${rowDataProps[0].type_id}`;

      saveToLocalStorage(keyName, rowDataProps);
    }

    if (onCellValueChanged) {
      onCellValueChanged(event);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", autoSizeAll, false);

    return () => window.removeEventListener("resize", autoSizeAll, false);
  });

  useEffect(() => {
    if (rowDataProps && gridApi && gridApi.getDisplayedRowCount() < 1) {
      gridApi?.showNoRowsOverlay();
    } else {
      if (!isLoadingData) {
        gridApi?.hideOverlay();
      }
    }
    selectStoredRow();
  }, [
    gridApi,
    isLoadingData,
    rowDataProps,
    searchValue,
    gridSearchValue,
    gridName,
    selectStoredRow,
  ]);

  useEffect(() => {
    if (withSavingPage) {
      gridApi?.paginationGoToPage(getFromLocalStorage(`${gridName}-${PAGE}`));
    }
  }, [currentPage, gridApi, gridName, withSavingPage]);

  const handlePrintToExcel = () => {
    gridApi?.exportDataAsExcel({
      columnKeys,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(`${gridName}-${SEARCH}`, null);
      saveToLocalStorage(`selected_${gridName}`, null);
      saveToLocalStorage(`${gridName}-${PAGE}`, 0);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [gridName]);

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingData}
      loader={<CircularProgress />}
      keepInView
    >
      <AgGridContainer>
        {customTitle ? (
          customTitle(gridApi)
        ) : (
          <AgGridTitleWrapper>
            <AgGridTitle>{title}</AgGridTitle>
            <PrintToExcelButton
              onClick={handlePrintToExcel}
              id="print-to-excel"
            >
              {t("print-to-excel")}
            </PrintToExcelButton>
          </AgGridTitleWrapper>
        )}
        <InputWrapper>
          {customFilters ? (
            customFilters(gridApi)
          ) : (
            <Input
              value={searchValue || ""}
              placeholder={t(`search-in-${gridName}`)}
              onChange={onFilterTextBoxChanged}
              id={`search-in-${gridName}`}
            />
          )}
        </InputWrapper>
        <AgGridWrapper className="ag-theme-alpine">
          <AgGridReact
            quickFilterMatcher={quickFilterMatcher}
            rowGroupPanelShow={rowGroupPanelShow}
            suppressRowGroupHidesColumns
            onCellClicked={onCellClicked}
            rowData={rowDataProps}
            columnDefs={columnDefs}
            rowHeight={AG_GRID_ROW_HEIGHT}
            detailRowAutoHeight={detailRowAutoHeight}
            pagination
            suppressMenuHide
            suppressPaginationPanel
            suppressDragLeaveHidesColumns
            enableCellTextSelection
            paginationPageSize={
              PAGINATION_PAGE_SIZE_OPTIONS?.find(
                (paginationPerSize: IOption) =>
                  paginationPerSize?.id === paginationPageSize
              )?.val as unknown as number
            }
            defaultColDef={defaultColDef}
            detailCellRendererParams={detailCellRendererParams}
            detailCellRenderer={detailCellRenderer}
            masterDetail={masterDetail}
            domLayout="autoHeight"
            onGridReady={handleGridReady}
            onFirstDataRendered={handleFirstDataRendered}
            onSortChanged={handleSortChanged}
            onFilterChanged={handleFilterChanged}
            onColumnVisible={handleSaveGridColumnState}
            onColumnPinned={handleSaveGridColumnState}
            onColumnResized={handleSaveGridColumnState}
            onColumnMoved={handleSaveGridColumnState}
            onColumnRowGroupChanged={handleRowGroupChanged}
            onColumnValueChanged={handleSaveGridColumnState}
            onColumnPivotChanged={handleSaveGridColumnState}
            onDragStopped={handleDragStopped}
            onCellValueChanged={handleCellValueChanged}
            onPaginationChanged={handleChangeCurrentPage}
            onRowSelected={handleSaveGridColumnState}
            overlayNoRowsTemplate={
              noRowsText && `<span style="padding: 10px">${noRowsText}</span>`
            }
            getContextMenuItems={getContextMenuItems}
            cellSelection={cellSelection}
            allowContextMenuWithControlKey={allowContextMenuWithControlKey}
            localeText={localeText}
            rowSelection={rowSelection}
            onSelectionChanged={onSelectionChanged}
            loadingOverlayComponent={CustomLoadingOverlay}
          />
          {rowDataProps && (
            <CustomPagination
              gridName={gridName}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              gridApi={gridApi}
              rowDataProps={rowDataProps}
              searchValue={searchValue}
              paginationPageSize={paginationPageSize}
              sortModel={sortModel}
              setPaginationPageSize={setPaginationPageSize}
              setChangedColumnState={setChangedColumnState}
              changedColumnState={changedColumnState}
            />
          )}
        </AgGridWrapper>
      </AgGridContainer>
    </BlockUi>
  );
};
