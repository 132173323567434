import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const Input = styled(TextField)(({ theme }) => ({
  input: {
    padding: 8,
    height: 16,
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: 32,
    marginTop: 2,
    fontFamily: "Roboto",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      lineHeight: "normal",
    },
    "& fieldset": {
      borderColor: "#B1B1B1",
    },
    "&:hover fieldset": {
      borderColor: "#217BDB",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#217BDB",
    },
    "&.Mui-error fieldset": {
      borderColor: "#E35D2B",
    },
  },
}));
