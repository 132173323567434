import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useGetSiteByOrganizationByIDQuery } from "src/shared/store/api/organization.api";
import { InventoryAgingParameters } from "src/components/inventoryAgingParameters/inventoryAgingParameters";
import {
  EditInventoryAgingContainer,
  EditInventoryAgingBody,
  AgingInventoryTitle,
} from "./styles";

export const EditInventoryAging = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agingID } = useParams();
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const { data: site } = useGetSiteByOrganizationByIDQuery(agingID, {
    skip: !agingID,
  });

  const backToSiteInformation = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      if (site?.organizationUnitID) {
        navigate(`/site-information/${site?.organizationUnitID}`);
      }
    }
  };

  return (
    <EditInventoryAgingContainer>
      <CssBaseline />
      <Header />
      <EditInventoryAgingBody>
        <BackTo text={t("back-to-site")} handleBack={backToSiteInformation} />
        <AgingInventoryTitle>{t("manage-inventory-aging")}</AgingInventoryTitle>
        <InventoryAgingParameters />
      </EditInventoryAgingBody>
    </EditInventoryAgingContainer>
  );
};
