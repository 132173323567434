import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const REGULATORY_SUB_URL = "Regulatory";

export const regulatory = api.injectEndpoints({
  endpoints: (builder) => ({
    getDescriptionWasteGeneratingProcesses: builder.query({
      query: () => ({
        url: `${REGULATORY_SUB_URL}/GetDescriptionWasteGeneratingProcesses`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getChemicalsByKeyword: builder.query({
      query: ({ keyword }) => ({
        url: `${REGULATORY_SUB_URL}/GetChemicalsByKeyword/${keyword}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["Chemicals"],
    }),
    newChemical: builder.mutation({
      query: (body) => ({
        url: `${REGULATORY_SUB_URL}/CreateChemical`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["Chemicals"],
    }),
    getWasteCodes: builder.query({
      query: () => ({
        url: `${REGULATORY_SUB_URL}/GetWasteCodes`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getWasteCharacteristics: builder.query({
      query: () => ({
        url: `${REGULATORY_SUB_URL}/GetWasteCharacteristics`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getHazardousMaterials: builder.query({
      query: () => ({
        url: `${REGULATORY_SUB_URL}/GetHazardousMaterials`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getNonDotShippingNames: builder.query({
      query: () => ({
        url: `${REGULATORY_SUB_URL}/GetNonDotShippingNames`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getStateWasteCodes: builder.query({
      query: (state) => ({
        url: `${REGULATORY_SUB_URL}/GetStateWasteCodes/${state}`,
        method: HTTP_METHOD.GET,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStateWasteCodesQuery,
  useGetNonDotShippingNamesQuery,
  useGetHazardousMaterialsQuery,
  useGetWasteCharacteristicsQuery,
  useGetWasteCodesQuery,
  useGetDescriptionWasteGeneratingProcessesQuery,
  useGetChemicalsByKeywordQuery,
  useLazyGetChemicalsByKeywordQuery,
  useNewChemicalMutation,
} = regulatory;
