import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { FormCard } from "src/shared/components/formCard/formCard";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import {
  useLazyGeneratedPdfWithBarcodeByAccountIDQuery,
  useLazyGetSiteTreeExportByAccountIDQuery,
} from "src/shared/store/api/organization.api";
import { PrintToExcelButton } from "../printToExcelButton/printToExcelButton";
import { PrintQrCodesButton } from "../printQrCodesButton/printQrCodesButton";
import {
  Divider,
  FieldWrapper,
  FieldInformationWrapper,
  ButtonWrapper,
} from "./styles";
import { IOrganizationCard } from "./types";
import { getOrganizationCardFields } from "./constants";

export const OrganizationCard = ({
  organizationName,
  totalSites,
}: IOrganizationCard) => {
  const { t } = useTranslation();
  const { organizationUnitID } = useParams();
  const [getSiteTreeExport, { isFetching: isFetchingSiteTreeExport }] =
    useLazyGetSiteTreeExportByAccountIDQuery();
  const [
    generatePdfWithBarcode,
    { isFetching: isFetchingGeneratePdfWithBarcode },
  ] = useLazyGeneratedPdfWithBarcodeByAccountIDQuery({});
  const organizationCardFields = getOrganizationCardFields(totalSites, t);

  const handleDownloadExcel = () => {
    getSiteTreeExport(organizationUnitID);
  };

  const handlePrintQrCodes = () => {
    generatePdfWithBarcode(organizationUnitID);
  };

  return (
    <BlockUi
      tag="div"
      blocking={isFetchingSiteTreeExport || isFetchingGeneratePdfWithBarcode}
      loader={<CircularProgress />}
      keepInView
    >
      <FormCard title={organizationName}>
        <>
          {organizationCardFields.map(({ name, value }, index) => (
            <FieldWrapper key={index}>
              <FieldInformationWrapper>
                <FieldName>{name}</FieldName>
                <FieldValue>{value}</FieldValue>
              </FieldInformationWrapper>
            </FieldWrapper>
          ))}
          <Divider />
          <ButtonWrapper>
            <PrintToExcelButton handleDownloadExcel={handleDownloadExcel} />
            <PrintQrCodesButton handlePrintQrCodes={handlePrintQrCodes} />
          </ButtonWrapper>
        </>
      </FormCard>
    </BlockUi>
  );
};
