import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as Edit } from "src/shared/assets/svgs/edit.svg";
import { EditManifestStatusDialog } from "src/components/editManifestStatusDialog/editManifestStatusDialog";
import { IHazardousWaste, IHazardousWasteForm } from "./types";
import { ButtonWrapper, HazardousWrapper, FieldValueWrapper } from "./styles";

export const HazardousWasteForm = ({
  hazardousWasteConfig,
  manifest,
}: IHazardousWasteForm) => {
  const { t } = useTranslation();
  const [isOpenEditManifestStatusDialog, setIsOpenEditManifestStatusDialog] =
    useState(false);

  const handleOpenEditManifestStatusDialog = () => {
    setIsOpenEditManifestStatusDialog(true);
  };

  const handleCloseEditManifestStatusDialog = () => {
    setIsOpenEditManifestStatusDialog(false);
  };

  return (
    <FormCard title={manifest?.manifestNumber}>
      <React.Fragment>
        {hazardousWasteConfig?.map(
          (hazardousWaste: IHazardousWaste, index: number) => (
            <React.Fragment key={index}>
              <HazardousWrapper>
                <FieldWrapper>
                  <FieldValueWrapper>
                    <FieldName>{hazardousWaste.name}</FieldName>
                    {hazardousWaste?.customRenderValue ? (
                      hazardousWaste?.customRenderValue()
                    ) : (
                      <FieldValue>{hazardousWaste.value}</FieldValue>
                    )}
                  </FieldValueWrapper>
                  {hazardousWaste.isStatus && (
                    <ButtonWrapper>
                      <ButtonWithIcon
                        text={t("edit-return-status")}
                        onClick={handleOpenEditManifestStatusDialog}
                        icon={<Edit />}
                        id="hazardous-waste-edit-return-status"
                      />
                    </ButtonWrapper>
                  )}
                </FieldWrapper>
              </HazardousWrapper>
              {index !== hazardousWasteConfig?.length - 1 && <Divider />}
            </React.Fragment>
          )
        )}
        <EditManifestStatusDialog
          open={isOpenEditManifestStatusDialog}
          handleClose={handleCloseEditManifestStatusDialog}
          selectedManifest={manifest}
        />
      </React.Fragment>
    </FormCard>
  );
};
