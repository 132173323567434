import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { SupportForm } from "../../components/supportForm/supportForm";
import { SupportBody, SupportContainer, SupportTitle } from "./styles";

export const Support = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsOpenUnsavedChangedDialog, setSecondMenuTab } = useAppActions();
  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const hasUserAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ADMIN
  );

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const backToPreviousPage = () => {
    navigate(-1);
  };

  const handleClickBackToPreviousPage = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToPreviousPage();
    }
  };

  useEffect(() => {
    setSecondMenuTab(0);

    return () => {
      setSecondMenuTab(undefined);
    };
  }, [setSecondMenuTab, hasUserAdminPermission]);

  return (
    <SupportContainer>
      <CssBaseline />
      <Header />
      <SupportBody>
        <BackTo
          text={t("back-to-previous-page")}
          handleBack={handleClickBackToPreviousPage}
        />
        <SupportTitle>{t("contact-advise-support")}</SupportTitle>
        <SupportTitle>{t("support-description")}</SupportTitle>
        <SupportForm />
      </SupportBody>
    </SupportContainer>
  );
};
