import { Box, Button, styled } from "@mui/material";

export const PaginationButtonStyled = styled(Button)(({ theme }) => ({
  width: 100,
  padding: "5.5px 8px",
  height: 28,
  borderRadius: 8,
  border: "1px solid #D9D9D9",
  background: "#F5F5F5",
  color: "#3A3A3A",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "initial",
  [theme.breakpoints.down("md")]: {
    width: 24,
    height: 24,
    padding: 2,
    minWidth: 0,
    display: "flex",
  },
  "&:hover": {
    background: "#c2c3c4",
  },
  "&:disabled": {
    color: "#3A3A3A",
    opacity: 0.5,
  },
}));

export const NextArrowWrapper = styled(Box)(() => ({
  transform: "rotate(270deg)",
  width: 16,
  height: 16,
  "& > svg": {
    width: 16,
    height: 16,
    "& > path": {
      fill: "#3A3A3A",
    },
  },
}));

export const PrevArrowWrapper = styled(Box)(() => ({
  transform: "rotate(90deg)",
  width: 16,
  height: 16,
  "& > svg": {
    width: 16,
    height: 16,
    "& > path": {
      fill: "#3A3A3A",
    },
  },
}));
