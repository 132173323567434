import {
  Box,
  styled,
  Radio as MuiRadio,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";

export const AddNewQuestionWrapper = styled(Box)(() => ({
  width: "fit-content",
}));

export const AddQuestionButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  width: "fit-content",
  gap: 8,
  alignSelf: "end",
}));

export const AddQuestionContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

export const Radio = styled(MuiRadio)(() => ({
  color: "#DDD",
  "&.Mui-checked": {
    color: "#217BDB",
  },
  "& > span > svg": {
    width: 16,
    height: 16,
  },
  "& > input": {
    backgroundColor: "#FFF",
  },
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: "fit-content",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
}));

export const RadioGroupWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "start",
    height: "auto !important",
  },
}));
