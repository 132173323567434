import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { ITransporterForm, ITransporter } from "./types";

export const TransporterForm = ({ transporterConfig }: ITransporterForm) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("transporter")}>
      <React.Fragment>
        {transporterConfig?.map((transporter: ITransporter, index: number) => (
          <React.Fragment key={index}>
            <FieldWrapper>
              <FieldName>{transporter.name}</FieldName>
              <FieldValue>{transporter.value}</FieldValue>
            </FieldWrapper>
            {index !== transporterConfig?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </FormCard>
  );
};
