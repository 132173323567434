import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AccountCard } from "src/components/accountCard/accountCard";
import Notifications from "src/shared/assets/svgs/notifications.svg";
import InventoryReports from "src/shared/assets/svgs/inventory-reports.svg";
import { InventoryTable } from "../../components/inventoryTable/inventoryTable";
import {
  InventoryContainer,
  InventoryTitle,
  InventoryWrapper,
  InventoryDescription,
  InventoryCardsWrapper,
} from "./styles";

export const Inventory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickNotifications = () => {
    navigate("/inventory-notifications");
  };

  const handleClickInventoryReports = () => {
    navigate("/inventory-reports");
  };

  return (
    <InventoryContainer>
      <InventoryTitle>{t("hazardous-waste-inventory")}</InventoryTitle>
      <InventoryWrapper>
        <InventoryDescription>
          {t("inventory-description")}
        </InventoryDescription>
        <InventoryCardsWrapper>
          <AccountCard
            title={t("inventory-reports")}
            buttonText={t("new")}
            img={InventoryReports}
            onClick={handleClickInventoryReports}
            id="inventory-inventory-reports-new"
          />
          <AccountCard
            title={t("notifications")}
            buttonText={t("create")}
            img={Notifications}
            onClick={handleClickNotifications}
            id="inventory-notifications-create"
          />
        </InventoryCardsWrapper>
      </InventoryWrapper>
      <InventoryTable />
    </InventoryContainer>
  );
};
