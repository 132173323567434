import React from "react";
import { UserProfile } from "src/components/userProfile/userProfile";
import { QuickOverview } from "src/components/quickOverview/quickOverview";
import { FacilitiesAndAccounts } from "src/components/facilitiesAndAccounts/facilitiesAndAccounts";

export const Home = () => {
  return (
    <React.Fragment>
      <QuickOverview />
      <UserProfile />
      <FacilitiesAndAccounts />
    </React.Fragment>
  );
};
