import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { t } from "i18next";
import { publicClientApplication, store } from "src";
import { RootState } from "../store";
import { tokenActions } from "../reducers/token/token.slice";
import { snackBarActions } from "../reducers/snackBar/snackBar.slice";
import { errorHandlingActions } from "../reducers/errorHandling/errorHandling.slice";
import {
  EXCEPTION_HANDLER_URL,
  REDUCER_PATH,
  RESPONSE_CODE,
  SCOPES,
  getErrorMessage,
} from "./constants";
import { IAdditionalParams } from "./types";

const baseQueryWithReAuth = () => {
  const baseQuery = (refreshedToken?: string) =>
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: (headers, { getState }) => {
        const token = refreshedToken
          ? refreshedToken
          : (getState() as RootState)?.tokenReducer?.token;
        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    });

  return async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const result: any = await baseQuery()(args, api, extraOptions);
    let refetchData;
    if (result?.error?.status === RESPONSE_CODE.BAD_REQUEST) {
      store.dispatch(
        snackBarActions.setSnackBarMessages(
          result?.error?.data?.map(
            (el: { errorID?: string; additionalParams?: IAdditionalParams }) =>
              getErrorMessage(el.errorID, el.additionalParams)
          )
        )
      );
      store.dispatch(snackBarActions.setIsOpenSnackBar(true));
    }

    if (
      result?.error?.status === RESPONSE_CODE.INTERNAL_SERVER_ERROR &&
      !args?.url?.includes(EXCEPTION_HANDLER_URL)
    ) {
      store.dispatch(
        snackBarActions.setSnackBarMessages([
          t("unhandled-exception-has-occurred"),
        ])
      );
      store.dispatch(snackBarActions.setIsOpenSnackBar(true));
      store.dispatch(
        errorHandlingActions.setErrorHandlingBody(
          JSON.stringify({
            result: result,
            args: args,
          })
        )
      );
    }

    if (result?.error?.status === RESPONSE_CODE.NO_AUTH) {
      const account = publicClientApplication.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: SCOPES,
        account: account,
      };

      const handleLogout = () => {
        publicClientApplication.logoutRedirect();
        store.dispatch(tokenActions.setToken(undefined));
      };
      await publicClientApplication
        .acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          const refreshedToken = accessTokenResponse.accessToken;
          store.dispatch(tokenActions.setToken(refreshedToken));

          const refetch: any = await baseQuery(refreshedToken)(
            args,
            api,
            extraOptions
          );
          if (refetch?.error?.status === RESPONSE_CODE.BAD_REQUEST) {
            store.dispatch(
              snackBarActions.setSnackBarMessages(
                result?.error?.data?.map(
                  (el: {
                    errorID?: string;
                    additionalParams?: IAdditionalParams;
                  }) => getErrorMessage(el.errorID, el.additionalParams)
                )
              )
            );
            store.dispatch(snackBarActions.setIsOpenSnackBar(true));
          }

          if (
            result?.error?.status === RESPONSE_CODE.INTERNAL_SERVER_ERROR &&
            !args?.url?.includes(EXCEPTION_HANDLER_URL)
          ) {
            store.dispatch(
              snackBarActions.setSnackBarMessages([
                t("unhandled-exception-has-occurred"),
              ])
            );
            store.dispatch(snackBarActions.setIsOpenSnackBar(true));
            store.dispatch(
              errorHandlingActions.setErrorHandlingBody(
                JSON.stringify({
                  result: result,
                  args: args,
                })
              )
            );
          }

          if (refetch?.error?.status === RESPONSE_CODE.NO_AUTH) {
            handleLogout();
          }
          refetchData = refetch;
        })
        .catch(() => {
          handleLogout();
        });
    }

    return refetchData ? refetchData : result;
  };
};

export const api = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: baseQueryWithReAuth(),
  endpoints: () => ({}),
  tagTypes: [
    "UserProfile",
    "Organization",
    "Manifest",
    "WasteAttachment",
    "ProfileRequest",
    "Chemicals",
    "UpdateAging",
    "UpdateLocation",
    "UpdateInspection",
    "UpdateInventory",
    "ManifestNotification",
    "InventoryNotification",
    "Subscription",
    "InspectionNotification",
    "UpdateBanner",
  ],
});
