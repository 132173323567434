import { ComponentType, useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate, useParams } from "react-router-dom";
import { isValidUrl } from "./constants";
import { IBarcodeProps, IScannerData } from "./types";
import { InspectionBarcodeContainer } from "./styles";

export const InspectionBarcode = () => {
  const navigate = useNavigate();
  const { accountID } = useParams();
  const [stopStream, setStopStream] = useState(false);
  const [scannerData, setScannerData] = useState<IScannerData>();

  const onUpdateBarcode = (_: unknown, result: unknown) => {
    if (result) {
      setScannerData(result);
      setStopStream(true);
    }
  };

  const BarcodeScannerSafeForReact18 =
    BarcodeScannerComponent as ComponentType<IBarcodeProps>;

  useEffect(() => {
    if (stopStream) {
      if (isValidUrl(scannerData?.text as string)) {
        const params = scannerData?.text?.split("/");
        const accountId = params?.[params?.length - 2];
        const barcode = params?.[params?.length - 1];

        navigate(`/new-inspection/${accountId}/${barcode}`);
      } else {
        navigate(`/new-inspection/${accountID}/${scannerData?.text}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopStream, navigate]);

  return (
    <InspectionBarcodeContainer>
      <BarcodeScannerSafeForReact18
        onUpdate={onUpdateBarcode}
        stopStream={stopStream}
      />
    </InspectionBarcodeContainer>
  );
};
