import * as yup from "yup";
import "yup-phone-lite";

export const NewUserValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(t("required-field")),
    lastName: yup.string().required(t("required-field")),
    organizationName: yup.string().required(t("required-field")),
    email: yup.string().email().required(t("required-field")),
    phone: yup.string().phone().required(t("required-field")),
    jobTitle: yup.string().required(t("required-field")),
    termsOfUse: yup.boolean(),
  });
