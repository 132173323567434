import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsAuthenticated } from "@azure/msal-react";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { Dialog } from "src/shared/components/dialog/dialog";
import { useUpdateAuthenticatedUserMutation } from "src/shared/store/api/userProfile.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { useGetOrganizationBySearchValueQuery } from "src/shared/store/api/organization.api";
import { IOption } from "src/shared/components/select/types";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { TriumvirateUserProfileValidationSchema } from "./model";
import { ITriumvirateUserProfileDialog } from "./types";
import {
  DialogLabel,
  LabelWrapper,
  TriumvirateUserProfileDialogContentContainer,
  SendBtnWrapper,
  DialogBody,
} from "./styles";

export const TriumvirateUserProfileDialog = ({
  open,
  handleClose,
}: ITriumvirateUserProfileDialog) => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [
    updateAuthenticatedUser,
    {
      isSuccess: isSuccessTriumvirateUserProfile,
      isLoading: isTriumvirateUserProfileLoading,
    },
  ] = useUpdateAuthenticatedUserMutation();

  useEffect(() => {
    if (isSuccessTriumvirateUserProfile && !isTriumvirateUserProfileLoading) {
      handleClose();
      navigate(`/dashboard/${TABS_NAMES[0]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTriumvirateUserProfileLoading]);

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(TriumvirateUserProfileValidationSchema(t)),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (isSuccessTriumvirateUserProfile) {
      reset({
        organization: {},
      });
    }
  }, [reset, isSuccessTriumvirateUserProfile]);

  const { data: organizationOptions, isFetching: isFetchingOrganizations } =
    useGetOrganizationBySearchValueQuery(
      {
        searchValue,
      },
      {
        skip: !isAuthenticated,
      }
    );

  const organizationOption = watch("organization");

  const handleSendTriumvirateUserProfile = handleSubmit(({ organization }) => {
    updateAuthenticatedUser({
      id: organization.id,
    });
  });

  const triumvirateUserProfileDialogActions = () => {
    return (
      <SendBtnWrapper>
        <FilledButton
          text={t("save")}
          onClick={handleSendTriumvirateUserProfile}
          disabled={!isValid || isTriumvirateUserProfileLoading}
          id="triumvirate-user-save"
        />
      </SendBtnWrapper>
    );
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setValue("organization", newInputValue as IOption, {
      shouldValidate: true,
    });
  };

  const renderTriumvirateUserProfileDialogFields = () => {
    return (
      <TriumvirateUserProfileDialogContentContainer>
        <DialogBody>{t("select-account-description")}</DialogBody>
        <LabelWrapper>
          <DialogLabel>{t("organization")}</DialogLabel>
          <Autocomplete
            options={
              organizationOptions
                ? organizationOptions
                : organizationOption
                ? [organizationOption]
                : []
            }
            value={organizationOption || ""}
            updateData={(value: string) => {
              setSearchValue(value);
            }}
            onChange={handleChangeOrganization}
            errorMessage={errors?.organization?.message}
            noOptionText={
              !searchValue
                ? t("type-account-name")
                : isFetchingOrganizations
                ? ""
                : undefined
            }
            id="triumvirate-user-organization"
          />
        </LabelWrapper>
      </TriumvirateUserProfileDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      title={t("select-account")}
      actions={triumvirateUserProfileDialogActions}
      renderFields={renderTriumvirateUserProfileDialogFields}
    />
  );
};
