import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const WASTE_PROFILE_SUB_URL = "WasteProfile";

export const wasteProfile = api.injectEndpoints({
  endpoints: (builder) => ({
    wasteProfileSearch: builder.query({
      query: (body) => ({
        url: `${WASTE_PROFILE_SUB_URL}/WasteProfileSearch`,
        method: HTTP_METHOD.POST,
        ...(body ? { body } : {}),
      }),
    }),
    getWasteProfileByID: builder.query({
      query: ({ id }) => ({
        url: `${WASTE_PROFILE_SUB_URL}/GetWasteProfileByID/${id}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["WasteAttachment"],
    }),
    uploadWasteProfileAttachment: builder.mutation({
      query: ({ docID, body }) => ({
        url: `${WASTE_PROFILE_SUB_URL}/UploadWasteProfileAttachment?docID=${docID}`,
        method: HTTP_METHOD.POST,
        formData: true,
        body,
      }),
      invalidatesTags: ["WasteAttachment"],
    }),
    profileDetailExportFile: builder.mutation({
      queryFn: async (body, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${WASTE_PROFILE_SUB_URL}/ProfileDetailExportFile`,
          method: HTTP_METHOD.POST,
          body,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blobExcel = url.createObjectURL(result?.data);
        hiddenElement.href = blobExcel;
        hiddenElement.target = "_blank";
        hiddenElement.download = "profileList.xlsx";
        hiddenElement.click();
        return { data: null };
      },
    }),
    newProfile: builder.query({
      query: () => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/NewProfile`,
        method: HTTP_METHOD.GET,
      }),
    }),
    upsertProfileRequest: builder.mutation({
      query: (body) => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/UpsertProfileRequest`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["ProfileRequest"],
    }),
    getWasteProfileRequestByID: builder.query({
      query: ({ id }) => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/GetWasteProfileRequestByID/${id}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["ProfileRequest", "WasteAttachment"],
    }),
    uploadWasteProfileRequestAttachment: builder.mutation({
      query: ({ docID, body }) => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/UploadWasteProfileRequestAttachment?docID=${docID}`,
        method: HTTP_METHOD.POST,
        formData: true,
        body,
      }),
      invalidatesTags: ["WasteAttachment"],
    }),
    GetPendingProfileRequests: builder.query({
      query: (body) => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/GetPendingProfileRequests`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getProfileRequestAccounts: builder.query({
      query: () => ({
        url: `${WASTE_PROFILE_SUB_URL}Request/GetProfileRequestAccounts`,
        method: HTTP_METHOD.GET,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProfileRequestAccountsQuery,
  useLazyGetPendingProfileRequestsQuery,
  useUploadWasteProfileRequestAttachmentMutation,
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
  useLazyNewProfileQuery,
  useProfileDetailExportFileMutation,
  useLazyWasteProfileSearchQuery,
  useGetWasteProfileByIDQuery,
  useUploadWasteProfileAttachmentMutation,
} = wasteProfile;
