import { useState } from "react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress, ImageList, ImageListItem } from "@mui/material";
import { useGetInspectionResultByIDQuery } from "src/shared/store/api/inspection.api";
import { EditFollowUpDialog } from "src/components/editFollowUpDialog/editFollowUpDialog";
import { ReactComponent as PhotoAlbum } from "src/shared/assets/svgs/photo-album.svg";
import { InspectionQuestions } from "./inspectionQuestions/inspectionQuestions";
import { ZoomedImageWidthDownload } from "../../../shared/components/zoomedImageWithDownload/zoomedImageWithDownload";
import { FolderOpenWrapper } from "./inspectionQuestions/styles";
import { getInspectionInformationConfig } from "./constants";
import { IInspectionInformation, IInspectionConfig, IBlobFile } from "./types";
import {
  InspectionAttachmentsWrapper,
  InspectionDescription,
  InspectionInformationContainer,
  InspectionInformationTitle,
  InspectionWrapper,
} from "./styles";

export const InspectionInformation = ({ id }: IInspectionInformation) => {
  const { t } = useTranslation();

  const [isOpenImages, setIsOpenImages] = useState(false);
  const [isOpenEditFollowUpDialog, setIsOpenEditFollowUpDialog] =
    useState(false);

  const {
    data: inspectionInformationData,
    isLoading: isLoadingInspectionInformation,
  } = useGetInspectionResultByIDQuery(id, {
    skip: !id,
  });

  const handleCloseEditFollowUpDialog = () => {
    setIsOpenEditFollowUpDialog(false);
  };

  const handleOpenEditFollowUpDialog = () => {
    setIsOpenEditFollowUpDialog(true);
  };

  const inspectionConfig = getInspectionInformationConfig(
    inspectionInformationData,
    t,
    handleOpenEditFollowUpDialog
  ) as IInspectionConfig[];

  const handleOpenFolder = () => {
    setIsOpenImages(!isOpenImages);
  };

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingInspectionInformation}
      loader={<CircularProgress />}
      keepInView
    >
      <InspectionInformationContainer>
        <InspectionInformationTitle>
          {t("inspection-information")}
        </InspectionInformationTitle>
        {inspectionConfig?.map(
          ({ name, value, renderValue }: IInspectionConfig, index) => (
            <InspectionWrapper key={`${index}-${name}`}>
              {name && (
                <InspectionDescription>{`${name}:`}</InspectionDescription>
              )}
              {renderValue ? (
                renderValue()
              ) : value ? (
                <InspectionDescription>{value}</InspectionDescription>
              ) : null}
            </InspectionWrapper>
          )
        )}
        {!!inspectionInformationData?.blobFilePaths?.length && (
          <>
            <InspectionWrapper>
              <InspectionAttachmentsWrapper>
                <InspectionDescription>
                  {`${t("attachments")}:`}
                </InspectionDescription>
                <FolderOpenWrapper>
                  <PhotoAlbum onClick={handleOpenFolder} />
                </FolderOpenWrapper>
              </InspectionAttachmentsWrapper>
            </InspectionWrapper>
            {isOpenImages && (
              <ImageList cols={3}>
                {inspectionInformationData?.blobFilePaths?.map(
                  ({ containerName, file, folder }: IBlobFile) => {
                    return (
                      <ImageListItem key={file}>
                        <ZoomedImageWidthDownload
                          containerName={containerName}
                          folder={folder}
                          fileName={file}
                        />
                      </ImageListItem>
                    );
                  }
                )}
              </ImageList>
            )}
          </>
        )}
        <InspectionQuestions
          questions={inspectionInformationData?.questionResults}
          inspectionId={inspectionInformationData?.id}
        />
      </InspectionInformationContainer>
      <EditFollowUpDialog
        open={isOpenEditFollowUpDialog}
        handleClose={handleCloseEditFollowUpDialog}
        inspectionResultID={inspectionInformationData?.id}
        followUpStatus={inspectionInformationData?.followUpOptionID}
        comments={inspectionInformationData?.followUpComments}
      />
    </BlockUi>
  );
};
