import { Box, Typography, styled } from "@mui/material";
import { IAccountCardContainer } from "./types";

export const AccountCardContainer = styled(Box)<IAccountCardContainer>(
  ({ theme, img }) => ({
    position: "relative",
    flexShrink: 0,
    borderRadius: 8,
    border: "1.4px solid #25895C",
    padding: "31.5px 0 25.5px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 260,
    height: 150,
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-5px 90%",
    [theme.breakpoints.down("lg")]: {
      flexGrow: 2,
    },
    [theme.breakpoints.down("md")]: {
      height: 140,
    },
    "& > button": {
      width: 130,
      textTransform: "capitalize",
    },
    justifyContent: "space-between",
  })
);

export const AccountCardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  textAlign: "center",
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));
