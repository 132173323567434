import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const ManifestNotificationFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
}));

export const ManifestNotificationFormLabel = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "23.44px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  })
);

export const ManifestNotificationFormFieldDaysWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    gap: 20,
    flexDirection: "column",
    "& > div": {
      "& .MuiFormHelperText-root": {
        position: "absolute",
        top: 42,
      },
      "& > div,textArea": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        height: 42,
      },
    },
    [theme.breakpoints.down("md")]: {
      gap: 16,
    },
  })
);

export const ManifestNotificationFormFieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  flexDirection: "column",
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const ManifestNotificationManifestDaysWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      gap: 8,
    },
  })
);
