import { createSlice } from "@reduxjs/toolkit";
import { IManageScheduledReportsFilters } from "./manageScheduledReportsFilters";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS } from "src/components/manageScheduledReportsTable/constants";

const initialManageScheduledReportsFiltersState: IManageScheduledReportsFilters =
  {
    selectedRow: undefined,
  };

export const manageScheduledReportsFiltersSlice = createSlice({
  name: "manageScheduledReportsFilters",
  initialState: initialManageScheduledReportsFiltersState,
  reducers: {
    setManageScheduledReportsFilterScroll: (_, action) => {
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SCROLL_ROW,
        action.payload
      );
    },
    setManageScheduledReportsFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS_ROW,
        state.selectedRow
      );
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS,
        state.selectedRow
      );
    },
  },
});

export const {
  reducer: manageScheduledReportsFiltersReducer,
  actions: manageScheduledReportsFiltersActions,
} = manageScheduledReportsFiltersSlice;
