import { IBuilding } from "src/components/buildingsList/types";

export const getBuildingCardFields = (
  buildingData: IBuilding,
  t: (text: string) => string
) => {
  return [
    {
      name: t("name"),
      value: buildingData?.name,
    },
    {
      name: t("address"),
      value: `${buildingData?.city ? `${buildingData?.city},` : ""} ${
        buildingData?.state ? `${buildingData?.state},` : ""
      } ${buildingData?.postalCode ? buildingData?.postalCode : ""}`,
    },
  ];
};
