import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import { MultiEmail } from "src/shared/components/multiEmail/multiEmail";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Alert } from "src/shared/components/alert/alert";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import {
  useDeleteUserInspectionNotificationByInspectionIDMutation,
  useGetUserInspectionNotificationByInspectionIDQuery,
  useUpsertUserInspectionNotificationMutation,
} from "src/shared/store/api/notification.api";
import {
  useGetInspectionWithQuestionsTemplateByIDQuery,
  useGetScheduleOptionsQuery,
} from "src/shared/store/api/inspection.api";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { useGetAllLocationTypesQuery } from "src/shared/store/api/inspectionLocation.api";
import { getLocationTypesOptions } from "../editLocationParameters/constants";
import { getScheduleOptions } from "../manageInspectionForm/constants";
import { InspectionNotificationFormValidationSchema } from "./model";
import { IInspectionNotificationFields } from "./types";
import { NOTIFICATION_SCHEDULE_CONFIG } from "./constants";
import {
  ErrorText,
  ActionsWrapper,
  InspectionNotificationFormContainer,
  InspectionNotificationFormFieldWrapper,
  InspectionNotificationFormLabel,
  InspectionNotificationCheckboxFieldWrapper,
  InspectionNotificationCheckboxLabel,
  InspectionNotificationInformationWrapper,
  InspectionNotificationInformationText,
  InspectionNotificationScheduleWrapper,
} from "./styles";

export const InspectionNotificationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { inspectionID } = useParams();

  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const [
    deleteInspectionNotification,
    {
      isSuccess: isSuccessDeleteInspectionNotification,
      isLoading: isLoadingDeleteInspectionNotification,
    },
  ] = useDeleteUserInspectionNotificationByInspectionIDMutation({});

  const {
    data: inspectionNotificationData,
    isLoading: isLoadingInspectionNotification,
    isFetching: isFetchingInspectionNotification,
  } = useGetUserInspectionNotificationByInspectionIDQuery(inspectionID);

  const {
    data: inspectionWithQuestions,
    isLoading: isLoadingInspectionWithQuestions,
  } = useGetInspectionWithQuestionsTemplateByIDQuery(inspectionID);

  const { data: scheduleOptions } = useGetScheduleOptionsQuery({});

  const { data: locationTypes } = useGetAllLocationTypesQuery({});

  const locationTypesValue = [
    ...(locationTypes ? getLocationTypesOptions(locationTypes) : []),
  ]?.find(
    (option) => option?.id === inspectionWithQuestions?.storageTypeID
  ) || { id: "", val: "" };

  const scheduleIDValue = [
    ...(scheduleOptions ? getScheduleOptions(scheduleOptions) : []),
  ]?.find((option) => option?.id === inspectionWithQuestions?.scheduleID) || {
    id: "",
    val: "",
  };

  const { data: inspectionAccount, isLoading: isLoadingInspectionAccount } =
    useGetOrganizationsByIDsQuery(
      inspectionWithQuestions?.accountOrganizationUnitID,
      { skip: !inspectionWithQuestions?.accountOrganizationUnitID }
    );

  const [
    upsertInspectionNotification,
    {
      isSuccess: isSuccessUpsertInspectionNotification,
      isLoading: isLoadingUpsertInspectionNotification,
    },
  ] = useUpsertUserInspectionNotificationMutation({});

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InspectionNotificationFormValidationSchema(t)),
    mode: "onSubmit",
  });

  const emails = watch("emailDistributionList") || [];
  const notificationSchedule = watch("notificationSchedule") || [];

  const backToInspectionsNotifications = useCallback(() => {
    navigate("/inspections-notifications");
  }, [navigate]);

  const handleDeleteInspectionNotification = () => {
    deleteInspectionNotification(inspectionID);
  };

  const handleCreateNotification = handleSubmit((data) => {
    upsertInspectionNotification({
      inspectionID,
      ...data,
    });
  });

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleChangeEmails = (_emails: string[]) => {
    setValue("emailDistributionList", _emails);
    handleChangedData();
  };

  const handleCloseInspectionNotificationForm = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToInspectionsNotifications();
    }
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  useEffect(() => {
    if (inspectionNotificationData) {
      for (const value of Object.keys(inspectionNotificationData)) {
        setValue(
          value as IInspectionNotificationFields,
          inspectionNotificationData[value],
          {
            shouldValidate: true,
          }
        );
      }
    }
  }, [inspectionNotificationData, setValue]);

  const notificationScheduleIds = notificationSchedule?.map(
    ({ settingID }) => settingID
  );

  const selectAllNotificationSchedule = useMemo(
    () =>
      NOTIFICATION_SCHEDULE_CONFIG?.every(({ settingId }) =>
        notificationScheduleIds.includes(settingId)
      ),
    [notificationScheduleIds]
  );

  const onChangeNotificationSchedule = (settingId: number) => () => {
    const isSelectedId = notificationScheduleIds?.includes(settingId);

    if (isSelectedId) {
      const newAccounts = notificationSchedule?.filter(
        ({ settingID }) => settingID !== settingId
      );
      setValue("notificationSchedule", newAccounts, { shouldValidate: true });
    } else {
      const newAccounts = [
        ...notificationSchedule,
        {
          settingID: settingId,
          value: true,
        },
      ];
      setValue("notificationSchedule", newAccounts, { shouldValidate: true });
    }
    handleChangedData();
  };

  const onChangeSelectAllNotificationSchedule = () => {
    if (selectAllNotificationSchedule) {
      const newNotificationSchedule = notificationSchedule?.filter(
        ({ settingID }) => {
          return !notificationScheduleIds?.includes(settingID);
        }
      );

      setValue("notificationSchedule", newNotificationSchedule, {
        shouldValidate: true,
      });
    } else {
      const newNotificationSchedule = [
        ...notificationSchedule,
        ...NOTIFICATION_SCHEDULE_CONFIG.map(({ settingId }) => ({
          settingID: settingId,
          value: true,
        })),
      ];
      const NotificationScheduleSet = new Set(
        newNotificationSchedule?.map(({ settingID }) => settingID)
      );
      const notificationScheduleArray = Array.from(
        NotificationScheduleSet
      )?.map((id) => ({
        settingID: id,
        value: true,
      }));

      setValue("notificationSchedule", notificationScheduleArray, {
        shouldValidate: true,
      });
    }
    handleChangedData();
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      backToInspectionsNotifications();
      clearErrors();
      reset({});
    });
  }, [
    clearErrors,
    backToInspectionsNotifications,
    reset,
    setHandleBackUnsavedChanged,
  ]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleCreateNotification();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCreateNotification]);

  useEffect(() => {
    if (isSuccessUpsertInspectionNotification) {
      setChangedData(false);
      backToInspectionsNotifications();
    }
  }, [
    isSuccessUpsertInspectionNotification,
    setChangedData,
    backToInspectionsNotifications,
  ]);

  useEffect(() => {
    if (isSuccessDeleteInspectionNotification) {
      reset();
      clearErrors();
      setChangedData(false);
      backToInspectionsNotifications();
    }
  }, [
    clearErrors,
    isSuccessDeleteInspectionNotification,
    reset,
    setChangedData,
    backToInspectionsNotifications,
  ]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingUpsertInspectionNotification ||
        isFetchingInspectionNotification ||
        isLoadingInspectionNotification ||
        isLoadingDeleteInspectionNotification ||
        isLoadingInspectionAccount ||
        isLoadingInspectionWithQuestions
      }
      loader={<CircularProgress />}
      keepInView
    >
      <InspectionNotificationFormContainer>
        <InspectionNotificationInformationWrapper>
          <InspectionNotificationInformationText>
            {t("account")}:
          </InspectionNotificationInformationText>
          <InspectionNotificationInformationText isBold>
            {inspectionAccount?.[0]?.name}
          </InspectionNotificationInformationText>
        </InspectionNotificationInformationWrapper>
        <InspectionNotificationInformationWrapper>
          <InspectionNotificationInformationText>
            {t("inspection")}:
          </InspectionNotificationInformationText>
          <InspectionNotificationInformationText isBold>
            {inspectionWithQuestions?.title}
          </InspectionNotificationInformationText>
        </InspectionNotificationInformationWrapper>
        <InspectionNotificationInformationWrapper>
          <InspectionNotificationInformationText>
            {t("type")}:
          </InspectionNotificationInformationText>
          <InspectionNotificationInformationText isBold>
            {locationTypesValue?.val}
          </InspectionNotificationInformationText>
        </InspectionNotificationInformationWrapper>
        <InspectionNotificationInformationWrapper>
          <InspectionNotificationInformationText>
            {t("schedule")}:
          </InspectionNotificationInformationText>
          <InspectionNotificationInformationText isBold>
            {scheduleIDValue?.val}
          </InspectionNotificationInformationText>
        </InspectionNotificationInformationWrapper>
        <InspectionNotificationFormFieldWrapper>
          <InspectionNotificationScheduleWrapper>
            <InspectionNotificationFormLabel>
              {t("notification-schedule")}
            </InspectionNotificationFormLabel>
            <ErrorText>{errors?.notificationSchedule?.message}</ErrorText>
          </InspectionNotificationScheduleWrapper>
          <InspectionNotificationCheckboxFieldWrapper>
            <FormCardCheckbox
              checked={!!selectAllNotificationSchedule}
              onChange={onChangeSelectAllNotificationSchedule}
              id="inspection-notification-select-all-notification"
            />
            <InspectionNotificationCheckboxLabel>
              {t("all")}
            </InspectionNotificationCheckboxLabel>
          </InspectionNotificationCheckboxFieldWrapper>
          {NOTIFICATION_SCHEDULE_CONFIG.map(({ settingId, setting }) => (
            <InspectionNotificationCheckboxFieldWrapper key={settingId}>
              <FormCardCheckbox
                checked={
                  !!notificationSchedule?.find(
                    ({ settingID }) => settingID === settingId
                  )
                }
                onChange={onChangeNotificationSchedule(settingId)}
                id={`inspection-notification-${settingId}`}
              />
              <InspectionNotificationCheckboxLabel>
                {t(setting)}
              </InspectionNotificationCheckboxLabel>
            </InspectionNotificationCheckboxFieldWrapper>
          ))}
        </InspectionNotificationFormFieldWrapper>
        <InspectionNotificationFormFieldWrapper>
          <InspectionNotificationFormLabel>
            {t("distribution-list")}
          </InspectionNotificationFormLabel>
          <MultiEmail
            values={emails as string[]}
            onChange={handleChangeEmails}
            errorMessage={errors?.emailDistributionList?.message}
            id="inspection-notification-emails"
          />
        </InspectionNotificationFormFieldWrapper>
        <ActionsWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCloseInspectionNotificationForm}
            id="inspection-notification-cancel"
          />
          {inspectionNotificationData && (
            <Button
              text={t("delete")}
              isError
              onClick={handleDeleteInspectionNotification}
              disabled={
                !!isLoadingUpsertInspectionNotification ||
                !!isFetchingInspectionNotification
              }
              id="inspection-notification-delete"
            />
          )}
          <Button
            text={t("save")}
            onClick={handleCreateNotification}
            id="inspection-notification-form"
          />
        </ActionsWrapper>
      </InspectionNotificationFormContainer>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("success")]}
        severity="success"
      />
    </BlockUi>
  );
};
