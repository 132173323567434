import { Box, Typography, styled } from "@mui/material";

export const ListWrapper = styled(Box)(() => ({
  margin: "20px 0 20px 30px",
  "&  > ul > li > span > span": {
    fontWeight: 500,
  },
}));

export const ReportsTooltipText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
