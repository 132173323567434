import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const TextField = styled(MuiTextField)(({ theme }) => ({
  width: "100%",
  "& > div": {
    height: 32,
  },
  background: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
  borderRadius: 8,
  "input::-webkit-inner-spin-button, input::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: 32,
    marginTop: 2,
    fontFamily: "Roboto",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  input: {
    padding: "4px 8px",
    borderRadius: 8,
    color: theme.palette.secondary.main,
    fontFamily: "Roboto",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      borderColor: "#B1B1B1",
    },
    "&:hover fieldset": {
      borderColor: isDarkMode(theme.palette.mode)
        ? "rgba(37, 137, 92, 0.5);"
        : "rgba(37, 137, 92, 0.20)",
    },
    "&.Mui-focused fieldset": {
      borderColor: isDarkMode(theme.palette.mode)
        ? "rgba(32, 33, 36, 0.50)"
        : "#25895C",
    },
    "&.Mui-error fieldset": {
      borderColor: "#E35D2B",
    },
  },
}));
