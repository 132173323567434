import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import { generatedIdForSelect } from "src/shared/helpers/generatedId";
import { ReactComponent as SelectArrow } from "src/shared/assets/svgs/select-arrow.svg";
import { StyledSelect, SelectContainer, SelectFormHelperText } from "./styles";
import { IOption, ISelect } from "./types";

export const Select = ({
  options,
  value,
  onChange,
  disabled,
  register,
  errorMessage,
  multiple,
  id,
}: ISelect) => {
  const selectRef = useRef<HTMLSelectElement>();
  const { t } = useTranslation();

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: 4,
      },
    },
  };

  const onClose = () => {
    if (selectRef && selectRef.current) {
      selectRef?.current?.classList.remove("Mui-focused");
    }
  };

  const onOpen = () => {
    if (selectRef && selectRef.current) {
      selectRef?.current?.classList?.add("Mui-focused");
    }
  };

  const renderValue = multiple
    ? (selected: Array<IOption>) => {
        if (selected.length > 1) {
          return `${selected.length} ${t("options-selected")}`;
        }

        if (selected.length === 0) {
          return "";
        }

        const option = options.find(
          ({ id }: IOption) =>
            (id as string) === (selected[0] as unknown as string)
        );

        return option?.val;
      }
    : null;

  return (
    <SelectContainer>
      <StyledSelect
        ref={selectRef}
        value={options.length > 0 ? value : ""}
        defaultValue=""
        onChange={onChange}
        MenuProps={MenuProps}
        IconComponent={SelectArrow}
        onClose={onClose}
        onOpen={onOpen}
        disabled={disabled}
        error={!!errorMessage}
        multiple={multiple}
        renderValue={renderValue as unknown as (value: unknown) => ReactNode}
        id={generatedIdForSelect(id)}
        {...register}
      >
        {options?.map((option: IOption, index) => (
          <MenuItem key={`${option.id}-${index}`} value={option.id}>
            {option?.val}
          </MenuItem>
        ))}
      </StyledSelect>
      <SelectFormHelperText error={!!errorMessage}>
        {errorMessage}
      </SelectFormHelperText>
    </SelectContainer>
  );
};
