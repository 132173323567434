import { PaginationNumberStyled } from "./styles";
import { IPaginationNumber } from "./types";

export const PaginationNumber = ({
  onClick,
  number,
  isCurrentPage,
}: IPaginationNumber) => {
  return (
    <PaginationNumberStyled isCurrentPage={isCurrentPage} onClick={onClick}>
      {number}
    </PaginationNumberStyled>
  );
};
