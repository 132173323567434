import { Typography, styled } from "@mui/material";

export const SignatureText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  "& > span": {
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));
