import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Select } from "src/shared/components/select/select";
import { useGetInspectionTemplatesQuery } from "src/shared/store/api/inspection.api";
import { IManageInspectionFromTemplateDialog } from "./types";
import { getInspectionTemplatesOptions } from "./constants";
import {
  ManageInspectionFromTemplateDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const ManageInspectionFromTemplateDialog = ({
  open,
  handleClose,
}: IManageInspectionFromTemplateDialog) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [selectedInspectionTemplate, setSelectedInspectionTemplate] =
    useState("");

  const { data: inspectionTemplates } = useGetInspectionTemplatesQuery({});

  const handleNewManageInspectionFromTemplate = useCallback(() => {
    navigate(`/new-inspection-from-template/${selectedInspectionTemplate}`);
    setChangedData(false);
  }, [navigate, selectedInspectionTemplate, setChangedData]);

  const handleChangeSelectedInspectionTemplate = (
    e: SelectChangeEvent<unknown>
  ) => {
    setSelectedInspectionTemplate(e.target.value as string);
    setChangedData(true);
  };

  const handleCloseManageInspectionFromTemplateDialog = useCallback(() => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleClose();
      setChangedData(false);
    }
  }, [changedData, handleClose, setChangedData, setIsOpenUnsavedChangedDialog]);

  const manageInspectionFromTemplateDialogActions = () => {
    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCloseManageInspectionFromTemplateDialog}
          id="manage-inspection-cancel"
        />
        <Button
          text={t("create")}
          onClick={handleNewManageInspectionFromTemplate}
          disabled={!selectedInspectionTemplate}
          id="manage-inspection-create"
        />
      </SendBtnWrapper>
    );
  };

  const renderManageInspectionFromTemplateUserDialogFields = () => {
    return (
      <ManageInspectionFromTemplateDialogContentContainer>
        <Select
          value={selectedInspectionTemplate}
          options={getInspectionTemplatesOptions(inspectionTemplates)}
          onChange={handleChangeSelectedInspectionTemplate}
          id="manage-inspection-selected-inspection"
        />
      </ManageInspectionFromTemplateDialogContentContainer>
    );
  };

  const handleCloseWithClearData = useCallback(() => {
    handleClose();
    setSelectedInspectionTemplate("");
    setChangedData(false);
  }, [handleClose, setChangedData]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCloseWithClearData();
    });
  }, [handleCloseWithClearData, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() =>
      handleNewManageInspectionFromTemplate()
    );
  }, [handleNewManageInspectionFromTemplate, setHandleUpdateUnsavedChanged]);

  return (
    <Dialog
      open={open}
      handleClose={handleCloseManageInspectionFromTemplateDialog}
      title={t("select-template-to-review")}
      actions={manageInspectionFromTemplateDialogActions}
      renderFields={renderManageInspectionFromTemplateUserDialogFields}
    />
  );
};
