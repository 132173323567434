import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import {
  useGetBuildingByOrganizationByIDQuery,
  useLazyGeneratedPdfWithBarcodeByBuildingIDQuery,
} from "src/shared/store/api/organization.api";
import { useGetRoomsOrganizationsBySiteParentIDsQuery } from "src/shared/store/api/organization.api";
import { PrintQrCodesButton } from "src/components/printQrCodesButton/printQrCodesButton";
import { Button } from "src/shared/components/button/button";
import { BackTo } from "../../shared/components/backTo/backTo";
import { BuildingCard } from "../../components/buildingCard/buildingCard";
import { RoomsList } from "../../components/roomsList/roomsList";
import { getRoomItems } from "./constants";
import { IRoom } from "./types";
import {
  BuildingInformationBody,
  BuildingInformationContainer,
  BuildingInformationTitle,
  ButtonWrapper,
} from "./styles";

export const BuildingInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { buildingID } = useParams();

  const { data: buildingData, isLoading: isLoadingBuilding } =
    useGetBuildingByOrganizationByIDQuery(buildingID);

  const { data: roomsList, isLoading: isLoadingRooms } =
    useGetRoomsOrganizationsBySiteParentIDsQuery(buildingID);

  const [
    generatePdfWithBarcode,
    { isFetching: isFetchingGeneratePdfWithBarcode },
  ] = useLazyGeneratedPdfWithBarcodeByBuildingIDQuery({});

  const roomsItems = getRoomItems(roomsList);

  const backToSiteInformation = () => {
    if (buildingData?.parentId) {
      navigate(`/site-information/${buildingData?.parentId}`);
    }
  };

  const handlePrintQrCodes = () => {
    generatePdfWithBarcode(buildingID);
  };

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingBuilding || isLoadingRooms || isFetchingGeneratePdfWithBarcode
      }
      loader={<CircularProgress />}
      keepInView
    >
      <BuildingInformationContainer>
        <CssBaseline />
        <Header />
        <BuildingInformationBody>
          <BackTo
            text={t("back-to-building-list")}
            handleBack={backToSiteInformation}
          />
          <BuildingInformationTitle>
            {t("manage-building-information")}
          </BuildingInformationTitle>
          <BuildingCard />
          <PrintQrCodesButton handlePrintQrCodes={handlePrintQrCodes} />
          <RoomsList roomsItems={roomsItems as IRoom[]} />
          <ButtonWrapper>
            <Button
              text={t("back")}
              onClick={backToSiteInformation}
              isGrey
              id="building-information-back"
            />
          </ButtonWrapper>
        </BuildingInformationBody>
      </BuildingInformationContainer>
    </BlockUi>
  );
};
