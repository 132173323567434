import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const SiteAdminContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  paddingTop: 40,
}));

export const SiteAdminBody = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
    : "0px 10px 15px 4px #D9D9D9",
  padding: "44px 90px 22px 90px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: 16,
  },
}));

export const SiteAdminTitle = styled(Typography)(({ theme }) => ({
  marginTop: 32,
  color: theme.palette.text.secondary,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginTop: 24,
  },
}));

export const SiteAdminDescription = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  color: theme.palette.text.secondary,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginRight: 16,
  [theme.breakpoints.down("lg")]: {
    maxWidth: "none",
    marginRight: 0,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    marginTop: 8,
    marginRight: 0,
  },
}));

export const SiteAdminWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

export const SiteAdminCardsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  "& > div > button": {
    width: 210,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    " & > div": {
      width: "100%",
    },
  },
}));
