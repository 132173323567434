import { Box, Typography, styled } from "@mui/material";

export const ManageInspectionChangeStatusDialogContainer = styled(Typography)(
  ({ theme }) => ({
    textAlign: "center",
    width: "80vw",
    maxWidth: 650,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  })
);

export const ManageInspectionChangeStatusDialogActionsContainer = styled(Box)(
  () => ({
    display: "flex",
    gap: 16,
    "& > button": {
      width: "fit-content",
    },
  })
);
