import {
  Box,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 320,
    maxWidth: "none",
    borderRadius: 8,
    border: isDarkMode(theme.palette.mode)
      ? "1px solid #D9D9D9"
      : "1px solid #25895C",
    background: isDarkMode(theme.palette.mode) ? "#202124" : "#F5F5F5",
    boxShadow: isDarkMode(theme.palette.mode)
      ? "0px 4px 4px 0px rgb(0 0 0 / 15%)"
      : "0px 4px 10px 0px rgba(217, 217, 217, 0.20)",
  },
}));

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#25895C",
  padding: "7.5px 16px",
  borderRadius: "8px 8px 0px 0px",
  borderBottom: isDarkMode(theme.palette.mode)
    ? "1px solid #D9D9D9"
    : "1px solid #25895C",
  background: isDarkMode(theme.palette.mode)
    ? "#35363A"
    : "rgba(37, 137, 92, 0.15)",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "140%",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const CloseIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "& > svg": {
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "#25895C",
    },
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: "24px 16px !important",
}));
