import React from "react";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Exit from "../../shared/assets/svgs/exit-logo.svg";
import { Container, LogoutText } from "./styles";

export const LogOut = () => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <Container onClick={handleLogout}>
          <img src={Exit} alt="" />
          <LogoutText>{t("log-out")}</LogoutText>
        </Container>
      ) : null}
    </React.Fragment>
  );
};
