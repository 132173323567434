import { Typography, styled } from "@mui/material";
import { IPaginationNumberStyled } from "./types";

export const PaginationNumberStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isCurrentPage",
})<IPaginationNumberStyled>(({ theme, isCurrentPage }) => ({
  background: isCurrentPage ? "#25895C" : "none",
  color: isCurrentPage ? "#FFF" : "",
  borderRadius: 6,
  width: 30,
  height: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 24,
    height: 24,
    fontSize: 12,
  },
}));
