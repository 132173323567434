import { useCallback, useEffect, useMemo, useRef } from "react";
import { useScreenshot } from "use-react-screenshot";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import { useProcessUnhandledExceptionMutation } from "src/shared/store/api/exceptionHandler.api";
import { TABS_NAMES } from "./shared/store/reducers/menu/menu.slice";
import { DashboardPage } from "./pages/dashboard/dashboard";
import { useThemeContext } from "./shared/theme/themeContextProvider";
import { SignIn } from "./pages/signIn/signIn";
import { RedirectToSignIn } from "./pages/RedirectToSignIn/RedirectToSignIn";
import { WithAuth } from "./shared/components/withAuth/withAuth";
import { NewUser } from "./pages/newUser/newUser";
import { useAppActions, useAppSelector } from "./shared/hooks/redux/redux";
import { SCOPES } from "./shared/store/api/constants";
import { Alert } from "./shared/components/alert/alert";
import { EditUser } from "./pages/editUser/editUser";
import { UserListPage } from "./pages/userList/userList";
import { SingleManifest } from "./pages/singleManifest/singleManifest";
import { UnsavedChangesDialog } from "./shared/components/unsavedChangesDialog/unsavedChangesDialog";
import { SingleProfile } from "./pages/singleProfile/singleProfile";
import { HistoryProvider } from "./shared/hooks/history/historyProvider";
import { HistoryContainer } from "./shared/hooks/history/historyContainer";
import { NewProfile } from "./pages/newProfile/newProfile";
import { BuildingInformation } from "./pages/buildingInformation/buildingInformation";
import { RoomInformation } from "./pages/roomInformation/roomInformation";
import { AccountInformation } from "./pages/accountInformation/accountInformation";
import { SiteInformation } from "./pages/siteInformation/siteInfromation";
import { EditInventoryAging } from "./pages/editInventoryAging/editInventoryAging";
import { EditLocation } from "./components/editLocation/editLocation";
import { NewInspection } from "./pages/newInspection/newInspection";
import { ManageInspections } from "./pages/manageInspections/manageInspections";
import { NewManageInspection } from "./pages/newManageInspection/newManageInspection";
import { Reports } from "./pages/reports/reports";
import { ManifestNotifications } from "./pages/manifestNotifications/manifestNotifications";
import { InventoryNotifications } from "./pages/inventoryNotifications/inventoryNotifications";
import { ManageScheduledReports } from "./pages/manageScheduledReports/manageScheduledReports";
import { SetSchedule } from "./pages/setSchedule/setSchedule";
import { Unsubscribe } from "./pages/unsubscribe/unsubscribe";
import { Support } from "./pages/support/support";
import { FAQ } from "./pages/faq/faq";
import { InspectionBarcode } from "./pages/inspectionBarcode/inspectionBarcode";
import { InspectionsNotifications } from "./pages/inspectionsNotifications/inspectionsNotifications";
import { InspectionNotification } from "./pages/inspectionNotification/inspectionNotification";
import { SiteAdmin } from "./pages/siteAdmin/siteAdmin";
import { NewSystemMessage } from "./pages/newSystemMessage/newSystemMessage";
import "react-block-ui/style.css";
import "react-medium-image-zoom/dist/styles.css";
import "react-multi-email/dist/style.css";

export const App = () => {
  const { theme } = useThemeContext();
  const { setToken } = useAppActions();
  const { accounts, instance } = useMsal();
  const ref = useRef(null);
  const account = instance.getAllAccounts()[0];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [image, takeScreenshot] = useScreenshot();
  const openSnackBar = useAppSelector((state) => state.snackBarReducer.open);
  const snackBarMessages = useAppSelector(
    (state) => state.snackBarReducer.messages
  );
  const { body } = useAppSelector((state) => state.errorHandlingReducer);

  const [processUnhandledException] = useProcessUnhandledExceptionMutation({});

  const { setIsOpenSnackBar } = useAppActions();

  const handleCloseSnackBar = () => {
    setIsOpenSnackBar(false);
  };

  const accessTokenRequest = useMemo(
    () => ({
      scopes: SCOPES,
      account: account,
    }),
    [account]
  );

  useEffect(() => {
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        setToken(accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accessTokenRequest, instance, setToken]);

  const requestUnhandledException = useCallback(
    async (body: string, img: string) => {
      const formData = new FormData();
      const parsedBody = JSON.parse(body);
      const fileRes = await fetch(img);
      const blob = await fileRes.blob();
      const fileName = "screenShot.jpg";

      const file = new File([blob], fileName, {
        type: blob.type,
      });

      const exceptionHandlingString = {
        result: {
          ...parsedBody?.result,
          userEmail: accounts[0]?.username,
        },
        args: {
          ...parsedBody?.args,
          body: parsedBody?.args?.body
            ? JSON.stringify(parsedBody?.args?.body)
            : undefined,
        },
      };

      formData.append("files", file);
      formData.append(
        "exceptionHandlingString",
        JSON.stringify(exceptionHandlingString)
      );
      processUnhandledException(formData);
    },
    [accounts, processUnhandledException]
  );

  const getScreenShot = useCallback(
    async (body: string) => {
      const img = await takeScreenshot(ref.current);
      requestUnhandledException(body, img);
    },
    [requestUnhandledException, takeScreenshot]
  );

  useEffect(() => {
    if (body) {
      const newBody = body;
      getScreenShot(newBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<HistoryContainer />}>
          <Route path="/" element={<RedirectToSignIn />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route
            path="/unsubscribe/:subscriptionID"
            element={<Unsubscribe />}
          />
          <Route path="/new-user/:id/:verificationCode" element={<NewUser />} />
          <Route
            path="/edit-user/:id"
            element={
              <WithAuth>
                <EditUser />
              </WithAuth>
            }
          />
          <Route
            path="/manifest/:manifestID"
            element={
              <WithAuth>
                <SingleManifest />
              </WithAuth>
            }
          />
          <Route
            path="/dashboard/:tabId"
            element={
              <WithAuth>
                <DashboardPage />
              </WithAuth>
            }
          />
          <Route
            path="/user-list/:organizationUnitID"
            element={
              <WithAuth>
                <UserListPage />
              </WithAuth>
            }
          />
          <Route
            path="/user-list"
            element={
              <WithAuth>
                <UserListPage />
              </WithAuth>
            }
          />
          <Route
            path="/profile/:profileID"
            element={
              <WithAuth>
                <SingleProfile />
              </WithAuth>
            }
          />
          <Route
            path="/new-profile/:profileID"
            element={
              <WithAuth>
                <NewProfile />
              </WithAuth>
            }
          />
          <Route
            path="/account-information/:organizationUnitID"
            element={
              <WithAuth>
                <AccountInformation />
              </WithAuth>
            }
          />
          <Route
            path="/site-information/:siteUnitID"
            element={
              <WithAuth>
                <SiteInformation />
              </WithAuth>
            }
          />
          <Route
            path="/building-information/:buildingID"
            element={
              <WithAuth>
                <BuildingInformation />
              </WithAuth>
            }
          />
          <Route
            path="/room-information/:roomID"
            element={
              <WithAuth>
                <RoomInformation />
              </WithAuth>
            }
          />
          <Route
            path="/edit-inventory-aging/:agingID"
            element={
              <WithAuth>
                <EditInventoryAging />
              </WithAuth>
            }
          />
          <Route
            path="/edit-location/:locationID/:docType"
            element={
              <WithAuth>
                <EditLocation isEdit />
              </WithAuth>
            }
          />
          <Route
            path="/add-location/:roomID"
            element={
              <WithAuth>
                <EditLocation />
              </WithAuth>
            }
          />
          <Route
            path="/new-inspection"
            element={
              <WithAuth>
                <NewInspection />
              </WithAuth>
            }
          />
          <Route
            path="/manage-inspections"
            element={
              <WithAuth>
                <ManageInspections />
              </WithAuth>
            }
          />
          <Route
            path="/new-manage-inspection/:manageInspectionID"
            element={
              <WithAuth>
                <NewManageInspection />
              </WithAuth>
            }
          />
          <Route
            path="/new-inspection-from-template/:manageInspectionID"
            element={
              <WithAuth>
                <NewManageInspection isTemplate isEdit />
              </WithAuth>
            }
          />
          <Route
            path="/manage-inspection/:manageInspectionID"
            element={
              <WithAuth>
                <NewManageInspection isEdit />
              </WithAuth>
            }
          />
          <Route
            path="/waste-reports"
            element={
              <WithAuth>
                <Reports tab={TABS_NAMES[2]} />
              </WithAuth>
            }
          />
          <Route
            path="/waste-notifications"
            element={
              <WithAuth>
                <ManifestNotifications />
              </WithAuth>
            }
          />
          <Route
            path="/inventory-notifications"
            element={
              <WithAuth>
                <InventoryNotifications />
              </WithAuth>
            }
          />
          <Route
            path="/inspection-reports"
            element={
              <WithAuth>
                <Reports tab={TABS_NAMES[3]} />
              </WithAuth>
            }
          />
          <Route
            path="/profile-reports"
            element={
              <WithAuth>
                <Reports tab={TABS_NAMES[4]} />
              </WithAuth>
            }
          />
          <Route
            path="/inventory-reports"
            element={
              <WithAuth>
                <Reports tab={TABS_NAMES[5]} />
              </WithAuth>
            }
          />
          <Route
            path="/manage-scheduled-reports"
            element={
              <WithAuth>
                <ManageScheduledReports />
              </WithAuth>
            }
          />
          <Route
            path="/set-schedule/:reportType/:accountIDs"
            element={
              <WithAuth>
                <SetSchedule />
              </WithAuth>
            }
          />
          <Route
            path="/edit-schedule/:subscriptionID"
            element={
              <WithAuth>
                <SetSchedule isEdit />
              </WithAuth>
            }
          />
          <Route
            path="/support"
            element={
              <WithAuth>
                <Support />
              </WithAuth>
            }
          />
          <Route
            path="/faq"
            element={
              <WithAuth>
                <FAQ />
              </WithAuth>
            }
          />
          <Route
            path="/inspection-barcode/:accountID"
            element={
              <WithAuth>
                <InspectionBarcode />
              </WithAuth>
            }
          />
          <Route
            path="/new-inspection/:accountID/:barcode"
            element={
              <WithAuth>
                <NewInspection />
              </WithAuth>
            }
          />
          <Route
            path="/inspections-notifications"
            element={
              <WithAuth>
                <InspectionsNotifications />
              </WithAuth>
            }
          />
          <Route
            path="/inspection-notification/:inspectionID"
            element={
              <WithAuth>
                <InspectionNotification />
              </WithAuth>
            }
          />
          <Route
            path="/site-admin"
            element={
              <WithAuth>
                <SiteAdmin />
              </WithAuth>
            }
          />
          <Route
            path="/new-system-message"
            element={
              <WithAuth>
                <NewSystemMessage />
              </WithAuth>
            }
          />
          <Route
            path="/edit-system-message/:bannerId"
            element={
              <WithAuth>
                <NewSystemMessage />
              </WithAuth>
            }
          />
        </Route>
      </>
    )
  );

  return (
    <div ref={ref}>
      <ThemeProvider theme={theme}>
        <HistoryProvider>
          <RouterProvider router={router} />
        </HistoryProvider>
        <Alert
          open={openSnackBar}
          handleClose={handleCloseSnackBar}
          messages={snackBarMessages}
          severity="error"
        />
        <UnsavedChangesDialog />
      </ThemeProvider>
    </div>
  );
};
