import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { useGetUsersAllOrByOrganizationUnitIDQuery } from "src/shared/store/api/userProfile.api";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { DeleteUserDialog } from "src/components/deleteUserDialog/deleteUserDialog";
import { IUser } from "src/shared/helpers/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { IColumn } from "./types";
import {
  USERS_STORAGE_KEYS,
  USERS_GRID_NAME,
  getColumnDefs,
  defaultColDef,
  getContextMenuItems,
  paginationPageSize,
  rowGroupPanelShow,
  rowSelection,
} from "./constants";
import { Body, Container } from "./styles";

export const UserListPage = () => {
  const [openDeleteUserDialog, setOpenDeleteUserDialog] =
    useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<IUser>();
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const { scroll: scrollToTop } = useAppSelector(
    (state) => state.userFiltersReducer
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setChangedData,
    setUserFilterScroll,
    setUserFiltersSelectedRow,
    setAdminMenuTab,
  } = useAppActions();
  const { organizationUnitID } = useParams();
  const { mode } = useThemeContext();
  const { history } = useHistory();
  const {
    data: usersProfileData,
    isLoading: isLoadingUsersProfileData,
    isFetching: isFetchingUsersProfileData,
  } = useGetUsersAllOrByOrganizationUnitIDQuery({
    organizationUnitID,
  });
  const previousLocation = history?.[history.length - 2] as string;
  const previousEditUserView =
    previousLocation && previousLocation?.includes("/edit-user");
  const columnDefs = getColumnDefs(t);

  const handleClickRow = (id: string) => {
    navigate(`/edit-user/${id}`);
  };

  const handleOpenDeleteUserDialog = () => {
    setOpenDeleteUserDialog(true);
  };

  const handleCloseDeleteUserDialog = () => {
    setOpenDeleteUserDialog(false);
  };

  const onCellClicked = (params: CellClickedEvent) => {
    const target = params?.event?.target as HTMLButtonElement;
    const column = params.column as unknown as IColumn;

    if (column?.colId === "action" && target?.dataset?.action) {
      const action = target?.dataset?.action;

      if (action === "delete" && params?.data?.id) {
        setDeleteUser(params.data);
        handleOpenDeleteUserDialog();
      }
    } else {
      setUserFilterScroll(window.scrollY);
      setUserFiltersSelectedRow(params?.data);
      if (params?.data?.id === selectedUser?.id) {
        handleClickRow(params.data.id);
      }
    }
  };

  useEffect(() => {
    setChangedData(false);
  }, [location, setChangedData]);

  const contextMenuItems = getContextMenuItems(t, mode === DARK);

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedUser(selectedRows[0]);
  }, []);

  useEffect(() => {
    if (previousEditUserView) {
      saveToLocalStorage(
        USERS_STORAGE_KEYS.SELECTED_USERS,
        getFromLocalStorage(USERS_STORAGE_KEYS.SELECTED_USERS_ROW)
      );
      if (scrollToTop) {
        window.scrollTo(0, scrollToTop);
      }
    } else {
      saveToLocalStorage(USERS_STORAGE_KEYS.SELECTED_USERS, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(USERS_STORAGE_KEYS.SELECTED_USERS, null);
      saveToLocalStorage(USERS_STORAGE_KEYS.SELECTED_USERS_ROW, null);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setAdminMenuTab(0);

    return () => {
      setAdminMenuTab(undefined);
    };
  }, [setAdminMenuTab]);

  return (
    <Container>
      <CssBaseline />
      <Header />
      <Body>
        <AgGrid
          title={t("list-of-users")}
          rowGroupPanelShow={rowGroupPanelShow}
          rowData={usersProfileData}
          gridName={USERS_GRID_NAME}
          onCellClicked={onCellClicked}
          columnDefs={columnDefs}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
          getContextMenuItems={contextMenuItems}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          withSavingPage={!!previousEditUserView}
          withSavingSearch={!!previousEditUserView}
          isLoadingData={
            isLoadingUsersProfileData || isFetchingUsersProfileData
          }
        />
        <DeleteUserDialog
          user={deleteUser}
          open={openDeleteUserDialog}
          handleClose={handleCloseDeleteUserDialog}
        />
      </Body>
    </Container>
  );
};
