import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Active } from "src/shared/assets/svgs/green-tick-circle.svg";
import { ReactComponent as NotActive } from "src/shared/assets/svgs/close-red-icon.svg";
import { ReactComponent as Arrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import { IRoom } from "../../../pages/buildingInformation/types";
import { IRoomsBody } from "./types";
import {
  ArrowWrapper,
  Divider,
  FieldInformationWrapper,
  FieldWrapper,
  IconWrapperWithArrow,
  RoomItemContainer,
} from "./styles";

export const RoomsBody = ({ roomsItems }: IRoomsBody) => {
  const navigate = useNavigate();

  const handleClickRoom = (id: string) => () => {
    navigate(`/room-information/${id}`);
  };

  return (
    <React.Fragment>
      {roomsItems?.map(({ name, statusID, id }: IRoom, index: number) => (
        <React.Fragment key={id}>
          <RoomItemContainer key={id} onClick={handleClickRoom(id)}>
            <FieldWrapper>
              <FieldInformationWrapper>
                <FieldName>{`${index + 1}.`}</FieldName>
                <FieldValue>{name}</FieldValue>
              </FieldInformationWrapper>
              <IconWrapperWithArrow>
                {statusID === STATUS_ID_VALUES.ACTIVE ? (
                  <Active />
                ) : (
                  <NotActive />
                )}
                <ArrowWrapper>
                  <Arrow />
                </ArrowWrapper>
              </IconWrapperWithArrow>
            </FieldWrapper>
          </RoomItemContainer>
          {index !== roomsItems?.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
