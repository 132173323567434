import { Box, styled } from "@mui/material";

export const SiteCardContainer = styled(Box)(({ theme }) => ({
  marginBottom: 24,
  [theme.breakpoints.down("md")]: {
    marginBottom: 16,
  },
}));

export const IconWrapperWithArrow = styled(Box)(() => ({
  display: "flex",
  "& > svg": {
    width: 20,
    height: 20,
  },
}));

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  justifyContent: "space-between",
}));

export const FieldInformationWrapper = styled(Box)(() => ({
  display: "flex",
}));

export const ArrowWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 20,
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    marginLeft: 10,
  },
}));
