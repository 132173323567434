import React from "react";
import { Alert as MuiAlert, Snackbar } from "@mui/material";
import { IAlert } from "./types";

export const Alert = ({ open, handleClose, messages, severity }: IAlert) => {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={5000}
    >
      <MuiAlert onClose={handleClose} severity={severity}>
        {messages?.map((message, index) => (
          <React.Fragment key={index}>
            {message}
            <br />
          </React.Fragment>
        ))}
      </MuiAlert>
    </Snackbar>
  );
};
