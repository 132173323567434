import { generatedIdForInput } from "src/shared/helpers/generatedId";
import { Input } from "./styles";
import { IInputField } from "./types";

export const InputField = ({
  placeholder,
  errorMessage,
  register,
  disabled,
  id,
}: IInputField) => {
  return (
    <Input
      variant="outlined"
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      placeholder={placeholder}
      disabled={disabled}
      autoComplete="off"
      id={generatedIdForInput(id)}
      {...register}
    />
  );
};
