import { createSlice } from "@reduxjs/toolkit";
import { IUnsavedChangesState } from "./unsavedChanges.types";

const initialUnsavedChangesState: IUnsavedChangesState = {
  changedData: false,
  isOpenUnsavedChangedDialog: false,
  handleBackUnsavedChanged: undefined,
  handleUpdateUnsavedChanged: undefined,
};

export const unsavedChangesSlice = createSlice({
  name: "unsavedChanges",
  initialState: initialUnsavedChangesState,
  reducers: {
    setChangedData: (state, action) => {
      state.changedData = action.payload;
    },
    setIsOpenUnsavedChangedDialog: (state, action) => {
      state.isOpenUnsavedChangedDialog = action.payload;
    },
    setHandleBackUnsavedChanged: (state, action) => {
      state.handleBackUnsavedChanged = action.payload;
    },
    setHandleUpdateUnsavedChanged: (state, action) => {
      state.handleUpdateUnsavedChanged = action.payload;
    },
  },
});

export const {
  reducer: unsavedChangesReducer,
  actions: unsavedChangesActions,
} = unsavedChangesSlice;
