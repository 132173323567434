import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { IReportItem } from "./types";

export const REPORT_TYPE = {
  MANIFEST: "manifest",
  INSPECTION: "inspection",
  PROFILE: "profile",
  INVENTORY: "inventory",
};

export const getReportItemsOptions = (reportItems: IReportItem[]) => {
  return reportItems?.length > 0
    ? reportItems.map(({ reportName, reportPath }: IReportItem) => ({
        id: reportPath,
        val: reportName,
      }))
    : [];
};

export const isEqualsAccountsSelected = (a: string[], b: string[]) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a?.length === b?.length &&
    a?.every((val, index) => val === b?.[index])
  );
};

export const getReportTypeByTab = (tab: string) => {
  switch (tab) {
    case TABS_NAMES[2]:
      return REPORT_TYPE.MANIFEST;

    case TABS_NAMES[3]:
      return REPORT_TYPE.INSPECTION;

    case TABS_NAMES[4]:
      return REPORT_TYPE.PROFILE;

    case TABS_NAMES[5]:
      return REPORT_TYPE.INVENTORY;
  }
};
