import * as yup from "yup";
import { IReportParameter } from "./types";
import { DATE_OPTION_CUSTOM, PARAMETER_TYPE, SCHEDULE_TYPE } from "./constants";
import dayjs from "dayjs";

export const ScheduledReportConfigurationValidationSchema = (
  t: (value: string) => string,
  reportParameters: IReportParameter[]
) => {
  let paramsObject = {};

  reportParameters?.forEach(
    ({ name, parameterTypeName, nullable }: IReportParameter) => {
      paramsObject = {
        ...paramsObject,
        [name as string]:
          parameterTypeName === PARAMETER_TYPE.DATE_TIME
            ? yup.date().when(["dateOption"], (reportParams, schema) => {
                const dateOption = reportParams[0];

                return dateOption === DATE_OPTION_CUSTOM
                  ? nullable
                    ? schema.nullable()
                    : schema.required(t("required-field"))
                  : schema.nullable();
              })
            : parameterTypeName === PARAMETER_TYPE.BOOLEAN
            ? yup.boolean()
            : nullable
            ? yup.string().nullable()
            : yup.string(),
      };
    }
  );

  return yup.object().shape({
    description: yup.string().required(t("required-field")),
    type: yup.string().required(t("required-field")),
    dayType: yup.string().required(t("required-field")),
    startDateTime: yup.string().required(t("required-field")),
    repeatAfterWeeks: yup
      .number()
      .nullable()
      .typeError(t("must-be-a-number"))
      .when(["type"], (values, schema) => {
        const typeValue = values?.[0];

        return typeValue === SCHEDULE_TYPE.WEEK
          ? schema.integer().min(1).required(t("required-field"))
          : yup
              .number()
              .transform((_, val) => (val !== "" ? Number(val) : null))
              .nullable();
      }),
    onWeekOfMonth: yup.string().when(["type"], (values, schema) => {
      const typeValue = values?.[0];
      return typeValue === SCHEDULE_TYPE.MONTH
        ? schema.required(t("required-field"))
        : schema;
    }),
    daysOfWeek: yup
      .boolean()
      .when(
        [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ],
        (weeksOfDays, schema) => {
          return weeksOfDays?.every((day) => !day)
            ? schema.required(t("please-select-weekday")).nullable()
            : schema;
        }
      ),
    sunday: yup.boolean(),
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    startDate: yup
      .date()
      .nullable()
      .typeError(t("required-field"))
      .required(t("required-field")),
    endDate: yup
      .date()
      .nullable()
      .when(["startDate"], (date, schema) => {
        const startDate = date?.[0];

        return !isNaN(startDate?.getTime())
          ? schema.min(
              dayjs(startDate).subtract(1, "day"),
              t("end-date-before-start-date")
            )
          : schema;
      }),
    emailDistributionList: yup
      .array()
      .of(yup.string())
      .required(t("required-field")),
    body: yup.string().required(t("required-field")),
    subject: yup.string().required(t("required-field")),
    renderFormat: yup.string().required(t("required-field")),
    reportParams: yup.object().shape(paramsObject),
  });
};
