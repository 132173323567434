import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { Header } from "src/components/header/header";
import NewSystemMessage from "src/shared/assets/svgs/new-system-message.svg";
import { AccountCard } from "src/components/accountCard/accountCard";
import { SystemMessagesTable } from "src/components/systemMessagesTable/systemMessagesTable";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import {
  SiteAdminContainer,
  SiteAdminBody,
  SiteAdminTitle,
  SiteAdminWrapper,
  SiteAdminDescription,
  SiteAdminCardsWrapper,
} from "./styles";

export const SiteAdmin = () => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { setAdminMenuTab } = useAppActions();
  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const hasUserAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ADMIN
  );

  const handleNewSystemMessage = () => {
    navigate("/new-system-message");
  };

  useEffect(() => {
    setAdminMenuTab(hasUserAdminPermission ? 1 : 0);

    return () => {
      setAdminMenuTab(undefined);
    };
  }, [setAdminMenuTab, hasUserAdminPermission]);

  return (
    <SiteAdminContainer>
      <CssBaseline />
      <Header />
      <SiteAdminBody>
        <SiteAdminTitle>{t("system-messages")}</SiteAdminTitle>
        <SiteAdminWrapper>
          <SiteAdminDescription>
            {t("system-messages-description")}
          </SiteAdminDescription>
          <SiteAdminCardsWrapper>
            <AccountCard
              title={t("system-messages")}
              buttonText={t("new")}
              img={NewSystemMessage}
              onClick={handleNewSystemMessage}
              id="site-admin-system-messages-new"
            />
          </SiteAdminCardsWrapper>
        </SiteAdminWrapper>
        <SystemMessagesTable />
      </SiteAdminBody>
    </SiteAdminContainer>
  );
};
