import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/arrow-down.svg";
import {
  NextArrowWrapper,
  PaginationButtonStyled,
  PrevArrowWrapper,
} from "./styled";
import { IPaginationButton } from "./types";

export const PaginationButton = ({
  isNext,
  disabled,
  onClick,
}: IPaginationButton) => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PaginationButtonStyled disabled={disabled} onClick={onClick}>
      {isNext && !isMobileView ? (
        t("next")
      ) : isNext ? (
        <NextArrowWrapper>
          <ArrowDown />
        </NextArrowWrapper>
      ) : !isMobileView ? (
        t("prev")
      ) : (
        <PrevArrowWrapper>
          <ArrowDown />
        </PrevArrowWrapper>
      )}
    </PaginationButtonStyled>
  );
};
