import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const UNSUBSCRIBE_SUB_URL = "Unsubscribe";

export const subscription = api.injectEndpoints({
  endpoints: (builder) => ({
    unsubscribeFromSubscription: builder.mutation({
      query: (body) => ({
        url: `${UNSUBSCRIBE_SUB_URL}/UnsubscribeFromSubscription`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getSubscriptionNameByID: builder.query({
      query: (subscriptionID) => ({
        url: `${UNSUBSCRIBE_SUB_URL}/GetSubscriptionNameByID/${subscriptionID}`,
        method: HTTP_METHOD.GET,
        responseHandler: "text",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSubscriptionNameByIDQuery,
  useUnsubscribeFromSubscriptionMutation,
} = subscription;
