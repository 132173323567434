import { useTranslation } from "react-i18next";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { Dialog } from "src/shared/components/dialog/dialog";
import { ReactComponent as Success } from "src/shared/assets/svgs/success.svg";
import { ISubmitProfileDialog } from "./types";
import {
  CongratulationsText,
  DialogBody,
  DialogBodyWrapper,
  DialogHeader,
  SubmitProfileDialogContentContainer,
  SuccessWrapper,
  DoneButtonWrapper,
} from "./styles";

export const SubmitProfileDialog = ({
  open,
  handleClose,
}: ISubmitProfileDialog) => {
  const { t } = useTranslation();

  const submitProfileDialogActions = () => {
    return (
      <DoneButtonWrapper>
        <FilledButton
          text={t("done")}
          onClick={handleClose}
          id="submit-profile-done"
        />
      </DoneButtonWrapper>
    );
  };

  const renderSubmitProfileDialogFields = () => {
    return (
      <SubmitProfileDialogContentContainer>
        <CongratulationsText>{t("congratulations")}</CongratulationsText>
        <SuccessWrapper>
          <Success />
        </SuccessWrapper>
        <DialogHeader>{t("your-request-has-been-submitted")}</DialogHeader>
        <DialogBodyWrapper>
          <DialogBody>{t("your-request-was-sent")}</DialogBody>
          <DialogBody>{t("the-tsr-will-contact")}</DialogBody>
        </DialogBodyWrapper>
      </SubmitProfileDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      actions={submitProfileDialogActions}
      renderFields={renderSubmitProfileDialogFields}
    />
  );
};
