import { useTranslation } from "react-i18next";
import { IInventoryInformation } from "./types";
import { getInventoryInformationConfig } from "./constants";
import {
  InventoryDescription,
  InventoryInformationContainer,
  InventoryInformationTitle,
  InventoryWrapper,
} from "./styles";

export const InventoryInformation = ({ inventory }: IInventoryInformation) => {
  const { t } = useTranslation();

  const inventoryInformationConfig = getInventoryInformationConfig(
    inventory,
    t
  );

  return (
    <InventoryInformationContainer>
      <InventoryInformationTitle>
        {t("inventory-information")}
      </InventoryInformationTitle>
      {inventoryInformationConfig?.map(({ title, value }, index) => (
        <InventoryWrapper key={`${index}-${title}`}>
          <InventoryDescription>{title}</InventoryDescription>
          <InventoryDescription>{value}</InventoryDescription>
        </InventoryWrapper>
      ))}
    </InventoryInformationContainer>
  );
};
