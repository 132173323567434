import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { SwitchColorModeButton } from "./styles";

export const SwitchColorMode = () => {
  const { mode, toggleColorMode } = useThemeContext();
  const checked = mode === DARK ? true : false;

  return <SwitchColorModeButton checked={checked} onChange={toggleColorMode} />;
};
