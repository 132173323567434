import { Autocomplete, InputAdornment, TextField, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    minHeight: 24,
    borderRadius: 8,
    background: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
    color: theme.palette.secondary.main,
    fontFamily: "Roboto",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "0 8px",
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B1B1B1",
      },
      "&:hover fieldset": {
        borderColor: isDarkMode(theme.palette.mode)
          ? "rgba(37, 137, 92, 0.5);"
          : "rgba(37, 137, 92, 0.20)",
      },
      "&.Mui-focused fieldset": {
        borderColor: isDarkMode(theme.palette.mode)
          ? "rgba(32, 33, 36, 0.50)"
          : "#25895C",
      },
      "&.Mui-error fieldset": {
        borderColor: "#E35D2B",
      },
    },
  },
  "& .MuiAutocomplete-input": {
    padding: "0 !important",
  },
  "& .MuiAutocomplete-popper": {
    color: theme.palette.secondary.main,
  },
}));

export const Input = styled(TextField)(() => ({
  "& .MuiFormHelperText-root": {
    position: "absolute",
    top: 24,
  },
}));

export const IconWrapper = styled(InputAdornment)(({ theme }) => ({
  cursor: "pointer",
  width: 20,
  height: 20,
  minWidth: 20,
  marginLeft: 0,
  [theme.breakpoints.down("lg")]: {
    width: 16,
    height: 16,
    minWidth: 16,
  },
  "& > svg": {
    cursor: "pointer",
    width: 20,
    height: 20,
    [theme.breakpoints.down("lg")]: {
      width: 16,
      height: 16,
    },
    "& > path": {
      width: "inherit",
      height: "inherit",
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "#B1B1B1",
    },
  },
}));
