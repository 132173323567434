import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ErrorNewUserContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 368,
  alignItems: "center",
}));

export const Error = styled(Typography)(({ theme }) => ({
  color: "#E35D2B",
  fontSize: 24,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "capitalize",
  marginTop: 40,
  [theme.breakpoints.down("md")]: {
    fontSize: 18,
    marginTop: 24,
  },
}));

export const ErrorLogoWrapper = styled(Box)(({ theme }) => ({
  "& > svg": {
    width: 60,
    height: 60,
    marginTop: 24,
    "& > path": {
      fill: "#E35D2B",
    },
    [theme.breakpoints.down("md")]: {
      width: 40,
      height: 40,
    },
  },
}));

export const ErrorNewUserText = styled(Typography)(({ theme }) => ({
  marginTop: 24,
  textAlign: "center",
  color: "#282828",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  [theme.breakpoints.down("md")]: {
    marginTop: 32,
  },
}));
