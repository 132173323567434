import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const INSPECTION_SUB_URL = "Inspection";

export const inspection = api.injectEndpoints({
  endpoints: (builder) => ({
    inspectionResultSearch: builder.query({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/InspectionResultSearch`,
        method: HTTP_METHOD.POST,
        body,
      }),
      providesTags: ["UpdateInspection"],
    }),
    getInspectionResultByID: builder.query({
      query: (id) => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionResultByID/${id}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateInspection"],
    }),
    getFollowUpOptions: builder.query({
      query: () => ({
        url: `${INSPECTION_SUB_URL}/getFollowUpOptions`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInspectionTemplatesByAccount: builder.query({
      query: (accountOrganizationUnitID) => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionTemplatesByAccount/${accountOrganizationUnitID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    updateInspectionFollowUp: builder.mutation({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/UpdateInspectionFollowUp`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateInspection"],
    }),
    getInspectionWithQuestionsTemplateByID: builder.query({
      query: (inspectionID) => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionWithQuestionsTemplateByID/${inspectionID}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateInspection"],
    }),
    getInspectionCommentsByAccount: builder.query({
      query: (accountOrganizationUnitID) => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionCommentsByAccount/${accountOrganizationUnitID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    upsertInspectionResult: builder.mutation({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/UpsertInspectionResult`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getAllInspectionTemplatesByUserAccount: builder.query({
      query: ({ statusId }) => ({
        url: `${INSPECTION_SUB_URL}/GetAllInspectionTemplatesByUserAccount${
          statusId ? `/${statusId}` : ""
        }`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateInspection", "InspectionNotification"],
    }),
    newInspectionTemplate: builder.query({
      query: () => ({
        url: `${INSPECTION_SUB_URL}/NewInspectionTemplate`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getScheduleOptions: builder.query({
      query: () => ({
        url: `${INSPECTION_SUB_URL}/GetScheduleOptions`,
        method: HTTP_METHOD.GET,
      }),
    }),
    upsertInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/UpsertInspectionTemplate`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateInspection"],
    }),
    uploadInspectionBlob: builder.mutation({
      query: ({ inspectionResultID, body }) => ({
        url: `${INSPECTION_SUB_URL}/UploadInspectionBlob/${inspectionResultID}`,
        method: HTTP_METHOD.POST,
        formData: true,
        body,
      }),
    }),
    updateInspectionStatus: builder.mutation({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/UpdateInspectionStatus`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateInspection"],
    }),
    deleteInspectionResult: builder.mutation({
      query: (inspectionResultID) => ({
        url: `${INSPECTION_SUB_URL}/DeleteInspectionResult/${inspectionResultID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["UpdateInspection"],
    }),
    upsertInspectionResultBulk: builder.mutation({
      query: (body) => ({
        url: `${INSPECTION_SUB_URL}/UpsertInspectionResultBulk`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getInspectionTemplatesForBulkByAccount: builder.query({
      query: (accountOrganizationUnitID) => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionTemplatesForBulkByAccount/${accountOrganizationUnitID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInspectionTemplates: builder.query({
      query: () => ({
        url: `${INSPECTION_SUB_URL}/GetInspectionTemplates`,
        method: HTTP_METHOD.GET,
      }),
    }),
    deleteInspectionTemplate: builder.mutation({
      query: (inspectionID) => ({
        url: `${INSPECTION_SUB_URL}/DeleteInspectionTemplate/${inspectionID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["UpdateInspection"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteInspectionTemplateMutation,
  useGetInspectionTemplatesQuery,
  useUpsertInspectionResultBulkMutation,
  useGetInspectionTemplatesForBulkByAccountQuery,
  useDeleteInspectionResultMutation,
  useUpdateInspectionStatusMutation,
  useUploadInspectionBlobMutation,
  useUpsertInspectionTemplateMutation,
  useGetScheduleOptionsQuery,
  useLazyNewInspectionTemplateQuery,
  useGetAllInspectionTemplatesByUserAccountQuery,
  useUpsertInspectionResultMutation,
  useGetInspectionCommentsByAccountQuery,
  useGetInspectionWithQuestionsTemplateByIDQuery,
  useLazyInspectionResultSearchQuery,
  useGetInspectionResultByIDQuery,
  useGetFollowUpOptionsQuery,
  useUpdateInspectionFollowUpMutation,
  useGetInspectionTemplatesByAccountQuery,
} = inspection;
