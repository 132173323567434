import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export class CustomLoadingOverlay implements ICellRendererComp {
  eGui!: HTMLElement;

  init(params: ICellRendererParams & { loadingMessage: string }) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = ``;
  }

  getGui() {
    return this.eGui;
  }

  refresh(): boolean {
    return false;
  }
}
