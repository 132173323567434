import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WasteReport from "src/shared/assets/svgs/waste-reports.svg";
import Notifications from "src/shared/assets/svgs/notifications.svg";
import { AccountCard } from "src/components/accountCard/accountCard";
import { ManifestTable } from "src/components/manifestTable/manifestTable";
import {
  ManifestDescription,
  ManifestContainer,
  ManifestWrapper,
  ManifestTitle,
  ManifestCardsWrapper,
} from "./styles";

export const Manifest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickWasteReports = () => {
    navigate("/waste-reports");
  };

  const handleClickNotifications = () => {
    navigate("/waste-notifications");
  };

  return (
    <ManifestContainer>
      <ManifestTitle>{t("manifest")}</ManifestTitle>
      <ManifestWrapper>
        <ManifestDescription>{t("manifest-description")}</ManifestDescription>
        <ManifestCardsWrapper>
          <AccountCard
            title={t("waste-reports")}
            buttonText={t("new")}
            img={WasteReport}
            onClick={handleClickWasteReports}
            id="manifest-waste-reports-new"
          />
          <AccountCard
            title={t("notifications")}
            buttonText={t("create")}
            img={Notifications}
            onClick={handleClickNotifications}
            id="manifest-notifications-create"
          />
        </ManifestCardsWrapper>
      </ManifestWrapper>
      <ManifestTable />
    </ManifestContainer>
  );
};
