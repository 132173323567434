import { createSlice } from "@reduxjs/toolkit";
import { ISnackBarState } from "./snackBar.types";

const initialMenuState: ISnackBarState = {
  open: false,
  messages: [""],
};

export const snackBarSlice = createSlice({
  name: "snackBar",
  initialState: initialMenuState,
  reducers: {
    setIsOpenSnackBar: (state, action) => {
      state.open = action.payload;
    },
    setSnackBarMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { reducer: snackBarReducer, actions: snackBarActions } =
  snackBarSlice;
