import { Box, Typography, styled } from "@mui/material";

export const AdditionalInformationContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const NotesWrapper = styled(Box)(() => ({
  "& > div": {
    maxWidth: "none",
    "& > textarea": {
      resize: "none",
    },
  },
}));

export const UploadedFile = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  cursor: "pointer",
  width: "fit-content",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const AdditionalInformationWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
    borderRadius: 16,
    border: "1px solid #D9D9D9",
  },
}));

export const AdditionalInformationHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  width: 24,
  height: 24,
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));

export const QuestionWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  position: "relative",
}));

export const AdditionalFilesWrapper = styled(Box)(() => ({
  "& > div": {
    maxWidth: "none",
    height: "auto",
    "& > div > div": {
      height: "fit-content",
      "& > div": {
        height: "fit-content",
      },
    },
  },
}));

export const AddAttachmentButtonWrapper = styled(Box)(() => ({
  "& > div": {
    width: "fit-content",
  },
}));
