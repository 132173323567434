import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ReactComponent as AdviseLogo } from "src/shared/assets/svgs/advise-green-logo.svg";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { MainBackground } from "src/shared/components/mainBackground/mainBackground";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { SCOPES } from "src/shared/store/api/constants";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import {
  LogoContainer,
  SignInText,
  LoginContainer,
  SignInWrapper,
} from "./styles";

export const SignIn = () => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { setToken } = useAppActions();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/dashboard/${TABS_NAMES[0]}`);
    }
  }, [isAuthenticated, navigate]);

  const handleSignIn = async () => {
    try {
      const loginResponse = await instance.ssoSilent({
        scopes: SCOPES,
      });
      setToken(loginResponse?.accessToken);
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        await instance
          .loginRedirect({
            scopes: SCOPES,
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <MainBackground>
      <SignInWrapper>
        <LogoContainer>
          <AdviseLogo />
        </LogoContainer>
        <SignInText>{t("your-complete-online")}</SignInText>
        <LoginContainer>
          <FilledButton
            text={t("login")}
            onClick={handleSignIn}
            id="signIn-login"
          />
        </LoginContainer>
      </SignInWrapper>
    </MainBackground>
  );
};
