import { LiItem, Ul } from "./styles";
import { IList } from "./types";

export const List = ({ items }: IList) => {
  return (
    <Ul>
      {items.map((text, index) => (
        <LiItem key={index}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </LiItem>
      ))}
    </Ul>
  );
};
