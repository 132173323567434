import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { IInventoryAgingCardTitle } from "./types";
import { TitleContainer, Title } from "./styles";

export const InventoryAgingCardTitle = ({
  generatorStatusID,
}: IInventoryAgingCardTitle) => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <Title>
        {t("inventory-aging-status", { status: generatorStatusID })}
      </Title>
      <Arrow />
    </TitleContainer>
  );
};
