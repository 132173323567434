import {
  GetContextMenuItemsParams,
  RowSelectionOptions,
  SortDirection,
} from "ag-grid-community";
import NewTab from "src/shared/assets/svgs/new-tab.svg";
import WhiteNewTab from "src/shared/assets/svgs/white-new-tab.svg";

export const PROFILES_GRID_NAME = "profiles";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

export const getLocaleDateValue = (date?: string) => {
  return date ? new Date(date).toLocaleDateString() : "";
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const rowSelection = {
  mode: "singleRow",
  checkboxes: false,
  enableClickSelection: true,
} as RowSelectionOptions;

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("name"),
    field: "profileName",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("profile-number"),
    field: "profileNumber",
    ...excelMode,
    flex: 1,
    sort: "asc" as SortDirection,
    sortable: true,
  },
  {
    headerName: t("facility"),
    field: "facilityName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    sortable: true,
  },
  {
    headerName: t("site"),
    field: "siteName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    sortable: true,
  },
  {
    headerName: t("shipping-name"),
    field: "fullShippingName",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("account"),
    field: "accountName",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("label-code"),
    field: "labelCodes",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("constituents"),
    field: "constituents",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("epa-waste-codes"),
    field: "epaWasteCodes",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("tsdf-state-waste-codes"),
    field: "facilityStateCodes",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("generator-state-waste-codes"),
    field: "generatorStateCodes",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("hazard-class"),
    field: "hazardClass",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("process-code"),
    field: "processCode",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("un-number"),
    field: "unNumber",
    ...excelMode,
    flex: 1,
    hide: true,
  },
];

export const getContextMenuItems =
  (t: (value: string) => string, isDark: boolean) =>
  (params: GetContextMenuItemsParams) =>
    [
      {
        name: t("open-in-new-tab"),
        action: () => {
          window.open(
            `${window.location.origin}/profile/${params?.node?.data?.id}`,
            "_blank"
          );
        },
        icon: `<img src="${isDark ? WhiteNewTab : NewTab}"/>`,
      },
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];
