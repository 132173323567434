import { Box, styled } from "@mui/material";

export const FormCardAutocompleteContainer = styled(Box)(({ theme }) => ({
  "& > div > div > div": {
    minHeight: "42px !important",
    [theme.breakpoints.down("md")]: {
      minHeight: "28px !important",
    },
  },
}));
