import dayjs, { Dayjs } from "dayjs";
import { IDaysOfWeek, IDaysOfWeekFields } from "./types";

export const REPORT_PARAMS_FIELDS = {
  DATE_OPTION: "dateOption",
  START_DATE: "startDate",
  END_DATE: "endDate",
};

export const RENDER_FORMAT_OPTIONS = [
  { id: "PDF", val: "PDF" },
  { id: "WORDOPENXML", val: "Word" },
  { id: "EXCELOPENXML", val: "Excel" },
  { id: "CSV", val: "CSV" },
];

export const ON_WEEK_OF_MONTH_OPTIONS = [
  { id: "0", val: "1st" },
  { id: "1", val: "2nd" },
  { id: "2", val: "3rd" },
  { id: "3", val: "4th" },
  { id: "4", val: "Last" },
];

export const MONTH_OF_YEAR = {
  January: true,
  February: true,
  March: true,
  April: true,
  May: true,
  June: true,
  July: true,
  August: true,
  September: true,
  October: true,
  November: true,
  December: true,
};

export const DAYS_OF_WEEK_PARAMS = [
  { day: "sunday", label: "sun", defaultValue: false },
  { day: "monday", label: "mon", defaultValue: true },
  { day: "tuesday", label: "tue", defaultValue: true },
  { day: "wednesday", label: "wed", defaultValue: true },
  { day: "thursday", label: "thu", defaultValue: true },
  { day: "friday", label: "fri", defaultValue: true },
  { day: "saturday", label: "sat", defaultValue: false },
];

export const SCHEDULE_TYPE = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};

export const DATE_OPTION_CUSTOM = "0";

export const PARAMETER_TYPE = {
  DATE_TIME: "DateTime",
  BOOLEAN: "Boolean",
  STRING: "String",
};

export const DYNAMIC_VALUES = {
  SITE: "siteID",
  BUILDING: "buildingID",
  ROOM: "roomID",
  LOCATION: "locationID",
};

export const TYPE_OBJECT = "object";

export const DAYS_OF_WEEK_NUMBER = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
} as unknown as {
  [key: number]: IDaysOfWeekFields;
};

export const getNextScheduledRun = (
  startDateTime: string,
  daysOfWeek: IDaysOfWeek
) => {
  if (startDateTime && daysOfWeek) {
    let nextScheduledRunDate;
    let currentDate = dayjs().isBefore(dayjs(startDateTime))
      ? dayjs(startDateTime)
      : dayjs(startDateTime)
          .set("year", dayjs(dayjs()).get("year"))
          .set("month", dayjs(dayjs()).get("month"))
          .set("date", dayjs(dayjs()).get("date"));

    while (!nextScheduledRunDate) {
      let currentDay = currentDate.day();

      if (daysOfWeek?.[DAYS_OF_WEEK_NUMBER?.[currentDay]]) {
        nextScheduledRunDate = currentDate;
      } else {
        currentDate = currentDate.add(1, "day");
      }
    }

    return nextScheduledRunDate;
  } else {
    return;
  }
};

const getWeekOfMonth = (dayjsDate: Dayjs) => {
  const date = new Date(dayjsDate.format("MM-DD-YYYY"));
  const month = date.getMonth(),
    year = date.getFullYear(),
    firstWeekday = new Date(year, month, 1).getDay(),
    lastDateOfMonth = new Date(year, month + 1, 0).getDate(),
    offsetDate = date.getDate() + firstWeekday - 1,
    index = 0,
    weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7),
    week = index + Math.floor(offsetDate / 7);

  if (date || week < 2 + index) {
    return week;
  }

  return week === weeksInMonth ? index + 5 : week;
};

const getCurrentDateMonthForSchedule = (
  date: Dayjs,
  whichWeek: number,
  daysOfWeek: IDaysOfWeek
) => {
  let currentMonthDate = dayjs(date);
  const weekOfMonth = getWeekOfMonth(date);
  let currentDay = currentMonthDate.day();

  if (weekOfMonth !== whichWeek) {
    if (weekOfMonth > whichWeek) {
      currentMonthDate = currentMonthDate?.add(1, "month");
    }

    currentMonthDate = currentMonthDate?.set("date", 1).add(whichWeek, "week");
    currentDay = currentMonthDate.day();

    if (currentMonthDate.date() < currentDay) {
      currentMonthDate = getCurrentDateMonthForSchedule(
        currentMonthDate,
        whichWeek,
        daysOfWeek
      );
    } else {
      currentMonthDate = currentMonthDate.subtract(currentDay - 1, "day");
    }
  }

  return currentMonthDate;
};

export const getNextScheduledMonthRun = (
  date: Dayjs | undefined,
  whichWeek: number,
  daysOfWeek: IDaysOfWeek
) => {
  let nextScheduledMonthRun = dayjs(date);
  if (date) {
    nextScheduledMonthRun = getCurrentDateMonthForSchedule(
      nextScheduledMonthRun,
      whichWeek,
      daysOfWeek
    );
    let isCurrentDay = false;

    while (!isCurrentDay) {
      nextScheduledMonthRun = dayjs(
        getNextScheduledRun(
          nextScheduledMonthRun.format("YYYY-MM-DDTHH:mm:ssZ"),
          daysOfWeek
        )
      );

      if (getWeekOfMonth(nextScheduledMonthRun) === whichWeek) {
        isCurrentDay = true;
      } else {
        nextScheduledMonthRun = getCurrentDateMonthForSchedule(
          nextScheduledMonthRun.add(1, "month").set("date", 1),
          whichWeek,
          daysOfWeek
        );
      }
    }

    return nextScheduledMonthRun;
  }
};

export const getNextScheduledWeekRun = (
  date: Dayjs | undefined,
  weeksInterval: number,
  daysOfWeek: IDaysOfWeek
) => {
  const today = dayjs();
  let nextScheduledWeekRun = dayjs(date);
  let isCurrentWeek = false;

  while (!isCurrentWeek) {
    if (nextScheduledWeekRun.isBefore(today)) {
      nextScheduledWeekRun = nextScheduledWeekRun.add(weeksInterval, "week");
    } else {
      isCurrentWeek = true;
    }
  }

  if (
    getWeekOfMonth(nextScheduledWeekRun) === getWeekOfMonth(today) &&
    nextScheduledWeekRun.month() === today.month()
  ) {
    nextScheduledWeekRun = today;
  } else {
    nextScheduledWeekRun = nextScheduledWeekRun.startOf("week").add(1, "day");
  }

  const nextScheduledRun = getNextScheduledRun(
    nextScheduledWeekRun.format("YYYY-MM-DDTHH:mm:ssZ"),
    daysOfWeek
  );

  return nextScheduledRun;
};

export const getNextRunDateRangeStartDate = (
  dateOption: string,
  paramsStartDate: string,
  nextScheduledRun?: Dayjs
) => {
  switch (dateOption) {
    case "0":
      return dayjs(paramsStartDate).format("YYYY-MM-DD");
    case "1":
      return dayjs(nextScheduledRun)
        .set("month", 0)
        .set("date", 1)
        .format("YYYY-MM-DD");
    case "2":
      return dayjs(nextScheduledRun).set("date", 1).format("YYYY-MM-DD");
    case "3":
      return dayjs(nextScheduledRun)
        .startOf("quarter")
        .set("date", 1)
        .format("YYYY-MM-DD");
    case "4":
      return dayjs(nextScheduledRun).subtract(12, "month").format("YYYY-MM-DD");
    case "5":
      return dayjs(nextScheduledRun)
        .set("date", 1)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    case "6":
      return dayjs(nextScheduledRun)
        .subtract(1, "quarter")
        .startOf("quarter")
        .set("date", 1)
        .format("YYYY-MM-DD");
    case "7":
      return dayjs(nextScheduledRun)
        .set("day", 1)
        .subtract(1, "week")
        .format("YYYY-MM-DD");
    case "8":
      return dayjs(nextScheduledRun).set("day", 1).format("YYYY-MM-DD");
    default:
      return "";
  }
};

export const getNextRunDateRangeEndDate = (
  dateOption: string,
  paramsEndDate: string,
  nextScheduledRun?: Dayjs
) => {
  switch (dateOption) {
    case "0":
      return dayjs(paramsEndDate).format("YYYY-MM-DD");
    case "5":
      const previousMonth = dayjs(nextScheduledRun)
        .set("date", 1)
        .subtract(1, "month");

      return previousMonth.endOf("month").format("YYYY-MM-DD");
    case "6":
      return dayjs(nextScheduledRun)
        .subtract(1, "quarter")
        .endOf("quarter")
        .endOf("date")
        .format("YYYY-MM-DD");
    case "7":
      return dayjs(nextScheduledRun)
        .set("day", 5)
        .subtract(1, "week")
        .format("YYYY-MM-DD");
    case "8":
      return dayjs(nextScheduledRun).set("day", 5).format("YYYY-MM-DD");
    default:
      return dayjs(nextScheduledRun).format("YYYY-MM-DD");
  }
};
