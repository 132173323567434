import * as yup from "yup";

export const CreateChemicalValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    chemicalName: yup.string().max(100).required(t("required-field")),
    wasteCodes: yup.array(),
    uhc1: yup.string(),
    uhc2: yup.string(),
    cas: yup.string().max(20),
  });
