import { useTranslation } from "react-i18next";
import { ItemsTitle, ItemsTitleWrapper } from "./styles";

export const LocationTitle = () => {
  const { t } = useTranslation();

  return (
    <ItemsTitleWrapper>
      <ItemsTitle>{t("locations")}</ItemsTitle>
    </ItemsTitleWrapper>
  );
};
