import { UploadedFile } from "../additionalInformation/styles";
import { IAttachment } from "../additionalInformation/types";
import { ReviewDescription } from "./styles";
import { IProfileWaste, IWasteProfileData } from "./types";

export const getProfileReviewConfig = (
  wasteProfileData: IWasteProfileData,
  t: (value: string, params?: any) => string,
  handleGetAttachment: (fileName: string, fileID: string) => () => void
) => {
  return [
    {
      header: t("customer-information"),
      items: [
        {
          values: [
            {
              title: t("account"),
              values: [
                wasteProfileData?.profileAccount?.name,
                wasteProfileData?.profileAccount?.street1,
                `${wasteProfileData?.profileAccount?.city}, ${wasteProfileData?.profileAccount?.state} ${wasteProfileData?.profileAccount?.postalCode}`,
              ],
            },
            {
              title: t("site"),
              values: [
                wasteProfileData?.profileSite?.name,
                wasteProfileData?.profileSite?.street1,
                `${wasteProfileData?.profileSite?.city}, ${wasteProfileData?.profileSite?.state} ${wasteProfileData?.profileSite?.postalCode}`,
              ],
            },
          ],
        },
        {
          values: [
            {
              title: t("contact-information"),
              values: [
                `${wasteProfileData?.contactFirstName} ${wasteProfileData?.contactLastName}`,
              ],
            },
            {
              title: t("email"),
              values: [wasteProfileData?.contactEmail],
            },
          ],
        },
      ],
    },
    {
      header: t("waste-stream-description"),
      items: [
        {
          values: [
            {
              title: t("profile-name"),
              values: [wasteProfileData?.wasteName],
            },
            {
              title: t("shipment-options"),
              values: [wasteProfileData?.shipmentAs],
            },
          ],
        },
        {
          values: [
            {
              title: t("description-of-waste-generating-process"),
              values: [wasteProfileData?.wasteGeneratingProcessDescription],
            },
          ],
        },
      ],
    },
    {
      header: t("chemical-composition"),
      items: [
        {
          values: [
            {
              title: t(t("chemical-name-or-cas")),
              values: [
                wasteProfileData?.profileWastes?.map(
                  (
                    {
                      savedChemicalName,
                      minimumValue,
                      maximumValue,
                      units,
                    }: IProfileWaste,
                    index
                  ) => (
                    <ReviewDescription key={`${index}-chemical-name-or-cas`}>
                      {savedChemicalName}
                      {` (${t("min-max-avg", {
                        min: minimumValue,
                        max: maximumValue,
                        units,
                        avg: (Number(minimumValue) + Number(maximumValue)) / 2,
                      })})`}
                    </ReviewDescription>
                  )
                ),
              ],
            },
          ],
        },
      ],
    },
    {
      header: t("general-characteristics"),
      items: [
        {
          values: [
            {
              title: t("color"),
              values: [wasteProfileData?.color || t("no-data")],
            },
            {
              title: t("odor"),
              values: [wasteProfileData?.odor || t("no-data")],
            },
            {
              title: t("strength"),
              values: [wasteProfileData?.odorStrength || t("no-data")],
            },
            {
              title: t("physical-state-70f"),
              values: [wasteProfileData?.physicalState || t("no-data")],
            },
            {
              title: t("layers-phases"),
              values: [wasteProfileData?.layersPhases || t("no-data")],
            },
          ],
        },
        {
          values: [
            {
              title: t("percent-liquid"),
              values: [wasteProfileData?.percentLiquid || t("no-data")],
            },
            {
              title: t("percent-solid"),
              values: [wasteProfileData?.percentSolid || t("no-data")],
            },
            {
              title: t("percent-sludge"),
              values: [wasteProfileData?.percentSludge || t("no-data")],
            },
            {
              title: t("percent-powder"),
              values: [wasteProfileData?.percentPowder || t("no-data")],
            },
            {
              title: t("percent-gas"),
              values: [wasteProfileData?.percentGas || t("no-data")],
            },
          ],
        },
        {
          values: [
            {
              title: t("handling-code"),
              values: [wasteProfileData?.handlingCodes || t("no-data")],
            },
          ],
        },
      ],
    },
    {
      header: t("dot-information"),
      items: [
        {
          values: [
            {
              title: t("shipping-name"),
              values: [wasteProfileData?.fullShippingName],
            },
            {
              title: t("constituents"),
              values: [wasteProfileData?.lineConstituents],
            },
          ],
        },
        {
          values: [
            {
              title: t("shipping-container-details"),
              values: [
                `${t("size")}: ${wasteProfileData?.containerSize}`,
                `${t("weight-oum")}: ${wasteProfileData?.containerWeightUom}`,
                `${t("type")}: ${wasteProfileData?.containerType}`,
              ],
            },
            {
              title: t("anticipated-shipment-volume"),
              values: [
                `${t("quantity")}: ${wasteProfileData?.anticipatedQuantity}`,
                `${t("size")}: ${wasteProfileData?.anticipatedSize}`,
                `${t("time-frame")}: ${wasteProfileData?.anticipatedTimeframe}`,
              ],
            },
          ],
        },
      ],
    },
    {
      header: t("additional-information"),
      items: [
        {
          values: [
            {
              title: t("notes"),
              values: [wasteProfileData?.note],
            },
            {
              title: t("additional-files"),
              values: [
                wasteProfileData?.attachments?.map(
                  ({ fileName, fileID }: IAttachment) => (
                    <UploadedFile
                      key={fileID}
                      onClick={handleGetAttachment(fileName, fileID)}
                    >
                      {fileName}
                    </UploadedFile>
                  )
                ),
              ],
            },
          ],
        },
      ],
    },
  ];
};
