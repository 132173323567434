import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const QuickOverviewContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  padding: 16,
  borderRadius: 8,
  border: "1px solid #D9D9D9",
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15);"
    : "0px 4px 4px 0px rgba(217, 217, 217, 0.15)",
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    borderRadius: 0,
    borderLeft: "none",
    borderRight: "none",
  },
}));

export const QuickOverviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    gap: 8,
  },
}));

export const QuickOverviewTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  minWidth: 292,
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
    minWidth: 217,
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 153,
  },
}));

export const AssumeAccountWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 9,
}));

export const AssumeAccountTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    lineHeight: "140%",
  },
}));

export const QuickOverviewSelectWrapper = styled(Box)(() => ({
  flexGrow: 1,
}));

export const InviteButtonWrapper = styled(Box)(() => ({
  width: "fit-content",
}));

export const ChartsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  marginTop: 16,
  width: "100%",
  overflow: "auto",
  paddingBottom: 20,
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

export const ResetButtonWrapper = styled(Box)(() => ({
  marginTop: 16,
  width: "fit-content",
}));

export const ChartsWrapper = styled(Box)(() => ({
  width: "100%",
  cursor: "pointer",
  toucheEvent: "none",
  "& > div": {
    minWidth: 250,
  },
}));
