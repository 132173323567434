import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LeftBackground from "src/shared/assets/images/left-background.webp";
import RightBackground from "src/shared/assets/images/right-background.webp";
import LeftBackgroundTablet from "src/shared/assets/images/left-background-tablet.webp";
import RightBackgroundTablet from "src/shared/assets/images/right-background-tablet.webp";
import LeftBackgroundMobile from "src/shared/assets/images/left-background-mobile.webp";
import RightBackgroundMobile from "src/shared/assets/images/right-background-mobile.webp";

export const MainBackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  background: `url(${LeftBackground}), url(${RightBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0 100%, 100% 0",
  [theme.breakpoints.down("lg")]: {
    background: `url(${LeftBackgroundTablet}), url(${RightBackgroundTablet})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 100%, 100% 0",
  },
  [theme.breakpoints.down("md")]: {
    background: `url(${LeftBackgroundMobile}), url(${RightBackgroundMobile})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 100%, 100% 0",
  },
}));
