import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { Logo } from "src/shared/components/logo/logo";
import BurgerMenuIcon from "src/shared/assets/svgs/burger-menu.svg";
import { Tabs } from "src/shared/components/tabs/tabs";
import { TABS_DASHBOARD_CONFIG } from "src/pages/dashboard/dashboard";
import { useAppSelector } from "src/shared/hooks/redux/redux";
import { ScrollToTop } from "src/shared/helpers/scrollToTop/scrollToTop";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import {
  ITabsId,
  ISecondMenuTabsId,
  IAdminMenuTabsId,
} from "src/shared/store/reducers/menu/menu.types";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { ChangeLanguage } from "../changeLanguage/changeLanguage";
import { LogOut } from "../logOut/logOut";
import { SwitchColorMode } from "../switchColorMode/switchColorMode";
import { UpperNavigation } from "../upperNavigation/upperNavigation";
import {
  getAdminTabsConfig,
  getAdminTabsNames,
  getSecondTabsConfig,
  getSecondTabsNames,
} from "./constants";
import {
  HeaderContainer,
  HeaderWrapper,
  Wrapper,
  BurgerMenu,
  MenuDrawer,
  MobileColorModeWrapper,
  MobileTabsWrapper,
  Divider,
  AdminMobileTabsWrapper,
} from "./styles";

export const Header = () => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const tab = useAppSelector((state) => state.menuReducer.tab);
  const adminMenuTab = useAppSelector(
    (state) => state.menuReducer.adminMenuTab
  );
  const secondMenuTab = useAppSelector(
    (state) => state.menuReducer.secondMenuTab
  );
  const theme = useTheme();
  const moreThanMobile = useMediaQuery(theme.breakpoints.up("md"));
  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const hasUserAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ADMIN
  );

  const hasEditBannerPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.EDIT_BANNER
  );

  useEffect(() => {
    if (moreThanMobile) {
      setIsOpenMenu(false);
    }
  }, [moreThanMobile]);

  const handleClickMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const setNewValue = useCallback(
    (value: number) => {
      navigate(`/dashboard/${TABS_NAMES[value as ITabsId]}`);
      setIsOpenMenu(false);
    },
    [navigate]
  );

  const ADMIN_TABS_NAMES = useMemo(
    () => getAdminTabsNames(hasUserAdminPermission),
    [hasUserAdminPermission]
  );

  const SECOND_TABS_NAMES = useMemo(() => getSecondTabsNames(), []);

  const setNewValueForAdminMenu = useCallback(
    (value: number) => {
      navigate(`/${ADMIN_TABS_NAMES[value as IAdminMenuTabsId]}`);
      setIsOpenMenu(false);
    },
    [navigate, ADMIN_TABS_NAMES]
  );

  const setNewValueForSecondMenu = useCallback(
    (value: number) => {
      navigate(`/${SECOND_TABS_NAMES[value as ISecondMenuTabsId]}`);
      setIsOpenMenu(false);
    },
    [navigate, SECOND_TABS_NAMES]
  );

  const ADMIN_TABS_CONFIG = getAdminTabsConfig(
    hasUserAdminPermission,
    hasEditBannerPermission,
    t
  );
  const SECOND_TABS_CONFIG = getSecondTabsConfig(t);

  return (
    <HeaderContainer>
      <ScrollToTop />
      <HeaderWrapper>
        <BurgerMenu src={BurgerMenuIcon} alt="" onClick={handleClickMenu} />
        <MenuDrawer
          anchor="left"
          open={isOpenMenu}
          onClose={handleClickMenu}
          ModalProps={{ onBackdropClick: handleClickMenu }}
        >
          <React.Fragment>
            <MobileTabsWrapper>
              <Tabs
                config={TABS_DASHBOARD_CONFIG}
                orientation="vertical"
                value={tab}
                setValue={setNewValue}
              />
            </MobileTabsWrapper>
            <Divider />
            <AdminMobileTabsWrapper>
              <Tabs
                config={ADMIN_TABS_CONFIG}
                orientation="vertical"
                value={adminMenuTab}
                setValue={setNewValueForAdminMenu}
              />
            </AdminMobileTabsWrapper>
            <MobileTabsWrapper>
              <Tabs
                config={SECOND_TABS_CONFIG}
                orientation="vertical"
                value={secondMenuTab}
                setValue={setNewValueForSecondMenu}
              />
            </MobileTabsWrapper>
            <MobileColorModeWrapper>
              <SwitchColorMode />
            </MobileColorModeWrapper>
          </React.Fragment>
        </MenuDrawer>
        <Wrapper>
          <Logo />
          <ChangeLanguage />
        </Wrapper>
        <Wrapper>
          <UpperNavigation />
          <SwitchColorMode />
          <LogOut />
        </Wrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};
