import { api } from "./api";
import { HTTP_METHOD, RESPONSE_CODE } from "./constants";

const USER_INVITATION_SUB_URL = "UserInvitation";

export const userInvitation = api.injectEndpoints({
  endpoints: (builder) => ({
    sendUserInvitation: builder.mutation({
      query: (body) => ({
        url: `${USER_INVITATION_SUB_URL}/SendUserInvitation`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getUserProfile: builder.query({
      query: ({ id, verificationCode }) => ({
        url: `${USER_INVITATION_SUB_URL}/GetUserProfile/${id}/${verificationCode}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    updateUserInvitation: builder.mutation({
      query: (body) => ({
        url: `${USER_INVITATION_SUB_URL}/UpdateUserInvitation`,
        method: HTTP_METHOD.POST,
        body,
        responseHandler: (response) => {
          return response.status === RESPONSE_CODE.OK
            ? response.text()
            : response.json();
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSendUserInvitationMutation,
  useGetUserProfileQuery,
  useUpdateUserInvitationMutation,
} = userInvitation;
