import { List } from "src/shared/components/list/list";
import { IWasteProfileData } from "./types";
import { SingleProfileListWrapper, WasteProfileDetailsText } from "./styles";

const getCodesArray = (value: string) =>
  value?.trim()?.slice(0, -1)?.split(";");

export const getSingeProfileConfig = (
  wasteProfileData: IWasteProfileData,
  getAttachment: (body: unknown) => void,
  t: (value: string) => string,
  isMobileView: boolean
) => [
  {
    title: "profile",
    isBoldTitle: true,
    item: wasteProfileData?.wasteName,
  },
  {
    title: "profile-number",
    isBoldTitle: true,
    item: wasteProfileData?.profileNumber,
  },
  {
    title: "account",
    item: wasteProfileData?.profileAccount?.name,
  },
  {
    title: "epa-number",
    item: wasteProfileData?.profileSite?.epaIDNumber,
  },
  {
    title: "site",
    item: wasteProfileData?.profileSite?.name,
  },
  {
    title: isMobileView ? "tsdf" : "tsdf-with-description",
    item: wasteProfileData?.profileFacility?.name,
  },
  {
    title: "full-shipping-name",
    item: wasteProfileData?.fullShippingName,
  },
  {
    title: "common-shipping-name",
    item: wasteProfileData?.commonShippingName,
  },
  {
    title: "hazard-class",
    item: wasteProfileData?.hazardClass,
  },
  {
    title: "un-number",
    item: wasteProfileData?.unna,
  },
  {
    title: "packing-group",
    item: wasteProfileData?.packingGroup,
  },
  {
    title: "process-code",
    item: wasteProfileData?.processCode,
  },
  {
    title: "label-codes",
    item: wasteProfileData?.labelCodes,
  },
  {
    title: "shipment-as",
    item: wasteProfileData?.shipmentAs,
  },
  {
    title: "container",
    item: wasteProfileData?.containerType,
  },
  {
    title: "chemical-waste",
    customItems: () => (
      <SingleProfileListWrapper>
        <List
          items={
            wasteProfileData?.profileWastes?.length
              ? [
                  ...wasteProfileData?.profileWastes?.map(
                    (waste) =>
                      `${waste?.savedChemicalName} ( ${t("min")}: ${
                        waste?.minimumValue
                      }${waste?.units}, ${t("max")}: ${waste?.maximumValue}${
                        waste?.units
                      })`
                  ),
                ]
              : []
          }
        />
      </SingleProfileListWrapper>
    ),
  },
  {
    title: "waste-codes",
    customItems: () => (
      <SingleProfileListWrapper>
        <List
          items={[
            ...(wasteProfileData?.epaWasteCodes
              ? getCodesArray(wasteProfileData?.epaWasteCodes)
              : []),
            ...(wasteProfileData?.facilityStateCodes
              ? getCodesArray(wasteProfileData?.facilityStateCodes)
              : []),
            ...(wasteProfileData?.generatorStateCodes
              ? getCodesArray(wasteProfileData?.generatorStateCodes)
              : []),
          ]}
        />
      </SingleProfileListWrapper>
    ),
  },
  {
    title: "attachments-s",
    customItems: () => (
      <SingleProfileListWrapper>
        {wasteProfileData?.attachments?.map(({ fileName, fileID }) => (
          <WasteProfileDetailsText
            key={fileID}
            withLink
            onClick={() =>
              getAttachment({
                fileName,
                docID: wasteProfileData?.id,
                fileID,
              })
            }
          >
            {fileName}
          </WasteProfileDetailsText>
        ))}
      </SingleProfileListWrapper>
    ),
  },
];

export const MAX_FILE_SIZE = 10240;

export const inputFileAccept = "application/pdf,.xlsx,.doc,.docx,.xls";
