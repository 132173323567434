import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Chip, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { EditFollowUpDialog } from "src/components/editFollowUpDialog/editFollowUpDialog";
import { getLocaleDateValue } from "../../constants";
import { COLORS } from "../constants";
import { EditFollowUp } from "../styles";
import { QuestionPromptWithImages } from "./questionPromptWithImages/questionPromptWithImages";
import { IInspectionQuestions, IQuestion } from "./types";
import { getInspectionQuestionAnswer } from "./constants";
import {
  TableHeadCell,
  TableRowCell,
  InspectionText,
  InspectionComments,
  QuestionContainer,
  FollowContainer,
} from "./styles";

export const InspectionQuestions = ({
  questions,
  inspectionId,
}: IInspectionQuestions) => {
  const { t } = useTranslation();

  const [isOpenEditFollowUpDialog, setIsOpenEditFollowUpDialog] =
    useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();

  const handleCloseEditFollowUpDialog = () => {
    setIsOpenEditFollowUpDialog(false);
  };

  const handleOpenEditFollowUpDialog = (question: IQuestion) => () => {
    setSelectedQuestion(question);
    setIsOpenEditFollowUpDialog(true);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell>{t("prompt")}</TableHeadCell>
            <TableHeadCell>{t("answer")}</TableHeadCell>
            <TableHeadCell>{t("findings")}</TableHeadCell>
            <TableHeadCell>{t("follow-up")}</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions?.map((question: IQuestion) => {
            const answer = getInspectionQuestionAnswer(question);

            return (
              <TableRow key={question?.questionNumber}>
                <TableRowCell top withoutWrap>
                  {question?.questionNumber}
                </TableRowCell>
                <TableRowCell top>
                  <QuestionContainer>
                    <QuestionPromptWithImages
                      prompt={question?.prompt}
                      blobFilePaths={question?.blobFilePaths}
                    />
                    <InspectionComments>
                      {question?.comments &&
                        `${t(
                          question?.comments ? "inspector-comments" : ""
                        )}: `}
                      {question?.comments}
                    </InspectionComments>
                  </QuestionContainer>
                </TableRowCell>
                <TableRowCell>
                  {question?.answerColor === COLORS.RED ? (
                    <Chip label={answer} color="error" />
                  ) : (
                    answer
                  )}
                </TableRowCell>
                <TableRowCell>{question?.numberOfViolations}</TableRowCell>
                <TableRowCell withoutWrap>
                  <FollowContainer>
                    <EditFollowUp
                      onClick={handleOpenEditFollowUpDialog(question)}
                    >
                      {question?.followUpOption}
                    </EditFollowUp>
                    <InspectionText>
                      {getLocaleDateValue(question?.followUpDate || "")}
                    </InspectionText>
                    <InspectionText>
                      {question?.followUpChangedByName}
                    </InspectionText>
                    {!question?.followUpOption && (
                      <EditFollowUp
                        onClick={handleOpenEditFollowUpDialog(question)}
                      >
                        {t("edit")}
                      </EditFollowUp>
                    )}
                  </FollowContainer>
                </TableRowCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <EditFollowUpDialog
        open={isOpenEditFollowUpDialog}
        handleClose={handleCloseEditFollowUpDialog}
        inspectionResultID={inspectionId}
        questionID={selectedQuestion?.questionID}
        followUpStatus={selectedQuestion?.followUpOptionID}
        comments={selectedQuestion?.comments}
      />
    </>
  );
};
