import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  NavigationLine,
  NavigationCircle,
  NavigationSectionsContainer,
  SectionText,
  SectionContainer,
} from "./styles";
import { INavigationSections } from "./types";

export const NavigationSections = ({
  size,
  activeSection,
  passedSection,
  setActiveSection,
}: INavigationSections) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const moreThanMobile = useMediaQuery(theme.breakpoints.up("md"));
  const sectionsSize = new Array(size).fill(0);

  const handleClickSection = (section: number) => () => {
    if (passedSection >= section) {
      setActiveSection(section);
    }
  };

  return (
    <NavigationSectionsContainer>
      {sectionsSize.map((_, index) => (
        <React.Fragment key={index}>
          <SectionContainer key={index}>
            <NavigationCircle
              isPassedSection={passedSection > index}
              isActiveSection={activeSection === index}
              isClickable={passedSection >= index}
              onClick={handleClickSection(index)}
            />
            <SectionText>
              {moreThanMobile ? `${t("section")} ${index + 1}` : index + 1}
            </SectionText>
          </SectionContainer>
          {index !== sectionsSize.length - 1 && (
            <NavigationLine isPassedSection={passedSection > index} />
          )}
        </React.Fragment>
      ))}
    </NavigationSectionsContainer>
  );
};
