import { Button } from "src/shared/components/button/button";
import { ButtonContainer } from "./styles";
import { IButtonWithIcon } from "./types";

export const ButtonWithIcon = ({
  text,
  onClick,
  icon,
  id,
}: IButtonWithIcon) => {
  return (
    <ButtonContainer>
      <Button startIcon={icon} text={text} onClick={onClick} id={id} />
    </ButtonContainer>
  );
};
