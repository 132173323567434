import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import BlockUi from "@availity/block-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { Button } from "src/shared/components/button/button";
import {
  useGetUserProfileByIDQuery,
  useUpdateUserProfileMutation,
} from "src/shared/store/api/userProfile.api";
import { UserInformationForm } from "../../components/userInformationForm/userInformationForm";
import { EditUserInformationForm } from "../../components/userInformationForm/editUserInformationForm/editUserInformationForm";
import { ContactInformationForm } from "../../components/contactInformationForm/contactInformationForm";
import { EditContactInformationForm } from "../../components/editContactInformationForm/editContactInformationForm";
import { AssignedAccountsForm } from "../../components/assignedAccountsForm/assignedAccountsForm";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { EditAssignedAccountsForm } from "../../components/editAssignedAccountsForm/editAssignedAccountsForm";
import { useGetPermissionsQuery } from "src/shared/store/api/permissions.api";
import { PermissionsForm } from "../../components/permissitonsForm/permissionsForm";
import {
  IUserAccount,
  IUserAccounts,
} from "../../components/editAssignedAccountsForm/types";
import { IOrganization } from "src/components/quickOverview/types";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { CustomReactRouterPrompt } from "src/shared/components/customReactRouterPrompt/customReactRouterPrompt";
import { IPermissions, IValue } from "../../components/permissitonsForm/types";
import { EditUserProfileValidationSchema } from "./model";
import {
  FORM_TYPE,
  getContactInformationConfig,
  getUserInformationConfig,
} from "./constants";
import { IEditUserFields } from "./types";
import {
  Container,
  Body,
  BackText,
  BackIcon,
  BackWrapper,
  Title,
  ButtonsWrapper,
} from "./styles";

export const EditUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const navigate = useNavigate();
  const [show, setShow] = useState(FORM_TYPE.FORM);
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const isOpenUnsavedChangedDialog = useAppSelector(
    (state) => state.unsavedChangesReducer.isOpenUnsavedChangedDialog
  );
  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [updateAfterCancel, setUpdateAfterCancel] = useState(false);
  const { data: userProfileData } = useGetUserProfileByIDQuery({ id });
  const { data: ownUserProfileData } = useGetUserProfileByIDQuery({
    id: userName,
  });

  const [
    updateUser,
    { isSuccess: isSuccessUpdateUser, isLoading: isLoadingUpdateUser },
  ] = useUpdateUserProfileMutation();
  const { data: permissionsData } = useGetPermissionsQuery({
    userID: id,
  });
  const { data: organizationsData } = useGetOrganizationsByIDsQuery(
    userProfileData?.userAccounts?.map(
      (account: IUserAccount) => account?.organizationUnitID
    ),
    {
      skip: !userProfileData?.userAccounts?.length,
    }
  );

  const ownPermissions = ownUserProfileData?.userPermissions;

  const hasAssignAccountAccessPermission = hasPermissions(
    ownPermissions,
    PERMISSIONS_LIST.ASSIGN_ACCOUNTS_ACCESS
  );

  const hasEditUserPermissionListPermission = hasPermissions(
    ownPermissions,
    PERMISSIONS_LIST.EDIT_USER_PERMISSION_LIST
  );

  const hasEditUserPermissionForSelfPermission = hasPermissions(
    ownPermissions,
    PERMISSIONS_LIST.EDIT_USER_PERMISSION_FOR_SELF
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(EditUserProfileValidationSchema(t)),
    mode: "onSubmit",
  });

  const userAccounts = watch("userAccounts") as IUserAccounts;
  const permissionsValues = watch("userPermissions") as IPermissions;
  const defaultOrganizationId = userAccounts?.find(
    (account) => !!account.isDefault
  )?.organizationUnitID;

  useEffect(() => {
    if (userProfileData || updateAfterCancel) {
      for (const value of Object.keys(userProfileData)) {
        setValue(value as IEditUserFields, userProfileData[value], {
          shouldValidate: true,
        });
      }
      setUpdateAfterCancel(false);
    }
  }, [setValue, updateAfterCancel, userProfileData]);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      setShow(FORM_TYPE.FORM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateUser]);

  const handleChangedData = () => {
    setChangedData(true);
  };

  useEffect(() => {
    setChangedData(false);
  }, [setChangedData, show]);

  const userInformationConfig = getUserInformationConfig(t, userProfileData);

  const contactInformationConfig = getContactInformationConfig(
    t,
    userProfileData
  );

  const assignedAccountsConfig = organizationsData?.map(
    ({ organizationUnitID, name }: IOrganization) => ({
      name,
      val: organizationUnitID,
      isDefault: organizationUnitID === defaultOrganizationId,
    })
  );

  const handleEditContactInformation = () => {
    setShow(FORM_TYPE.EDIT_CONTRACT_INFORMATION);
  };

  const handleEditUserInformation = () => {
    setShow(FORM_TYPE.EDIT_USER_INFORMATION);
  };

  const handleEditAssignedAccounts = () => {
    setShow(FORM_TYPE.EDIT_ASSIGNED_ACCOUNTS);
  };

  const updateUserData = handleSubmit((data) => {
    updateUser({ body: data });
    setChangedData(false);
  });

  const setUserAccounts = (userAccounts: Array<IUserAccount>) => {
    setValue("userAccounts", userAccounts);
  };

  const setUserPermissions = (userPermissions: IValue[]) => {
    setValue("userPermissions", userPermissions);
  };

  const handleCancel = useCallback(() => {
    if (changedData && !isOpenUnsavedChangedDialog && show !== FORM_TYPE.FORM) {
      setIsOpenUnsavedChangedDialog(true);
    } else if (show === FORM_TYPE.FORM) {
      navigate(-1);
    } else {
      setShow(FORM_TYPE.FORM);
      setUpdateAfterCancel(true);
    }
  }, [
    changedData,
    isOpenUnsavedChangedDialog,
    navigate,
    setIsOpenUnsavedChangedDialog,
    show,
  ]);

  const canEditPermissionsData =
    id === ownUserProfileData?.id
      ? hasEditUserPermissionForSelfPermission
      : hasEditUserPermissionListPermission;

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCancel();
    });
  }, [handleCancel, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      updateUserData();
    });
  }, [updateUserData, setHandleUpdateUnsavedChanged]);

  useEffect(() => {
    if (show === FORM_TYPE.FORM && isSuccessUpdateUser) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateUser]);

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingUpdateUser}
      loader={<CircularProgress />}
      keepInView
    >
      <Container>
        <CssBaseline />
        <Header />
        <Body>
          {show === FORM_TYPE.FORM && (
            <React.Fragment>
              <BackWrapper onClick={handleCancel}>
                <BackIcon src={backArrow} alt="" />
                <BackText>{t("back")}</BackText>
              </BackWrapper>
              <Title>{t("manage-user-information")}</Title>
              <UserInformationForm
                handleEditUserInformation={handleEditUserInformation}
                userInformationConfig={userInformationConfig}
              />
              <ContactInformationForm
                handleEditContactInformation={handleEditContactInformation}
                contactInformationConfig={contactInformationConfig}
              />
              <AssignedAccountsForm
                handleEditAssignedAccounts={
                  hasAssignAccountAccessPermission
                    ? handleEditAssignedAccounts
                    : undefined
                }
                assignedAccountsConfig={assignedAccountsConfig}
              />
              <PermissionsForm
                permissionsData={permissionsData}
                values={permissionsValues || []}
                setUserPermissions={setUserPermissions}
                canEditPermissionsData={canEditPermissionsData}
                handleChangedData={handleChangedData}
              />
              <ButtonsWrapper>
                <Button
                  text={t("back")}
                  onClick={handleCancel}
                  isGrey
                  id="edit-user-back"
                />
                {canEditPermissionsData && (
                  <Button
                    text={t("save-and-finish")}
                    onClick={updateUserData}
                    disabled={!isValid || isLoadingUpdateUser}
                    id="edit-user-save-and-finish"
                  />
                )}
              </ButtonsWrapper>
            </React.Fragment>
          )}
          {show === FORM_TYPE.EDIT_USER_INFORMATION && (
            <EditUserInformationForm
              errors={errors}
              register={register as unknown as UseFormRegister<FieldValues>}
              handleCancel={handleCancel}
              updateUserData={updateUserData}
              disabledSaveButton={isLoadingUpdateUser}
              handleChangedData={handleChangedData}
            />
          )}
          {show === FORM_TYPE.EDIT_CONTRACT_INFORMATION && (
            <EditContactInformationForm
              errors={errors}
              register={register as unknown as UseFormRegister<FieldValues>}
              handleChangedData={handleChangedData}
              handleCancel={handleCancel}
              updateUserData={updateUserData}
              disabledSaveButton={isLoadingUpdateUser}
            />
          )}
          {show === FORM_TYPE.EDIT_ASSIGNED_ACCOUNTS && (
            <EditAssignedAccountsForm
              errors={errors}
              setUserAccounts={setUserAccounts}
              handleCancel={handleCancel}
              updateUserData={updateUserData}
              disabledSaveButton={!isValid || isLoadingUpdateUser}
              userAccounts={userAccounts}
              handleChangedData={handleChangedData}
            />
          )}
        </Body>
        <CustomReactRouterPrompt />
      </Container>
    </BlockUi>
  );
};
