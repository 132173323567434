import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { ICreateNewProfileDialog } from "./types";
import {
  CreateNewProfileDescription,
  CreateNewProfileWrapper,
  MailToLink,
} from "./styles";

export const CreateNewProfileDialog = ({
  open,
  handleClose,
}: ICreateNewProfileDialog) => {
  const { t } = useTranslation();

  const createNewRejectProfileDialogFields = () => {
    return (
      <CreateNewProfileWrapper>
        <CreateNewProfileDescription>
          {t("user-not-have-permissions-to-access-1")}
          <MailToLink href="mailto:advisesupport@triumvirate.com">
            advisesupport@triumvirate.com
          </MailToLink>
          {t("user-not-have-permissions-to-access-2")}
        </CreateNewProfileDescription>
      </CreateNewProfileWrapper>
    );
  };

  const createNewProfileDialogActions = () => {
    return (
      <FilledButton
        text={t("ok")}
        onClick={handleClose}
        id="create-new-waste-profile-ok"
      />
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("create-a-new-waste-profile")}
      actions={createNewProfileDialogActions}
      renderFields={createNewRejectProfileDialogFields}
    />
  );
};
