import { IFollowUp } from "src/components/inspectionQuestions/types";
import { IInspectionType, IListOfObject } from "./types";

export const INSPECTIONS_STORAGE_KEYS = {
  KEYWORD: "INSPECTIONS_KEYWORD",
  ACCOUNT_ID_LIST: "INSPECTIONS_ACCOUNT_ID_LIST",
  SEARCH_ORGANIZATION_VALUE: "INSPECTIONS_SEARCH_ORGANIZATION_VALUE",
  SELECTED_INSPECTIONS_ROW: "SELECTED_INSPECTIONS_ROW",
  SELECTED_INSPECTIONS: "selected_inspections",
};

export const getListOfIdsFromObject = (listOfObjects: Array<IListOfObject>) => {
  return listOfObjects ? listOfObjects?.map((object) => object?.id) : [];
};

export const getFollowUpOptions = (followUpOptions: IFollowUp[]) => {
  return followUpOptions
    ? followUpOptions?.map(
        ({ followUpOptionID, followupOption }: IFollowUp) => ({
          id: followUpOptionID,
          val: followupOption,
        })
      )
    : [];
};

export const COMPARISON_VALUE = {
  EQUAL_TO: "Equal To",
  LESS_THAN: "Less Than",
  GREATER_THAN: "Greater Than",
};

export const getComparisonValue = (comparison: string) => {
  switch (comparison) {
    case COMPARISON_VALUE?.EQUAL_TO:
      return "=";
    case COMPARISON_VALUE?.LESS_THAN:
      return "<";
    case COMPARISON_VALUE?.GREATER_THAN:
      return ">";
  }
};

export const scoreComparisonOptions = [
  {
    id: "0",
    val: COMPARISON_VALUE?.EQUAL_TO,
  },
  {
    id: "1",
    val: COMPARISON_VALUE?.LESS_THAN,
  },
  {
    id: "2",
    val: COMPARISON_VALUE?.GREATER_THAN,
  },
];

export const HAS_VALUES_OPTIONS = {
  ALL: "all",
  HAS: "has",
  HAS_NOT: "has-not",
};

export const getHasValue = (value?: string) => {
  switch (value) {
    case HAS_VALUES_OPTIONS?.ALL:
      return undefined;
    case HAS_VALUES_OPTIONS?.HAS:
      return true;
    case HAS_VALUES_OPTIONS?.HAS_NOT:
      return false;
  }
};

export const getInspectionsTypeOptions = (
  inspectionType: IInspectionType[]
) => {
  return inspectionType?.map(({ inspectionID, title }: IInspectionType) => ({
    id: inspectionID,
    val: title,
  }));
};
