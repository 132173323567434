import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { Select } from "src/shared/components/select/select";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  useGetFollowUpOptionsQuery,
  useUpdateInspectionFollowUpMutation,
} from "src/shared/store/api/inspection.api";
import { getFollowUpOptions } from "src/components/inspectionsTable/inspectionsFilters/constants";
import { TextArea } from "src/shared/components/textArea/textArea";
import { IOption } from "src/shared/components/select/types";
import { IEditFollowUpDialog } from "./types";
import { EditFollowUpValidationSchema } from "./model";
import { emptyEditFollowUpStatusFields } from "./constants";
import {
  DialogLabel,
  LabelWrapper,
  EditFollowUpDialogContentContainer,
  ActionButtonsWrapper,
  CommentsContainer,
} from "./styles";

export const EditFollowUpDialog = ({
  open,
  handleClose: handleCloseEditFollowUpDialog,
  inspectionResultID,
  questionID,
  followUpStatus,
  comments,
}: IEditFollowUpDialog) => {
  const { t } = useTranslation();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditFollowUpValidationSchema(t)),
    mode: "onSubmit",
  });

  const followUp = watch("followUp");

  const { data: followUpOptions } = useGetFollowUpOptionsQuery({});

  useEffect(() => {
    if (open) {
      if (followUpStatus) {
        setValue("followUp", followUpStatus);
      } else {
        setValue("followUp", "-1");
      }
      if (comments) {
        setValue("comments", comments);
      }
    }
    setChangedData(false);
  }, [comments, followUpStatus, open, setChangedData, setValue]);

  const [
    updateInspectionFollowUp,
    {
      isSuccess: isSuccessUpdateFollowUpStatus,
      isLoading: isLoadingUpdateFollowUpStatus,
    },
  ] = useUpdateInspectionFollowUpMutation();

  const handleClose = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCloseEditFollowUpDialog();
    }
  };

  const EditFollowUp = handleSubmit((data) => {
    updateInspectionFollowUp({
      inspectionResultID,
      questionID,
      followUpOptionID: data?.followUp,
      comments: data?.comments,
    });
  });

  const handleChangeFollowUp = (e: SelectChangeEvent<unknown>) => {
    setValue("followUp", e.target.value as string);
    setChangedData(true);
  };

  const EditFollowUpDialogActions = () => {
    return (
      <ActionButtonsWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="edit-follow-up-cancel"
        />
        <Button
          text={t("save")}
          onClick={EditFollowUp}
          id="edit-follow-up-save"
        />
      </ActionButtonsWrapper>
    );
  };

  const handleChangedData = () => {
    setChangedData(true);
  };

  const resetData = useCallback(() => {
    reset({ ...emptyEditFollowUpStatusFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => EditFollowUp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EditFollowUp]);

  useEffect(() => {
    if (!open) {
      resetData();
      clearErrors();
      setChangedData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCloseEditFollowUpDialog();
      clearErrors();
      resetData();
    });
  }, [
    clearErrors,
    handleCloseEditFollowUpDialog,
    resetData,
    setHandleBackUnsavedChanged,
  ]);

  useEffect(() => {
    if (isSuccessUpdateFollowUpStatus) {
      resetData();
    }
  }, [isSuccessUpdateFollowUpStatus, clearErrors, resetData]);

  useEffect(() => {
    if (isSuccessUpdateFollowUpStatus && !isLoadingUpdateFollowUpStatus) {
      handleCloseEditFollowUpDialog();
      clearErrors();
      resetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdateFollowUpStatus]);

  const renderEditFollowUpDialogFields = () => {
    return (
      <EditFollowUpDialogContentContainer>
        <LabelWrapper>
          <DialogLabel>{t("follow-up")}</DialogLabel>
          <Select
            value={followUp || ""}
            onChange={handleChangeFollowUp}
            options={
              followUpOptions?.length
                ? (getFollowUpOptions(followUpOptions) as IOption[])
                : []
            }
            disabled={!followUpOptions?.length}
            errorMessage={errors?.followUp?.message}
            id="edit-follow-up"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("comments")}</DialogLabel>
          <CommentsContainer>
            <TextArea
              errorMessage={errors?.comments?.message}
              register={{
                ...register("comments", {
                  onChange: handleChangedData,
                }),
              }}
              id="edit-follow-up-comments"
            />
          </CommentsContainer>
        </LabelWrapper>
      </EditFollowUpDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("edit")}
      actions={EditFollowUpDialogActions}
      renderFields={renderEditFollowUpDialogFields}
    />
  );
};
