import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { IFooterLinksArrow } from "./types";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    gap: 8,
  },
  [theme.breakpoints.down("lg")]: {
    gap: 2,
  },
}));

export const FooterLinksTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#F5F5F5",
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const FooterLinksItem = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  cursor: "pointer",
  lineHeight: "140%",
  width: "fit-content",
  color: "#F5F5F5",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    lineHeight: "130%",
  },
  "&:hover": {
    opacity: 0.7,
  },
}));

export const FooterLinksTitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    margin: "16px 0 8px 0",
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    margin: "8px 0 4px 0",
  },
}));

export const FooterLinksArrow = styled("img")<IFooterLinksArrow>(
  ({ opened }) => ({
    width: 16,
    height: 16,
    transform: opened ? "rotate(180deg)" : "",
  })
);
