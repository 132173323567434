import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroup } from "@mui/material";
import { Select } from "src/shared/components/select/select";
import { TextArea } from "src/shared/components/textArea/textArea";
import { Input } from "src/shared/components/input/input";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import { Button } from "src/shared/components/button/button";
import { QUESTION_TYPE_ID } from "src/components/inspectionsTable/inspectionInformation/inspectionQuestions/constants";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  ManageInspectionFormFieldWrapper,
  ManageInspectionFormLabel,
} from "../manageInspectionForm/styles";
import {
  AddQuestionButtonsWrapper,
  AddQuestionContainer,
  FormControlLabel,
  Radio,
  RadioGroupWrapper,
} from "../manageInspectionQuestion/styles";
import { TYPE_OPTIONS } from "../manageInspectionQuestion/constants";
import { ManageInspectionQuestionValidationSchema } from "../manageInspectionQuestion/model";
import { IManageInspectionQuestionFields } from "../manageInspectionQuestion/types";
import {
  IEditManageInspectionFields,
  IEditManageInspectionQuestion,
} from "./types";
import { EditManageInspectionQuestionContainer } from "./styles";

export const EditManageInspectionQuestion = ({
  detailCellProps,
  setManageInspectionValues,
  inspectionQuestions,
}: IEditManageInspectionQuestion) => {
  const { t } = useTranslation();
  const { setChangedData } = useAppActions();

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      defaultAnswerBool: true,
      numberOfViolationsRequired: false,
      answerRequired: false,
    },
    resolver: yupResolver(ManageInspectionQuestionValidationSchema(t)),
    mode: "onSubmit",
  });

  const detailCellPropsValues = detailCellProps?.data;
  const numberOfViolationsRequired = watch("numberOfViolationsRequired");
  const answerRequired = watch("answerRequired");
  const typeID = watch("typeID");

  const handleChanged = () => {
    setChangedData(true);
  };

  const handleChangeAnswerRequired = () => {
    setValue("answerRequired", !answerRequired);
    handleChanged();
  };

  const handleChangeNumberOfViolations = () => {
    setValue("numberOfViolationsRequired", !numberOfViolationsRequired);
    handleChanged();
  };

  const handleUpdateQuestion = handleSubmit((data) => {
    setManageInspectionValues(
      inspectionQuestions?.map((question: IManageInspectionQuestionFields) =>
        question?.questionNumber === detailCellPropsValues?.questionNumber
          ? { ...data }
          : { ...question }
      )
    );
  });

  const handleDeleteQuestion = () => {
    setManageInspectionValues(
      inspectionQuestions
        ?.filter((question: IManageInspectionQuestionFields) => {
          return (
            detailCellPropsValues?.questionNumber !== question?.questionNumber
          );
        })
        ?.map((question: IManageInspectionQuestionFields, index) => ({
          ...question,
          questionNumber: index + 1,
        }))
    );
  };

  useEffect(() => {
    for (const value of Object.keys(detailCellPropsValues)) {
      setValue(
        value as IEditManageInspectionFields,
        detailCellPropsValues[value],
        {
          shouldValidate: true,
        }
      );
    }
  }, [detailCellPropsValues, setValue]);

  return (
    <EditManageInspectionQuestionContainer>
      <AddQuestionContainer>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>{t("type")}</ManageInspectionFormLabel>
          <Select
            register={register("typeID", {
              onChange: handleChanged,
            })}
            options={TYPE_OPTIONS}
            value={watch("typeID") || ""}
            errorMessage={errors.typeID?.message}
            id="edit-manage-inspection-typeID"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>{t("prompt")}</ManageInspectionFormLabel>
          <TextArea
            register={register("prompt", {
              onChange: handleChanged,
            })}
            errorMessage={errors.prompt?.message}
            id="edit-manage-inspection-prompt"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>{t("citation")}</ManageInspectionFormLabel>
          <Input
            register={register("citation", {
              onChange: handleChanged,
            })}
            id="edit-manage-inspection-citation"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("recommendation")}
          </ManageInspectionFormLabel>
          <TextArea
            register={register("recommendation", {
              onChange: handleChanged,
            })}
            id="edit-manage-inspection-recommendation"
          />
        </ManageInspectionFormFieldWrapper>
        {(typeID === QUESTION_TYPE_ID.TRFL ||
          typeID === QUESTION_TYPE_ID.TEXT) && (
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("default-answer")}
            </ManageInspectionFormLabel>
            {typeID === QUESTION_TYPE_ID.TRFL && (
              <RadioGroupWrapper>
                <Controller
                  control={control}
                  name={"defaultAnswerBool"}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value={"true"}
                        control={<Radio icon={<CheckedIcon />} />}
                        label={t("yes")}
                        onChange={handleChanged}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio icon={<CheckedIcon />} />}
                        label={t("no")}
                        onChange={handleChanged}
                      />
                    </RadioGroup>
                  )}
                />
              </RadioGroupWrapper>
            )}
            {typeID === QUESTION_TYPE_ID.TEXT && (
              <Input
                register={{ ...register("defaultAnswerText") }}
                id="edit-manage-inspection-default-answer-text"
              />
            )}
          </ManageInspectionFormFieldWrapper>
        )}
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("answer-required")}
          </ManageInspectionFormLabel>
          <FormCardCheckbox
            checked={!!answerRequired}
            onChange={handleChangeAnswerRequired}
            id="edit-manage-inspection-answer-required"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("number-of-violations-required")}
          </ManageInspectionFormLabel>
          <FormCardCheckbox
            checked={!!numberOfViolationsRequired}
            onChange={handleChangeNumberOfViolations}
            id="edit-manage-inspection-number-of-violations"
          />
        </ManageInspectionFormFieldWrapper>
        <AddQuestionButtonsWrapper>
          <Button
            text={t("update")}
            onClick={handleUpdateQuestion}
            id="edit-manage-inspection-update"
          />

          <Button
            text={t("delete")}
            onClick={handleDeleteQuestion}
            isError
            id="edit-manage-inspection-delete"
          />
        </AddQuestionButtonsWrapper>
      </AddQuestionContainer>
    </EditManageInspectionQuestionContainer>
  );
};
