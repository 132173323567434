import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import { ReactComponent as AddCircle } from "src/shared/assets/svgs/add-circle.svg";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { MultiEmail } from "src/shared/components/multiEmail/multiEmail";
import { Alert } from "src/shared/components/alert/alert";
import { ReactComponent as Trash } from "src/shared/assets/svgs/trash.svg";
import { Input } from "src/shared/components/input/input";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { useProcessSupportFormMutation } from "src/shared/store/api/notification.api";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { SupportFormValidationSchema } from "./model";
import { inputFileAccept } from "./constants";
import {
  SupportFormContainer,
  SupportFormFieldWrapper,
  SupportFormLabel,
  ActionsWrapper,
  UploadedFile,
  UploadedFileWrapper,
  UploadedFileContainer,
  AddAttachmentButtonWrapper,
} from "./styles";

export const SupportForm = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const [
    processSupportForm,
    {
      isLoading: isLoadingProcessSupportForm,
      isSuccess: isSuccessProcessSupportForm,
    },
  ] = useProcessSupportFormMutation({});

  const { data: userProfile = {}, isLoading: isLoadingUserProfile } =
    useGetUserProfileByIDQuery(
      {
        id: accounts[0]?.username,
      },
      {
        skip: !accounts[0]?.username,
      }
    );

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SupportFormValidationSchema(t)),
    mode: "onSubmit",
  });

  const subject = watch("subject") || "";
  const emails = watch("emailDistributionList") || [];
  const attachments = watch("attachments") || [];

  const {
    setChangedData,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const handleChangedData = () => {
    setChangedData(true);
  };

  const backToPreviousPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleCreateSupportTicket = handleSubmit((data) => {
    const formData = new FormData();

    for (let index = 0; index < attachments?.length; index++) {
      formData.append("files", attachments?.[index] as File);
    }

    formData.append("supportFormString", JSON.stringify(data));

    processSupportForm(formData);
  });

  const handleChangeEmails = (_emails: string[]) => {
    setValue("emailDistributionList", _emails);
    handleChangedData();
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  const handleClickAttachFile = () => {
    inputRef?.current?.click();
  };

  const handleDeleteFile = (deletedFile: File) => () => {
    const newFiles = attachments.filter((file: File) => file !== deletedFile);
    setValue("attachments", newFiles);
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...attachments];

    files.forEach((file: File) => {
      if (
        uploaded.findIndex(
          (uploadedFile) => uploadedFile.name === file.name
        ) === -1
      ) {
        uploaded.push(file);
      }
    });

    setValue("attachments", uploaded);
  };

  const handleAttachFile = (e: ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
    handleChangedData();

    e.target.value = "";
  };

  const handleDownloadFile = (file: File) => () => {
    const hiddenElement = document.createElement("a");
    const url = window.URL || window.webkitURL;
    const fileBlob = url.createObjectURL(file);
    hiddenElement.href = fileBlob;
    hiddenElement.target = "_blank";
    hiddenElement.download = file?.name;
    hiddenElement.click();
  };

  useEffect(() => {
    if (userProfile?.email) {
      setValue("emailDistributionList", [userProfile?.email]);
    }
    if (userProfile?.phone) {
      setValue("phone", userProfile?.phone);
    }
  }, [setValue, userProfile]);

  useEffect(() => {
    if (isSuccessProcessSupportForm) {
      setIsOpenSuccessAlert(true);
      setChangedData(false);
      backToPreviousPage();
    }
  }, [
    isSuccessProcessSupportForm,
    isLoadingProcessSupportForm,
    setChangedData,
    backToPreviousPage,
  ]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      backToPreviousPage();
      setChangedData(false);
    });
  }, [backToPreviousPage, setChangedData, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleCreateSupportTicket();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCreateSupportTicket]);

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingUserProfile || isLoadingProcessSupportForm}
      loader={<CircularProgress />}
      keepInView
    >
      <SupportFormContainer>
        <SupportFormFieldWrapper>
          <SupportFormLabel>{t("subject")}</SupportFormLabel>
          <Input
            errorMessage={errors?.subject?.message}
            register={{
              ...register("subject", {
                onChange: handleChangedData,
              }),
            }}
            id="support-subject"
          />
        </SupportFormFieldWrapper>
        <SupportFormFieldWrapper>
          <SupportFormLabel>{t("body")}</SupportFormLabel>
          <Input
            errorMessage={errors?.body?.message}
            register={{
              ...register("body", {
                onChange: handleChangedData,
              }),
            }}
            id="support-body"
          />
        </SupportFormFieldWrapper>
        <SupportFormFieldWrapper>
          <SupportFormLabel>{t("distribution-list")}</SupportFormLabel>
          <MultiEmail
            values={emails as string[]}
            onChange={handleChangeEmails}
            errorMessage={errors?.emailDistributionList?.message}
            id="support-emails"
          />
        </SupportFormFieldWrapper>
        <SupportFormFieldWrapper>
          <SupportFormLabel>{t("phone")}</SupportFormLabel>
          <Input
            errorMessage={errors?.phone?.message}
            register={{
              ...register("phone", {
                onChange: handleChangedData,
              }),
            }}
            id="support-phone"
          />
        </SupportFormFieldWrapper>
        <SupportFormFieldWrapper>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            capture="environment"
            onChange={handleAttachFile}
            accept={inputFileAccept}
            multiple
          />
          <AddAttachmentButtonWrapper>
            <ButtonWithIcon
              text={t("add-attachment")}
              onClick={handleClickAttachFile}
              icon={<AddCircle />}
              id="support-add-attachment"
            />
          </AddAttachmentButtonWrapper>
        </SupportFormFieldWrapper>
        {attachments?.length > 0 && (
          <UploadedFileContainer>
            <SupportFormLabel>{`${t("uploaded-files")}`}</SupportFormLabel>
            {attachments?.map((file: File) => (
              <UploadedFileWrapper key={file?.name}>
                <UploadedFile
                  key={file?.name}
                  onClick={handleDownloadFile(file)}
                >
                  {file?.name}
                </UploadedFile>
                <Trash onClick={handleDeleteFile(file)} />
              </UploadedFileWrapper>
            ))}
          </UploadedFileContainer>
        )}
        <ActionsWrapper>
          <Button
            text={t("submit")}
            onClick={handleCreateSupportTicket}
            id="support-submit"
          />
        </ActionsWrapper>
      </SupportFormContainer>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("support-ticket-successfully-submitted", { subject })]}
        severity="success"
      />
    </BlockUi>
  );
};
