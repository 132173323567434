import { Box, styled } from "@mui/material";

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > div": {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: 42,
    },
    "& > div > div": {
      minHeight: "42px !important",
      [theme.breakpoints.down("md")]: {
        minHeight: "38px !important",
      },
    },
  },
}));

export const InventoryAgingParametersContainer = styled(Box)(() => ({
  "& > div > div": {
    "&:last-of-type": {
      gap: 20,
    },
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  marginTop: 48,
  display: "flex",
  justifyContent: "end",
  gap: 24,
  "& > button": {
    "&:first-of-type": {
      width: 111,
    },
    "&:last-of-type": {
      width: 150,
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const FieldsWrapper = styled(Box)(() => ({
  marginBottom: 16,
  gap: 20,
  display: "flex",
  flexDirection: "column",
}));
