import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { DiscrepancyText } from "./styles";
import { IDiscrepancyForm } from "./types";

export const DiscrepancyForm = ({ discrepancy }: IDiscrepancyForm) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("discrepancy")}>
      <React.Fragment>
        <DiscrepancyText>{discrepancy}</DiscrepancyText>
      </React.Fragment>
    </FormCard>
  );
};
