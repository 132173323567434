import { COMPLIANCE_COLOR } from "../compliance/constants";
import { IManifestStatistics } from "./types";

export const manifestChartHeight = 140;

export const highlightScope = { faded: "global", highlighted: "item" };

export const getManifestComplianceStatistics = (
  manifestComplianceStatistics: IManifestStatistics[]
) => {
  return (
    manifestComplianceStatistics?.map(
      ({ count, complianceDescription, compliance }: IManifestStatistics) => ({
        value: count || 0,
        label: complianceDescription,
        color: COMPLIANCE_COLOR[compliance],
      })
    ) || []
  );
};

export const getCountOfComplianceStatistics = (
  manifestComplianceStatistics: IManifestStatistics[]
) => {
  return manifestComplianceStatistics?.reduce((acc, { count }) => {
    acc += count;

    return acc;
  }, 0);
};
