import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { IUserInformation, IUserInformationForm } from "./types";

export const UserInformationForm = ({
  handleEditUserInformation,
  userInformationConfig,
}: IUserInformationForm) => {
  const { t } = useTranslation();

  return (
    <FormCard
      title={t("user-information")}
      handleEdit={handleEditUserInformation}
    >
      <React.Fragment>
        {userInformationConfig?.map(
          (userInformation: IUserInformation, index: number) => (
            <React.Fragment key={index}>
              <FieldWrapper>
                <FieldName>{userInformation.name}</FieldName>
                <FieldValue
                  withLink={!!userInformation.link}
                  href={userInformation.link}
                >
                  {userInformation.value}
                </FieldValue>
              </FieldWrapper>
              {index !== userInformationConfig?.length - 1 && <Divider />}
            </React.Fragment>
          )
        )}
      </React.Fragment>
    </FormCard>
  );
};
