import * as yup from "yup";

export const GeneralCharacteristicsValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    color: yup.string(),
    odor: yup.string(),
    odorStrength: yup.string(),
    physicalState: yup.string(),
    layersPhases: yup.string(),
    percentLiquid: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    percentSolid: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    percentSludge: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    percentPowder: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    percentGas: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    handlingCodes: yup.string(),
    btu: yup.string(),
    btuMinimum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    btuMaximum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    ph: yup.string(),
    pHminimum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    pHmaximum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    flashPoint: yup.string(),
    flashPointMinimum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    flashPointMaximum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    specificGravity: yup.string(),
    specificGravityMinimum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    specificGravityMaximum: yup
      .number()
      .typeError(t("not-a-number"))
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null)),
  });
