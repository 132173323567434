import { NavigateFunction } from "react-router-dom";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";

export const getFooterLinks = (navigate: NavigateFunction) => [
  {
    title: "features",
    items: [
      {
        item: "manifest",
        onClick: () => navigate(`/dashboard/${TABS_NAMES[2]}`),
      },
      {
        item: "inspections",
        onClick: () => navigate(`/dashboard/${TABS_NAMES[3]}`),
      },
      {
        item: "inventory",
        onClick: () => navigate(`/dashboard/${TABS_NAMES[5]}`),
      },
    ],
  },
  {
    title: "resources",
    items: [
      {
        item: "resource-library",
        onClick: () => {
          window.open("https://www.triumvirate.com/resource-library", "_blank");
        },
      },
      {
        item: "EHS-learn",
        onClick: () => {
          window.open(
            "https://www.triumvirate.com/services/ehslearn",
            "_blank"
          );
        },
      },
      {
        item: "webinar-and-events",
        onClick: () => {
          window.open(
            "https://www.triumvirate.com/resources/webinars-and-events",
            "_blank"
          );
        },
      },
    ],
  },
  {
    title: "company",
    items: [
      {
        item: "support",
        onClick: () => navigate("/support"),
      },
      {
        item: "about-us",
        onClick: () => {
          window.open(
            "https://www.triumvirate.com/about-triumvirate",
            "_blank"
          );
        },
      },
      {
        item: "testimonials",
        onClick: () => {
          window.open(
            "https://www.triumvirate.com/wow-customer-testimonials",
            "_blank"
          );
        },
      },
    ],
  },
];

export const getFooterLanguageConfig = (
  handleChangeLanguage: (language: string) => void
) => [
  {
    title: "language",
    items: [
      {
        item: "en",
        onClick: () => handleChangeLanguage("en"),
      },
      {
        item: "fr",
        onClick: () => handleChangeLanguage("fr"),
      },
      {
        item: "es",
        onClick: () => handleChangeLanguage("es"),
      },
    ],
  },
];
