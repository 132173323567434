import { getLocaleDateValue } from "../constants";
import { IInventory } from "./types";

export const getInventoryInformationConfig = (
  {
    packDate,
    account,
    site,
    departmentName,
    building,
    room,
    personalInvestigator,
    profileNumber,
    manifestNumber,
    saa,
    wasteSummary,
    epaWasteCodes,
    generatorStateCodes,
    facilityStateCodes,
  }: IInventory,
  t: (value: string) => string
) => [
  {
    title: `${t("pack-date")}:`,
    value: getLocaleDateValue(packDate) || "-",
  },
  {
    title: `${t("account")}:`,
    value: account || "-",
  },
  {
    title: `${t("site")}:`,
    value: site || "-",
  },
  {
    title: `${t("department")}:`,
    value: departmentName || "-",
  },
  {
    title: `${t("building")}:`,
    value: building || "-",
  },
  {
    title: `${t("room")}:`,
    value: room || "-",
  },
  {
    title: `${t("pi")}:`,
    value: personalInvestigator || "-",
  },
  {
    title: `${t("profile-number")}:`,
    value: profileNumber || "-",
  },
  {
    title: `${t("manifest-number")}:`,
    value: manifestNumber || "-",
  },
  {
    title: `${t("saa")}:`,
    value: saa || "-",
  },
  {
    title: `${t("waste-summary")}:`,
    value: wasteSummary || "-",
  },
  {
    title: `${t("waste-codes")}:`,
    value:
      !epaWasteCodes && !generatorStateCodes && !facilityStateCodes
        ? "-"
        : `${epaWasteCodes ? epaWasteCodes : ""}${
            generatorStateCodes ? generatorStateCodes : ""
          }${facilityStateCodes ? facilityStateCodes : ""}`,
  },
];
