import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Popover } from "@mui/material";
import {
  AgGridTitle,
  AgGridTitleWrapper,
} from "src/shared/components/agGrid/styled";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/arrow-down.svg";
import { ICustomInspectionsTitle } from "./types";
import { ArrowDownWrapper, ExportDescription, PopoverItem } from "./styles";

export const CustomInspectionsTitle = ({
  gridApi,
}: ICustomInspectionsTitle) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "custom-inspection-title" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPrintToExcel = () => {
    if (gridApi?.exportDataAsExcel) {
      gridApi?.exportDataAsExcel();
    }
  };

  const handleClickManageInspections = () => {
    navigate("/manage-inspections");
  };

  return (
    <AgGridTitleWrapper>
      <AgGridTitle>{t("list-of-inspections")}</AgGridTitle>
      <ArrowDownWrapper aria-describedby={id} onClick={handleClick} open={open}>
        <ExportDescription>{t("menu")}</ExportDescription>
        <ArrowDown />
      </ArrowDownWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverItem onClick={handleClickPrintToExcel}>
          {t("print-to-excel")}
        </PopoverItem>
        <PopoverItem onClick={handleClickManageInspections}>
          {t("manage-inspections")}
        </PopoverItem>
      </Popover>
    </AgGridTitleWrapper>
  );
};
