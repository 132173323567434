import { Box, styled } from "@mui/material";

export const DetailNotesContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  borderRadius: 16,
  padding: 16,
  border: "1px solid #DDDDDD",
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
    gap: 8,
  },
}));

export const DetailNotesFieldWrapper = styled(Box)(() => ({
  maxWidth: "none !important",
  "& > div,textArea": {
    maxWidth: "none",
    width: "100%",
    "& > div": {
      height: 32,
    },
  },
}));
