import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { useDeleteInspectionResultMutation } from "src/shared/store/api/inspection.api";
import { IDeleteInspectionDialog } from "./types";
import {
  DialogBody,
  DeleteInspectionDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const DeleteInspectionDialog = ({
  inspection,
  open,
  handleClose,
}: IDeleteInspectionDialog) => {
  const { t } = useTranslation();
  const [
    deleteInspectionProfile,
    {
      isSuccess: isSuccessDeleteInspection,
      isLoading: isDeleteInspectionLoading,
    },
  ] = useDeleteInspectionResultMutation();

  useEffect(() => {
    if (isSuccessDeleteInspection && !isDeleteInspectionLoading) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteInspectionLoading]);

  const deleteDialogActions = () => {
    const handleDeleteInspection = inspection
      ? () => deleteInspectionProfile(inspection?.id)
      : undefined;

    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="delete-inspection-cancel"
        />
        <Button
          text={t("continue")}
          onClick={handleDeleteInspection}
          id="delete-inspection-continue"
        />
      </SendBtnWrapper>
    );
  };

  const renderDeleteInspectionDialogFields = () => {
    return (
      <DeleteInspectionDialogContentContainer>
        <DialogBody>{t("are-you-sure-delete-inspection")}</DialogBody>
      </DeleteInspectionDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("delete-inspection")}
      actions={deleteDialogActions}
      renderFields={renderDeleteInspectionDialogFields}
    />
  );
};
