import { Field, WatchInternal } from "react-hook-form";
import { IUser } from "src/shared/helpers/types";
import { IInspection } from "./types";

export const getFieldOption = (
  watch: WatchInternal<Field>,
  firstField: string,
  secondField: string
) => ({
  id: watch(firstField),
  val: watch(secondField),
});

export const getInspectionOptions = (inspectionOptions: IInspection[]) => {
  return inspectionOptions?.map(({ inspectionID, title }: IInspection) => ({
    id: inspectionID,
    val: title,
  }));
};

export const getUserOptions = (userOptions: IUser[]) => {
  return userOptions?.map(({ id, firstName, lastName }: IUser) => ({
    id,
    val: `${lastName || ""} ${firstName || ""}`,
  }));
};
