import { Middleware, combineReducers, configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { api } from "./api/api";
import { userReducer } from "./reducers/user/user.slice";
import { menuReducer } from "./reducers/menu/menu.slice";
import { snackBarReducer } from "./reducers/snackBar/snackBar.slice";
import { tokenReducer } from "./reducers/token/token.slice";
import { unsavedChangesReducer } from "./reducers/unsavedChanges/unsavedChanges.slice";
import { profileFiltersReducer } from "./reducers/profileFilters/profileFilters.slice";
import { userFiltersReducer } from "./reducers/userFilters/userFilters.slice";
import { manifestFiltersReducer } from "./reducers/manifestFilters/manifestFilters.slice";
import { activeSectionReducer } from "./reducers/activeSection/activeSection.slice";
import { wasteProfilesFiltersReducer } from "./reducers/wasteProfilesFilters/wasteProfilesFilters.slice";
import { inventoryFiltersReducer } from "./reducers/inventoryFIlters/inventoryFilters.slice";
import { inspectionsFiltersReducer } from "./reducers/inspectionsFilters/inspectionsFilters.slice";
import { manageInspectionsFiltersReducer } from "./reducers/manageInspectionsFilters/manageInspectionsFilters.slice";
import { manageScheduledReportsFiltersReducer } from "./reducers/manageScheduledReportsFilters/manageScheduledReportsFilters.slice";
import { errorHandlingReducer } from "./reducers/errorHandling/errorHandling.slice";
import { inspectionNotificationFiltersReducer } from "./reducers/inspectionNotificationFilters/inspectionNotificationFilters.slice";
import { systemMessagesFiltersReducer } from "./reducers/systemMessagesFilters/systemMessagesFilters.slice";

export const rootReducer = combineReducers({
  userReducer,
  menuReducer,
  tokenReducer,
  snackBarReducer,
  unsavedChangesReducer,
  profileFiltersReducer,
  userFiltersReducer,
  manifestFiltersReducer,
  activeSectionReducer,
  wasteProfilesFiltersReducer,
  inventoryFiltersReducer,
  inspectionsFiltersReducer,
  manageInspectionsFiltersReducer,
  manageScheduledReportsFiltersReducer,
  errorHandlingReducer,
  inspectionNotificationFiltersReducer,
  systemMessagesFiltersReducer,
  [api.reducerPath]: api.reducer,
});

const logger = createLogger({ collapsed: true }) as Middleware;

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(api.middleware)
        .concat(logger),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
