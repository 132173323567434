import { createSlice } from "@reduxjs/toolkit";
import { IProfileFilters } from "./profileFilters.types";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { PROFILES_STORAGE_KEYS } from "src/components/customProfilesFilters/constants";

const initialProfileFiltersState: IProfileFilters = {
  keyword: "",
  accounts: [],
  searchOrganization: "",
  scroll: undefined,
  selectedRow: undefined,
};

export const profileFiltersSlice = createSlice({
  name: "profileFilters",
  initialState: initialProfileFiltersState,
  reducers: {
    setProfileFiltersKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setProfileFiltersAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setProfileFiltersSearchOrganization: (state, action) => {
      state.searchOrganization = action.payload;
    },
    setProfileFilterScroll: (state, action) => {
      state.scroll = action.payload;
    },
    setProfileFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        PROFILES_STORAGE_KEYS.SELECTED_PROFILE_ROW,
        state.selectedRow
      );
    },
    saveToLocalStorageProfileFilters: (state) => {
      saveToLocalStorage(PROFILES_STORAGE_KEYS.KEYWORD, state.keyword);
      saveToLocalStorage(PROFILES_STORAGE_KEYS.ACCOUNT_ID_LIST, state.accounts);
      saveToLocalStorage(
        PROFILES_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE,
        state.searchOrganization
      );
    },
    clearFromLocalStorageProfileFilters: (state) => {
      state.keyword = "";
      state.accounts = [];
      state.selectedRow = undefined;
    },
  },
});

export const {
  reducer: profileFiltersReducer,
  actions: profileFiltersActions,
} = profileFiltersSlice;
