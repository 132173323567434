import * as yup from "yup";

export const TriumvirateUserProfileValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    organization: yup
      .object({
        id: yup.string().required(t("required-field")),
        val: yup.string().required(t("required-field")),
      })
      .required(t("required-field")),
  });
