import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { ReactComponent as Arrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import { PAGINATION_PAGE_SIZE_OPTIONS } from "src/shared/components/agGrid/customPagination/constants";
import { ISite } from "../../../pages/accountInformation/types";
import { CustomPagination } from "./customPagination/customPagination";
import { ISitesBody } from "./types";
import {
  ArrowWrapper,
  Divider,
  FieldInformationWrapper,
  FieldWrapper,
  IconWrapperWithArrow,
  SiteItemContainer,
} from "./styles";

export const SitesBody = ({
  siteItems,
  keyword,
  currentPage,
  setCurrentPage,
  paginationPageSize,
  setPaginationPageSize,
}: ISitesBody) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickSite = (id: string) => () => {
    navigate(`/site-information/${id}`);
  };

  const paginationPageNumberSize = Number(
    PAGINATION_PAGE_SIZE_OPTIONS?.find((el) => el?.id === paginationPageSize)
      ?.val
  );

  const filteredItems = siteItems?.filter(
    (item: ISite) =>
      !!item?.name?.toLowerCase().includes(keyword?.toLowerCase())
  );

  const totalPages = Math.ceil(
    filteredItems?.length / paginationPageNumberSize
  );

  const handleChangePaginationPageSize = (e: SelectChangeEvent<unknown>) => {
    setPaginationPageSize(e?.target?.value as string);
    setCurrentPage(0);
  };

  return (
    <>
      {filteredItems
        ?.slice(
          currentPage * paginationPageNumberSize,
          (currentPage + 1) * paginationPageNumberSize
        )
        ?.map(({ name, epaNumber, address, id }: ISite, index: number) => (
          <React.Fragment key={id}>
            <SiteItemContainer key={id} onClick={handleClickSite(id as string)}>
              <FieldWrapper>
                <FieldInformationWrapper>
                  <FieldName>{t("name")}</FieldName>
                  <FieldValue bold>{name}</FieldValue>
                </FieldInformationWrapper>
                <IconWrapperWithArrow>
                  <ArrowWrapper>
                    <Arrow />
                  </ArrowWrapper>
                </IconWrapperWithArrow>
              </FieldWrapper>
              <FieldWrapper>
                <FieldInformationWrapper>
                  <FieldName>{t("epa-id")}</FieldName>
                  <FieldValue>{epaNumber}</FieldValue>
                </FieldInformationWrapper>
              </FieldWrapper>
              <FieldWrapper>
                <FieldInformationWrapper>
                  <FieldName>{t("address")}</FieldName>
                  <FieldValue>{address}</FieldValue>
                </FieldInformationWrapper>
              </FieldWrapper>
            </SiteItemContainer>
            {index !== siteItems?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <CustomPagination
        totalPages={totalPages}
        currentPage={currentPage}
        paginationPageSize={paginationPageSize}
        setCurrentPage={setCurrentPage}
        handleChangePaginationPageSize={handleChangePaginationPageSize}
      />
    </>
  );
};
