import { HighlightScope } from "@mui/x-charts";
import { COMPLIANCE_COLOR } from "../compliance/constants";
import {
  IModifiedWasteShipped,
  IWasteShipped,
  IWasteShippedInformation,
} from "./types";

export const wasteShippedChartHeight = 180;

export const highlightScope = { faded: "global" };

export const wasteShippedChartMargin = {
  left: -20,
  right: -20,
  top: 0,
  bottom: 20,
};

export const getModifiedWasteShippedByMonth = (
  wasteShippedByMonth: IWasteShipped[]
) => {
  return wasteShippedByMonth?.reduce(
    (acc: IModifiedWasteShipped, waste: IWasteShipped) => {
      if (acc[waste?.wasteType]) {
        acc[waste?.wasteType]?.push(waste);
      } else {
        acc[waste?.wasteType] = [waste];
      }

      return acc;
    },
    {}
  );
};

export const getPercentageByTotal = (count: number, totalCount: number) => {
  return Math.round((count * 100) / totalCount);
};

export const getWasteShippedInformation = (
  wasteShipped: IModifiedWasteShipped = {}
) => {
  const wasteShippedArray = Object.keys(wasteShipped).map((key) => ({
    ...wasteShipped[key],
  }));

  return wasteShippedArray?.map((waste: IWasteShipped[]) => ({
    complianceDescription: waste?.[0]?.wasteType,
    complianceColor: waste?.[0]?.color || "red",
  }));
};
[
  { complianceDescription: "Hazardous", complianceColor: "red" },
  { complianceDescription: "Non-Hazardous", complianceColor: "blue" },
] as IWasteShippedInformation[];

export const getWasteShippedSeries = (
  wasteShipped: IModifiedWasteShipped = {}
) => {
  const wasteShippedArray = Object.keys(wasteShipped).map((key) => [
    ...wasteShipped[key],
  ]);

  return wasteShippedArray?.map((waste) => ({
    type: "bar",
    data:
      waste?.length > 0
        ? waste?.map(({ wasteTotal }: IWasteShipped) => wasteTotal)
        : [0],
    color: COMPLIANCE_COLOR[waste?.[0]?.color || "red"],
    highlightScope: highlightScope as Partial<HighlightScope>,
  }));
};

export const getTotalWasteShipped = (
  wasteShipped: IModifiedWasteShipped,
  complianceDescription: string
) =>
  wasteShipped?.[complianceDescription]?.reduce(
    (acc: number, { wasteTotal }: IWasteShipped) => {
      acc += wasteTotal;

      return acc;
    },
    0
  );

const getMonthName = (monthNumber: number) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  date.setFullYear(date.getFullYear(), monthNumber - 1, 1);

  return date.toLocaleString("en-US", {
    month: "short",
  });
};

export const getXAxisWasteShipped = (wasteData: IWasteShipped[]) => {
  const arrayOfMonth = wasteData?.map(({ month }: IWasteShipped) => month);
  const monthSet = new Set(arrayOfMonth);
  const monthArray = Array.from(monthSet);

  return [
    {
      scaleType: "band",
      id: "x-axis-id",
      data: monthArray?.map((monthNumber) =>
        getMonthName(monthNumber as number)
      ),
    },
  ];
};
