import { createSlice } from "@reduxjs/toolkit";
import { TOKEN } from "../../api/constants";
import { ITokenState } from "./token.types";

const initialUserState: ITokenState = {
  token: localStorage.getItem(TOKEN) || "",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState: initialUserState,
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem(TOKEN, action.payload);
      state.token = action.payload;
    },
  },
});

export const { reducer: tokenReducer, actions: tokenActions } = tokenSlice;
