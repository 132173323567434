import * as yup from "yup";

export const InventoryNotificationFormValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    daysUntilNonCompliant: yup
      .number()
      .typeError(t("please-enter-a-positive-whole-number"))
      .required(t("required-field"))
      .integer(t("please-enter-a-positive-whole-number"))
      .min(1, t("please-enter-a-positive-whole-number")),
    emailDistributionList: yup.array().of(yup.string()).min(1, t("required")),
    siteIDList: yup
      .array()
      .of(yup.number())
      .required(t("select-at-least-one-site-to-save")),
  });
