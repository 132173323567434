import { Box, Typography, styled } from "@mui/material";

export const ActionsWrapper = styled(Box)(() => ({
  marginTop: 24,
  display: "flex",
  gap: 16,
  "& > button": {
    width: "fit-content",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#25895C",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  "& > svg": {
    width: 250,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 48,
    },
  },
}));

export const UnsubscribeFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "50vw",
  maxWidth: 480,
  gap: 16,
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    width: "-webkit-fill-available",
    padding: "0 8px",
    gap: 8,
  },
}));

export const UnsubscribeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));
