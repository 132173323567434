import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AllSeriesType,
  ChartsTooltip,
  PiePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { CircularProgress } from "@mui/material";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ChartLegend } from "src/shared/components/chartLegend/chartLegend";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  manifestChartHeight,
  getManifestComplianceStatistics,
  getCountOfComplianceStatistics,
  highlightScope,
} from "./constants";
import { IManifestStatistics, IManifestChart } from "./types";
import {
  CloseIconWrapper,
  ManifestChartContainer,
  ResponsiveWrapper,
} from "./styles";

export const ManifestChart = ({
  manifestData,
  isLoadingManifestData,
  handleDelete,
}: IManifestChart) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsAfterClickOnManifestChart } = useAppActions();

  const countOfItems = getCountOfComplianceStatistics(manifestData);

  const series = [
    {
      type: "pie",
      data: getManifestComplianceStatistics(manifestData),
      highlightScope: highlightScope,
      outerRadius: 70,
    },
  ] as AllSeriesType[];

  const manifestChartLegendConfig = manifestData?.map(
    ({ count, complianceDescription, compliance }: IManifestStatistics) => ({
      description: complianceDescription,
      value: `${count}`,
      color: compliance,
    })
  );

  const handleClickManifestChart = () => {
    setIsAfterClickOnManifestChart(true);
    navigate(`/dashboard/${TABS_NAMES[2]}`);
  };

  return (
    <ManifestChartContainer
      tag="div"
      blocking={isLoadingManifestData}
      loader={<CircularProgress />}
      keepInView
      onClick={handleClickManifestChart}
    >
      <CloseIconWrapper onClick={handleDelete}>
        <Close />
      </CloseIconWrapper>
      <ResponsiveWrapper>
        <ResponsiveChartContainer series={series} height={manifestChartHeight}>
          <PiePlot />
          <ChartsTooltip trigger="item" />
        </ResponsiveChartContainer>
      </ResponsiveWrapper>
      <ChartLegend
        title={t("manifests")}
        secondTitle={countOfItems ? ` (${countOfItems})` : ""}
        showLegend={!!manifestData}
        chartLegendInformationConfig={manifestChartLegendConfig}
      />
    </ManifestChartContainer>
  );
};
