import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const ListOfItemsContainer = styled(Box)(({ theme }) => ({
  background: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
  cursor: "default",
  marginTop: 24,
  display: "flex",
  padding: 16,
  flexDirection: "column",
  gap: 16,
  borderRadius: 16,
  border: "1px solid #D9D9D9",
  [theme.breakpoints.down("md")]: {
    gap: 8,
    marginTop: 16,
  },
}));
