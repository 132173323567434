import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Alert } from "@mui/material";
import { AccountCard } from "src/components/accountCard/accountCard";
import InspectionReports from "src/shared/assets/svgs/inspection-reports.svg";
import NewInspection from "src/shared/assets/svgs/new-inspection.svg";
import Notifications from "src/shared/assets/svgs/notifications.svg";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { InspectionsTable } from "../../components/inspectionsTable/inspectionsTable";
import {
  InspectionsContainer,
  InspectionsTitle,
  InspectionsWrapper,
  InspectionsDescription,
  InspectionsCardsWrapper,
  PermissionAlertWrapper,
} from "./styles";

export const Inspections = () => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const userName = accounts[0]?.username;

  const { data: userProfileData, isSuccess: isSuccessUserProfile } =
    useGetUserProfileByIDQuery({ id: userName }, { skip: !userName });
  const permissions = userProfileData?.userPermissions;

  const hasAccessInspectionPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.ACCESS_INSPECTIONS
  );

  const handleNewInspection = () => {
    navigate("/new-inspection");
  };

  const handleInspectionReports = () => {
    navigate("/inspection-reports");
  };

  const handleInspectionNotifications = () => {
    navigate("/inspections-notifications");
  };

  return (
    <InspectionsContainer>
      <InspectionsTitle>{t("inspections")}</InspectionsTitle>
      <InspectionsWrapper>
        <InspectionsDescription>
          {t("inspections-description")}
        </InspectionsDescription>
        {hasAccessInspectionPermission && (
          <InspectionsCardsWrapper>
            <AccountCard
              title={t("inspection-reports")}
              buttonText={t("new")}
              img={InspectionReports}
              onClick={handleInspectionReports}
              id="inspections-inspection-reports-new"
            />
            <AccountCard
              title={t("perform-inspections")}
              buttonText={t("go")}
              img={NewInspection}
              onClick={handleNewInspection}
              id="inspections-perform-inspections-go"
            />
            <AccountCard
              title={t("notifications")}
              buttonText={t("create")}
              img={Notifications}
              onClick={handleInspectionNotifications}
              id="inspections-notifications-create"
            />
          </InspectionsCardsWrapper>
        )}
      </InspectionsWrapper>
      {hasAccessInspectionPermission && <InspectionsTable />}
      {isSuccessUserProfile && !hasAccessInspectionPermission && (
        <PermissionAlertWrapper>
          <Alert severity="info">{t("do-not-have-permission-to-access")}</Alert>
        </PermissionAlertWrapper>
      )}
    </InspectionsContainer>
  );
};
