import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { IInventoryFilters } from "./inventoryFilters.types";

const initialInventoryFiltersState: IInventoryFilters = {
  keyword: "",
  accounts: [],
  sites: [],
  buildings: [],
  rooms: [],
  locations: [],
  searchOrganization: "",
  isAfterClickOnInventoryChart: false,
  pickupStartDate: dayjs(new Date()).subtract(365, "days"),
  pickupEndDate: dayjs(new Date()),
};

export const inventoryFiltersSlice = createSlice({
  name: "inventoryFilters",
  initialState: initialInventoryFiltersState,
  reducers: {
    setInventoryFiltersAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setInventoryFilterSites: (state, action) => {
      state.sites = action.payload;
    },
    setInventoryFilterBuildings: (state, action) => {
      state.buildings = action.payload;
    },
    setInventoryFilterRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setInventoryFilterLocations: (state, action) => {
      state.locations = action.payload;
    },
    setInventoryFiltersKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setInventoryFiltersSearchOrganization: (state, action) => {
      state.searchOrganization = action.payload;
    },
    setIsAfterClickOnInventoryChart: (state, action) => {
      state.isAfterClickOnInventoryChart = action.payload;
    },
    setInventoryPickupStartDate: (state, action) => {
      state.pickupStartDate = action.payload;
    },
    setInventoryPickupEndDate: (state, action) => {
      state.pickupEndDate = action.payload;
    },
    clearFromLocalStorageInventoryFilters: (state) => {
      state.keyword = "";
      state.accounts = [];
      state.sites = [];
      state.searchOrganization = "";
      state.pickupStartDate = dayjs(new Date()).subtract(365, "days");
      state.pickupEndDate = dayjs(new Date());
      state.buildings = [];
      state.rooms = [];
      state.locations = [];
    },
  },
});

export const {
  reducer: inventoryFiltersReducer,
  actions: inventoryFiltersActions,
} = inventoryFiltersSlice;
