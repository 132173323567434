import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { ManageInspectionForm } from "../../components/manageInspectionForm/manageInspectionForm";
import { INewManageInspection } from "./types";
import {
  NewManageInspectionBody,
  NewManageInspectionContainer,
  NewManageInspectionTitle,
} from "./styles";

export const NewManageInspection = ({
  isEdit,
  isTemplate,
}: INewManageInspection) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { setChangedData } = useAppActions();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const handleBackToManageInspections = useCallback(() => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      setChangedData(false);
      navigate("/manage-inspections");
    }
  }, [changedData, navigate, setChangedData, setIsOpenUnsavedChangedDialog]);

  return (
    <NewManageInspectionContainer>
      <CssBaseline />
      <Header />
      <NewManageInspectionBody>
        <BackTo
          text={t("back-to-manage-inspections")}
          handleBack={handleBackToManageInspections}
        />
        <NewManageInspectionTitle>
          {t("create-a-new-inspection-template")}
        </NewManageInspectionTitle>
        <ManageInspectionForm isEdit={isEdit} isTemplate={isTemplate} />
      </NewManageInspectionBody>
    </NewManageInspectionContainer>
  );
};
