import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const INSPECTION_LOCATION_SUB_URL = "InspectionLocation";

export const inspectionLocation = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllLocationTypes: builder.query({
      query: () => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetAllLocationTypes`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSitesByAccountAndLocationTypeID: builder.query({
      query: ({ organizationUnitID, storageTypeID }) => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetSitesByAccountAndLocationTypeID/${organizationUnitID}/${storageTypeID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getBuildingsBySiteAndLocationTypeID: builder.query({
      query: ({ organizationUnitID, storageTypeID }) => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetBuildingsBySiteAndLocationTypeID/${organizationUnitID}/${storageTypeID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getRoomsByBuildingAndLocationTypeID: builder.query({
      query: ({ organizationUnitID, storageTypeID }) => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetRoomsByBuildingAndLocationTypeID/${organizationUnitID}/${storageTypeID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getLocationsByRoomAndLocationTypeID: builder.query({
      query: ({ organizationUnitID, storageTypeID }) => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetLocationsByRoomAndLocationTypeID/${organizationUnitID}/${storageTypeID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInspectionStorageByBarcode: builder.query({
      query: ({ accountID, barcode }) => ({
        url: `${INSPECTION_LOCATION_SUB_URL}/GetInspectionStorageByBarcode/${accountID}/${barcode}`,
        method: HTTP_METHOD.GET,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInspectionStorageByBarcodeQuery,
  useGetAllLocationTypesQuery,
  useGetSitesByAccountAndLocationTypeIDQuery,
  useGetBuildingsBySiteAndLocationTypeIDQuery,
  useGetRoomsByBuildingAndLocationTypeIDQuery,
  useGetLocationsByRoomAndLocationTypeIDQuery,
} = inspectionLocation;
