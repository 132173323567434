import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetWasteCharacteristicsQuery } from "src/shared/store/api/regulatory.api";
import { Select } from "src/shared/components/select/select";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Input } from "src/shared/components/input/input";
import { Button } from "src/shared/components/button/button";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import question from "src/shared/assets/svgs/question.svg";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import {
  ButtonWrapper,
  FieldWrapper,
  IndicatesRequiredField,
  SectionDivider,
  SectionHeader,
  TextField,
} from "../../pages/newProfile/styles";
import { HandlingCodeTooltip } from "../handlingCodeTooltip/handlingCodeTooltip";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { GENERAL_CHARACTERISTICS_FIELDS } from "./constants";
import { GeneralCharacteristicsValidationSchema } from "./model";
import {
  IGeneralCharacteristicsFields,
  IItems,
  IWasteCharacteristics,
} from "./types";
import {
  GeneralCharacteristicsContainer,
  FieldsWrapper,
  InputLabel,
  CharacteristicsWrapper,
  InputLabelWrapper,
  Question,
  HandlingCodeTooltipContainer,
  GeneralCharacteristicsWrapper,
  GeneralCharacteristicsHeaderWrapper,
} from "./styles";

export const GeneralCharacteristics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");

  const [isOpenHandlingCodeTooltip, setIsOpenHandlingCodeTooltip] =
    useState(false);

  const {
    setChangedData,
    setHandleBackUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GeneralCharacteristicsValidationSchema(t)),
    mode: "onSubmit",
  });

  const btuMaximum = watch("btuMaximum") || 0;
  const btuMinimum = watch("btuMinimum") || 0;
  const pHMaximum = watch("pHmaximum") || 0;
  const pHMinimum = watch("pHminimum") || 0;
  const flashPointMaximum = watch("flashPointMaximum") || 0;
  const flashPointMinimum = watch("flashPointMinimum") || 0;
  const specificGravityMaximum = watch("specificGravityMaximum") || 0;
  const specificGravityMinimum = watch("specificGravityMinimum") || 0;

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const { data: wasteCharacteristics } = useGetWasteCharacteristicsQuery({});

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  const handleOpenHandlingCodeTooltip = () => {
    setIsOpenHandlingCodeTooltip(true);
  };

  useEffect(() => {
    GENERAL_CHARACTERISTICS_FIELDS.forEach((field) => {
      if (
        wasteProfileData?.[field] !== null &&
        wasteProfileData?.[field] !== undefined
      ) {
        setValue(
          field as IGeneralCharacteristicsFields,
          wasteProfileData?.[field]
        );
      }
    });
  }, [setValue, wasteProfileData]);

  const characteristics = wasteCharacteristics?.reduce(
    (
      acc: { [key: string]: IWasteCharacteristics },
      elem: IWasteCharacteristics
    ) => {
      acc[elem?.key] = elem;

      return acc;
    },
    {}
  );

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleChangedGenericData =
    (currentField: string) => (e: SelectChangeEvent) => {
      handleChangedData();
      const field = currentField === "pH" ? "ph" : currentField;
      const item = characteristics?.[field]?.itemRanges?.find(
        ({ id }: IItems) => id === e.target.value
      );
      setValue(
        `${currentField}${
          field === "ph" ? "maximum" : "Maximum"
        }` as IGeneralCharacteristicsFields,
        item?.maxValue
      );
      setValue(
        `${currentField}${
          field === "ph" ? "minimum" : "Minimum"
        }` as IGeneralCharacteristicsFields,
        item?.minValue
      );
    };

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContinue = useCallback(
    handleSubmit((data) => {
      upsertProfileRequest({
        id: profileID,
        ...data,
        sectionStep: SECTION.DOT_INFORMATION,
      });
      setChangedData(false);
    }),
    [handleSubmit, profileID, setChangedData, upsertProfileRequest]
  );

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToDashboard();
      });
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  return (
    <GeneralCharacteristicsContainer>
      <GeneralCharacteristicsWrapper>
        <GeneralCharacteristicsHeaderWrapper>
          <SectionHeader>{t("general-characteristics-section")}</SectionHeader>
          <IndicatesRequiredField>
            <span>*</span> {t("indicates-a-required-field")}
          </IndicatesRequiredField>
        </GeneralCharacteristicsHeaderWrapper>
        <FieldsWrapper>
          <FieldWrapper>
            <TextField>{`${t("color")}`}</TextField>
            <Select
              value={watch("color") || ""}
              errorMessage={errors?.color?.message}
              options={
                characteristics?.color?.items?.map(({ val }: IItems) => ({
                  id: val,
                  val,
                })) || []
              }
              register={{
                ...register("color", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-color"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("odor")}`}</TextField>
            <Select
              value={watch("odor") || ""}
              errorMessage={errors?.odor?.message}
              options={
                characteristics?.odor?.items?.map(({ val }: IItems) => ({
                  id: val,
                  val,
                })) || []
              }
              register={{
                ...register("odor", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-odor"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("strength")}`}</TextField>
            <Select
              value={watch("odorStrength") || ""}
              errorMessage={errors?.odorStrength?.message}
              options={
                characteristics?.odorStrength?.items?.map(
                  ({ val }: IItems) => ({
                    id: val,
                    val,
                  })
                ) ||
                [] ||
                []
              }
              register={{
                ...register("odorStrength", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-odor-strength"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("physical-state-70f")}`}</TextField>
            <Select
              value={watch("physicalState") || ""}
              errorMessage={errors?.physicalState?.message}
              options={
                characteristics?.physicalState?.items?.map(
                  ({ val }: IItems) => ({
                    id: val,
                    val,
                  })
                ) ||
                [] ||
                []
              }
              register={{
                ...register("physicalState", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-physical-state"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("layers-phases")}`}</TextField>
            <Select
              value={watch("layersPhases") || ""}
              errorMessage={errors?.layersPhases?.message}
              options={
                characteristics?.phase?.items?.map(({ val }: IItems) => ({
                  id: val,
                  val,
                })) ||
                [] ||
                []
              }
              register={{
                ...register("layersPhases", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-layers-phases"
            />
          </FieldWrapper>
        </FieldsWrapper>
        <SectionDivider />
        <FieldsWrapper>
          <FieldWrapper>
            <TextField>{`${t("percent-liquid")}`}</TextField>
            <Input
              errorMessage={errors?.percentLiquid?.message}
              register={{
                ...register("percentLiquid", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-percent-liquid"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("percent-solid")}`}</TextField>
            <Input
              errorMessage={errors?.percentSolid?.message}
              register={{
                ...register("percentSolid", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-percent-solid"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("percent-sludge")}`}</TextField>
            <Input
              errorMessage={errors?.percentSludge?.message}
              register={{
                ...register("percentSludge", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-percent-sludge"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("percent-powder")}`}</TextField>
            <Input
              errorMessage={errors?.percentPowder?.message}
              register={{
                ...register("percentPowder", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-percent-powder"
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("percent-gas")}`}</TextField>
            <Input
              errorMessage={errors?.percentGas?.message}
              register={{
                ...register("percentGas", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-percent-gas"
            />
          </FieldWrapper>
        </FieldsWrapper>
        <SectionDivider />
        <HandlingCodeTooltipContainer>
          <FieldWrapper>
            <TextField>
              {`${t("handling-code")}`}
              <Question
                src={question}
                alt=""
                onClick={handleOpenHandlingCodeTooltip}
              />
              <HandlingCodeTooltip
                open={isOpenHandlingCodeTooltip}
                setOpen={setIsOpenHandlingCodeTooltip}
              />
            </TextField>
            <Select
              value={watch("handlingCodes") || ""}
              errorMessage={errors?.handlingCodes?.message}
              options={
                characteristics?.hCode?.items?.map(({ val }: IItems) => ({
                  id: val,
                  val,
                })) ||
                [] ||
                []
              }
              register={{
                ...register("handlingCodes", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-handling-codes"
            />
          </FieldWrapper>
        </HandlingCodeTooltipContainer>
        <SectionDivider />
        <CharacteristicsWrapper>
          <FieldWrapper>
            <TextField>{`${t("btu")}`}</TextField>
            <Select
              value={watch("btu") || ""}
              errorMessage={errors?.btu?.message}
              options={characteristics?.btu?.itemRanges || []}
              register={{
                ...register("btu", {
                  onChange: handleChangedGenericData("btu"),
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-btu"
            />
            <InputLabelWrapper>
              <InputLabel>{t("min")}</InputLabel>
              <Input
                errorMessage={errors?.btuMinimum?.message}
                register={{
                  ...register("btuMinimum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-btu-min"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("max")}</InputLabel>
              <Input
                errorMessage={errors?.btuMaximum?.message}
                register={{
                  ...register("btuMaximum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-btu-max"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("avg")}</InputLabel>
              <Input
                disabled
                value={(Number(btuMaximum) + Number(btuMinimum)) / 2 || ""}
                id="general-characteristics-btu-avg"
              />
            </InputLabelWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("ph")}`}</TextField>
            <Select
              value={watch("ph") || ""}
              errorMessage={errors?.ph?.message}
              options={characteristics?.ph?.itemRanges || []}
              register={{
                ...register("ph", {
                  onChange: handleChangedGenericData("pH"),
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-pH"
            />
            <InputLabelWrapper>
              <InputLabel>{t("min")}</InputLabel>
              <Input
                errorMessage={errors?.pHminimum?.message}
                register={{
                  ...register("pHminimum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-pH-min"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("max")}</InputLabel>
              <Input
                errorMessage={errors?.pHmaximum?.message}
                register={{
                  ...register("pHmaximum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-pH-max"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("avg")}</InputLabel>
              <Input
                disabled
                value={(Number(pHMaximum) + Number(pHMinimum)) / 2 || ""}
                id="general-characteristics-pH-avg"
              />
            </InputLabelWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("flash-point")}`}</TextField>
            <Select
              value={watch("flashPoint") || ""}
              errorMessage={errors?.flashPoint?.message}
              options={characteristics?.flashPoint?.itemRanges || []}
              register={{
                ...register("flashPoint", {
                  onChange: handleChangedGenericData("flashPoint"),
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-flash-point"
            />
            <InputLabelWrapper>
              <InputLabel>{t("min")}</InputLabel>
              <Input
                errorMessage={errors?.flashPointMinimum?.message}
                register={{
                  ...register("flashPointMinimum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-flash-point-min"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("max")}</InputLabel>
              <Input
                errorMessage={errors?.flashPointMaximum?.message}
                register={{
                  ...register("flashPointMaximum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-flash-point-max"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("avg")}</InputLabel>
              <Input
                disabled
                value={
                  (Number(flashPointMaximum) + Number(flashPointMinimum)) / 2 ||
                  ""
                }
                id="general-characteristics-flash-point-avg"
              />
            </InputLabelWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <TextField>{`${t("specific-gravity")}`}</TextField>
            <Select
              value={watch("specificGravity") || ""}
              errorMessage={errors?.specificGravity?.message}
              options={characteristics?.specificGravity?.itemRanges || []}
              register={{
                ...register("specificGravity", {
                  onChange: handleChangedGenericData("specificGravity"),
                }),
              }}
              disabled={isActiveWasteProfile}
              id="general-characteristics-specific-gravity"
            />
            <InputLabelWrapper>
              <InputLabel>{t("min")}</InputLabel>
              <Input
                errorMessage={errors?.specificGravityMinimum?.message}
                register={{
                  ...register("specificGravityMinimum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-specific-gravity-min"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("max")}</InputLabel>
              <Input
                errorMessage={errors?.specificGravityMaximum?.message}
                register={{
                  ...register("specificGravityMaximum", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="general-characteristics-specific-gravity-max"
              />
            </InputLabelWrapper>
            <InputLabelWrapper>
              <InputLabel>{t("avg")}</InputLabel>
              <Input
                disabled
                value={
                  (Number(specificGravityMaximum) +
                    Number(specificGravityMinimum)) /
                    2 || ""
                }
                id="general-characteristics-specific-gravity-avg"
              />
            </InputLabelWrapper>
          </FieldWrapper>
        </CharacteristicsWrapper>
        <SectionDivider />
      </GeneralCharacteristicsWrapper>
      <ButtonWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCancel}
          id="general-characteristics-cancel"
        />
        {!isActiveWasteProfile && (
          <Button
            text={t("continue")}
            onClick={handleContinue}
            id="general-characteristics-continue"
          />
        )}
      </ButtonWrapper>
    </GeneralCharacteristicsContainer>
  );
};
