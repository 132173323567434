import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { ReactComponent as Users } from "src/shared/assets/svgs/users.svg";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as Hierarchy } from "src/shared/assets/svgs/hierarchy.svg";
import { IAccountInformation } from "./types";
import {
  AccountBody,
  AccountInformationContainer,
  AccountInformationTitle,
  AccountName,
  AccountPhone,
  ButtonWrapper,
  Divider,
  ButtonsWrapper,
} from "./styles";

export const AccountInformation = ({ account }: IAccountInformation) => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const {
    organizationUnitID,
    name,
    street1,
    city,
    state,
    postalCode,
    phoneNumber,
  } = account;

  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const handleClickUserList = () => {
    navigate(`/user-list/${organizationUnitID}`);
  };

  const handleClickSiteInformation = () => {
    navigate(`/account-information/${organizationUnitID}`);
  };

  const hasUserAccountAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ACCOUNT_ADMIN
  );

  return (
    <AccountInformationContainer>
      <AccountInformationTitle>
        {t("account-information")}
      </AccountInformationTitle>
      <AccountName>{name}</AccountName>
      <AccountBody>{street1}</AccountBody>
      <AccountBody>{`${city}, ${state} ${postalCode}`}</AccountBody>
      <AccountPhone>{phoneNumber}</AccountPhone>
      <Divider />
      <ButtonsWrapper>
        {hasUserAccountAdminPermission && (
          <ButtonWrapper>
            <ButtonWithIcon
              text={t("user-list")}
              onClick={handleClickUserList}
              icon={<Users />}
              id="account-information-user-list"
            />
          </ButtonWrapper>
        )}
        <ButtonWrapper>
          <ButtonWithIcon
            text={t("site-list")}
            onClick={handleClickSiteInformation}
            icon={<Hierarchy />}
            id="account-information-site-list"
          />
        </ButtonWrapper>
      </ButtonsWrapper>
    </AccountInformationContainer>
  );
};
