import { ISite } from "./types";

export const getSitesItems = (sites: Array<ISite>): ISite[] => {
  return sites?.map(
    ({
      epaNumber,
      name,
      city,
      state,
      postalCode,
      street1,
      organizationUnitID,
    }: ISite) => ({
      epaNumber,
      name,
      address: `${street1 ? `${street1} ` : ""}${city ? `${city}, ` : ""}${
        state ? `${state}, ` : ""
      } ${postalCode ? postalCode : ""}`,
      id: organizationUnitID,
    })
  );
};
