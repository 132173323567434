import { useTranslation } from "react-i18next";
import {
  FollowUsBody,
  FollowUsContainer,
  FollowUsLink,
  FollowUsLinksWrapper,
  FollowUsTitle,
} from "./styles";
import { FOLLOW_US_LINKS_CONFIG } from "./constants";

export const FollowUs = () => {
  const { t } = useTranslation();

  return (
    <FollowUsContainer>
      <FollowUsTitle>{t("follow-us")}</FollowUsTitle>
      <FollowUsLinksWrapper>
        {FOLLOW_US_LINKS_CONFIG.map(({ link, icon }, index) => (
          <a href={link} key={index} target="_blank" rel="noreferrer">
            <FollowUsLink src={icon} />
          </a>
        ))}
      </FollowUsLinksWrapper>
      <FollowUsBody>{t("newsletter")}</FollowUsBody>
    </FollowUsContainer>
  );
};
