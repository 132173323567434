import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import ProfileReports from "src/shared/assets/svgs/profile-reports.svg";
import NewProfile from "src/shared/assets/svgs/new-profile.svg";
import { AccountCard } from "src/components/accountCard/accountCard";
import { ProfilesTable } from "src/components/profilesTable/profilesTable";
import { useLazyNewProfileQuery } from "src/shared/store/api/wasteProfile.api";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { CreateNewProfileDialog } from "../../components/createNewProfileDialog/createNewProfileDialog";
import {
  ProfilesDescription,
  ProfilesContainer,
  ProfilesWrapper,
  ProfilesTitle,
  ProfilesCardsWrapper,
} from "./styles";

export const Profiles = () => {
  const { t } = useTranslation();
  const [getNewProfileId] = useLazyNewProfileQuery();
  const navigate = useNavigate();
  const { accounts } = useMsal();

  const userName = accounts[0]?.username;

  const { data: userProfileData, isSuccess: isSuccessUserProfileData } =
    useGetUserProfileByIDQuery({ id: userName }, { skip: !userName });

  const permissions = userProfileData?.userPermissions;

  const hasCreateProfilePermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.CREATE_PROFILE
  );

  const [isOpenCreateNewProfileDialog, setIsOpenCreateNewProfileDialog] =
    useState(false);

  const handleOpenCreateNewProfile = () => {
    setIsOpenCreateNewProfileDialog(true);
  };

  const handleCloseCreateNewProfile = () => {
    setIsOpenCreateNewProfileDialog(false);
  };

  const handleNewProfile = () => {
    if (!hasCreateProfilePermission) {
      handleOpenCreateNewProfile();
    } else {
      getNewProfileId({}).then(({ data }) => {
        const generatedProfileId = data?.id;

        if (generatedProfileId) {
          navigate(`/new-profile/${generatedProfileId}`);
        }
      });
    }
  };

  const handleProfileReports = () => {
    navigate("/profile-reports");
  };

  return (
    <ProfilesContainer>
      <ProfilesTitle>{t("waste-profiles")}</ProfilesTitle>
      <ProfilesWrapper>
        <ProfilesDescription>{t("profiles-description")}</ProfilesDescription>
        <ProfilesCardsWrapper>
          <AccountCard
            title={t("profile-reports")}
            buttonText={t("new")}
            img={ProfileReports}
            onClick={handleProfileReports}
            id="profiles-profile-reports-new"
          />
          <AccountCard
            title={t("new-profile")}
            buttonText={t("create")}
            img={NewProfile}
            onClick={handleNewProfile}
            disabled={!isSuccessUserProfileData}
            id="profiles-new-profile-create"
          />
        </ProfilesCardsWrapper>
      </ProfilesWrapper>
      <ProfilesTable />
      <CreateNewProfileDialog
        open={isOpenCreateNewProfileDialog}
        handleClose={handleCloseCreateNewProfile}
      />
    </ProfilesContainer>
  );
};
