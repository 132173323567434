import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { debounce } from "lodash";
import { Input } from "src/shared/components/input/input";
import question from "src/shared/assets/svgs/question.svg";
import { ReactComponent as FilterIcon } from "src/shared/assets/svgs/filter-icon.svg";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { Button } from "src/shared/components/button/button";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IOrganization } from "../quickOverview/types";
import { ProfilesTooltip } from "../profilesTooltip/profilesTooltip";
import { ICustomProfilesFilters } from "./types";
import {
  ActionsWrapper,
  FilterIconWrapper,
  FilterWrapper,
  FiltersContainer,
  FiltersWrapper,
  Label,
  ProfilesFiltersContainer,
  ProfilesFiltersWrapper,
  ProfilesSearchWrapper,
  Question,
} from "./styles";

export const CustomProfilesFilters = ({
  gridApi,
  getProfilesData,
  updateData,
  setUpdateData,
}: ICustomProfilesFilters) => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const {
    setProfileFiltersAccounts,
    setProfileFiltersKeyword,
    setProfileFiltersSearchOrganization,
  } = useAppActions();
  const {
    accounts: profileAccounts,
    keyword,
    searchOrganization,
  } = useAppSelector((state) => state.profileFiltersReducer);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();
  const [handleOpenProfilesTooltip, setHandleOpenProfilesTooltip] =
    useState(false);

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const getProfilesDataByKeyword = useCallback(
    (newKeyword: string) => {
      const accountIds = profileAccounts?.map(
        (organization) => organization?.id
      );
      const requestData = profileAccounts?.length
        ? { accountIDList: accountIds, keyword: newKeyword }
        : { accountIDList: [], keyword: newKeyword };

      getProfilesData(requestData);
      gridApi?.paginationGoToPage(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getProfilesData, profileAccounts]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTrigger = useCallback(
    debounce((newKeyword) => getProfilesDataByKeyword(newKeyword), 2000),
    [getProfilesDataByKeyword]
  );

  const onChangeSearchProfiles = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setProfileFiltersKeyword(value);
      debouncedTrigger(value);
    },
    [setProfileFiltersKeyword, debouncedTrigger]
  );

  const handleOpenProfileTooltip = () => {
    setHandleOpenProfilesTooltip(true);
  };

  const handleFilters = () => setIsOpenFilters(!isOpenFilters);

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setProfileFiltersAccounts(newInputValue as Array<IOrganization>);
  };

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (defaultOrganization && !profileAccounts?.length) {
      const defaultAccountList = [
        {
          id: defaultOrganization?.[0]?.organizationUnitID,
          val: defaultOrganization?.[0]?.name,
        },
      ];
      setProfileFiltersAccounts(defaultAccountList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  const handleClearFilters = () => {
    gridApi?.setFilterModel(null);
    setProfileFiltersKeyword("");
    setProfileFiltersSearchOrganization("");
    setProfileFiltersAccounts([
      {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      },
    ]);
    getProfilesData({
      keyword: "",
      accountIDList: [defaultOrganization?.[0]?.organizationUnitID],
    });
    gridApi?.paginationGoToPage(0);
  };

  const handleApplyFilters = () => {
    const accountIds = profileAccounts?.map((organization) => organization?.id);
    const requestData = profileAccounts?.length
      ? { accountIDList: accountIds, keyword }
      : { accountIDList: [], keyword };
    getProfilesData(requestData);
    gridApi?.paginationGoToPage(0);
  };

  useEffect(() => {
    if (profileAccounts?.length || defaultOrganizationId) {
      getProfilesData({
        keyword,
        accountIDList: profileAccounts?.length
          ? profileAccounts?.map(
              (organization: IOrganization) => organization?.id
            )
          : defaultOrganizationId
          ? [defaultOrganizationId]
          : [],
      });

      if (updateData) {
        setUpdateData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganizationId, updateData]);

  return (
    <ProfilesFiltersContainer>
      <ProfilesSearchWrapper>
        <Input
          value={keyword || ""}
          placeholder={t("search-in-profiles")}
          onChange={onChangeSearchProfiles}
          InputProps={{
            endAdornment: (
              <FilterIconWrapper position="end">
                <FilterIcon onClick={handleFilters} />
              </FilterIconWrapper>
            ),
          }}
          id="search-in-profiles"
        />
        <Question src={question} alt="" onClick={handleOpenProfileTooltip} />
        <ProfilesTooltip
          open={handleOpenProfilesTooltip}
          setOpen={setHandleOpenProfilesTooltip}
        />
      </ProfilesSearchWrapper>
      {isOpenFilters && (
        <>
          <ProfilesFiltersWrapper>
            {t("please-use-all-filters")}
          </ProfilesFiltersWrapper>
          <FiltersContainer>
            <FiltersWrapper>
              {userProfile?.userAccounts?.length > 1 && (
                <FilterWrapper>
                  <Label>{t("account")}</Label>
                  <Autocomplete
                    options={organizationOptions ? organizationOptions : []}
                    value={profileAccounts || ""}
                    updateData={(value: string) => {
                      setProfileFiltersSearchOrganization(value);
                    }}
                    onChange={handleChangeOrganization}
                    multiple
                    id="custom-profiles-filters-account"
                  />
                </FilterWrapper>
              )}
            </FiltersWrapper>
          </FiltersContainer>
          <ActionsWrapper>
            <Button
              isGrey
              text={t("clear-filters")}
              onClick={handleClearFilters}
              id="custom-profiles-filters-clear-filters"
            />
            <Button
              isGrey
              text={t("cancel")}
              onClick={handleFilters}
              id="custom-profiles-filters-cancel"
            />
            <Button
              text={t("apply")}
              onClick={handleApplyFilters}
              id="custom-profiles-filters-apply"
            />
          </ActionsWrapper>
        </>
      )}
    </ProfilesFiltersContainer>
  );
};
