import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const TooltipContainer = styled(Box)(({ theme }) => ({
  width: 602,
  maxWidth: "calc(100vw - 32px)",
  padding: "8px 16px 16px 16px",
  borderRadius: 8,
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
  background: isDarkMode(theme.palette.mode) ? "#2A2C2F" : "#F5F5F5",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 10px 0px rgba(0, 0, 0, 0.20)"
    : "",
  position: "absolute",
  left: "50%",
  top: 24,
  transform: "translate(-50%, 0)",
  zIndex: 100,
}));

export const TooltipWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const TooltipCloseIconWrapper = styled(Box)(() => ({
  alignSelf: "end",
  width: 20,
  height: 20,
  marginBottom: 8,
  "& > svg ": {
    width: "inherit",
    height: "inherit",
    cursor: "pointer",
    "& > path": {
      fill: "#217BDB",
    },
  },
}));
