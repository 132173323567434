import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const BANNER_SUB_URL = "Banner";

export const permission = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveBanner: builder.query({
      query: () => ({
        url: `${BANNER_SUB_URL}/GetActiveBanner`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateBanner"],
    }),
    getBanners: builder.query({
      query: () => ({
        url: `${BANNER_SUB_URL}/GetBanners`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateBanner"],
    }),
    getBannerById: builder.query({
      query: (bannerId) => ({
        url: `${BANNER_SUB_URL}/GetBannerByID/${bannerId}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateBanner"],
    }),
    upsertBanner: builder.mutation({
      query: (body) => ({
        url: `${BANNER_SUB_URL}/UpsertBanner`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateBanner"],
    }),
    deleteBannerById: builder.mutation({
      query: (bannerId) => ({
        url: `${BANNER_SUB_URL}/DeleteBannerByID/${bannerId}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["UpdateBanner"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActiveBannerQuery,
  useGetBannersQuery,
  useGetBannerByIdQuery,
  useUpsertBannerMutation,
  useDeleteBannerByIdMutation,
} = permission;
