import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  BackIcon,
  BackText,
  BackWrapper,
  ButtonsWrapper,
} from "../../../pages/editUser/styles";
import { FormCardInput } from "src/shared/components/formCard/formCardInput/formCardInput";
import { Button } from "src/shared/components/button/button";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { IEditUserInformationForm } from "./types";
import { EDIT_USER_INFORMATION_FIELDS } from "./constants";

export const EditUserInformationForm = ({
  errors,
  handleChangedData,
  register,
  handleCancel,
  updateUserData,
  disabledSaveButton,
}: IEditUserInformationForm) => {
  const { t } = useTranslation();

  return (
    <div>
      <BackWrapper onClick={handleCancel}>
        <BackIcon src={backArrow} alt="" />
        <BackText>{t("edit-user-information")}</BackText>
      </BackWrapper>
      <FormCard title={t("user-information")}>
        <React.Fragment>
          {EDIT_USER_INFORMATION_FIELDS?.map(
            ({ field, placeholder }, index) => (
              <FormCardInput
                key={index}
                errorMessage={errors?.[field]?.message as string}
                register={{
                  ...register(field, { onChange: handleChangedData }),
                }}
                placeholder={t(placeholder)}
                id={`edit-user-information-${field}`}
              />
            )
          )}
          <ButtonsWrapper>
            <Button
              text={t("cancel")}
              onClick={handleCancel}
              isGrey
              id="edit-user-information-cancel"
            />
            <Button
              text={t("save")}
              onClick={updateUserData}
              disabled={disabledSaveButton}
              id="edit-user-information-save"
            />
          </ButtonsWrapper>
        </React.Fragment>
      </FormCard>
    </div>
  );
};
