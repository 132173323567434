import BlockUi from "@availity/block-ui";
import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InspectionsDueChartContainer = styled(BlockUi)(() => ({
  width: "100%",
  height: "100%",
  position: "relative",
  borderRadius: 8,
  border: "1px solid rgba(177, 177, 177, 0.50)",
  display: "flex",
  padding: "16px 8px",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16,
}));

export const ResponsiveWrapper = styled(Box)(() => ({
  width: "100%",
  "& > div": {
    maxHeight: 140,
    "& > svg > rect": {
      height: "46px !important",
    },
  },
}));

export const CloseIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  position: "absolute",
  top: 5,
  right: 10,
  zIndex: 100,
  "& > svg": {
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "#25895C",
    },
  },
}));
