import { styled } from "@mui/system";
import { Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";
import { ITab } from "./types";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  "& > div": {
    "& .MuiTabs-indicator": {
      bottom: 4,
      [theme.breakpoints.down("md")]: {
        bottom: 16,
      },
    },
    "& > div": {
      gap: 16,
      [theme.breakpoints.down("lg")]: {
        gap: 12,
      },
      [theme.breakpoints.down("md")]: {
        gap: 8,
      },
    },
  },
}));

export const Tab = styled(MuiTab)<ITab>(({ theme, orientation }) => ({
  flex: "1 1 0%",
  padding: "18px 0px 10px 0px",
  minWidth: 168,
  minHeight: 44,
  height: 44,
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "initial",
  color:
    orientation === "vertical" && !isDarkMode(theme.palette.mode)
      ? "#282828"
      : orientation === "vertical" && isDarkMode(theme.palette.mode)
      ? "#FFF"
      : "#B1B1B1",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    padding: "6.5px 0",
    minHeight: 32,
    height: 32,
    alignItems: "start",
  },
  "&.Mui-selected": {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  "&:after":
    orientation === "vertical"
      ? {}
      : {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          background: "#B1B1B1",
          height: 4,
          borderRadius: 2,
        },
  [theme.breakpoints.down("lg")]: {
    minWidth: 111,
  },
}));
