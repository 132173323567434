import { styled } from "@mui/material/styles";
import { Box, MenuItem, Select, Typography } from "@mui/material";

export const ChangeLanguageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ChangeLanguageText = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    color: "#F5F5F5",
  },
}));

export const ChangeLanguageSelect = styled(Select)(({ theme }) => ({
  boxShadow: "none",
  padding: 0,
  ".MuiOutlinedInput-input": {
    [theme.breakpoints.down("lg")]: {
      padding: "0 26px 0 0 !important",
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

export const ChangeLanguageMenuItem = styled(MenuItem)(() => ({
  "&::selected": {
    background: "rgba(33, 123, 219, 0.15)",
  },
}));
