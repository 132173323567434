import {
  GetContextMenuItemsParams,
  RowSelectionOptions,
  ValueFormatterParams,
} from "ag-grid-enterprise";
import Edit from "src/shared/assets/svgs/edit.svg";
import WhiteEdit from "src/shared/assets/svgs/white-edit.svg";
import NewTab from "src/shared/assets/svgs/new-tab.svg";
import WhiteNewTab from "src/shared/assets/svgs/white-new-tab.svg";
import { Compliance } from "../compliance/compliance";
import { IManifest } from "../editManifestStatusDialog/types";
import { ComplianceContainer } from "./styles";

export const MANIFEST_GRID_NAME = "manifest";

export const paginationPageSize = 25;

export const getSortedByMonthFormat = (formattedDate: string) => {
  const date = new Date(formattedDate);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return year + "/" + month + "/" + day;
};

export const getContextMenuItems =
  (
    setSelectedManifest: (manifest: IManifest) => void,
    setIsOpenEditManifestStatusDialog: (status: boolean) => void,
    t: (value: string) => string,
    isDark: boolean
  ) =>
  (params: GetContextMenuItemsParams) =>
    [
      {
        name: t("open-in-new-tab"),
        action: () => {
          window.open(
            `${window.location.origin}/manifest/${params?.node?.data?.id}`,
            "_blank"
          );
        },
        icon: `<img src="${isDark ? WhiteNewTab : NewTab}"/>`,
      },
      {
        name: t("edit-return-status"),
        action: () => {
          setSelectedManifest(params?.node?.data);
          setIsOpenEditManifestStatusDialog(true);
        },
        icon: `<img src="${isDark ? WhiteEdit : Edit}"/>`,
      },
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];

export const defaultColDef = {
  sortable: true,
  resizable: true,
};

export const rowSelection = {
  mode: "singleRow",
  checkboxes: false,
  enableClickSelection: true,
} as RowSelectionOptions;

export const getLocaleDateValue = (date?: string) => {
  return date ? new Date(date).toLocaleDateString() : "";
};

export const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnKeys = (length: number, t: (value: string) => string) => [
  {
    headerName: t("manifest-number"),
    field: "manifestNumber",
    ...excelMode,
  },
  {
    headerName: t("compliance"),
    field: "complianceColor",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return (
          <ComplianceContainer>
            <Compliance color={valueFormatterParams?.value} />
          </ComplianceContainer>
        );
      },
    },
    ...excelMode,
    hide: true,
    enableRowGroup: true,
  },
  {
    headerName: t("account"),
    field: "accountName",
    hide: length > 1 ? false : true,
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
  },
  {
    headerName: t("site"),
    field: "siteName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
  },
  {
    headerName: t("status"),
    field: "status",
    hide: true,
    ...excelMode,
    enableRowGroup: true,
  },
  {
    headerName: t("shipped-date"),
    field: "dateShipped",
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.dateShipped);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    cellRenderer: "agGroupCellRenderer",
    useValueFormatterForExport: true,
    ...excelMode,
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.dateShipped);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    enableRowGroup: true,
  },
  {
    headerName: t("return-date"),
    field: "dateReturned",
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.dateReturned);
    },
    useValueFormatterForExport: true,
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    cellRenderer: "agGroupCellRenderer",
    hide: true,
    ...excelMode,
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.dateReturned);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    enableRowGroup: true,
  },
  {
    headerName: t("facility"),
    field: "facilityName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
  },
];

export const rowGroupPanelShow = "always";
