import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "src/shared/components/list/list";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IReportsTooltip } from "./types";
import { ListWrapper, ReportsTooltipText } from "./styles";

export const ReportsTooltip = ({
  open,
  setOpen,
  reportType,
}: IReportsTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <React.Fragment>
        <ReportsTooltipText>
          {t(`create-a-${reportType}-report-description`)}
        </ReportsTooltipText>
        <ListWrapper>
          <List
            items={[
              t("first-select-report-account"),
              t("then-select-report-account"),
            ]}
          />
        </ListWrapper>
        <ReportsTooltipText>
          {t(`creating-various-${reportType}-never-easy`)}
        </ReportsTooltipText>
      </React.Fragment>
    </Tooltip>
  );
};
