import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Popover } from "@mui/material";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { AdminPopoverItem, Container, NavigationItem } from "./styles";

export const UpperNavigation = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "admin-popover" : undefined;

  const handleAdminPopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClickAdmin = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickUserAdmin = () => {
    navigate("/user-list");
  };

  const handleClickSiteAdmin = () => {
    navigate("/site-admin");
  };

  const handleClickSupport = () => {
    navigate("/support");
  };

  const handleClickFAQ = () => {
    navigate("/faq");
  };

  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const hasUserAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ADMIN
  );

  const hasEditBannerPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.EDIT_BANNER
  );

  return (
    <Container>
      {(hasUserAdminPermission || hasEditBannerPermission) && (
        <NavigationItem aria-describedby={id} onClick={handleClickAdmin}>
          {t("admin")}
        </NavigationItem>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleAdminPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {hasUserAdminPermission && (
          <AdminPopoverItem onClick={handleClickUserAdmin}>
            {t("user-admin")}
          </AdminPopoverItem>
        )}
        {hasEditBannerPermission && (
          <AdminPopoverItem onClick={handleClickSiteAdmin}>
            {t("site-admin")}
          </AdminPopoverItem>
        )}
      </Popover>
      <NavigationItem onClick={handleClickSupport}>
        {t("support")}
      </NavigationItem>
      <NavigationItem onClick={handleClickFAQ}>{t("faq")}</NavigationItem>
    </Container>
  );
};
