import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const SignInWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "0 16px",
    width: "calc(100% - 32px)",
  },
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  "& > svg": {
    width: 250,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 48,
    },
  },
}));

export const SignInText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#25895C",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const LoginContainer = styled(Box)(({ theme }) => ({
  width: 368,
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
