import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { IUserProfileArrowWrapper } from "./types";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const UserProfileContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
  border: isDarkMode(theme.palette.mode) ? "" : "1px solid #B1B1B1",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
    : "0px 4px 4px 0px rgba(217, 217, 217, 0.15)",
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  padding: 16,
  marginTop: 24,
  marginBottom: 24,
  [theme.breakpoints.down("lg")]: {
    margin: "24px 16px",
  },
  [theme.breakpoints.down("md")]: {
    padding: 8,
    background: isDarkMode(theme.palette.mode) ? "#202124" : "#FAFAFA",
  },
}));

export const UserProfileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const UserProfileStatus = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
    lineHeight: "140%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    lineHeight: "normal",
  },
}));

export const UserProfileLink = styled(Typography)(({ theme }) => ({
  marginTop: 4,
  color: "#217BDB",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    marginTop: 4.5,
    fontSize: 16,
    lineHeight: "140%",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 5.5,
    fontSize: 12,
    lineHeight: "130%",
  },
}));

export const UserProfileJobTitle = styled(Typography)(({ theme }) => ({
  marginTop: 8.5,
  color: theme.palette.text.secondary,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    marginTop: 8,
    fontSize: 16,
    lineHeight: "140%",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 1,
    fontSize: 14,
    lineHeight: "normal",
  },
}));

export const UserProfileArrowWrapper = styled(Box)<IUserProfileArrowWrapper>(
  ({ theme, open }) => ({
    marginTop: 24,
    cursor: "pointer",
    alignSelf: "center",
    width: 24,
    height: 24,
    [theme.breakpoints.down("lg")]: {
      marginTop: 16,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 18,
      width: 16,
      height: 16,
    },
    transform: open ? "rotate(180deg)" : "",
    "& > svg ": {
      width: "inherit",
      height: "inherit",
      "& > path": {
        fill: "#B1B1B1",
      },
    },
  })
);

export const UserProfileQuestion = styled("img")(({ theme }) => ({
  cursor: "pointer",
  width: 24,
  height: 24,
  [theme.breakpoints.down("md")]: {
    width: 14,
    height: 14,
  },
}));

export const UserProfileEdit = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: "#217BDB",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const UserProfileWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
}));

export const UserProfileMainWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const OrganizationName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const OrganizationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
    lineHeight: "140%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    lineHeight: "normal",
  },
}));

export const OrganizationContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  gap: 4,
  [theme.breakpoints.down("lg")]: {
    marginTop: 16,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 14,
    gap: 6,
  },
}));
