export const emptyEditManifestStatusFields = {
  status: "",
  returnDate: undefined,
};

export const MANIFEST_STATUSES = {
  SHIPPED: "Shipped",
  REVIEWED: "Reviewed",
  RETURNED: "Returned",
  ARCHIVED: "Archived",
};

export const MANIFEST_STATUS_OPTIONS = [
  { id: MANIFEST_STATUSES.SHIPPED, val: MANIFEST_STATUSES.SHIPPED },
  { id: MANIFEST_STATUSES.REVIEWED, val: MANIFEST_STATUSES.REVIEWED },
  { id: MANIFEST_STATUSES.RETURNED, val: MANIFEST_STATUSES.RETURNED },
  { id: MANIFEST_STATUSES.ARCHIVED, val: MANIFEST_STATUSES.ARCHIVED },
];
