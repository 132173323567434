import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IManifestLine, IManifestLinesTable } from "./types";
import {
  ManifestLinesTableContainer,
  TableHeadCell,
  TableRowCell,
  ManifestTableWrapper,
  ManifestInformationText,
  ManifestInformationWrapper,
} from "./styles";

export const ManifestLinesTable = ({ manifestLines }: IManifestLinesTable) => {
  const { t } = useTranslation();

  return (
    <ManifestLinesTableContainer>
      <ManifestTableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell />
              <TableHeadCell />
              <TableHeadCell align="center" colSpan={2} withBottomBorder>
                {t("containers")}
              </TableHeadCell>
              <TableHeadCell />
              <TableHeadCell />
              <TableHeadCell />
            </TableRow>
            <TableRow>
              <TableHeadCell>#</TableHeadCell>
              <TableHeadCell>
                {t("description-and-shipping-name")}
              </TableHeadCell>
              <TableHeadCell withBottomBorder>{t("no")}</TableHeadCell>
              <TableHeadCell withBottomBorder>{t("type")}</TableHeadCell>
              <TableHeadCell>{t("total-qty")}</TableHeadCell>
              <TableHeadCell>{t("uom")}</TableHeadCell>
              <TableHeadCell>{t("waste-codes")}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {manifestLines?.map(
              (
                {
                  lineNumber,
                  dotFullShippingName,
                  containerNumber,
                  containerType,
                  containerTotalWeight,
                  containerWeightUom,
                  generatorStateCodes,
                  facilityStateCodes,
                  epaWasteCodes,
                }: IManifestLine,
                index
              ) => (
                <TableRow key={index}>
                  <TableRowCell>{lineNumber}</TableRowCell>
                  <TableRowCell>{dotFullShippingName}</TableRowCell>
                  <TableRowCell>{containerNumber}</TableRowCell>
                  <TableRowCell>{containerType}</TableRowCell>
                  <TableRowCell>{containerTotalWeight}</TableRowCell>
                  <TableRowCell>{containerWeightUom}</TableRowCell>
                  <TableRowCell>{`${epaWasteCodes ? epaWasteCodes : ""}${
                    generatorStateCodes ? generatorStateCodes : ""
                  }${
                    facilityStateCodes ? facilityStateCodes : ""
                  }`}</TableRowCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </ManifestTableWrapper>
      <ManifestInformationWrapper>
        {manifestLines?.map(
          (
            {
              lineNumber,
              containerSize,
              profileNumber,
              containerNumber,
            }: IManifestLine,
            index
          ) => (
            <ManifestInformationText
              key={`${index}-manifestInformation`}
            >{`${lineNumber} - (${containerNumber} x ${containerSize}) ${profileNumber}`}</ManifestInformationText>
          )
        )}
      </ManifestInformationWrapper>
    </ManifestLinesTableContainer>
  );
};
