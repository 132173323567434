import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { ManageScheduledReportsTable } from "../../components/manageScheduledReportsTable/manageScheduledReportsTable";
import {
  ManageScheduledReportsContainer,
  ManageScheduledReportsBody,
} from "./styles";

export const ManageScheduledReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();

  const historyReverse = [...history]?.reverse();

  const previousReportLocation = historyReverse?.find(
    (location: string) =>
      !!location?.includes("-reports") &&
      !location?.includes("/manage-scheduled-reports")
  ) as unknown as string;

  const handleClickBack = () => {
    if (previousReportLocation) {
      navigate(previousReportLocation);
    } else {
      navigate(-1);
    }
  };

  return (
    <ManageScheduledReportsContainer>
      <CssBaseline />
      <Header />
      <ManageScheduledReportsBody>
        <BackTo text={t("back-to-reports")} handleBack={handleClickBack} />
        <ManageScheduledReportsTable />
      </ManageScheduledReportsBody>
    </ManageScheduledReportsContainer>
  );
};
