import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { FormCardInput } from "src/shared/components/formCard/formCardInput/formCardInput";
import { Button } from "src/shared/components/button/button";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import {
  BackIcon,
  BackText,
  BackWrapper,
  ButtonsWrapper,
} from "../../pages/editUser/styles";
import { IEditContractInformationForm } from "./types";
import { CONTACT_INFORMATION_FIELDS } from "./constants";

export const EditContactInformationForm = ({
  errors,
  register,
  handleCancel,
  handleChangedData,
  updateUserData,
  disabledSaveButton,
}: IEditContractInformationForm) => {
  const { t } = useTranslation();

  return (
    <div>
      <BackWrapper onClick={handleCancel}>
        <BackIcon src={backArrow} alt="" />
        <BackText>{t("edit-contact-information")}</BackText>
      </BackWrapper>
      <FormCard title={t("contact-information")}>
        <React.Fragment>
          {CONTACT_INFORMATION_FIELDS?.map(({ field, placeholder }, index) => (
            <FormCardInput
              key={index}
              errorMessage={errors?.[field]?.message as string}
              register={{
                ...register(field, { onChange: handleChangedData }),
              }}
              placeholder={t(placeholder)}
              id={`edit-contact-information-${field}`}
            />
          ))}
          <ButtonsWrapper>
            <Button
              text={t("cancel")}
              onClick={handleCancel}
              isGrey
              id="edit-contact-information-cancel"
            />
            <Button
              text={t("save")}
              onClick={updateUserData}
              disabled={disabledSaveButton}
              id="edit-contact-information-save"
            />
          </ButtonsWrapper>
        </React.Fragment>
      </FormCard>
    </div>
  );
};
