import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ReturnStyled = styled(Typography)(({ theme }) => ({
  marginTop: 24,
  color: "#217BDB",
  alignSelf: "center",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  cursor: "pointer",
  width: "fit-content",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    lineHeight: "130%",
    marginTop: 16,
  },
}));
