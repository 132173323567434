import * as yup from "yup";

export const ManageInspectionValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    statusID: yup.number(),
    accountName: yup.string().required(t("required-field")),
    accountOrganizationUnitID: yup.number().required(t("required-field")),
    title: yup.string().required(t("required-field")),
    description: yup.string(),
    scheduleID: yup.string().required(t("required-field")),
    inspectionTimeRequired: yup.boolean(),
    storageTypeID: yup.string().required(t("required-field")),
    tresholdGood: yup
      .number()
      .typeError(t("must-be-a-number"))
      .required(t("required-field")),
    tresholdSubstandard: yup
      .number()
      .typeError(t("must-be-a-number"))
      .required(t("required-field")),
    inspectionQuestions: yup.array().of(
      yup.object().shape({
        questionID: yup.string(),
        inspectionID: yup.string(),
        typeID: yup.string(),
        answerRequired: yup.boolean(),
        citation: yup.string(),
        createdByUserName: yup.string(),
        defaultAnswerText: yup.string().nullable(),
        numberOfViolationsRequired: yup.boolean(),
        prompt: yup.string(),
        questionNumber: yup.number(),
        recommendation: yup.string(),
        defaultAnswerBool: yup.boolean().nullable(),
      })
    ),
  });
