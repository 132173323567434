import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FormCard } from "src/shared/components/formCard/formCard";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import { useGetBuildingByOrganizationByIDQuery } from "src/shared/store/api/organization.api";
import { BuildingCardTitle } from "./buildingCardTitle/buildingCardTitle";
import { getBuildingCardFields } from "./constants";
import {
  FieldWrapper,
  FieldInformationWrapper,
  BuildingCardContainer,
} from "./styles";

export const BuildingCard = () => {
  const { t } = useTranslation();
  const { buildingID } = useParams();

  const { data: buildingData } =
    useGetBuildingByOrganizationByIDQuery(buildingID);

  const buildingCardFields = getBuildingCardFields(buildingData, t);

  return (
    <BuildingCardContainer>
      <FormCard customTitle={() => <BuildingCardTitle />}>
        <>
          {buildingCardFields.map(({ name, value }, index) => (
            <FieldWrapper key={index}>
              <FieldInformationWrapper>
                <FieldName>{name}</FieldName>
                <FieldValue>{value}</FieldValue>
              </FieldInformationWrapper>
            </FieldWrapper>
          ))}
        </>
      </FormCard>
    </BuildingCardContainer>
  );
};
