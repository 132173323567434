import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InspectionsNotificationsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  paddingTop: 40,
}));

export const InspectionsNotificationsBody = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
    : "0px 10px 15px 4px #D9D9D9",
  padding: "44px 90px 22px 90px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: 16,
  },
}));

export const InspectionsNotificationsTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: 28,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      marginTop: 26,
    },
  })
);
