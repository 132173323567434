import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { debounce } from "lodash";
import { Button } from "src/shared/components/button/button";
import { Input } from "src/shared/components/input/input";
import { ReactComponent as FilterIcon } from "src/shared/assets/svgs/filter-icon.svg";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { IOrganization } from "src/components/quickOverview/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useGetProfileRequestAccountsQuery } from "src/shared/store/api/wasteProfile.api";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { IWasteProfilesFilters } from "./types";
import {
  ActionsWrapper,
  FilterIconWrapper,
  FilterWrapper,
  FiltersContainer,
  FiltersWrapper,
  Label,
  WasteProfilesFiltersContainer,
  WasteProfilesFiltersWrapper,
  WasteProfilesSearchWrapper,
} from "./styles";

export const WasteProfilesFilters = ({
  gridApi,
  getPendingProfileData,
  updateData,
  setUpdateData,
}: IWasteProfilesFilters) => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const { keyword, accounts: accountsIds } = useAppSelector(
    (state) => state.wasteProfilesFiltersReducer
  );
  const {
    setWasteProfilesFiltersKeyword,
    setWasteProfilesFiltersAccounts,
    setWasteProfilesFiltersSearchOrganization,
  } = useAppActions();

  const { data: profileRequestAccounts } = useGetProfileRequestAccountsQuery(
    {}
  );

  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const getPendingProfile = useCallback(
    (newKeyword: string) => {
      getPendingProfileData({
        keyword: newKeyword,
        accountIDList: accountsIds?.map((organization) => organization?.id),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPendingProfileData, accountsIds]
  );

  const handleFilters = () => setIsOpenFilters(!isOpenFilters);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPendingProfile = useCallback(
    debounce((keyword) => getPendingProfile(keyword), 2000),
    [getPendingProfile]
  );

  const onChangeSearchWasteProfiles = (e: ChangeEvent<HTMLInputElement>) => {
    const searchWasteProfilesValue = e?.target?.value as string;
    setWasteProfilesFiltersKeyword(searchWasteProfilesValue);
    debouncedPendingProfile(searchWasteProfilesValue);
  };

  const handleClearFilters = () => {
    gridApi?.setFilterModel(null);
    setWasteProfilesFiltersAccounts([
      {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      },
    ]);
  };

  const handleApplyFilters = () => {
    getPendingProfile(keyword);
    gridApi?.paginationGoToPage(0);
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setWasteProfilesFiltersAccounts(newInputValue as Array<IOrganization>);
  };

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (accountsIds?.length || defaultOrganizationId) {
      getPendingProfileData({
        keyword,
        accountIDList: accountsIds?.length
          ? accountsIds?.map((organization) => organization?.id)
          : defaultOrganizationId
          ? [defaultOrganizationId]
          : [],
      });

      if (updateData) {
        setUpdateData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganizationId, updateData]);

  useEffect(() => {
    if (defaultOrganization && !accountsIds?.length) {
      const defaultAccountList = [
        {
          id: defaultOrganization?.[0]?.organizationUnitID,
          val: defaultOrganization?.[0]?.name,
        },
      ];
      setWasteProfilesFiltersAccounts(defaultAccountList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  const updateWasteProfileData = (value: string) => {
    setWasteProfilesFiltersSearchOrganization(value);
  };

  return (
    <WasteProfilesFiltersContainer>
      <WasteProfilesSearchWrapper>
        <Input
          value={keyword}
          placeholder={t("search-in-waste-profiles")}
          onChange={onChangeSearchWasteProfiles}
          InputProps={{
            endAdornment: (
              <FilterIconWrapper position="end">
                <FilterIcon onClick={handleFilters} />
              </FilterIconWrapper>
            ),
          }}
          id="search-in-waste-profiles"
        />
      </WasteProfilesSearchWrapper>
      {isOpenFilters && (
        <>
          <WasteProfilesFiltersWrapper>
            {t("please-use-all-filters")}
          </WasteProfilesFiltersWrapper>
          <FiltersContainer>
            <FiltersWrapper>
              {userProfile?.userAccounts?.length > 1 && (
                <FilterWrapper>
                  <Label>{t("account")}</Label>
                  <Autocomplete
                    options={
                      profileRequestAccounts ? profileRequestAccounts : []
                    }
                    value={accountsIds || ""}
                    updateData={updateWasteProfileData}
                    onChange={handleChangeOrganization}
                    multiple
                    id="waste-profile-filters-account"
                  />
                </FilterWrapper>
              )}
            </FiltersWrapper>
          </FiltersContainer>
          <ActionsWrapper>
            <Button
              isGrey
              text={t("clear-filters")}
              onClick={handleClearFilters}
              id="waste-profile-filters-clear-filters"
            />
            <Button
              isGrey
              text={t("cancel")}
              onClick={handleFilters}
              id="waste-profile-filters-cancel"
            />
            <Button
              text={t("apply")}
              onClick={handleApplyFilters}
              id="waste-profile-filters-apply"
            />
          </ActionsWrapper>
        </>
      )}
    </WasteProfilesFiltersContainer>
  );
};
