import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useIsAuthenticated } from "@azure/msal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { Input } from "src/shared/components/input/input";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { useSendUserInvitationMutation } from "src/shared/store/api/userInvitation.api";
import { useGetOrganizationBySearchValueQuery } from "src/shared/store/api/organization.api";
import { IOrganization } from "../quickOverview/types";
import {
  DialogLabel,
  LabelWrapper,
  InviteDialogContentContainer,
  SendBtnWrapper,
} from "./styles";
import { InviteValidationSchema } from "./model";
import { IInviteDialog } from "./types";

const emptyFields = { email: "" };

export const InviteDialog = ({
  open,
  handleClose: handleCloseInviteDialog,
  organizationOption,
}: IInviteDialog) => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganization>();
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery(
    {
      searchValue,
    },
    {
      skip: !isAuthenticated,
    }
  );

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOrganization;

    setSelectedOrganization(inputValue);
    setValue("organizationName", inputValue?.val || "", {
      shouldValidate: true,
    });
    setValue("organizationUnitID", inputValue?.id || 0, {
      shouldValidate: true,
    });
  };

  const [invite, { isSuccess: isSuccessInvite, isLoading: isInviteLoading }] =
    useSendUserInvitationMutation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InviteValidationSchema(t)),
    mode: "onSubmit",
  });
  const resetData = useCallback(() => {
    reset({ ...emptyFields });
  }, [reset]);

  const handleClose = () => {
    handleCloseInviteDialog();
    clearErrors();
    resetData();
  };

  useEffect(() => {
    if (isSuccessInvite) {
      resetData();
    }
  }, [isSuccessInvite, clearErrors, resetData]);

  useEffect(() => {
    if (isSuccessInvite && !isInviteLoading) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInviteLoading]);

  useEffect(() => {
    setSelectedOrganization(organizationOption);
    setValue("organizationName", organizationOption?.val || "", {
      shouldValidate: true,
    });
    setValue("organizationUnitID", organizationOption?.id || 0, {
      shouldValidate: true,
    });
  }, [
    open,
    organizationOption,
    organizationOption?.id,
    organizationOption?.val,
    setValue,
  ]);

  const handleSendInvite = handleSubmit((data) => invite(data));

  const inviteDialogActions = () => {
    return (
      <SendBtnWrapper>
        <FilledButton
          text={t("send")}
          onClick={handleSendInvite}
          disabled={isInviteLoading}
          id="invite-send"
        />
      </SendBtnWrapper>
    );
  };

  const renderInviteDialogFields = () => {
    return (
      <InviteDialogContentContainer>
        <LabelWrapper>
          <DialogLabel>{t("organization")}</DialogLabel>
          <Autocomplete
            options={
              organizationOptions
                ? organizationOptions
                : selectedOrganization
                ? [selectedOrganization]
                : []
            }
            value={selectedOrganization}
            defaultValue={organizationOption}
            updateData={(value: string) => {
              setSearchValue(value);
            }}
            onChange={handleChangeOrganization}
            errorMessage={errors?.organizationName?.message}
            id="invite-organization"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("email")}</DialogLabel>
          <Input
            errorMessage={errors?.email?.message}
            register={{ ...register("email") }}
            id="invite-email"
          />
        </LabelWrapper>
      </InviteDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("new-user-invitation")}
      actions={inviteDialogActions}
      renderFields={renderInviteDialogFields}
    />
  );
};
