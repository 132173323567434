import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import {
  useGetUserProfileByIDQuery,
  useUpdateAuthenticatedUserMutation,
} from "src/shared/store/api/userProfile.api";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { IAccount } from "./accountInformation/types";
import {
  ACCOUNTS_GRID_NAME,
  DetailCellRenderer,
  defaultColDef,
  getColumnDefs,
  paginationPageSize,
} from "./constants";
import { FacilitiesAndAccountsContainer } from "./styles";

export const FacilitiesAndAccounts = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const organizationIds = userProfileData?.userAccounts?.map(
    (account: IUserAccount) => account?.organizationUnitID
  );

  const organizations = userProfileData?.userAccounts?.reduce(
    (acc: IAccount, item: IUserAccount) => {
      const { organizationUnitID = "" } = item;

      acc = { ...acc, [organizationUnitID]: item };
      return acc;
    },
    {}
  );

  const {
    data: organizationData,
    isLoading: isLoadingOrganizationData,
    isFetching: isFetchingOrganizationData,
  } = useGetOrganizationsByIDsQuery(organizationIds, {
    skip: !organizationIds,
  });

  const organizationsData = organizationData?.map(
    (organization: IUserAccount) => {
      const organizationId = organization.organizationUnitID;

      return {
        ...organization,
        ...organizations[organizationId],
      };
    }
  );

  const [updateAuthenticatedUser] = useUpdateAuthenticatedUserMutation();

  const handleOnClickStar =
    (id: number, isFavorite: boolean, isDefault: boolean) => () => {
      updateAuthenticatedUser({
        id,
        isFavorite,
        isDefault,
      });
    };

  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);

  const columnDefs = getColumnDefs(handleOnClickStar, t);

  return (
    <FacilitiesAndAccountsContainer>
      <AgGrid
        title={t("my-accounts")}
        gridName={ACCOUNTS_GRID_NAME}
        rowData={organizationsData}
        columnDefs={columnDefs}
        paginationPageSize={paginationPageSize}
        defaultColDef={defaultColDef}
        detailCellRenderer={detailCellRenderer}
        masterDetail={true}
        detailRowAutoHeight={true}
        isLoadingData={isLoadingOrganizationData || isFetchingOrganizationData}
      />
    </FacilitiesAndAccountsContainer>
  );
};
