import * as yup from "yup";

export const DTOInformationValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    commonShippingName: yup.string().required(t("required-field")),
    fullShippingName: yup.string().nullable(),
    lineConstituents: yup.string().nullable(),
    containerSize: yup.string().required(t("required-field")),
    containerWeightUom: yup.string().required(t("required-field")),
    containerType: yup.string().required(t("required-field")),
    nonDotShippingName_id: yup.string().nullable(),
    hazardousMaterial_id: yup.string().nullable(),
    anticipatedQuantity: yup
      .number()
      .typeError(t("not-a-number"))
      .integer()
      .nullable()
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .required(t("required-field"))
      .min(1),
    anticipatedSize: yup.string().required(t("required-field")),
    anticipatedTimeframe: yup.string().required(t("required-field")),
  });
