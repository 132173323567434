import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const EXCEPTION_HANDLER_SUB_URL = "ExceptionHandler";

export const exceptionHandler = api.injectEndpoints({
  endpoints: (builder) => ({
    processUnhandledException: builder.mutation({
      query: (body) => ({
        url: `${EXCEPTION_HANDLER_SUB_URL}/ProcessUnhandledException`,
        method: HTTP_METHOD.POST,
        formData: true,
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useProcessUnhandledExceptionMutation } = exceptionHandler;
