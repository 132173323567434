export const NOTIFICATION_SCHEDULE_CONFIG = [
  {
    settingId: 1,
    setting: "due-date",
  },
  {
    settingId: 2,
    setting: "1-day-before-due-date",
  },
  {
    settingId: 3,
    setting: "2-days-before-due-date",
  },
  {
    settingId: 4,
    setting: "3-days-before-due-date",
  },
  {
    settingId: 5,
    setting: "every-day-for-week-until-due-date",
  },
  {
    settingId: 6,
    setting: "1-week-before-due-date",
  },
  {
    settingId: 7,
    setting: "2-weeks-before-due-date",
  },
  {
    settingId: 8,
    setting: "3-weeks-before-due-date",
  },
  {
    settingId: 9,
    setting: "1-month-before-due-date",
  },
  {
    settingId: 10,
    setting: "1-day-after-due-date-if-not-complete",
  },
  {
    settingId: 11,
    setting: "every-day-after-due-date-if-not-complete",
  },
  {
    settingId: 12,
    setting: "2-months-before-due-date",
  },
  {
    settingId: 13,
    setting: "3-months-before-due-date",
  },
  {
    settingId: 14,
    setting: "6-months-before-due-date",
  },
];
