import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { useDeleteUserProfileMutation } from "src/shared/store/api/userProfile.api";
import {
  DialogBody,
  DeleteUserDialogContentContainer,
  SendBtnWrapper,
} from "./styles";
import { IDeleteUserDialog } from "./types";

export const DeleteUserDialog = ({
  user,
  open,
  handleClose,
}: IDeleteUserDialog) => {
  const { t } = useTranslation();
  const [
    deleteUserProfile,
    { isSuccess: isSuccessDeleteUser, isLoading: isDeleteUserLoading },
  ] = useDeleteUserProfileMutation();

  useEffect(() => {
    if (isSuccessDeleteUser && !isDeleteUserLoading) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteUserLoading]);

  const deleteDialogActions = () => {
    const handleDeleteUser = user
      ? () => deleteUserProfile({ userID: user?.id })
      : undefined;

    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="delete-user-cancel"
        />
        <Button
          text={t("continue")}
          onClick={handleDeleteUser}
          id="delete-user-continue"
        />
      </SendBtnWrapper>
    );
  };

  const renderDeleteUserDialogFields = () => {
    return (
      <DeleteUserDialogContentContainer>
        <DialogBody>
          {t("are-you-sure-delete-user", {
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
        </DialogBody>
      </DeleteUserDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("delete-advise-user")}
      actions={deleteDialogActions}
      renderFields={renderDeleteUserDialogFields}
    />
  );
};
