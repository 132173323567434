import { Typography, styled } from "@mui/material";

export const UploadedFileContainer = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  cursor: "pointer",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  [theme.breakpoints.down("md")]: {
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 12,
  },
}));
