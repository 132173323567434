import { createSlice } from "@reduxjs/toolkit";
import { IUser, IUserState } from "./user.types";

const initialUserState: IUserState = {
  user: null as unknown as IUser,
  isLoading: false,
  error: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { reducer: userReducer, actions: userActions } = userSlice;
