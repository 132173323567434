import { ISite } from "src/pages/singleManifest/types";

export const getSiteCardList = (site: ISite, t: (text: string) => string) => {
  return [
    {
      name: t("name"),
      value: site?.name,
      isFirstField: true,
    },
    {
      name: t("epa-id"),
      value: site?.epaNumber,
    },
    {
      name: t("address"),
      value: `${site?.street1 ? `${site?.street1} ` : ""}${
        site?.city ? `${site?.city},` : ""
      } ${site?.state ? `${site?.state},` : ""} ${
        site?.postalCode ? site?.postalCode : ""
      }`,
    },
    {
      name: t("phone"),
      value: site?.phoneNumber || "-",
    },
  ];
};
