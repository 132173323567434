import * as yup from "yup";

export const EditLocationParametersValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    name: yup.string().required(t("required-field")),
    barcode: yup.string().nullable(),
    id: yup.string().nullable(),
    parentId: yup.string().required(t("required-field")),
    contactID: yup.string(),
    piContactFullName: yup.string(),
    departmentID: yup.string(),
    departmentName: yup.string(),
    locationType: yup.string().required(t("required-field")),
    locationTypeID: yup.string().nullable(),
  });
