import * as yup from "yup";

export const SupportFormValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    subject: yup.string().required(t("required-field")),
    body: yup.string().required(t("required-field")),
    phone: yup.string().phone(),
    emailDistributionList: yup
      .array()
      .of(yup.string())
      .min(1, t("required-field")),
    attachments: yup.array(),
  });
