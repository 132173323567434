import { IComplianceArray } from "../manifestTooltip/types";

export const COMPLIANCE_COLOR = {
  blue: "#588DF1",
  red: "#E35D2B",
  green: "#25895C",
  yellow: "#E0CA00",
  grey: "#F5F5F5",
};

export const COMPLIANCE_COLOR_TEXT_ARRAY = [
  { color: "blue", text: "manifest-archived" },
  { color: "green", text: "manifest-compliant" },
  { color: "yellow", text: "manifest-approaching" },
  { color: "red", text: "manifest-non-compliant" },
] as unknown as Array<IComplianceArray>;

export const COMPLIANCE_COLOR_OPTIONS = [
  { id: "compliant", val: "Compliant" },
  { id: "yellow", val: "Warning" },
  { id: "red", val: "Expired" },
];
