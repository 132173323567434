import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMsal } from "@azure/msal-react";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import {
  useGetOrganizationBySearchValueQuery,
  useGetSiteOrganizationsByIDsQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { Input } from "src/shared/components/input/input";
import { Button } from "src/shared/components/button/button";
import { IOption } from "src/shared/components/select/types";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { IOrganization } from "src/components/quickOverview/types";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import {
  IndicatesRequiredField,
  SectionDivider,
  SectionHeader,
  TextField,
  FieldWrapper,
  ButtonWrapper,
} from "../../pages/newProfile/styles";
import { CustomerInformationValidationSchema } from "./model";
import {
  CustomerInformationContainer,
  ContactInformationTitle,
  CustomerInformationWrapper,
  ContactInformationWrapper,
  CustomerInformationHeaderWrapper,
  CustomerInformationFieldWrapper,
  CustomerInformationTextField,
} from "./styles";

export const CustomerInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CustomerInformationValidationSchema(t)),
    mode: "onSubmit",
  });

  const accountOrganization = watch("accountOrganization");
  const siteOrganization = watch("siteOrganization");

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [searchOrganization, setSearchOrganization] = useState("");
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const { data: organizationSites } = useGetSiteOrganizationsByIDsQuery(
    accountOrganization?.id,
    {
      skip: !accountOrganization,
    }
  );

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: account?.username,
    },
    {
      skip: !account?.username,
    }
  );
  const { firstName, lastName, email } = userProfile;

  const handleChangedData = () => {
    setChangedData(true);
  };

  const updateOrganizationData = (value: string) => {
    setSearchOrganization(value);
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setValue("accountOrganization", newInputValue as IOption, {
      shouldValidate: true,
    });
    setValue("siteOrganization", { id: "", val: "" });
    handleChangedData();
  };

  const handleChangeSite = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setValue("siteOrganization", newInputValue as IOption, {
      shouldValidate: true,
    });
    handleChangedData();
  };

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContinue = useCallback(
    handleSubmit(
      ({
        accountOrganization,
        siteOrganization,
        contactFirstName,
        contactLastName,
        contactEmail,
      }) => {
        upsertProfileRequest({
          id: profileID,
          accountOrganizationUnitID: Number(accountOrganization?.id),
          siteOrganizationUnitID: Number(siteOrganization?.id),
          contactFirstName: contactFirstName,
          contactLastName: contactLastName,
          contactEmail: contactEmail,
          sectionStep: SECTION.WASTE_STREAM_DESCRIPTION,
        });
        setChangedData(false);
      }
    ),
    [handleSubmit, profileID, setChangedData, upsertProfileRequest]
  );

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToDashboard();
      });
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (defaultOrganization && !accountOrganization) {
      const defaultAccountList = {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      };
      setValue("accountOrganization", defaultAccountList, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (wasteProfileData?.profileAccount) {
      setValue("accountOrganization", {
        id: wasteProfileData?.profileAccount?.organizationUnitID,
        val: wasteProfileData?.profileAccount?.name,
      });
    }
    if (wasteProfileData?.profileSite) {
      setValue("siteOrganization", {
        id: wasteProfileData?.profileSite?.organizationUnitID,
        val: wasteProfileData?.profileSite?.name,
      });
    }
    setValue(
      "contactFirstName",
      wasteProfileData?.contactFirstName || firstName
    );
    setValue("contactLastName", wasteProfileData?.contactLastName || lastName);
    setValue("contactEmail", wasteProfileData?.contactEmail || email);
  }, [setValue, wasteProfileData, firstName, lastName, email]);

  return (
    <CustomerInformationContainer>
      <CustomerInformationWrapper>
        <CustomerInformationHeaderWrapper>
          <SectionHeader>{t("customer-information-section")}</SectionHeader>
          <IndicatesRequiredField>
            <span>*</span> {t("indicates-a-required-field")}
          </IndicatesRequiredField>
        </CustomerInformationHeaderWrapper>
        <CustomerInformationFieldWrapper>
          <CustomerInformationTextField>
            {`${t("account")}`}
            <span> *</span>
          </CustomerInformationTextField>
          <Autocomplete
            options={organizationOptions ? organizationOptions : []}
            value={accountOrganization || null}
            updateData={updateOrganizationData}
            onChange={handleChangeOrganization}
            errorMessage={
              errors?.accountOrganization?.id?.message ||
              errors?.accountOrganization?.message
            }
            disabled={isActiveWasteProfile}
            id="customer-information-account"
          />
        </CustomerInformationFieldWrapper>
        <SectionDivider />
        <CustomerInformationFieldWrapper>
          <CustomerInformationTextField>
            {`${t("site")}`}
            <span> *</span>
          </CustomerInformationTextField>
          <Autocomplete
            placeholder={t("select-a-site")}
            value={siteOrganization || null}
            onChange={handleChangeSite}
            options={
              organizationSites
                ? organizationSites?.map((organization: IOrganization) => ({
                    ...organization,
                    val: organization?.name,
                    id: organization?.organizationUnitID,
                  }))
                : []
            }
            disabled={!organizationSites || isActiveWasteProfile}
            errorMessage={
              errors?.siteOrganization?.id?.message ||
              errors?.siteOrganization?.message
            }
            id="customer-information-site"
          />
        </CustomerInformationFieldWrapper>
      </CustomerInformationWrapper>
      <ContactInformationWrapper>
        <ContactInformationTitle>
          {t("contact-information")}
        </ContactInformationTitle>
        <SectionDivider />
        <FieldWrapper>
          <TextField>
            {`${t("first-name")}`}
            <span> *</span>
          </TextField>
          <Input
            errorMessage={errors?.contactFirstName?.message}
            register={{
              ...register("contactFirstName", { onChange: handleChangedData }),
            }}
            disabled={isActiveWasteProfile}
            id="customer-information-contact-first-name"
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField>
            {`${t("last-name")}`}
            <span> *</span>
          </TextField>
          <Input
            errorMessage={errors?.contactLastName?.message}
            register={{
              ...register("contactLastName", { onChange: handleChangedData }),
            }}
            disabled={isActiveWasteProfile}
            id="customer-information-contact-last-name"
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField>
            {`${t("email")}`}
            <span> *</span>
          </TextField>
          <Input
            errorMessage={errors?.contactEmail?.message}
            register={{
              ...register("contactEmail", { onChange: handleChangedData }),
            }}
            disabled={isActiveWasteProfile}
            id="customer-information-contact-email"
          />
        </FieldWrapper>
        <SectionDivider />
        <ButtonWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCancel}
            id="customer-information-cancel"
          />
          {!isActiveWasteProfile && (
            <Button
              text={t("continue")}
              onClick={handleContinue}
              id="customer-information-continue"
            />
          )}
        </ButtonWrapper>
      </ContactInformationWrapper>
    </CustomerInformationContainer>
  );
};
