import * as yup from "yup";

export const ManageInspectionQuestionValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    typeID: yup.string().required(t("required-field")),
    prompt: yup.string().required(t("required-field")),
    citation: yup.string(),
    recommendation: yup.string(),
    defaultAnswerText: yup.string().nullable(),
    questionNumber: yup.number(),
    defaultAnswerBool: yup.boolean(),
    numberOfViolationsRequired: yup.boolean(),
    answerRequired: yup.boolean(),
  });
