import { useTranslation } from "react-i18next";
import {
  AllSeriesType,
  ChartsTooltip,
  PiePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { CircularProgress } from "@mui/material";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ChartLegend } from "src/shared/components/chartLegend/chartLegend";
import { IChartLegendInformationConfig } from "src/shared/components/chartLegend/types";
import {
  inspectionChartHeight,
  getInformationInspection,
  getInspectionChartStatistics,
  highlightScope,
} from "./constants";
import { IInspectionChart } from "./types";
import {
  CloseIconWrapper,
  InspectionChartContainer,
  ResponsiveWrapper,
} from "./styles";

export const InspectionChart = ({
  inspectionsData,
  isLoadingInspectionsData,
  handleDelete,
}: IInspectionChart) => {
  const { t } = useTranslation();

  const totalInspectionCount = inspectionsData?.totalInspectionCount;

  const informationInspection = getInformationInspection(inspectionsData, t);

  const series = [
    {
      type: "pie",
      data: getInspectionChartStatistics(inspectionsData, t),
      highlightScope: highlightScope,
      innerRadius: 30,
      outerRadius: 70,
    },
  ] as AllSeriesType[];

  const inspectionChartLegendConfig = informationInspection?.map(
    ({ count, title, color }) => ({
      description: title,
      value: `${count}`,
      color,
    })
  );

  return (
    <InspectionChartContainer
      tag="div"
      blocking={isLoadingInspectionsData}
      loader={<CircularProgress />}
      keepInView
    >
      <CloseIconWrapper onClick={handleDelete}>
        <Close />
      </CloseIconWrapper>
      <ResponsiveWrapper>
        <ResponsiveChartContainer
          series={series}
          height={inspectionChartHeight}
        >
          <PiePlot />
          <ChartsTooltip trigger="item" />
        </ResponsiveChartContainer>
      </ResponsiveWrapper>
      <ChartLegend
        title={t("inspection-scores")}
        secondTitle={totalInspectionCount ? ` (${totalInspectionCount})` : ""}
        showLegend={!!inspectionsData}
        chartLegendInformationConfig={
          inspectionChartLegendConfig as IChartLegendInformationConfig[]
        }
      />
    </InspectionChartContainer>
  );
};
