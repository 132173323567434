import { Box, TableCell, Typography, styled } from "@mui/material";
import { ITableRowCell } from "./types";

export const TableRowCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "withoutWrap" && prop !== "top",
})<ITableRowCell>(({ theme, withoutWrap, top }) => ({
  color: theme.palette.text.secondary,
  verticalAlign: top ? "top" : "",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  borderBottom: "none",
  padding: "7px 4px",
  overflowWrap: "anywhere",
  whiteSpace: withoutWrap ? "nowrap" : "pre-wrap",
  "&:not(:last-of-type)": {
    borderRight: "1px solid #D9D9D9",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const TableHeadCell = styled(TableCell)(({ theme }) => ({
  color: "#217BDB",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  padding: "7px 6px",
  "&:not(:last-of-type)": {
    borderRight: "1px solid #D9D9D9",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const InspectionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const InspectionComments = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "left",
  fontFamily: "Roboto",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const QuestionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "start",
}));

export const FollowContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const FolderOpenWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 6,
  cursor: "pointer",
  "& > svg > path": {
    fill: "#217BDB",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 4,
  },
}));
