import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const SupportFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
}));

export const SupportFormLabel = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "18.75px",
  textWrap: "nowrap",
  minWidth: 120,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const SupportFormFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  "& > div": {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "start",
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const UploadedFile = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  cursor: "pointer",
  width: "fit-content",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const UploadedFileWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  "& > svg": {
    cursor: "pointer",
  },
}));

export const AddAttachmentButtonWrapper = styled(Box)(() => ({
  "& > div": {
    width: "fit-content",
  },
}));

export const UploadedFileContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));
