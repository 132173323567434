import { useTranslation } from "react-i18next";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IChemicalNameTooltip } from "./types";
import { ChemicalTooltipText } from "./styles";

export const ChemicalNameTooltip = ({
  open,
  setOpen,
}: IChemicalNameTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <ChemicalTooltipText>
        {t("please-use-precise-chemical-names")}
      </ChemicalTooltipText>
    </Tooltip>
  );
};
