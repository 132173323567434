import { generatedIdForInput } from "src/shared/helpers/generatedId";
import { IInput } from "./types";
import { TextField } from "./styles";

export const Input = ({
  value,
  onChange,
  disabled,
  errorMessage,
  register,
  placeholder,
  InputProps,
  inputProps,
  type,
  id,
  onKeyUp,
}: IInput) => {
  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      autoComplete="off"
      InputProps={InputProps}
      inputProps={inputProps}
      type={type}
      onKeyUp={onKeyUp}
      id={generatedIdForInput(id)}
      {...register}
    />
  );
};
