import * as yup from "yup";

export const CustomerInformationValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    accountOrganization: yup
      .object({
        id: yup.string().required(t("required-field")),
        val: yup.string().required(t("required-field")),
      })
      .required(t("required-field")),
    siteOrganization: yup
      .object({
        id: yup.string().required(t("required-field")),
        val: yup.string().required(t("required-field")),
      })
      .required(t("required-field")),
    contactFirstName: yup.string().required(t("required-field")),
    contactLastName: yup.string().required(t("required-field")),
    contactEmail: yup.string().email().required(t("required-field")),
  });
