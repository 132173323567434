import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "src/shared/components/list/list";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IEManifestTooltip } from "./types";
import { ListWrapper } from "./styles";

export const EManifestTooltip = ({ open, setOpen }: IEManifestTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <React.Fragment>
        <ListWrapper>
          <List
            items={[
              t("no-need-to-log-RCRA"),
              t("mark-manifest-returned-tool"),
              t("you-may-download-and-attach-it"),
            ]}
          />
        </ListWrapper>
      </React.Fragment>
    </Tooltip>
  );
};
