import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import ArrowDown from "src/shared/assets/svgs/arrow-down.svg";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { IFooterLinks, IItem } from "./types";
import {
  Wrapper,
  FooterLinksTitle,
  FooterLinksItem,
  FooterLinksArrow,
  FooterLinksTitleWrapper,
} from "./styles";

export const FooterLink = ({ config }: IFooterLinks) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const handleToggleLink = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Wrapper>
      <FooterLinksTitleWrapper onClick={handleToggleLink}>
        <FooterLinksTitle>{t(config.title)}</FooterLinksTitle>
        {lessThanLarge && (
          <FooterLinksArrow src={ArrowDown} alt="" opened={isOpened} />
        )}
      </FooterLinksTitleWrapper>
      {((lessThanLarge && isOpened) || !lessThanLarge) &&
        config.items.map(({ item, onClick }: IItem, index: number) => (
          <FooterLinksItem onClick={onClick} key={index}>
            {t(item)}
          </FooterLinksItem>
        ))}
    </Wrapper>
  );
};
