import { PaletteMode } from "@mui/material";
import React from "react";
import { getDesignTokens } from "../../theme/theme";

export const THEME_MODE = "theme_mode";
export const DARK = "dark";
export const LIGHT = "light";

export const isDarkMode = (mode: string) => {
  return mode === DARK;
};

export const useColorTheme = () => {
  const [mode, setMode] = React.useState<PaletteMode>(
    (localStorage.getItem(THEME_MODE) as PaletteMode) || LIGHT
  );

  const toggleColorMode = () => {
    setMode((prevMode) => {
      const newMode = prevMode === LIGHT ? DARK : LIGHT;
      localStorage.setItem(THEME_MODE, newMode);

      return newMode;
    });
  };

  const modifiedTheme = React.useMemo(() => getDesignTokens(mode), [mode]);

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};
