import { useTranslation } from "react-i18next";
import { Button } from "src/shared/components/button/button";
import { Dialog } from "src/shared/components/dialog/dialog";
import { IManageInspectionChangeStatusDialog } from "./types";
import {
  ManageInspectionChangeStatusDialogContainer,
  ManageInspectionChangeStatusDialogActionsContainer,
} from "./styles";

export const ManageInspectionChangeStatusDialog = ({
  open,
  handleClose,
  handleDiscardChanges,
  handleSaveAndPublish,
}: IManageInspectionChangeStatusDialog) => {
  const { t } = useTranslation();

  const handleBackManageInspectionChangeStatusDialog = () => {
    handleClose();
    if (handleDiscardChanges) {
      handleDiscardChanges();
    }
  };

  const handleUpdateManageInspectionChangeStatusData = () => {
    if (handleSaveAndPublish) {
      handleSaveAndPublish();
    }
    handleClose();
  };

  const renderManageInspectionChangeStatusDialogActions = () => (
    <ManageInspectionChangeStatusDialogActionsContainer>
      <Button
        text={t("cancel")}
        isGrey
        onClick={handleClose}
        id="manage-inspection-change-status-cancel"
      />
      <Button
        text={t("discard-unsaved-changes")}
        onClick={handleBackManageInspectionChangeStatusDialog}
        isGrey
        id="manage-inspection-change-status-discard-unsaved-changes"
      />
      <Button
        text={t("save-changes")}
        onClick={handleUpdateManageInspectionChangeStatusData}
        id="manage-inspection-change-status-save-changes"
      />
    </ManageInspectionChangeStatusDialogActionsContainer>
  );

  const renderManageInspectionChangeStatusDialogFields = () => (
    <ManageInspectionChangeStatusDialogContainer>
      {t("are-you-sure-to-publish")}
    </ManageInspectionChangeStatusDialogContainer>
  );

  return (
    <ManageInspectionChangeStatusDialogContainer>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t("unsaved-changes")}
        actions={renderManageInspectionChangeStatusDialogActions}
        renderFields={renderManageInspectionChangeStatusDialogFields}
      />
    </ManageInspectionChangeStatusDialogContainer>
  );
};
