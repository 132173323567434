import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { Input } from "src/shared/components/input/input";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { MultiEmail } from "src/shared/components/multiEmail/multiEmail";
import {
  useDeleteUserManifestNotificationMutation,
  useGetUserManifestNotificationQuery,
  useUpsertUserManifestNotificationMutation,
} from "src/shared/store/api/notification.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { IOption } from "src/shared/components/select/types";
import { Alert } from "src/shared/components/alert/alert";
import { IOrganization } from "src/components/quickOverview/types";
import { ManifestNotificationFormValidationSchema } from "./model";
import { IManifestNotificationFields } from "./types";
import {
  ManifestNotificationFormContainer,
  ManifestNotificationFormFieldDaysWrapper,
  ManifestNotificationFormFieldWrapper,
  ManifestNotificationFormLabel,
  ActionsWrapper,
  ManifestNotificationManifestDaysWrapper,
} from "./styles";

export const ManifestNotificationForm = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);
  const [searchOrganization, setSearchOrganization] = useState("");

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const { data: userProfile = {}, isLoading: isLoadingUserProfile } =
    useGetUserProfileByIDQuery(
      {
        id: accounts[0]?.username,
      },
      {
        skip: !accounts[0]?.username,
      }
    );

  const [
    upsertUserManifestNotification,
    {
      isLoading: isLoadingUpsertManifestNotification,
      isSuccess: isSuccessUpsertManifestNotification,
    },
  ] = useUpsertUserManifestNotificationMutation({});

  const {
    data: manifestNotificationData,
    isFetching: isFetchingManifestNotificationData,
    isSuccess: isSuccessManifestNotificationData,
  } = useGetUserManifestNotificationQuery({});

  const [
    deleteManifestNotification,
    {
      isLoading: isLoadingDeleteManifestNotification,
      isSuccess: isSuccessDeleteManifestNotification,
    },
  ] = useDeleteUserManifestNotificationMutation({});

  const { data: organizationData, isLoading: isLoadingOrganizationData } =
    useGetOrganizationsByIDsQuery(manifestNotificationData?.accountIDList, {
      skip: !manifestNotificationData?.accountIDList,
    });

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ManifestNotificationFormValidationSchema(t)),
    mode: "onSubmit",
  });

  const selectedAccounts = watch("accountIDList") || [];
  const emails = watch("emailDistributionList") || [];

  const backToManifestTab = useCallback(() => {
    navigate(`/dashboard/${TABS_NAMES[2]}`);
  }, [navigate]);

  const handleCreateNotification = handleSubmit((data) => {
    upsertUserManifestNotification({
      ...data,
      accountIDList: data?.accountIDList?.map(({ id }) => id),
    });
  });

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValues = newInputValue as Array<IOption>;

    setValue(
      "accountIDList",
      inputValues?.length > 0
        ? inputValues
        : (undefined as unknown as IOption[]),
      { shouldValidate: errors?.accountIDList?.message ? true : false }
    );
  };

  const handleChangeEmails = (_emails: string[]) => {
    setValue("emailDistributionList", _emails);
    handleChangedData();
  };

  const handleCloseManifestNotificationForm = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToManifestTab();
    }
  };

  const handleDeleteManifestNotification = () => {
    deleteManifestNotification({});
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      backToManifestTab();
      clearErrors();
      reset({});
    });
  }, [clearErrors, backToManifestTab, reset, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleCreateNotification().then(() => {
        backToManifestTab();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCreateNotification]);

  useEffect(() => {
    if (manifestNotificationData) {
      for (const value of Object.keys(manifestNotificationData || {})) {
        if (value !== "accountIDList") {
          setValue(
            value as IManifestNotificationFields,
            manifestNotificationData[value],
            {
              shouldValidate: true,
            }
          );
        }
      }
    } else {
      if (isSuccessManifestNotificationData) {
        if (userProfile?.email) {
          setValue("emailDistributionList", [userProfile?.email]);
        }
        setValue("hazardousManifestDays", 35);
        setValue("medicalManifestDays", 35);
      }
    }
  }, [
    isSuccessManifestNotificationData,
    manifestNotificationData,
    setValue,
    userProfile?.email,
  ]);

  useEffect(() => {
    setValue(
      "accountIDList",
      organizationData?.map(({ organizationUnitID, name }: IOrganization) => ({
        id: organizationUnitID,
        val: name,
      }))
    );
  }, [organizationData, setValue]);

  useEffect(() => {
    if (isSuccessUpsertManifestNotification) {
      setChangedData(false);
      setIsOpenSuccessAlert(true);
    }
  }, [isSuccessUpsertManifestNotification, setChangedData]);

  useEffect(() => {
    if (isSuccessDeleteManifestNotification) {
      reset();
      clearErrors();
      setChangedData(false);
    }
  }, [clearErrors, isSuccessDeleteManifestNotification, reset, setChangedData]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingUserProfile ||
        isLoadingOrganizationData ||
        isLoadingUpsertManifestNotification ||
        isLoadingDeleteManifestNotification
      }
      loader={<CircularProgress />}
      keepInView
    >
      <ManifestNotificationFormContainer>
        <ManifestNotificationManifestDaysWrapper>
          <ManifestNotificationFormFieldDaysWrapper>
            <ManifestNotificationFormLabel>
              {t("hazardous-waste-manifest")}
            </ManifestNotificationFormLabel>
            <Input
              register={{
                ...register("hazardousManifestDays", {
                  onChange: handleChangedData,
                }),
              }}
              errorMessage={errors?.hazardousManifestDays?.message}
              id="manifest-notification-hazardous-manifest-days"
            />
          </ManifestNotificationFormFieldDaysWrapper>
          <ManifestNotificationFormFieldDaysWrapper>
            <ManifestNotificationFormLabel>
              {t("medical-waste-tracking-forms")}
            </ManifestNotificationFormLabel>
            <Input
              register={{
                ...register("medicalManifestDays", {
                  onChange: handleChangedData,
                }),
              }}
              errorMessage={errors?.medicalManifestDays?.message}
              id="manifest-notification-medical-manifest-days"
            />
          </ManifestNotificationFormFieldDaysWrapper>
        </ManifestNotificationManifestDaysWrapper>
        <ManifestNotificationFormFieldWrapper>
          <ManifestNotificationFormLabel>
            {t("assigned-accounts")}
          </ManifestNotificationFormLabel>
          <Autocomplete
            value={selectedAccounts}
            options={organizationOptions?.length ? organizationOptions : []}
            disabled={!organizationOptions?.length}
            onChange={handleChangeOrganization}
            updateData={(value: string) => {
              setSearchOrganization(value);
            }}
            multiple
            errorMessage={errors?.accountIDList?.message}
            id="manifest-notification-accounts"
          />
        </ManifestNotificationFormFieldWrapper>
        <ManifestNotificationFormFieldWrapper>
          <ManifestNotificationFormLabel>
            {t("distribution-list")}
          </ManifestNotificationFormLabel>
          <MultiEmail
            values={emails as string[]}
            onChange={handleChangeEmails}
            id="manifest-notification-distribution-list"
          />
        </ManifestNotificationFormFieldWrapper>
        <ActionsWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCloseManifestNotificationForm}
            id="manifest-notification-cancel"
          />
          {manifestNotificationData && (
            <Button
              text={t("delete")}
              isError
              onClick={handleDeleteManifestNotification}
              disabled={
                !!isLoadingUpsertManifestNotification ||
                !!isFetchingManifestNotificationData
              }
              id="manifest-notification-delete"
            />
          )}
          <Button
            text={t("save")}
            onClick={handleCreateNotification}
            id="manifest-notification-save"
          />
        </ActionsWrapper>
      </ManifestNotificationFormContainer>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("manifest-notification-distribution-saved-successfully")]}
        severity="success"
      />
    </BlockUi>
  );
};
