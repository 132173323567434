import { useTranslation } from "react-i18next";
import { TextArea } from "src/shared/components/textArea/textArea";
import { FormCard } from "src/shared/components/formCard/formCard";
import { DetailInspectionLabel } from "../../pages/newInspection/styles";
import { DetailInspectionFieldWrapper } from "../detailInspectionCard/styles";
import { IScoringDetails } from "./types";

export const ScoringDetails = ({ description }: IScoringDetails) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("instructions")} withCollapse>
      <DetailInspectionFieldWrapper>
        <DetailInspectionLabel>{t("instructions")}</DetailInspectionLabel>
        <TextArea
          value={description}
          disabled
          id="scoring-details-instructions"
        />
      </DetailInspectionFieldWrapper>
    </FormCard>
  );
};
