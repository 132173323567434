import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { Divider, FieldValue } from "src/shared/components/formCard/styles";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import question from "src/shared/assets/svgs/question.svg";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import {
  IIsOpenTooltip,
  IModifiedPermission,
  IModifiedPermissions,
  IPermission,
  IPermissionsForm,
  IValue,
} from "./types";
import {
  CheckboxWrapper,
  PermissionContainer,
  PermissionFormCardContainer,
  Question,
} from "./styles";

export const PermissionsForm = ({
  permissionsData,
  values,
  setUserPermissions,
  canEditPermissionsData,
  handleChangedData,
}: IPermissionsForm) => {
  const { t } = useTranslation();
  const [isOpenTooltip, setIsOpenTooltip] = useState<IIsOpenTooltip>({});
  const [permissionDescription, setPermissionDescription] = useState("");
  const sortedPermissionsByModuleListOrder = permissionsData
    ?.slice()
    ?.sort((firstPermission: IPermission, secondPermission: IPermission) =>
      firstPermission?.moduleListOrder > secondPermission?.moduleListOrder
        ? 1
        : -1
    );
  const modifiedPermission = sortedPermissionsByModuleListOrder?.reduce(
    (acc: IModifiedPermissions, permission: IPermission) => {
      const index = acc?.findIndex(
        (modifiedValue: IModifiedPermission) =>
          modifiedValue?.title === permission?.module
      );
      if (index !== -1) {
        acc[index]?.items.push(permission);
      } else {
        acc.push({
          title: permission?.module,
          items: [permission],
        });
      }
      return acc;
    },
    []
  );
  const selectedPermissions = values?.map(
    ({ permissionID }: IValue) => permissionID
  );

  const sortedPermissionsByPermissionOrder = modifiedPermission?.map(
    (permission: IModifiedPermission) => ({
      title: permission?.title,
      items: permission?.items?.sort(
        (firstPermission: IPermission, secondPermission: IPermission) =>
          firstPermission?.permissionListOrder >
          secondPermission?.permissionListOrder
            ? 1
            : -1
      ),
    })
  );

  const onChangePermission = (permission: IPermission) => () => {
    handleChangedData();
    if (selectedPermissions?.includes(permission?.permissionID)) {
      setUserPermissions([
        ...values.filter(
          ({ permissionID }: IValue) =>
            permissionID !== permission?.permissionID
        ),
      ]);
    } else {
      setUserPermissions([
        ...values,
        { permissionID: permission?.permissionID },
      ]);
    }
  };

  const handleClickQuestion = (permission: IPermission) => () => {
    setPermissionDescription(permission?.description);
    setIsOpenTooltip({
      [permission?.permissionID]: true,
    });
  };

  return (
    <PermissionFormCardContainer>
      <FormCard title={t("permissions-information")}>
        <React.Fragment>
          {sortedPermissionsByPermissionOrder?.map(
            (
              modifiedValue: IModifiedPermission,
              modifiedValueIndex: number
            ) => (
              <React.Fragment key={modifiedValue?.title}>
                <FieldValue>{modifiedValue?.title}</FieldValue>
                {modifiedValue?.items?.map(
                  (permission: IPermission, permissionIndex: number) => (
                    <PermissionContainer key={permissionIndex}>
                      <CheckboxWrapper>
                        <FormCardCheckbox
                          checked={selectedPermissions?.includes(
                            permission?.permissionID
                          )}
                          onChange={onChangePermission(permission)}
                          disabled={!canEditPermissionsData}
                          id={`permission-${permission.permissionName}`}
                        />
                        <FieldValue>{permission?.permissionName}</FieldValue>
                        {permission?.description && (
                          <React.Fragment>
                            <Question
                              src={question}
                              alt=""
                              onClick={handleClickQuestion(permission)}
                            />
                            <Tooltip
                              open={isOpenTooltip?.[permission?.permissionID]}
                              setOpen={(isOpen: boolean) =>
                                setIsOpenTooltip({
                                  [permission?.permissionID]: isOpen,
                                })
                              }
                            >
                              <React.Fragment>
                                {permissionDescription}
                              </React.Fragment>
                            </Tooltip>
                          </React.Fragment>
                        )}
                      </CheckboxWrapper>
                      {modifiedValueIndex !==
                        sortedPermissionsByPermissionOrder?.length - 1 &&
                        permissionIndex ===
                          modifiedValue?.items?.length - 1 && <Divider />}
                    </PermissionContainer>
                  )
                )}
              </React.Fragment>
            )
          )}
        </React.Fragment>
      </FormCard>
    </PermissionFormCardContainer>
  );
};
