import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { Select } from "src/shared/components/select/select";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { useUpdateManifestStatusMutation } from "src/shared/store/api/manifest.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IManifestStatusDialog } from "./types";
import { EditManifestStatusValidationSchema } from "./model";
import {
  MANIFEST_STATUSES,
  MANIFEST_STATUS_OPTIONS,
  emptyEditManifestStatusFields,
} from "./constants";
import {
  DialogLabel,
  LabelWrapper,
  EditManifestStatusDialogContentContainer,
  ActionButtonsWrapper,
} from "./styles";

export const EditManifestStatusDialog = ({
  open,
  handleClose: handleCloseEditManifestStatusDialog,
  selectedManifest,
  handleEditStatus,
}: IManifestStatusDialog) => {
  const { t } = useTranslation();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [
    updateManifestStatus,
    {
      isSuccess: isSuccessUpdateManifestStatus,
      isLoading: isLoadingUpdateManifestStatus,
    },
  ] = useUpdateManifestStatusMutation();

  const minDate = dayjs(selectedManifest?.dateShipped).add(1, "day");
  const maxDate = dayjs(new Date());

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      EditManifestStatusValidationSchema(t, minDate, maxDate)
    ),
    mode: "onSubmit",
  });
  const status = watch("status");
  const returnDate = watch("returnDate");

  useEffect(() => {
    if (open) {
      if (selectedManifest?.status) {
        setValue("status", selectedManifest?.status);
      }
      if (selectedManifest?.dateReturned) {
        setValue("returnDate", new Date(selectedManifest?.dateReturned));
      }
    }
    setChangedData(false);
  }, [open, selectedManifest, setChangedData, setValue]);

  const resetData = useCallback(() => {
    reset({ ...emptyEditManifestStatusFields });
  }, [reset]);

  const handleClose = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCloseEditManifestStatusDialog();
    }
  };

  useEffect(() => {
    if (isSuccessUpdateManifestStatus) {
      resetData();
    }
  }, [isSuccessUpdateManifestStatus, clearErrors, resetData]);

  useEffect(() => {
    if (isSuccessUpdateManifestStatus && !isLoadingUpdateManifestStatus) {
      handleCloseEditManifestStatusDialog();
      clearErrors();
      resetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdateManifestStatus]);

  const EditManifestStatusDialogActions = () => {
    const handleChangeStatus = handleSubmit((data) => {
      if (handleEditStatus) {
        handleEditStatus();
      }

      updateManifestStatus({
        body: {
          ...selectedManifest,
          status: data?.status,
          dateReturned:
            data?.returnDate &&
            (data?.status === MANIFEST_STATUSES.ARCHIVED ||
              data?.status === MANIFEST_STATUSES.RETURNED)
              ? data?.returnDate
              : null,
        },
      });
    });

    useEffect(() => {
      setHandleUpdateUnsavedChanged(() => handleChangeStatus());
    }, [handleChangeStatus]);

    return (
      <ActionButtonsWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="edit-manifest-status-cancel"
        />
        <Button
          text={t("save")}
          onClick={handleChangeStatus}
          id="edit-manifest-status-save"
        />
      </ActionButtonsWrapper>
    );
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCloseEditManifestStatusDialog();
      clearErrors();
      resetData();
    });
  }, [
    clearErrors,
    handleCloseEditManifestStatusDialog,
    resetData,
    setHandleBackUnsavedChanged,
  ]);

  const onChangeReturnDate = (date: unknown) => {
    setChangedData(true);
    if (date) {
      setValue("returnDate", date as Date, { shouldValidate: true });
    }
  };

  const onChangeStatus = (e: SelectChangeEvent<unknown>) => {
    setValue("status", e.target.value as string);
    setChangedData(true);
  };

  const renderEditManifestStatusDialogFields = () => {
    return (
      <EditManifestStatusDialogContentContainer>
        <LabelWrapper>
          <DialogLabel>{t("status")}</DialogLabel>
          <Select
            value={status || ""}
            options={MANIFEST_STATUS_OPTIONS}
            errorMessage={errors?.status?.message}
            onChange={onChangeStatus}
            id="edit-manifest-status"
          />
        </LabelWrapper>
        {(status === MANIFEST_STATUSES.ARCHIVED ||
          status === MANIFEST_STATUSES.RETURNED) && (
          <LabelWrapper>
            <DialogLabel>{t("return-date")}</DialogLabel>
            <DatePicker
              value={returnDate ? dayjs(new Date(returnDate)) : null}
              onChange={onChangeReturnDate}
              errorMessage={errors?.returnDate?.message}
              minDate={minDate}
              maxDate={maxDate}
              id="edit-manifest-return-date"
            />
          </LabelWrapper>
        )}
      </EditManifestStatusDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("edit-manifest-status", {
        manifest: selectedManifest?.manifestNumber,
      })}
      actions={EditManifestStatusDialogActions}
      renderFields={renderEditManifestStatusDialogFields}
    />
  );
};
