import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../dialog/dialog";
import { Button } from "../button/button";
import {
  UnsavedChangedFieldsContainer,
  UnsavedChangesActionsContainer,
} from "./styles";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";

export const UnsavedChangesDialog = () => {
  const { t } = useTranslation();
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const isOpenUnsavedChangedDialog = useAppSelector(
    (state) => state.unsavedChangesReducer.isOpenUnsavedChangedDialog
  );

  const handleBackUnsavedChanged = useAppSelector(
    (state) => state.unsavedChangesReducer.handleBackUnsavedChanged
  );

  const handleUpdateUnsavedChanged = useAppSelector(
    (state) => state.unsavedChangesReducer.handleUpdateUnsavedChanged
  );

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const handleCloseUnsavedChangesDialog = () => {
    setIsOpenUnsavedChangedDialog(false);
  };

  const handleBackUnsavedChangesDialog = () => {
    handleCloseUnsavedChangesDialog();
    if (handleBackUnsavedChanged) {
      handleBackUnsavedChanged();
    }
  };

  const handleUpdateUnsavedData = () => {
    if (handleUpdateUnsavedChanged) {
      handleUpdateUnsavedChanged();
    }
    handleCloseUnsavedChangesDialog();
  };

  const renderUnsavedChangesDialogActions = () => (
    <UnsavedChangesActionsContainer>
      <Button
        text={t("cancel")}
        isGrey
        onClick={handleCloseUnsavedChangesDialog}
        id="cancel"
      />
      <Button
        text={t("discard-changes")}
        onClick={handleBackUnsavedChangesDialog}
        isGrey
        id="discard-changes"
      />
      <Button
        text={t("save-changes")}
        onClick={handleUpdateUnsavedData}
        id="save-changes"
      />
    </UnsavedChangesActionsContainer>
  );

  const renderUnsavedChangesDialogFields = () => (
    <UnsavedChangedFieldsContainer>
      {t("all-unsaved-changes-lost")}
    </UnsavedChangedFieldsContainer>
  );

  useEffect(() => {
    const onBeforeUnload = (ev: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      ev.preventDefault();
      if (changedData) {
        ev.returnValue = "";

        return "";
      }
    };

    if (changedData) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [changedData]);

  return (
    <Dialog
      open={isOpenUnsavedChangedDialog}
      handleClose={handleCloseUnsavedChangesDialog}
      title={t("unsaved-changes")}
      actions={renderUnsavedChangesDialogActions}
      renderFields={renderUnsavedChangesDialogFields}
    />
  );
};
