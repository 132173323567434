import { styled, Box } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const IconWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const IconsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 10,
  "& > svg": {
    cursor: "pointer",
    width: 21,
    height: 21,
    [theme.breakpoints.down("md")]: {
      width: 14,
      height: 14,
    },
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
    },
  },
}));

export const AddButtonWrapper = styled(Box)(({ theme }) => ({
  width: "fit-content",
  [theme.breakpoints.down("lg")]: {
    alignSelf: "center",
  },
}));
