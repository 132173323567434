import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { useDeleteSubscriptionMutation } from "src/shared/store/api/report.api";
import { IDeleteSubscriptionDialog } from "./types";
import {
  DialogBody,
  DeleteSubscriptionDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const DeleteSubscriptionDialog = ({
  subscriptionID,
  open,
  handleClose,
}: IDeleteSubscriptionDialog) => {
  const { t } = useTranslation();
  const [
    deleteSubscription,
    {
      isSuccess: isSuccessDeleteSubscription,
      isLoading: isLoadingDeleteSubscription,
    },
  ] = useDeleteSubscriptionMutation();

  useEffect(() => {
    if (isSuccessDeleteSubscription && !isLoadingDeleteSubscription) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDeleteSubscription]);

  const deleteDialogActions = () => {
    const handleDeleteSubscription = subscriptionID
      ? () => deleteSubscription(subscriptionID)
      : undefined;

    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="delete-subscription-cancel"
        />
        <Button
          text={t("continue")}
          onClick={handleDeleteSubscription}
          disabled={isLoadingDeleteSubscription}
          id="delete-subscription-continue"
        />
      </SendBtnWrapper>
    );
  };

  const renderDeleteSubscriptionDialogFields = () => {
    return (
      <DeleteSubscriptionDialogContentContainer>
        <DialogBody>{t("are-you-sure-delete-schedule")}</DialogBody>
      </DeleteSubscriptionDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("delete-schedule-report")}
      actions={deleteDialogActions}
      renderFields={renderDeleteSubscriptionDialogFields}
    />
  );
};
