import {
  ColDef,
  GroupCellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { DeleteIconWrapper } from "src/pages/userList/styles";
import { ReactComponent as Trash } from "src/shared/assets/svgs/trash.svg";

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (
  isActiveWasteProfile: boolean,
  t: (value: string) => string
) => {
  const columns = [
    {
      headerName: t("waste-name"),
      field: "savedChemicalName",
      ...excelMode,
      flex: 1,
      sortable: true,
    },
    {
      headerName: t("min"),
      field: "minimumValue",
      ...excelMode,
      flex: 1,
      sortable: true,
    },
    {
      headerName: t("max"),
      field: "maximumValue",
      ...excelMode,
      flex: 1,
      sortable: true,
    },
    {
      headerName: t("avg"),
      field: "",
      ...excelMode,
      flex: 1,
      sortable: true,
      valueFormatter: ({ data }: ValueFormatterParams) => {
        return String(
          (Number(data?.minimumValue) + Number(data?.maximumValue)) / 2
        );
      },
    },
    {
      headerName: t("units"),
      field: "units",
      ...excelMode,
      flex: 1,
      sortable: true,
    },
  ] as ColDef[];

  if (!isActiveWasteProfile) {
    const deleteField = {
      headerName: t("action"),
      cellRenderer: ({ data }: GroupCellRendererParams) =>
        data ? (
          <DeleteIconWrapper>
            <Trash data-action="delete" />
          </DeleteIconWrapper>
        ) : null,
      editable: false,
      colId: "action",
    };

    columns.push(deleteField as ColDef);
  }

  return columns;
};

export const defaultColDef = {
  resizable: true,
};

export const paginationPageSize = 25;

export const AVAILABLE_CONSTITUENTS_GRID_NAME = "available-constituents";

export const PERCENT = "%";
export const PPM = "PPM";

export const UNITS_OPTIONS = [
  { id: PERCENT, val: PERCENT },
  { id: PPM, val: PPM },
];

export const getTotalValuesByField = (
  values: Array<{ [key: string]: string }>,
  field: string
) => {
  return values?.reduce((acc: number, val: { [key: string]: string }) => {
    if (val?.units === PERCENT) {
      acc += Number(val?.[field]);
    }

    return acc;
  }, 0);
};
