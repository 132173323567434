import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { Button } from "src/shared/components/button/button";
import { MainBackground } from "src/shared/components/mainBackground/mainBackground";
import { ReactComponent as AdviseLogo } from "src/shared/assets/svgs/advise-green-logo.svg";
import { Alert } from "src/shared/components/alert/alert";
import {
  useGetSubscriptionNameByIDQuery,
  useUnsubscribeFromSubscriptionMutation,
} from "src/shared/store/api/unsubscribe.api";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { Input } from "src/shared/components/input/input";
import { UnsubscribeValidationSchema } from "./model";
import {
  ActionsWrapper,
  UnsubscribeFieldWrapper,
  UnsubscribeLabel,
  LogoContainer,
  Title,
} from "./styles";

export const Unsubscribe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subscriptionID } = useParams();
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UnsubscribeValidationSchema(t)),
    mode: "onSubmit",
  });

  const {
    data: subscriptionName,
    isLoading: isLoadingSubscriptionName,
    isFetching: isFetchingSubscriptionName,
  } = useGetSubscriptionNameByIDQuery(subscriptionID);

  const [
    unsubscribeFromSubscription,
    { isSuccess: isSuccessUnsubscribeFromSubscription },
  ] = useUnsubscribeFromSubscriptionMutation({});

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  const handleConfirmUnsubscribe = handleSubmit((data) =>
    unsubscribeFromSubscription({
      subscriptionID,
      emailAddress: data?.email,
    })
  );

  const handleCancel = () => {
    navigate(`/dashboard/${TABS_NAMES[0]}`);
  };

  const handleClickAdviseLogo = () => {
    navigate(`/dashboard/${TABS_NAMES[0]}`);
  };

  useEffect(() => {
    if (isSuccessUnsubscribeFromSubscription) {
      setIsOpenSuccessAlert(true);
    }
  }, [isSuccessUnsubscribeFromSubscription]);

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingSubscriptionName || isFetchingSubscriptionName}
      loader={<CircularProgress />}
      keepInView
    >
      <MainBackground>
        <>
          <LogoContainer onClick={handleClickAdviseLogo}>
            <AdviseLogo />
          </LogoContainer>
          <Title>
            {t("are-you-sure-unsubscribe", {
              subscriptionName: subscriptionName || "",
            })}
          </Title>
          <UnsubscribeFieldWrapper>
            <UnsubscribeLabel>{t("email")}</UnsubscribeLabel>
            <Input
              errorMessage={errors?.email?.message}
              register={{ ...register("email") }}
              id="unsubscribe-email"
            />
          </UnsubscribeFieldWrapper>
          <ActionsWrapper>
            <Button
              text={t("cancel")}
              onClick={handleCancel}
              isGrey
              id="unsubscribe-cancel"
            />
            <Button
              text={t("confirm")}
              onClick={handleConfirmUnsubscribe}
              id="unsubscribe-confirm"
            />
          </ActionsWrapper>
        </>
      </MainBackground>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("success-unsubscribe")]}
        severity="success"
      />
    </BlockUi>
  );
};
