import { createSlice } from "@reduxjs/toolkit";
import { IManageInspectionsFilters } from "./manageInspectionsFilters";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { MANAGE_INSPECTIONS_STORAGE_KEYS } from "src/components/manageInspectionsTable/constants";

const initialManageInspectionsFiltersState: IManageInspectionsFilters = {
  selectedRow: undefined,
};

export const manageInspectionsFiltersSlice = createSlice({
  name: "manageInspectionsFilters",
  initialState: initialManageInspectionsFiltersState,
  reducers: {
    setManageInspectionsFilterScroll: (_, action) => {
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SCROLL_ROW,
        action.payload
      );
    },
    setManageInspectionsFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS_ROW,
        state.selectedRow
      );
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS,
        state.selectedRow
      );
    },
  },
});

export const {
  reducer: manageInspectionsFiltersReducer,
  actions: manageInspectionsFiltersActions,
} = manageInspectionsFiltersSlice;
