import { Box, TableCell, Typography, styled } from "@mui/material";
import { ITableHeadCell } from "./types";

export const ManifestLinesTableContainer = styled(Box)(() => ({
  borderRadius: 16,
  marginTop: 24,
  border: "1px solid #D9D9D9",
  padding: "16px 4px",
}));

export const TableHeadCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "withBottomBorder",
})<ITableHeadCell>(({ withBottomBorder, theme }) => ({
  color: "#217BDB",
  maxWidth: 322,
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  padding: "7px 6px",
  borderBottom: withBottomBorder ? "1px solid #D9D9D9" : "none",
  "&:not(:last-of-type)": {
    borderRight: "1px solid #D9D9D9",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const TableRowCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  borderBottom: "none",
  padding: "7px 4px",
  overflowWrap: "anywhere",
  "&:not(:last-of-type)": {
    borderRight: "1px solid #D9D9D9",
  },
  "&:nth-of-type(2)": {
    [theme.breakpoints.up("lg")]: {
      padding: "8px 16px",
      width: 322,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "8px 16px",
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: 230,
    },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const ManifestTableWrapper = styled(Box)(() => ({
  padding: "0 4px 8px 4px",
  borderBottom: "1px solid #D9D9D9",
}));

export const ManifestInformationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const ManifestInformationWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px 4px 0 4px",
  gap: 2,
}));
