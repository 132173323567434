import { useTranslation } from "react-i18next";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as Export } from "src/shared/assets/svgs/export.svg";
import { IPringToExcelButton } from "./types";
import {
  PrintToEXcelButtonWrapper,
  PrintToExcelButtonContainer,
} from "./styles";

export const PrintToExcelButton = ({
  handleDownloadExcel,
}: IPringToExcelButton) => {
  const { t } = useTranslation();

  return (
    <PrintToExcelButtonContainer>
      <PrintToEXcelButtonWrapper>
        <ButtonWithIcon
          text={t("export-to-excel")}
          onClick={handleDownloadExcel}
          icon={<Export />}
          id="print-to-excel"
        />
      </PrintToEXcelButtonWrapper>
    </PrintToExcelButtonContainer>
  );
};
