import { Key } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { IMultiEmail } from "./types";
import { MultiEmailWrapper, ErrorHelperText } from "./styles";
import { generatedIdForMultiEmail } from "src/shared/helpers/generatedId";

export const MultiEmail = ({
  values,
  onChange,
  errorMessage,
  id,
}: IMultiEmail) => {
  const _getLabel = (
    email: string,
    index: Key | null | undefined,
    removeEmail: (arg0: any) => void
  ) => {
    const handleDelete = () => removeEmail(index);

    return (
      <div data-tag key={index}>
        <div data-tag-item>{email}</div>
        <span data-tag-handle onClick={handleDelete}>
          ×
        </span>
      </div>
    );
  };

  return (
    <MultiEmailWrapper error={errorMessage}>
      <ReactMultiEmail
        emails={values}
        onChange={onChange}
        getLabel={_getLabel}
        id={generatedIdForMultiEmail(id)}
      />
      <ErrorHelperText>{errorMessage}</ErrorHelperText>
    </MultiEmailWrapper>
  );
};
