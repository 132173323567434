import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Return } from "src/shared/components/return/return";
import { ReactComponent as ErrorLogo } from "src/shared/assets/svgs/error.svg";
import {
  ErrorNewUserContainer,
  ErrorNewUserText,
  ErrorLogoWrapper,
  Error,
} from "./styles";

export const ErrorNewUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickReturn = () => {
    navigate("/sign-in");
  };

  return (
    <ErrorNewUserContainer>
      <Error>{t("error")}</Error>
      <ErrorLogoWrapper>
        <ErrorLogo />
      </ErrorLogoWrapper>
      <ErrorNewUserText>{t("verification-error")}</ErrorNewUserText>
      <Return onClick={handleClickReturn} />
    </ErrorNewUserContainer>
  );
};
