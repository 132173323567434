import { useState } from "react";
import { ReactComponent as EditFormArrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/arrow-down.svg";
import {
  FormCardContainer,
  FormCardWrapper,
  FormCardTitleWrapper,
  FormCardTitle,
  EditFormCardIconWrapper,
  FormCardTitleWithCollapse,
} from "./styles";
import { IFormCard } from "./types";

export const FormCard = ({
  customTitle,
  withCollapse,
  title,
  handleEdit,
  children,
  onClick,
}: IFormCard) => {
  const [isOpenFormCard, setIsOpenFormCard] = useState(false);

  const handleIsOpenCollapse = () => {
    setIsOpenFormCard(!isOpenFormCard);
  };

  const isOpen = withCollapse ? isOpenFormCard : true;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <FormCardContainer
      onClick={
        onClick
          ? handleClick
          : withCollapse
          ? handleIsOpenCollapse
          : handleEdit
          ? handleEdit
          : undefined
      }
      isCursorPointer={!!onClick || !!handleEdit}
      withCollapse={!!withCollapse}
    >
      <FormCardTitleWrapper isOpen={isOpen}>
        {withCollapse ? (
          <FormCardTitleWithCollapse isOpen={isOpenFormCard}>
            <FormCardTitle>{title}</FormCardTitle>
            <ArrowDown onClick={handleIsOpenCollapse} />
          </FormCardTitleWithCollapse>
        ) : customTitle ? (
          customTitle()
        ) : (
          <FormCardTitle>{title}</FormCardTitle>
        )}
        {handleEdit && (
          <EditFormCardIconWrapper>
            <EditFormArrow onClick={handleEdit} />
          </EditFormCardIconWrapper>
        )}
      </FormCardTitleWrapper>
      <FormCardWrapper isOpen={isOpen}>{children}</FormCardWrapper>
    </FormCardContainer>
  );
};
