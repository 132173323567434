import { useTranslation } from "react-i18next";
import { ItemsTitle, ItemsTitleWrapper } from "./styles";
import { ISitesTitle } from "./type";

export const SitesTitle = ({ organizationName }: ISitesTitle) => {
  const { t } = useTranslation();

  return (
    <ItemsTitleWrapper>
      <ItemsTitle>
        {organizationName
          ? t("list-of-sites", {
              organization: organizationName,
            })
          : ""}
      </ItemsTitle>
    </ItemsTitleWrapper>
  );
};
