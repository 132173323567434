export const GENERAL_CHARACTERISTICS_FIELDS = [
  "color",
  "odor",
  "odorStrength",
  "physicalState",
  "layersPhases",
  "percentLiquid",
  "percentSolid",
  "percentSludge",
  "percentPowder",
  "percentGas",
  "handlingCodes",
  "btu",
  "btuMinimum",
  "btuMaximum",
  "ph",
  "pHminimum",
  "pHmaximum",
  "flashPoint",
  "flashPointMinimum",
  "flashPointMaximum",
  "specificGravity",
  "specificGravityMinimum",
  "specificGravityMaximum",
];
