import { Box, styled } from "@mui/material";

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  justifyContent: "center",
  "& > div": {
    width: 190,
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
    "& > button": {
      height: 38,
      [theme.breakpoints.down("md")]: {
        height: 28,
        padding: 8,
      },
      "& > span > svg": {
        width: 20,
        height: 20,
        [theme.breakpoints.down("md")]: {
          width: 16,
          height: 16,
        },
        "& > path": {
          fill: "#217BDB",
        },
      },
    },
  },
}));

export const HazardousWrapper = styled(Box)(() => ({
  "& > div": {
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const FieldValueWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));
