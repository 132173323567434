import { SyntheticEvent, useState } from "react";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { FormCard } from "src/shared/components/formCard/formCard";
import { useGetSiteOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { ListOfItems } from "src/shared/components/listOfItems/listOfItems";
import { SiteSelection } from "../siteSelection/siteSelection";
import { IAccountCardWithSiteSelection } from "./types";
import { SiteSelectionWrapper } from "./styles";

export const AccountCardWithSiteSelection = ({
  id,
  accountName,
  selectedSiteList,
  setSelectedSiteList,
}: IAccountCardWithSiteSelection) => {
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState("0");

  const { data: organizationSites, isLoading: isLoadingOrganizationSites } =
    useGetSiteOrganizationsByIDsQuery(id, {
      skip: !id,
    });

  const handleSiteSelection = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
    setCurrentPage(0);
  };

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingOrganizationSites}
      loader={<CircularProgress />}
      keepInView
    >
      <FormCard title={accountName} withCollapse>
        <SiteSelectionWrapper onClick={handleSiteSelection}>
          <ListOfItems
            withSearch
            keyword={keyword}
            setKeyword={changeKeyword}
            title=""
            id={id}
            body={
              <SiteSelection
                siteItems={organizationSites}
                keyword={keyword}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginationPageSize={paginationPageSize}
                setPaginationPageSize={setPaginationPageSize}
                selectedSiteList={selectedSiteList}
                setSelectedSiteList={setSelectedSiteList}
                id={id}
              />
            }
          />
        </SiteSelectionWrapper>
      </FormCard>
    </BlockUi>
  );
};
