import { ChangeEvent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { TextArea } from "src/shared/components/textArea/textArea";
import {
  useGetFollowUpOptionsQuery,
  useGetInspectionCommentsByAccountQuery,
} from "src/shared/store/api/inspection.api";
import { getFollowUpOptions } from "src/components/inspectionsTable/inspectionsFilters/constants";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { IOption } from "src/shared/components/select/types";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { InspectionFilesUploader } from "src/shared/components/inspectionFilesUploader/inspectionFilesUploader";
import { DetailInspectionFieldWrapper } from "../detailInspectionCard/styles";
import {
  DetailInspectionLabel,
  DetailInspectionTitle,
} from "../../pages/newInspection/styles";
import { getCommentsOptions } from "./constants";
import { IDetailNotes } from "./types";
import { DetailNotesContainer, DetailNotesFieldWrapper } from "./styles";

export const DetailNotes = ({
  followUp,
  accountId,
  comments,
  updateFormField,
  inspectionFiles,
  setInspectionFiles,
}: IDetailNotes) => {
  const { t } = useTranslation();
  const { setChangedData } = useAppActions();
  const { data: followUpOptions } = useGetFollowUpOptionsQuery({});
  const { data: commentOptions } = useGetInspectionCommentsByAccountQuery(
    accountId,
    { skip: !accountId }
  );

  const handleChanged = () => {
    setChangedData(true);
  };

  const handleChangeFollowUp = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("followUpOptionID", inputValue?.id);
    updateFormField("followUpOption", inputValue?.val);
  };

  const handleAddAdditionalComment = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField(
      "comments",
      `${comments ? comments : ""}` +
        `${inputValue?.val ? inputValue?.val : ""}`
    );
  };

  const handleChangeComments = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChanged();
    updateFormField("comments", e?.target?.value);
  };

  const mappedCommentOptions = commentOptions?.length
    ? getCommentsOptions(commentOptions)
    : [];

  const mappedFollowUpOptions = followUpOptions?.length
    ? getFollowUpOptions(followUpOptions)
    : [];

  return (
    <DetailNotesContainer>
      <DetailInspectionTitle>{t("notes")}</DetailInspectionTitle>
      {commentOptions?.length > 0 && (
        <DetailInspectionFieldWrapper>
          <DetailInspectionLabel>{t("additional")}</DetailInspectionLabel>
          <DetailNotesFieldWrapper>
            <Autocomplete
              value={null}
              placeholder={t("select-a-comment")}
              onChange={handleAddAdditionalComment}
              options={mappedCommentOptions}
              disabled={!commentOptions?.length}
              id="detail-notes-select-a-comment"
            />
          </DetailNotesFieldWrapper>
        </DetailInspectionFieldWrapper>
      )}
      <DetailInspectionFieldWrapper>
        <DetailInspectionLabel>{t("comments")}</DetailInspectionLabel>
        <DetailNotesFieldWrapper>
          <TextArea
            value={comments}
            onChange={handleChangeComments}
            id="detail-notes-comments"
          />
        </DetailNotesFieldWrapper>
      </DetailInspectionFieldWrapper>
      <DetailInspectionFieldWrapper>
        <DetailInspectionLabel>{t("follow-up")}</DetailInspectionLabel>
        <DetailNotesFieldWrapper>
          <Autocomplete
            value={followUp || ""}
            onChange={handleChangeFollowUp}
            options={mappedFollowUpOptions}
            disabled={!followUpOptions?.length}
            id="detail-notes-follow-up"
          />
        </DetailNotesFieldWrapper>
      </DetailInspectionFieldWrapper>
      <InspectionFilesUploader
        handleFiles={setInspectionFiles}
        files={inspectionFiles}
      />
    </DetailNotesContainer>
  );
};
