import { Box, styled } from "@mui/material";

export const EManifestCustomTitleWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));
