import { Box, styled } from "@mui/material";

export const RequestsWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const RequestsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: 14,
  marginBottom: 80,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const RequestsPanel = styled(Box)(({ theme }) => ({
  width: 168,
  borderRadius: 8,
  border: "1px solid #D9D9D9",
  padding: 16,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const RequestsTableWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: 16,
  "& > div > div": {
    marginTop: 0,
    marginBottom: 0,
  },
  [theme.breakpoints.down("lg")]: {
    paddingRight: 16,
  },
}));

export const RequestsTabsWrapper = styled(Box)(() => ({
  "& > div": {
    "& > div > div > button": {
      alignItems: "start",
      fontSize: "18px !important",
      justifyContent: "center",
      height: 38,
      minHeight: 38,
      maxHeight: 38,
      padding: 0,
    },
  },
}));

export const MobileTabsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0 16px 16px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
