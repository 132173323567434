import { IInspectionTemplate } from "./types";

export const getInspectionTemplatesOptions = (
  inspectionTemplates: IInspectionTemplate[]
) => {
  return inspectionTemplates?.length > 0
    ? inspectionTemplates?.map(
        ({ inspectionID, title }: IInspectionTemplate) => ({
          id: inspectionID,
          val: title,
        })
      )
    : [];
};
