import { Box, Typography, styled } from "@mui/material";

export const LegendWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
}));

export const LegendTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  "& > span": {
    color: "#B1B1B1",
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    "& > span": {
      color: "#B1B1B1",
      fontSize: 14,
    },
  },
}));

export const ComplianceContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ComplianceWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
}));

export const ComplianceDescription = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));
