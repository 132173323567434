import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const ATTACHMENT_SUB_URL = "Attachment";

export const attachment = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttachmentByDocumentIDAndFileID: builder.query({
      queryFn: async ({ docID, fileID, fileName }, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ATTACHMENT_SUB_URL}/GetAttachmentByDocumentIDAndFileID/${docID}/${fileID}/${fileName}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = fileName;
        hiddenElement.click();
        return { data: null };
      },
    }),

    download: builder.query({
      queryFn: async (
        { containerName, folder, fileName },
        _,
        __,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `${ATTACHMENT_SUB_URL}/Download/${containerName}/${folder}/${fileName}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });

        return result;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAttachmentByDocumentIDAndFileIDQuery,
  useLazyDownloadQuery,
} = attachment;
