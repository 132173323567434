import { createSlice } from "@reduxjs/toolkit";
import { IInspectionNotificationFilters } from "./inspectionNotificationFilters";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS } from "src/components/inspectionsNotificationsTable/constants";

const initialInspectionNotificationFiltersState: IInspectionNotificationFilters =
  {
    selectedRow: undefined,
  };

export const inspectionNotificationFiltersSlice = createSlice({
  name: "inspectionNotificationFilters",
  initialState: initialInspectionNotificationFiltersState,
  reducers: {
    setInspectionNotificationFilterScroll: (_, action) => {
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SCROLL_ROW,
        action.payload
      );
    },
    setInspectionNotificationFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS_ROW,
        state.selectedRow
      );
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS,
        state.selectedRow
      );
    },
  },
});

export const {
  reducer: inspectionNotificationFiltersReducer,
  actions: inspectionNotificationFiltersActions,
} = inspectionNotificationFiltersSlice;
