import dayjs from "dayjs";
import { COMPLIANCE_COLOR } from "src/components/compliance/constants";
import { getLocaleDateValue } from "../constants";
import { IInspectionInformationData } from "./types";
import {
  EditFollowUp,
  InspectionColoredText,
  InspectionDescription,
  FollowUpContainer,
} from "./styles";

export const COLORS = {
  GREEN: "green",
  RED: "red",
  YELLOW: "yellow",
};

export const getScoreRating = (color: string) => {
  switch (color) {
    case COLORS.GREEN:
      return "good";
    case COLORS.RED:
      return "deficient";
    case COLORS.YELLOW:
      return "substandard";
  }
};

export const getInspectionInformationConfig = (
  inspectionInformationData: IInspectionInformationData,
  t: (text: string) => string,
  handleOpenEditFollowUpDialog: () => void
) => {
  return [
    {
      name: t("inspection-date"),
      value: inspectionInformationData?.inspectionDate
        ? `${getLocaleDateValue(
            dayjs(inspectionInformationData?.inspectionDate)?.toISOString()
          )} ${
            inspectionInformationData?.inspectionTime
              ? inspectionInformationData?.inspectionTime
              : ""
          }`
        : "",
    },
    {
      name: t("inspector"),
      value: inspectionInformationData?.inspectorName || "-",
    },
    {
      name: t("account"),
      value: inspectionInformationData?.accountName || "-",
    },
    {
      name: t("school"),
      value: "-",
    },
    {
      name: t("site"),
      value: inspectionInformationData?.siteName || "-",
    },
    {
      name: t("department"),
      value: inspectionInformationData?.departmentName || "-",
    },
    {
      name: t("building"),
      value: inspectionInformationData?.buildingName || "-",
    },
    {
      name: t("room"),
      value: inspectionInformationData?.roomName || "-",
    },
    {
      name: t("location"),
      value: inspectionInformationData?.storageName || "-",
    },
    {
      name: t("pi"),
      value: inspectionInformationData?.piContactFullName || "-",
    },
    {
      name: t("safety-officer"),
      value: "-",
    },
    {
      name: t("schedule"),
      value: "-",
    },
    {
      name: t("score"),
      renderValue: () => (
        <InspectionDescription>
          {inspectionInformationData?.score
            ? `${inspectionInformationData?.score?.value} %, ${t("rating")}: `
            : ""}
          <InspectionColoredText
            color={COMPLIANCE_COLOR[inspectionInformationData?.score?.color]}
          >
            {t(getScoreRating(inspectionInformationData?.score?.color) || "")}
          </InspectionColoredText>
        </InspectionDescription>
      ),
    },
    {
      name: t("follow-up-status"),
      renderValue: () => (
        <FollowUpContainer>
          <EditFollowUp onClick={handleOpenEditFollowUpDialog}>
            {inspectionInformationData?.followUpOption}
          </EditFollowUp>
          {!inspectionInformationData?.followUpOption && (
            <EditFollowUp onClick={handleOpenEditFollowUpDialog}>
              {t("edit")}
            </EditFollowUp>
          )}
        </FollowUpContainer>
      ),
    },
    {
      ...(inspectionInformationData?.followUpComments
        ? {
            name: t("follow-up-comments"),
            value: inspectionInformationData?.followUpComments,
          }
        : {}),
    },
    {
      name: t("other-comments"),
      value: inspectionInformationData?.comments || "-",
    },
  ];
};
