import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { Box, CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import question from "src/shared/assets/svgs/question.svg";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { useGetOrganizationBySearchValueQuery } from "src/shared/store/api/organization.api";
import { Button } from "src/shared/components/button/button";
import { useGetReportItemsQuery } from "src/shared/store/api/report.api";
import { IOption } from "src/shared/components/select/types";
import { ReportsTooltip } from "../reportsTooltip/reportsTooltip";
import {
  isEqualsAccountsSelected,
  getReportItemsOptions,
  getReportTypeByTab,
} from "./constants";
import { ReportsToolValidationSchema } from "./model";
import { IReportsTool } from "./types";
import {
  ReportsToolContainer,
  ReportsToolTitle,
  ReportsToolHeader,
  Question,
  ReportsToolHeaderWrapper,
  ReportsToolFieldWrapper,
  ReportsToolLabel,
  ActionsWrapper,
  Iframe,
} from "./styles";

export const ReportsTool = ({ tab }: IReportsTool) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoadingIFrame, setIsLoadingIframe] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [reportTypeToRequest, setReportTypeToRequest] = useState<string>();
  const [accountIdsToRequest, setAccountIdsToRequest] = useState<
    Array<string | undefined>
  >([]);
  const [isOpenReportsTooltip, setIsOpenReportsTooltip] =
    useState<boolean>(false);

  const reportType = getReportTypeByTab(tab);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ReportsToolValidationSchema(t)),
    mode: "onSubmit",
  });

  const accountIDs = watch("accountIDs") || [];
  const reportTypeValue = watch("reportType");

  const handleClickCancel = () => {
    navigate(`/dashboard/${tab}`);
  };

  const handleSetSchedule = () => {
    navigate(
      `/set-schedule/${encodeURIComponent(reportTypeValue)}/${accountIDs}`
    );
  };

  const { data: reportItems, isLoading: isLoadingReportItems } =
    useGetReportItemsQuery(reportType);

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue,
  });

  const handleOpenReportsTooltip = () => {
    setIsOpenReportsTooltip(true);
  };

  const handleReportItems = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    setValue("reportType", inputValue?.id, {
      shouldValidate: errors?.reportType?.message ? true : false,
    });
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValues = newInputValue as Array<IOption>;
    const inputIds = inputValues?.map(({ id }: IOption) => id);

    setValue(
      "accountIDs",
      inputIds?.length > 0
        ? inputValues?.map(({ id }: IOption) => id)
        : (undefined as unknown as string[]),
      { shouldValidate: errors?.accountIDs?.message ? true : false }
    );
  };

  const handleApply = handleSubmit((data) => {
    if (
      data?.reportType !== reportTypeToRequest ||
      !isEqualsAccountsSelected(
        data?.accountIDs as string[],
        accountIdsToRequest as string[]
      )
    ) {
      setIsLoadingIframe(true);
    }
    setReportTypeToRequest(data?.reportType);
    setAccountIdsToRequest(data?.accountIDs);
  });

  const onLoadReport = () => {
    setIsLoadingIframe(false);
  };

  const updateData = (value: string) => {
    setSearchValue(value);
  };

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingIFrame}
      loader={<CircularProgress />}
      keepInView
    >
      <ReportsToolContainer>
        <ReportsToolTitle>
          {t("select-report-configuration-parameters")}
        </ReportsToolTitle>
        <ReportsToolHeaderWrapper>
          <ReportsToolHeader>
            {t(`${reportType}-report-tool`)}
          </ReportsToolHeader>
          <Box>
            <Question
              src={question}
              alt=""
              onClick={handleOpenReportsTooltip}
            />
            <ReportsTooltip
              open={isOpenReportsTooltip}
              setOpen={setIsOpenReportsTooltip}
              reportType={reportType as string}
            />
          </Box>
        </ReportsToolHeaderWrapper>
        <ReportsToolFieldWrapper>
          <ReportsToolLabel>{t("report-type")}</ReportsToolLabel>
          <Autocomplete
            options={getReportItemsOptions(reportItems)}
            register={register("reportType")}
            onChange={handleReportItems}
            errorMessage={errors?.reportType?.message}
            id="report-tool-report-type"
            noOptionText={
              isLoadingReportItems ? t("loading-options") : undefined
            }
          />
        </ReportsToolFieldWrapper>
        <ReportsToolFieldWrapper>
          <ReportsToolLabel>{t("account")}</ReportsToolLabel>
          <Autocomplete
            options={organizationOptions ? organizationOptions : []}
            register={register("accountIDs")}
            updateData={updateData}
            onChange={handleChangeOrganization}
            multiple
            errorMessage={errors?.accountIDs?.message}
            id="report-tool-account"
          />
        </ReportsToolFieldWrapper>
        <ActionsWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleClickCancel}
            id="report-tool-cancel"
          />
          <Button
            text={t("set-schedule")}
            onClick={handleSetSchedule}
            disabled={!accountIDs?.length || !reportTypeValue}
            id="report-tool-set-schedule"
          />
          <Button
            text={t("apply")}
            onClick={handleApply}
            id="report-tool-apply"
          />
        </ActionsWrapper>
        {reportTypeToRequest && accountIdsToRequest?.length > 0 && (
          <Iframe
            title="report"
            src={`https://advisereports.triumvirate.com:9443/ReportViewer/ReportTemplate?reportPath=${reportTypeToRequest}&accountOrganizationUnitIDs=${accountIdsToRequest}`}
            onLoad={onLoadReport}
          />
        )}
      </ReportsToolContainer>
    </BlockUi>
  );
};
