import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { Input } from "src/shared/components/input/input";
import { Dialog } from "src/shared/components/dialog/dialog";
import { useUpsertGuestUserProfileMutation } from "src/shared/store/api/userProfile.api";
import {
  DialogLabel,
  LabelWrapper,
  GuestUserProfileDialogContentContainer,
  SendBtnWrapper,
} from "./styles";
import { GuestUserProfileValidationSchema } from "./model";
import { IGuestUserProfileDialog } from "./types";

const emptyFields = {
  firstName: "",
  lastName: "",
  organizationName: "",
  jobTitle: "",
  phone: "",
};

export const GuestUserProfileDialog = ({
  open,
  handleClose: handleCloseGuestUserProfileDialog,
}: IGuestUserProfileDialog) => {
  const { t } = useTranslation();

  const [
    upsertUserProfile,
    {
      isSuccess: isSuccessUpsertUserProfile,
      isLoading: isUpsertUserProfileLoading,
    },
  ] = useUpsertGuestUserProfileMutation();

  useEffect(() => {
    if (isSuccessUpsertUserProfile && !isUpsertUserProfileLoading) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpsertUserProfileLoading]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: { ...emptyFields },
    resolver: yupResolver(GuestUserProfileValidationSchema(t)),
    mode: "onSubmit",
  });

  const handleClose = () => {
    handleCloseGuestUserProfileDialog();
    reset({ ...emptyFields });
    clearErrors();
  };

  useEffect(() => {
    if (isSuccessUpsertUserProfile) {
      reset({ ...emptyFields });
    }
  }, [reset, isSuccessUpsertUserProfile]);

  const handleSendGuestUserProfile = handleSubmit((data) =>
    upsertUserProfile({
      body: data,
    })
  );

  const guestUserProfileDialogActions = () => {
    return (
      <SendBtnWrapper>
        <FilledButton
          text={t("send")}
          onClick={handleSendGuestUserProfile}
          disabled={isUpsertUserProfileLoading}
          id="guest-user-profile-send"
        />
      </SendBtnWrapper>
    );
  };

  const renderGuestUserProfileDialogFields = () => {
    return (
      <GuestUserProfileDialogContentContainer>
        <LabelWrapper>
          <DialogLabel>{t("firstName")}</DialogLabel>
          <Input
            errorMessage={errors?.firstName?.message}
            register={{ ...register("firstName") }}
            id="guest-user-profile-first-name"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("lastName")}</DialogLabel>
          <Input
            errorMessage={errors?.lastName?.message}
            register={{ ...register("lastName") }}
            id="guest-user-profile-last-name"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("organizationName")}</DialogLabel>
          <Input
            errorMessage={errors?.organizationName?.message}
            register={{ ...register("organizationName") }}
            id="guest-user-profile-organization-name"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("jobTitle")}</DialogLabel>
          <Input
            errorMessage={errors?.jobTitle?.message}
            register={{ ...register("jobTitle") }}
            id="guest-user-profile-job-title"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("phone")}</DialogLabel>
          <Input
            errorMessage={errors?.phone?.message}
            register={{ ...register("phone") }}
            id="guest-user-profile-phone"
          />
        </LabelWrapper>
      </GuestUserProfileDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      title={t("guest-user-profile")}
      actions={guestUserProfileDialogActions}
      renderFields={renderGuestUserProfileDialogFields}
    />
  );
};
