import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FormCard } from "src/shared/components/formCard/formCard";
import { ReactComponent as Active } from "src/shared/assets/svgs/green-tick-circle.svg";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import { useGetSiteByOrganizationByIDQuery } from "src/shared/store/api/organization.api";
import { SiteCardTitle } from "./siteCardTitle/siteCardTitle";
import { getSiteCardList } from "./constants";
import {
  SiteCardContainer,
  FieldWrapper,
  FieldInformationWrapper,
  IconWrapperWithArrow,
} from "./styles";

export const SiteCard = () => {
  const { t } = useTranslation();
  const { siteUnitID } = useParams();
  const { data: site } = useGetSiteByOrganizationByIDQuery(siteUnitID, {
    skip: !siteUnitID,
  });

  const siteCardFields = getSiteCardList(site, t);

  return (
    <SiteCardContainer>
      <FormCard customTitle={() => <SiteCardTitle />}>
        <>
          {siteCardFields.map(({ name, value, isFirstField }, index) => (
            <FieldWrapper key={index}>
              <FieldInformationWrapper>
                <FieldName>{name}</FieldName>
                <FieldValue>{value}</FieldValue>
              </FieldInformationWrapper>
              {isFirstField && (
                <IconWrapperWithArrow>
                  <Active />
                </IconWrapperWithArrow>
              )}
            </FieldWrapper>
          ))}
        </>
      </FormCard>
    </SiteCardContainer>
  );
};
