import { useTranslation } from "react-i18next";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as Barcode } from "src/shared/assets/svgs/barcode.svg";
import { IPringQrCodesButton } from "./types";
import {
  PrintQrCodesButtonWrapper,
  PrintQrCodesButtonContainer,
} from "./styles";

export const PrintQrCodesButton = ({
  handlePrintQrCodes,
}: IPringQrCodesButton) => {
  const { t } = useTranslation();

  return (
    <PrintQrCodesButtonContainer>
      <PrintQrCodesButtonWrapper>
        <ButtonWithIcon
          text={t("print-qr-codes")}
          onClick={handlePrintQrCodes}
          icon={<Barcode />}
          id="print-qr-codes"
        />
      </PrintQrCodesButtonWrapper>
    </PrintQrCodesButtonContainer>
  );
};
