import { Box, styled, Typography } from "@mui/material";

export const CopyManageInspectionDialogContentContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: 500,
    gap: 24,
    [theme.breakpoints.down("md")]: {
      gap: 12,
      width: "auto",
    },
  })
);

export const SendBtnWrapper = styled(Box)(({ theme }) => ({
  width: 270,
  display: "flex",
  gap: 10,
  "& > button": {
    height: 35,
    "&:first-of-type": {
      border: "1px solid #282828",
    },
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
  },
}));

export const CopyManageInspectionFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  "& > div,textArea": {
    display: "flex",
    maxWidth: 400,
    width: "100%",
    flexWrap: "wrap",
    "& .MuiFormHelperText-root": {
      textWrap: "nowrap",
      top: 24,
    },
    "& > div,textArea": {
      "& .MuiFormHelperText-root": {
        textWrap: "nowrap",
        top: 24,
      },
      height: 24,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const CopyManageInspectionLabel = styled(Typography)(({ theme }) => ({
  minWidth: 140,
  color: "#B1B1B1",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  display: "flex",
  gap: 8,
  "& > span": {
    color: "#F41808",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    minWidth: 120,
  },
}));
