import { Box, styled } from "@mui/material";

export const BuildingCardContainer = styled(Box)(({ theme }) => ({
  marginBottom: 24,
  [theme.breakpoints.down("md")]: {
    marginBottom: 16,
  },
}));

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  justifyContent: "space-between",
}));

export const FieldInformationWrapper = styled(Box)(() => ({
  display: "flex",
}));
