import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { IInspectionsFilters } from "./inspectionsFilters.types";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import {
  HAS_VALUES_OPTIONS,
  INSPECTIONS_STORAGE_KEYS,
} from "src/components/inspectionsTable/inspectionsFilters/constants";

const initialInspectionsFiltersState: IInspectionsFilters = {
  keyword: "",
  account: undefined,
  sites: [],
  buildings: [],
  rooms: [],
  locations: [],
  contacts: [],
  departments: [],
  searchOrganization: "",
  scroll: undefined,
  selectedRow: undefined,
  startDate: dayjs(new Date()).subtract(365, "days"),
  endDate: dayjs(new Date()),
  followUp: [],
  scoreComparison: undefined,
  score: undefined,
  hasAttachments: HAS_VALUES_OPTIONS.ALL,
  hasComments: HAS_VALUES_OPTIONS.ALL,
  inspectionType: undefined,
};

export const inspectionsFiltersSlice = createSlice({
  name: "inspectionsFilters",
  initialState: initialInspectionsFiltersState,
  reducers: {
    setInspectionsFiltersKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setInspectionsFiltersAccount: (state, action) => {
      state.account = action.payload;
    },
    setInspectionsFilterSites: (state, action) => {
      state.sites = action.payload;
    },
    setInspectionsFilterBuildings: (state, action) => {
      state.buildings = action.payload;
    },
    setInspectionsFilterRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setInspectionsFilterStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setInspectionsFilterEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setInspectionsFilterLocations: (state, action) => {
      state.locations = action.payload;
    },
    setInspectionsFilterDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setInspectionsFilterContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setInspectionsFiltersSearchOrganization: (state, action) => {
      state.searchOrganization = action.payload;
    },
    setInspectionsFiltersFollowUp: (state, action) => {
      state.followUp = action.payload;
    },
    setInspectionsFiltersScoreComparison: (state, action) => {
      state.scoreComparison = action.payload;
    },
    setInspectionsFiltersHasAttachments: (state, action) => {
      state.hasAttachments = action.payload;
    },
    setInspectionsFiltersHasComments: (state, action) => {
      state.hasComments = action.payload;
    },
    setInspectionsFiltersScore: (state, action) => {
      state.score = action.payload;
    },
    setInspectionsFilterScroll: (state, action) => {
      state.scroll = action.payload;
    },
    setInspectionsFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        INSPECTIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_ROW,
        state.selectedRow
      );
    },
    setInspectionsFiltersInspectionType: (state, action) => {
      state.inspectionType = action.payload;
    },
    saveToLocalStorageInspectionsFilters: (state) => {
      saveToLocalStorage(INSPECTIONS_STORAGE_KEYS.KEYWORD, state.keyword);
      saveToLocalStorage(
        INSPECTIONS_STORAGE_KEYS.ACCOUNT_ID_LIST,
        state.account
      );
      saveToLocalStorage(
        INSPECTIONS_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE,
        state.searchOrganization
      );
    },
    clearFromLocalStorageInspectionsFilters: (state) => {
      state.keyword = "";
      state.account = undefined;
      state.buildings = [];
      state.rooms = [];
      state.locations = [];
      state.contacts = [];
      state.departments = [];
      state.startDate = dayjs(new Date()).subtract(365, "days");
      state.endDate = dayjs(new Date());
      state.selectedRow = undefined;
      state.followUp = [];
      state.scoreComparison = undefined;
      state.score = undefined;
      state.hasAttachments = HAS_VALUES_OPTIONS.ALL;
      state.hasComments = HAS_VALUES_OPTIONS.ALL;
      state.inspectionType = undefined;
    },
  },
});

export const {
  reducer: inspectionsFiltersReducer,
  actions: inspectionsFiltersActions,
} = inspectionsFiltersSlice;
