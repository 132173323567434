import { useNavigate } from "react-router-dom";
import { FormCard } from "src/shared/components/formCard/formCard";
import { useGetSiteByOrganizationByIDQuery } from "src/shared/store/api/organization.api";
import { InventoryAgingCardTitle } from "./inventoryAgingCardTitle/inventoryAgingCardTitle";
import { IInventoryAgingCard } from "./types";
import { InventoryAgingCardContainer } from "./styles";

export const InventoryAgingCard = ({ id }: IInventoryAgingCard) => {
  const navigate = useNavigate();
  const { data: site } = useGetSiteByOrganizationByIDQuery(id, { skip: !id });

  const handleClickAging = () => {
    if (id) {
      navigate(`/edit-inventory-aging/${id}`);
    }
  };

  return (
    <InventoryAgingCardContainer>
      <FormCard
        customTitle={() => (
          <InventoryAgingCardTitle
            generatorStatusID={site?.generatorStatusID}
          />
        )}
        onClick={handleClickAging}
      >
        <></>
      </FormCard>
    </InventoryAgingCardContainer>
  );
};
