import {
  ComplianceContainer,
  ComplianceDescription,
  ComplianceWrapper,
  LegendTitle,
  LegendWrapper,
} from "./styles";
import { Compliance } from "src/components/compliance/compliance";
import { IChartLegend, IChartLegendInformationConfig } from "./types";

export const ChartLegend = ({
  title,
  secondTitle,
  chartLegendInformationConfig,
  showLegend,
}: IChartLegend) => {
  return showLegend ? (
    <LegendWrapper>
      <LegendTitle>
        {title}
        <span>{secondTitle}</span>
      </LegendTitle>
      {chartLegendInformationConfig?.map(
        (
          { description, value, color }: IChartLegendInformationConfig,
          index: number
        ) => (
          <ComplianceContainer key={index}>
            <ComplianceWrapper>
              <Compliance color={color} />
              <ComplianceDescription>{description}</ComplianceDescription>
            </ComplianceWrapper>
            <ComplianceDescription>{value}</ComplianceDescription>
          </ComplianceContainer>
        )
      )}
    </LegendWrapper>
  ) : null;
};
