import * as yup from "yup";

export const WasteStreamDescriptionValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    profileName: yup.string().required(t("required-field")),
    shipmentOption: yup.string().required(t("required-field")),
    wasteGeneratingProcessDescription: yup
      .string()
      .required(t("required-field")),
  });
