import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { IFacilityForm, IFacility } from "./types";

export const FacilityForm = ({ facilityConfig }: IFacilityForm) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("facility")} withCollapse>
      <React.Fragment>
        {facilityConfig?.map((facility: IFacility, index: number) => (
          <React.Fragment key={index}>
            <FieldWrapper>
              <FieldName>{facility.name}</FieldName>
              <FieldValue>{facility.value}</FieldValue>
            </FieldWrapper>
            {index !== facilityConfig?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </FormCard>
  );
};
