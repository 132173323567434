import React from "react";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ClickOutside } from "../clickOutside/clickOutside";
import { ITooltip } from "./types";
import {
  TooltipContainer,
  TooltipCloseIconWrapper,
  TooltipWrapper,
} from "./styles";

export const Tooltip = ({ open, setOpen, children }: ITooltip) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {open ? (
        <ClickOutside onClick={handleClose}>
          <>
            <TooltipContainer>
              <TooltipWrapper>
                <TooltipCloseIconWrapper onClick={handleClose}>
                  <Close />
                </TooltipCloseIconWrapper>
                {children}
              </TooltipWrapper>
            </TooltipContainer>
          </>
        </ClickOutside>
      ) : null}
    </React.Fragment>
  );
};
