import { Switch } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSwitch = styled(Switch)(({ disabled }) => ({
  width: 32,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(1px) translateY(1px)",
    "&.Mui-checked": {
      transform: "translateX(17px) translateY(1px)",
      "& + .MuiSwitch-track": {
        background: "transparent",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 12,
    height: 12,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      borderRadius: 60,
      background: disabled ? "#282828" : "#217BDB",
      opacity: disabled ? 0.12 : 1,
    },
  },
  "& .MuiSwitch-track": {
    background: "transparent",
    border: "1px solid #217BDB",
  },
}));
