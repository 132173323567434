import { IRoom } from "../buildingInformation/types";
import { IBuilding } from "../../components/buildingsList/types";

export const getRoomConfig = (
  room: IRoom,
  building: IBuilding,
  t: (text: string) => string
) => {
  return [
    {
      name: t("room"),
      value: room?.name,
    },
    {
      name: t("building"),
      value: building?.name,
    },
  ];
};
