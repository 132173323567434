import { useTranslation } from "react-i18next";
import { TitleContainer, Title } from "./styles";

export const BuildingCardTitle = () => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <Title>{t("building")}</Title>
    </TitleContainer>
  );
};
