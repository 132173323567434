import { INotification } from "./types";
import {
  NotificationContainer,
  NotificationTitle,
  NotificationText,
} from "./styles";

export const Notification = ({ title, text }: INotification) => {
  return (
    <NotificationContainer>
      <NotificationTitle>{title}</NotificationTitle>
      <NotificationText dangerouslySetInnerHTML={{ __html: text }} />
    </NotificationContainer>
  );
};
