import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const AccountInformationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px 20px 16px 72px",
  gap: 8,
  zIndex: 0,
  userSelect: "text",
  cursor: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "12px 20px 16px 24px",
  },
}));

export const AccountInformationTitle = styled(Typography)(({ theme }) => ({
  color: "#25895C",
  textAlign: "center",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const AccountName = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#282828",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const AccountBody = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#282828",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const AccountPhone = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "130%",
  },
}));

export const Divider = styled(Box)(() => ({
  margin: "10px 0",
  background: "#D9D9D9",
  height: 1,
  width: "100%",
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "fit-content",
  alignSelf: "center",
  minWidth: 160,
  [theme.breakpoints.down("md")]: {
    minWidth: 0,
  },
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 16,
}));
