import { SyntheticEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BlockUi from "@availity/block-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  useGetSiteByOrganizationByIDQuery,
  useUpsertSiteAgingMutation,
} from "src/shared/store/api/organization.api";
import { useGetStateWasteCodesQuery } from "src/shared/store/api/regulatory.api";
import {
  Divider,
  FieldName,
  FieldValue,
} from "src/shared/components/formCard/styles";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { Button } from "src/shared/components/button/button";
import { IOption } from "src/shared/components/select/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  getFilteredWasteCodes,
  getInventoryAgingParametersFields,
  getWasteCodesArray,
  getWasteStateCodes,
  getWasteStateCodeByField,
} from "./constants";
import { InventoryAgingParametersValidationSchema } from "./model";
import { IInventoryAgingFields } from "./types";
import {
  FieldWrapper,
  InventoryAgingParametersContainer,
  ButtonsWrapper,
  FieldsWrapper,
} from "./styles";

export const InventoryAgingParameters = () => {
  const { t } = useTranslation();
  const { agingID } = useParams();
  const navigate = useNavigate();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InventoryAgingParametersValidationSchema(t)),
    mode: "onSubmit",
  });

  const stateCodes30Days = watch("stateCodes30Days") || [];
  const stateCodes90Days = watch("stateCodes90Days") || [];
  const stateCodes180Days = watch("stateCodes180Days") || [];
  const stateCodes365Days = watch("stateCodes365Days") || [];
  const stateCodesIndefinite = watch("stateCodesIndefinite") || [];

  const [
    upsertSiteAging,
    { isSuccess: isSuccessUpsertAging, isLoading: isLoadingUpsertAging },
  ] = useUpsertSiteAgingMutation();

  const { data: site, isLoading: isLoadingSite } =
    useGetSiteByOrganizationByIDQuery(agingID, {
      skip: !agingID,
    });

  const { data: stateWasteCodes } = useGetStateWasteCodesQuery(site?.state, {
    skip: !site?.state,
  });

  const inventoryAgingParametersFields = getInventoryAgingParametersFields(
    site,
    t
  );

  const agingOptions = getFilteredWasteCodes(stateWasteCodes, {
    stateCodes30Days: getWasteStateCodes(stateCodes30Days),
    stateCodes90Days: getWasteStateCodes(stateCodes90Days),
    stateCodes180Days: getWasteStateCodes(stateCodes180Days),
    stateCodes365Days: getWasteStateCodes(stateCodes365Days),
    stateCodesIndefinite: getWasteStateCodes(stateCodesIndefinite),
  });

  const handleChangeState =
    (field: string) =>
    (_: SyntheticEvent<Element, Event>, newInputValue: unknown) => {
      setValue(field as IInventoryAgingFields, newInputValue as IOption[], {
        shouldValidate: true,
      });
      setChangedData(true);
    };

  const handleSaveInventoryAging = handleSubmit(
    ({
      stateCodes30Days,
      stateCodes90Days,
      stateCodes180Days,
      stateCodes365Days,
      stateCodesIndefinite,
    }) => {
      upsertSiteAging({
        siteOrganizationUnitID: agingID,
        body: {
          ...getWasteStateCodeByField("stateCodes30Days", stateCodes30Days),
          ...getWasteStateCodeByField("stateCodes90Days", stateCodes90Days),
          ...getWasteStateCodeByField("stateCodes180Days", stateCodes180Days),
          ...getWasteStateCodeByField("stateCodes365Days", stateCodes365Days),
          ...getWasteStateCodeByField(
            "stateCodesIndefinite",
            stateCodesIndefinite
          ),
        },
      });
    }
  );

  const handleCancel = useCallback(() => {
    if (site?.organizationUnitID) {
      navigate(`/site-information/${site?.organizationUnitID}`);
    }
  }, [navigate, site?.organizationUnitID]);

  const backToSiteInformation = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCancel();
    });
  }, [handleCancel, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleSaveInventoryAging();
    });
  }, [handleSaveInventoryAging, setHandleUpdateUnsavedChanged]);

  useEffect(() => {
    return () => {
      setChangedData(false);
    };
  }, [setChangedData]);

  useEffect(() => {
    const stateCodes30Days = getWasteCodesArray(site?.aging?.stateCodes30Days);
    const stateCodes90Days = getWasteCodesArray(site?.aging?.stateCodes90Days);
    const stateCodes180Days = getWasteCodesArray(
      site?.aging?.stateCodes180Days
    );
    const stateCodes365Days = getWasteCodesArray(
      site?.aging?.stateCodes365Days
    );
    const stateCodesIndefinite = getWasteCodesArray(
      site?.aging?.stateCodesIndefinite
    );
    setValue("stateCodes30Days", stateCodes30Days);
    setValue("stateCodes90Days", stateCodes90Days);
    setValue("stateCodes180Days", stateCodes180Days);
    setValue("stateCodes365Days", stateCodes365Days);
    setValue("stateCodesIndefinite", stateCodesIndefinite);
  }, [setValue, site?.aging]);

  useEffect(() => {
    if (isSuccessUpsertAging) {
      setChangedData(false);
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpsertAging]);

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingSite || isLoadingUpsertAging}
      loader={<CircularProgress />}
      keepInView
    >
      <InventoryAgingParametersContainer>
        <FormCard title={t("inventory-aging-parameters")}>
          <>
            {inventoryAgingParametersFields?.map(({ name, value }, index) => (
              <FieldWrapper key={index}>
                <FieldName>{name}</FieldName>
                <FieldValue>{value}</FieldValue>
              </FieldWrapper>
            ))}
            <Divider />
            <FieldsWrapper>
              <FieldWrapper>
                <FieldName>{t("30-days")}</FieldName>
                <Autocomplete
                  options={[...agingOptions, ...stateCodes30Days]}
                  value={stateCodes30Days || []}
                  onChange={handleChangeState("stateCodes30Days")}
                  multiple
                  errorMessage={errors?.stateCodes30Days?.message}
                  id="inventory-aging-parameters-stateCodes30Days"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("90-days")}</FieldName>
                <Autocomplete
                  options={[...agingOptions, ...stateCodes90Days]}
                  value={stateCodes90Days || []}
                  onChange={handleChangeState("stateCodes90Days")}
                  multiple
                  errorMessage={errors?.stateCodes90Days?.message}
                  id="inventory-aging-parameters-stateCodes90Days"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("180-days")}</FieldName>
                <Autocomplete
                  options={[...agingOptions, ...stateCodes180Days]}
                  value={stateCodes180Days || []}
                  onChange={handleChangeState("stateCodes180Days")}
                  multiple
                  errorMessage={errors?.stateCodes180Days?.message}
                  id="inventory-aging-parameters-stateCodes180Days"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("1-year")}</FieldName>
                <Autocomplete
                  options={[...agingOptions, ...stateCodes365Days]}
                  value={stateCodes365Days || []}
                  onChange={handleChangeState("stateCodes365Days")}
                  multiple
                  errorMessage={errors?.stateCodes365Days?.message}
                  id="inventory-aging-parameters-stateCodes365Days"
                />
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("indefinite")}</FieldName>
                <Autocomplete
                  options={[...agingOptions, ...stateCodesIndefinite]}
                  value={stateCodesIndefinite || []}
                  onChange={handleChangeState("stateCodesIndefinite")}
                  multiple
                  errorMessage={errors?.stateCodesIndefinite?.message}
                  id="inventory-aging-parameters-indefinite"
                />
              </FieldWrapper>
            </FieldsWrapper>
          </>
        </FormCard>
        <ButtonsWrapper>
          <Button
            text={t("cancel")}
            onClick={backToSiteInformation}
            isGrey
            id="inventory-aging-parameters-cancel"
          />
          <Button
            text={t("save")}
            onClick={handleSaveInventoryAging}
            id="inventory-aging-parameters-save"
          />
        </ButtonsWrapper>
      </InventoryAgingParametersContainer>
    </BlockUi>
  );
};
