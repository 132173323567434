import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { IDeleteManifestBlobDialog } from "./types";
import {
  DialogBody,
  DeleteManifestBlobDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const DeleteManifestBlobDialog = ({
  manifestID,
  blobFilePath,
  open,
  handleClose,
  deleteManifestBlob,
  isSuccessDeleteManifestBlob,
  isLoadingDeleteManifestBlob,
}: IDeleteManifestBlobDialog) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccessDeleteManifestBlob && !isLoadingDeleteManifestBlob) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDeleteManifestBlob]);

  const deleteDialogActions = () => {
    const handleDeleteManifestBlob = blobFilePath
      ? () =>
          deleteManifestBlob({
            manifestID,
            containerName: blobFilePath?.containerName,
            folderName: blobFilePath?.folder,
            fileName: blobFilePath.file,
          })
      : undefined;

    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="delete-manifest-blob-cancel"
        />
        <Button
          text={t("continue")}
          onClick={handleDeleteManifestBlob}
          id="delete-manifest-blob-continue"
        />
      </SendBtnWrapper>
    );
  };

  const renderDeleteManifestBlobDialogFields = () => {
    return (
      <DeleteManifestBlobDialogContentContainer>
        <DialogBody>{t("are-you-sure-delete-this-attachment")}</DialogBody>
      </DeleteManifestBlobDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("delete-manifest-attachment")}
      actions={deleteDialogActions}
      renderFields={renderDeleteManifestBlobDialogFields}
    />
  );
};
