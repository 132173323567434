import { useTranslation } from "react-i18next";
import {
  AllSeriesType,
  ChartsTooltip,
  ChartsYAxis,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { BarPlot } from "@mui/x-charts/BarChart";
import { CircularProgress } from "@mui/material";
import {
  AxisConfig,
  ChartsYAxisProps,
  MakeOptional,
  ScaleName,
} from "@mui/x-charts/internals";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ChartLegend } from "src/shared/components/chartLegend/chartLegend";
import {
  inspectionsDueChartHeight,
  inspectionDueChartMargin,
  getModifiedInspectionDue,
  getInspectionsDueInformation,
  getInspectionsDueSeries,
  getTotalInspectionsDue,
  getYAxisInspectionsDue,
} from "./constants";
import { IInspectionsDueInformation, IInspectionsDueChart } from "./types";
import {
  InspectionsDueChartContainer,
  ResponsiveWrapper,
  CloseIconWrapper,
} from "./styles";

export const InspectionsDueChart = ({
  inspectionsDue,
  isLoadingInspectionsDue,
  handleDelete,
}: IInspectionsDueChart) => {
  const { t } = useTranslation();

  const inspectionsDueModifiedData = getModifiedInspectionDue(inspectionsDue);

  const series = getInspectionsDueSeries(
    inspectionsDueModifiedData
  ) as AllSeriesType[];

  const yAxis = getYAxisInspectionsDue();

  const inspectionsDueInformation = getInspectionsDueInformation(
    inspectionsDueModifiedData
  );

  const inspectionDueChartLegendConfig = inspectionsDueInformation?.map(
    ({
      complianceColor,
      complianceDescription,
    }: IInspectionsDueInformation) => ({
      description: complianceDescription,
      value: getTotalInspectionsDue(
        inspectionsDueModifiedData,
        complianceDescription
      ) as unknown as string,
      color: complianceColor,
    })
  );

  const totalInspectionsDue = inspectionDueChartLegendConfig?.reduce(
    (acc, { value }) => {
      acc += Number(value);

      return acc;
    },
    0
  );

  return (
    <InspectionsDueChartContainer
      tag="div"
      blocking={isLoadingInspectionsDue}
      loader={<CircularProgress />}
      keepInView
    >
      <CloseIconWrapper onClick={handleDelete}>
        <Close />
      </CloseIconWrapper>
      {inspectionsDue && (
        <ResponsiveWrapper>
          <ResponsiveChartContainer
            series={series}
            yAxis={
              yAxis as MakeOptional<
                AxisConfig<ScaleName, any, ChartsYAxisProps>,
                "id"
              >[]
            }
            height={inspectionsDueChartHeight}
            margin={inspectionDueChartMargin}
          >
            <BarPlot />
            <ChartsTooltip trigger="item" />
            <ChartsYAxis label="" position="left" axisId="y-axis-id" />
          </ResponsiveChartContainer>
        </ResponsiveWrapper>
      )}
      <ChartLegend
        title={t("inspections-due")}
        secondTitle={` (${totalInspectionsDue})`}
        showLegend={!!inspectionsDue}
        chartLegendInformationConfig={inspectionDueChartLegendConfig}
      />
    </InspectionsDueChartContainer>
  );
};
