import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InventoryNotificationFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
  [theme.breakpoints.down("md")]: {
    gap: 16,
  },
}));

export const InventoryNotificationFormLabel = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "23.44px",
    textWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      textWrap: "wrap",
      fontSize: 16,
    },
  })
);

export const InventoryNotificationFormFieldDaysWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 20,
    "& > div": {
      "& .MuiFormHelperText-root": {
        position: "absolute",
        top: 42,
      },
      "& > div,textArea": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        height: 42,
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 16,
    },
  })
);

export const InventoryNotificationFormFieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  flexDirection: "column",
}));

export const AccountCardWithSiteSelectionWrapper = styled(Box)(() => ({
  "& > div": {
    "&:first-of-type": {
      marginTop: 20,
      "& > div": {
        marginTop: 0,
      },
    },
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const ErrorHelperText = styled(Typography)(() => ({
  position: "absolute",
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
}));
