import { Box, Typography, styled } from "@mui/material";

export const ChemicalCompositionContainer = styled(Box)(() => ({
  marginTop: 70,
  position: "relative",
}));

export const ChemicalFieldsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  alignItems: "flex-end",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    "& > div": {
      width: "100%",
    },
  },
}));

export const AddButtonWrapper = styled(Box)(({ theme }) => ({
  "& > button": {
    background: "#28A745",
    "&:hover": {
      background: "#188732",
    },
    width: 112,
    height: 32,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export const ChemicalNameWrapper = styled(Box)(() => ({
  "& > div > div": {
    height: 32,
    "& > div": {
      height: 32,
      minWidth: 280,
    },
  },
}));

export const FieldsWrapper = styled(Box)(({ theme }) => ({
  minWidth: 124,
  "& > div > div": {
    height: 32,
    maxWidth: 124,
    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
    },
    "& > div": {
      height: 32,
      maxWidth: 124,
      [theme.breakpoints.down("md")]: {
        maxWidth: "none",
      },
    },
  },
}));

export const CreateChemical = styled(Typography)(() => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textDecorationLine: "underline",
  cursor: "pointer",
}));

export const CreateChemicalWrapper = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  marginTop: 16,
  gap: 8,
  alignItems: "center",
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));

export const TotalText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));

export const ChemicalCompositionHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

export const ChemicalCompositionWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
    borderRadius: 16,
    border: "1px solid #D9D9D9",
  },
}));
