import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  cursor: "pointer",
  gap: 8,
}));

export const LogoutText = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontFamily: "Roboto",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
