import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { InventoryNotificationForm } from "../../components/inventoryNotificationForm/inventoryNotificationForm";
import {
  InventoryNotificationsBody,
  InventoryNotificationsContainer,
  InventoryNotificationsDescription,
  InventoryNotificationsTitle,
} from "./styles";

export const InventoryNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const backToInventoryTab = () => {
    navigate(`/dashboard/${TABS_NAMES[5]}`);
  };

  const handleClickBackToInventoryTab = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToInventoryTab();
    }
  };

  return (
    <InventoryNotificationsContainer>
      <CssBaseline />
      <Header />
      <InventoryNotificationsBody>
        <BackTo
          text={t("back-to-inventory-tab")}
          handleBack={handleClickBackToInventoryTab}
        />
        <InventoryNotificationsTitle>
          {t("manage-inventory-notification")}
        </InventoryNotificationsTitle>
        <InventoryNotificationsDescription>
          {t("manage-your-inventory-description-1")}
        </InventoryNotificationsDescription>
        <InventoryNotificationsDescription>
          {t("manage-your-inventory-description-2")}
        </InventoryNotificationsDescription>
        <InventoryNotificationForm />
      </InventoryNotificationsBody>
    </InventoryNotificationsContainer>
  );
};
