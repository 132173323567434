import { Box, InputAdornment, Typography, styled } from "@mui/material";

export const WasteProfilesFiltersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px 8px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "20px 8px",
  },
}));

export const WasteProfilesSearchWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  "& > div > div > input": {
    [theme.breakpoints.up("md")]: {
      height: 32,
    },
  },
}));

export const FilterIconWrapper = styled(InputAdornment)(() => ({
  cursor: "pointer",
}));

export const WasteProfilesFiltersWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "27px 0 24px 0",
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  minWidth: 280,
  [theme.breakpoints.down("lg")]: {
    minWidth: 96,
  },
}));

export const FilterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    "& > div": {
      maxWidth: "calc(100% - 106px)",
    },
  },
}));

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));

export const FiltersWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  "& > div > div > div > div": {
    [theme.breakpoints.up("md")]: {
      height: 38,
      minWidth: 200,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));
