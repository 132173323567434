import { Box, Typography, styled } from "@mui/material";

export const BackWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 8,
  width: "fit-content",
}));

export const BackIcon = styled("img")(() => ({
  width: 24,
  height: 24,
}));

export const BackText = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textDecorationLine: "underline",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
