import { useState } from "react";
import { LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/select-arrow.svg";
import { generatedIdForTimePicker } from "src/shared/helpers/generatedId";
import { StyledTimePicker, ArrowWrapper } from "./styles";
import { ITimePicker } from "./types";

export const TimePicker = ({
  value,
  onChange,
  errorMessage,
  disabled,
  register,
  id,
}: ITimePicker) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const onClose = () => {
    setIsOpenDatePicker(false);
  };

  const onOpen = () => {
    setIsOpenDatePicker(true);
  };

  const slots = {
    openPickerIcon: () => (
      <ArrowWrapper>
        <ArrowDown />
      </ArrowWrapper>
    ),
  };

  const slotProps = {
    textField: {
      error: !!errorMessage,
      helperText: errorMessage,
      onClick: onOpen,
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledTimePicker
        {...register}
        closeOnSelect={false}
        format="hh:mm A"
        open={isOpenDatePicker}
        onClose={onClose}
        disabled={disabled}
        value={value}
        onChange={onChange}
        slots={slots}
        slotProps={{
          ...slotProps,
          textField: {
            ...slotProps?.textField,
            id: generatedIdForTimePicker(id),
          },
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
    </LocalizationProvider>
  );
};
