import { Box, Checkbox as MuiCheckbox, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const Checkbox = styled(MuiCheckbox)(() => ({
  padding: 5,
}));

export const CheckedCheckboxIconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  "& > svg > path": {
    fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
  },
}));

export const CheckboxIconWrapper = styled(Box)(() => ({
  display: "flex",
}));
