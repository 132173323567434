import { Box, styled } from "@mui/material";

export const EditManageInspectionQuestionContainer = styled(Box)(
  ({ theme }) => ({
    padding: "24px 32px",
    [theme.breakpoints.down("md")]: {
      padding: "12px 24px",
    },
  })
);
