export const getOrganizationCardFields = (
  totalSites: string,
  t: (text: string) => string
) => {
  return [
    {
      name: t("total-sites"),
      value: totalSites,
    },
  ];
};
