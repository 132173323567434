import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InventoryAgingCardContainer = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  "&:hover": {
    background: isDarkMode(theme.palette.mode) ? "#3F4044" : "#F0F1F2",
  },
  "& > div": {
    marginTop: 24,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
  },
}));
