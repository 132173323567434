export const getSecondTabsConfig = (t: (value: string) => string) => {
  return [
    {
      label: t("support"),
    },
    {
      label: t("faq"),
    },
  ];
};

export const getSecondTabsNames = () => {
  return {
    0: "support",
    1: "faq",
  };
};

export const getAdminTabsNames = (hasUserAdminPermission: boolean) => {
  const ADMIN_TABS_NAMES = hasUserAdminPermission
    ? {
        0: "user-list",
        1: "site-admin",
      }
    : {
        0: "site-admin",
      };

  return ADMIN_TABS_NAMES;
};

export const getAdminTabsConfig = (
  hasUserAdminPermission: boolean,
  hasEditBannerPermission: boolean,
  t: (value: string) => string
) => {
  const ADMIN_TABS_CONFIG = [];

  if (hasUserAdminPermission) {
    ADMIN_TABS_CONFIG.push({
      label: t("user-admin"),
    });
  }
  if (hasEditBannerPermission) {
    ADMIN_TABS_CONFIG.push({
      label: t("site-admin"),
    });
  }
  return ADMIN_TABS_CONFIG;
};
