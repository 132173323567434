import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AllSeriesType,
  BarPlot,
  ChartsTooltip,
  ChartsXAxis,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { CircularProgress } from "@mui/material";
import {
  AxisConfig,
  ChartsXAxisProps,
  MakeOptional,
  ScaleName,
} from "@mui/x-charts/internals";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ChartLegend } from "src/shared/components/chartLegend/chartLegend";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  wasteShippedChartHeight,
  wasteShippedChartMargin,
  getModifiedWasteShippedByMonth,
  getWasteShippedInformation,
  getWasteShippedSeries,
  getTotalWasteShipped,
  getXAxisWasteShipped,
} from "./constants";
import { IWasteShippedInformation, IWasteShippedChart } from "./types";
import {
  WasteShippedChartContainer,
  ResponsiveWrapper,
  CloseIconWrapper,
} from "./styles";

export const WasteShippedChart = ({
  wasteData,
  isLoadingWasteData,
  handleDelete,
}: IWasteShippedChart) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsAfterClickOnWasteByMonthChart } = useAppActions();

  const wasteShipped = getModifiedWasteShippedByMonth(wasteData);

  const series = getWasteShippedSeries(wasteShipped) as AllSeriesType[];

  const xAxis = getXAxisWasteShipped(wasteData);

  const wasteShippedInformation = getWasteShippedInformation(wasteShipped);

  const wasteShippedChartLegendConfig = wasteShippedInformation?.map(
    ({ complianceColor, complianceDescription }: IWasteShippedInformation) => ({
      description: complianceDescription,
      value: `${getTotalWasteShipped(wasteShipped, complianceDescription)} lb`,
      color: complianceColor,
    })
  );

  const handleClickWasteShippedChart = () => {
    setIsAfterClickOnWasteByMonthChart(true);
    navigate(`/dashboard/${TABS_NAMES[2]}`);
  };

  return (
    <WasteShippedChartContainer
      tag="div"
      blocking={isLoadingWasteData}
      loader={<CircularProgress />}
      keepInView
      onClick={handleClickWasteShippedChart}
    >
      <CloseIconWrapper onClick={handleDelete}>
        <Close />
      </CloseIconWrapper>
      {wasteData && (
        <ResponsiveWrapper>
          <ResponsiveChartContainer
            series={series}
            xAxis={
              xAxis as MakeOptional<
                AxisConfig<ScaleName, any, ChartsXAxisProps>,
                "id"
              >[]
            }
            height={wasteShippedChartHeight}
            margin={wasteShippedChartMargin}
          >
            <BarPlot />
            <ChartsTooltip trigger="item" />
            <ChartsXAxis label="" position="bottom" axisId="x-axis-id" />
          </ResponsiveChartContainer>
        </ResponsiveWrapper>
      )}
      <ChartLegend
        title={t("waste")}
        secondTitle={` (${t("by-month")})`}
        showLegend={!!wasteData}
        chartLegendInformationConfig={wasteShippedChartLegendConfig}
      />
    </WasteShippedChartContainer>
  );
};
