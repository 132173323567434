import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const ReportsToolContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
  [theme.breakpoints.down("md")]: {
    gap: 16,
  },
}));

export const ReportsToolTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16.41px",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const ReportsToolHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "23.44px",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));

export const ReportsToolHeaderWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  position: "relative",
}));

export const ReportsToolFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  "& > div,textArea": {
    display: "flex",
    maxWidth: 400,
    justifyContent: "end",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 108px)",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ReportsToolLabel = styled(Typography)(({ theme }) => ({
  minWidth: 120,
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    minWidth: 100,
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const Iframe = styled("iframe")(() => ({
  border: "none",
  height: "100vh",
  background: "#FFF",
  borderRadius: 8,
}));
