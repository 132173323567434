import { Box, Typography, styled } from "@mui/material";

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > p": {
    "& > span": {
      color: "#F41808",
    },
  },
  "& > div": {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: 42,
      [theme.breakpoints.down("md")]: {
        top: 38,
      },
    },
    "& > div": {
      minHeight: 42,
      height: 42,
      [theme.breakpoints.down("md")]: {
        minHeight: 38,
        height: 38,
      },
      "& > div": {
        minHeight: "42px !important",
        [theme.breakpoints.down("md")]: {
          minHeight: "38px !important",
        },
      },
    },
  },
}));

export const EditLocationParametersContainer = styled(Box)(() => ({
  "& > div > div": {
    "&:last-of-type": {
      gap: 16,
    },
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  marginTop: 48,
  display: "flex",
  justifyContent: "end",
  flexWrap: "wrap",
  gap: 24,
  "& > button": {
    width: 111,
    [theme.breakpoints.down("md")]: {
      width: "fit-content",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const FieldInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > p": {
    "& > span": {
      color: "#F41808",
    },
  },
  "& > div": {
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: 42,
      [theme.breakpoints.down("md")]: {
        top: 38,
      },
    },
    "& > div ": {
      minHeight: 42,
      height: 42,
      [theme.breakpoints.down("md")]: {
        minHeight: 38,
        height: 38,
      },
      "& > div": {
        minHeight: "42px !important",
        [theme.breakpoints.down("md")]: {
          minHeight: "38px !important",
        },
      },
    },
  },
}));

export const FieldsWrapper = styled(Box)(() => ({
  marginBottom: 16,
  gap: 20,
  display: "flex",
  flexDirection: "column",
}));

export const IndicatesRequiredField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  "& > span": {
    color: "#F41808",
  },
}));
