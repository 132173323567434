import { generatedIdForButton } from "src/shared/helpers/generatedId";
import { StyledButton } from "./styles";
import { IButton } from "./types";

export const Button = ({
  text,
  onClick,
  disabled,
  isGrey,
  startIcon,
  isError,
  id,
}: IButton) => {
  return (
    <StyledButton
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
      isGrey={isGrey}
      isError={isError}
      id={generatedIdForButton(id)}
    >
      {text}
    </StyledButton>
  );
};
