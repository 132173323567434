import { createSlice } from "@reduxjs/toolkit";
import { ISystemMessagesFilters } from "./systemMessagesFilters";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { SYSTEM_MESSAGES_STORAGE_KEYS } from "src/components/systemMessagesTable/constants";

const initialSystemMessagesFiltersState: ISystemMessagesFilters = {
  selectedRow: undefined,
};

export const systemMessagesFiltersSlice = createSlice({
  name: "systemMessagesFilters",
  initialState: initialSystemMessagesFiltersState,
  reducers: {
    setSystemMessagesFilterScroll: (_, action) => {
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SCROLL_ROW,
        action.payload
      );
    },
    setSystemMessagesFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES_ROW,
        state.selectedRow
      );
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES,
        state.selectedRow
      );
    },
  },
});

export const {
  reducer: systemMessagesFiltersReducer,
  actions: systemMessagesFiltersActions,
} = systemMessagesFiltersSlice;
