import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InspectionInformationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px 20px 24px 84px",
  gap: 8,
  zIndex: 0,
  userSelect: "text",
  cursor: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "12px 20px 16px 20px",
  },
}));

export const InspectionInformationTitle = styled(Typography)(({ theme }) => ({
  color: "#25895C",
  textAlign: "center",
  fontSize: 16,
  marginLeft: -84,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const InspectionWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export const InspectionDescription = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#2F2F2F",
  width: "50%",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const InspectionColoredText = styled("span")(({ color }) => ({
  color: color,
}));

export const FollowUpContainer = styled(Box)(() => ({
  width: "50%",
  display: "flex",
  alignItems: "center",
  gap: 16,
  "& > p": {
    width: "auto",
  },
}));

export const EditFollowUp = styled(Typography)(({ theme }) => ({
  alignSelf: "center",
  color: "#217BDB",
  cursor: "pointer",
  textDecoration: "underline",
  textAlign: "left",
  fontFamily: "Roboto",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const InspectionAttachmentsWrapper = styled(Box)(() => ({
  display: "flex",
  "& > p": {
    width: "100%",
  },
}));
