import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { NavigationSections } from "src/components/navigationSections/navigationSections";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { CustomerInformation } from "../../components/customerInformation/customerInformation";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { SubmitProfileDialog } from "../../components/submitProfileDialog/submitProfileDialog";
import { WasteStreamDescription } from "../../components/wasteStreamDescription/wasteStreamDescription";
import { ChemicalComposition } from "../../components/chemicalComposition/chemicalComposition";
import { GeneralCharacteristics } from "../../components/generalCharacteristics/generalCharacteristics";
import { DOTInformation } from "../../components/DOTInformation/DOTInformation";
import { AdditionalInformation } from "../../components/additionalInformation/additionalInformation";
import { Review } from "../../components/review/review";
import { STATUS_ID_VALUES, SECTION, SECTION_SIZE } from "./constants";
import {
  NewProfileBody,
  NewProfileContainer,
  NewProfileTitle,
  BackWrapper,
  BackIcon,
  BackText,
} from "./styles";

export const NewProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");
  const [isOpenSubmitProfileDialog, setIsOpenSubmitProfileDialog] =
    useState(false);

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const activeSection = useAppSelector(
    (state) => state.activeSectionReducer.activeSection
  );
  const lastSectionStep = useAppSelector(
    (state) => state.activeSectionReducer.lastSectionStep
  );

  const handleUpdateUnsavedChanged = useAppSelector(
    (state) => state.unsavedChangesReducer.handleUpdateUnsavedChanged
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setActiveSection,
    setLastSectionStep,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const { data: wasteProfileData, isSuccess: isSuccessWasteData } =
    useGetWasteProfileRequestByIDQuery({
      id: profileID,
    });

  const [
    upsertProfileRequest,
    {
      isLoading: isLoadingUpsertProfile,
      isSuccess: isSuccessUpsertProfileData,
    },
  ] = useUpsertProfileRequestMutation({});

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: account?.username,
    },
    {
      skip: !account?.username,
    }
  );

  const { firstName, lastName } = userProfile;

  const handleBackToDashboard = () => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
    setChangedData(false);
  };

  const backToDashborad = () => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        handleBackToDashboard();
      });
      setHandleUpdateUnsavedChanged(() => {
        if (handleUpdateUnsavedChanged) {
          handleUpdateUnsavedChanged();
        }
        handleBackToDashboard();
      });
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleBackToDashboard();
    }
  };

  useEffect(() => {
    if (wasteProfileData?.sectionStep) {
      setActiveSection(wasteProfileData?.sectionStep);
    } else if (isSuccessWasteData) {
      setActiveSection(SECTION.CUSTOMER_INFORMATION);
    }
    if (wasteProfileData?.lastSectionStep) {
      setLastSectionStep(wasteProfileData?.lastSectionStep);
    } else if (isSuccessWasteData) {
      setLastSectionStep(SECTION.CUSTOMER_INFORMATION);
    }
  }, [
    isSuccessWasteData,
    setActiveSection,
    setLastSectionStep,
    wasteProfileData,
  ]);

  const changeActiveSection = (section: number) => {
    setActiveSection(section);
    setChangedData(false);
  };

  const handleActiveSection = (section: number) => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        changeActiveSection(section);
      });
      setIsOpenUnsavedChangedDialog(true);
    } else {
      changeActiveSection(section);
    }
  };

  const handleOpenSubmitProfileDialog = () => {
    setIsOpenSubmitProfileDialog(true);
  };

  const handleCloseSubmitProfileDialog = () => {
    setIsOpenSubmitProfileDialog(false);
    navigate(`/dashboard/${TABS_NAMES[1]}`);
  };

  useEffect(() => {
    return () => {
      setActiveSection(SECTION.CLEAR);
      setLastSectionStep(SECTION.CLEAR);
    };
  }, [setActiveSection, setLastSectionStep]);

  useEffect(() => {
    if (isSuccessUpsertProfileData) {
      handleOpenSubmitProfileDialog();
    }
  }, [isSuccessUpsertProfileData, navigate]);

  return (
    <NewProfileContainer>
      <CssBaseline />
      <Header />
      <NewProfileBody>
        <BackWrapper onClick={backToDashborad}>
          <BackIcon src={backArrow} alt="" />
          <BackText>
            {t(
              previousProfilesView ? "back-to-profile-tab" : "back-to-requests"
            )}
          </BackText>
        </BackWrapper>
        <NewProfileTitle>
          {t("new-profile-request", {
            firstName,
            lastName,
          })}
        </NewProfileTitle>
        <NavigationSections
          passedSection={lastSectionStep}
          size={SECTION_SIZE}
          activeSection={activeSection}
          setActiveSection={handleActiveSection}
        />
        {activeSection === SECTION.CUSTOMER_INFORMATION && (
          <CustomerInformation />
        )}
        {activeSection === SECTION.WASTE_STREAM_DESCRIPTION && (
          <WasteStreamDescription />
        )}
        {activeSection === SECTION.CHEMICAL_COMPOSITION && (
          <ChemicalComposition />
        )}
        {activeSection === SECTION.GENERAL_CHARACTERISTICS && (
          <GeneralCharacteristics />
        )}
        {activeSection === SECTION.DOT_INFORMATION && <DOTInformation />}
        {activeSection === SECTION.ADDITIONAL_INFORMATION && (
          <AdditionalInformation />
        )}
        {(activeSection === SECTION.REVIEW ||
          activeSection === STATUS_ID_VALUES.SUCCESS) && (
          <Review
            upsertProfileRequest={upsertProfileRequest}
            isLoadingUpsertProfile={isLoadingUpsertProfile}
          />
        )}
      </NewProfileBody>
      <SubmitProfileDialog
        open={isOpenSubmitProfileDialog}
        handleClose={handleCloseSubmitProfileDialog}
      />
    </NewProfileContainer>
  );
};
