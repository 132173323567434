import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IWasteProfileDetailsText } from "./types";

export const SingleProfileContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  paddingTop: 40,
}));

export const SingleProfileBody = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
    : "0px 10px 15px 4px #D9D9D9",
  padding: "44px 90px 22px 90px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: 16,
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  marginTop: 48,
  display: "flex",
  justifyContent: "end",
  gap: 24,
  "& > button": {
    "&:first-of-type": {
      width: 111,
    },
    "&:last-of-type": {
      width: 150,
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const BackWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 8,
}));

export const BackIcon = styled("img")(() => ({
  width: 24,
  height: 24,
}));

export const BackText = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textDecorationLine: "underline",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const SingleProfileTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "center",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginTop: 28,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    marginTop: 26,
  },
}));

export const WasteProfileDetails = styled(Box)(({ theme }) => ({
  marginTop: 24,
  border: "1px solid #DDD",
  borderRadius: 16,
  padding: 16,
  gap: 7,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
  },
}));

export const WasteProfileWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 0,
  },
}));

export const WasteProfileDetailsText = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "isBold" && prop !== "withLink" && prop !== "isTitle",
})<IWasteProfileDetailsText>(({ theme, isBold, withLink, isTitle }) => ({
  color: withLink ? "#217BDB" : theme.palette.text.secondary,
  cursor: withLink ? "pointer" : "default",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: isBold && !isTitle ? 500 : 400,
  lineHeight: "140%",
  width: "50%",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    width: "100%",
    color: isTitle ? "#B1B1B1" : "",
    lineHeight: "140%",
  },
}));

export const WasteProfileDetailsTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 14.5,
  color: theme.palette.text.secondary,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginBottom: 20,
  },
}));

export const Divider = styled(Box)(({ theme }) => ({
  marginTop: 150,
  height: 1,
  width: "100%",
  background: "#DDD",
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const SingleProfileListWrapper = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& > p": {
    width: "100%",
  },
  "& > ul": {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

export const SingleProfileButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  marginTop: 20,
  justifyContent: "center",
  "& > div": {
    width: 180,
    [theme.breakpoints.down("md")]: {
      width: 140,
    },
    "& > button": {
      height: 38,
      [theme.breakpoints.down("md")]: {
        height: 28,
        padding: 8,
      },
      "& > span > svg": {
        width: 24,
        height: 24,
        [theme.breakpoints.down("md")]: {
          width: 16,
          height: 16,
        },
      },
    },
  },
}));
