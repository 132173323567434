import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { IContactInformationForm, IContractInformation } from "./types";

export const ContactInformationForm = ({
  handleEditContactInformation,
  contactInformationConfig,
}: IContactInformationForm) => {
  const { t } = useTranslation();

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <FormCard
      title={t("contact-information")}
      handleEdit={handleEditContactInformation}
    >
      <React.Fragment>
        {contactInformationConfig?.map(
          (contactInformation: IContractInformation, index: number) => (
            <React.Fragment key={index}>
              <FieldWrapper>
                <FieldName>{contactInformation.name}</FieldName>
                <FieldValue
                  withLink={!!contactInformation.link}
                  href={contactInformation.link}
                  onClick={handleStopPropagation}
                >
                  {contactInformation.value}
                </FieldValue>
              </FieldWrapper>
              {index !== contactInformationConfig?.length - 1 && <Divider />}
            </React.Fragment>
          )
        )}
      </React.Fragment>
    </FormCard>
  );
};
