import { createContext, useContext, useState } from "react";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useAppActions } from "../redux/redux";
import { IHistoryProvider } from "./types";
import { HISTORY_STORAGE_VALUES } from "./constants";

const HistoryContext = createContext({
  history: [] as Array<string>,
  push: (_location: string) => {},
});

export const HistoryProvider = ({ children }: IHistoryProvider) => {
  const [History, setHistory] = useState<Array<string>>(
    getFromLocalStorage(HISTORY_STORAGE_VALUES) || []
  );

  const { setChangedData } = useAppActions();

  const push = (location: string) => {
    const histories = [...History, location];
    setHistory(histories);
    setChangedData(false);
    saveToLocalStorage(HISTORY_STORAGE_VALUES, histories);
  };

  return (
    <HistoryContext.Provider value={{ history: History, push }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);
