import { Step, StepsContainer } from "./styles";
import { ISteps } from "./types";

export const Steps = ({ size, selected }: ISteps) => {
  const stepsSize = new Array(size).fill(0);

  return (
    <StepsContainer>
      {stepsSize.map((_, index) => (
        <Step selected={selected === index} key={index} />
      ))}
    </StepsContainer>
  );
};
