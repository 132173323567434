import { Box, Typography, styled } from "@mui/material";

export const CongratulationsText = styled(Typography)(({ theme }) => ({
  marginTop: 25,
  fontSize: 24,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 18,
  },
}));

export const DialogBody = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 12,
  },
}));

export const DialogHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));

export const SubmitProfileDialogContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 400,
  gap: 24,
  [theme.breakpoints.down("md")]: {
    gap: 12,
    width: "auto",
  },
}));

export const DialogBodyWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 70.5,
  gap: 24,
}));

export const SuccessWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginBottom: 70,
}));

export const DoneButtonWrapper = styled(Box)(() => ({
  marginBottom: 47,
  width: "100%",
  padding: "0 8px",
}));
