import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroup } from "@mui/material";
import { IDetailCellRendererParams } from "ag-grid-community";
import { Button } from "src/shared/components/button/button";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { Select } from "src/shared/components/select/select";
import { TextArea } from "src/shared/components/textArea/textArea";
import { Input } from "src/shared/components/input/input";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { QUESTION_TYPE_ID } from "src/components/inspectionsTable/inspectionInformation/inspectionQuestions/constants";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  ManageInspectionFormContainer,
  ManageInspectionFormFieldWrapper,
  ManageInspectionFormLabel,
  ManageInspectionFormTitle,
} from "../manageInspectionForm/styles";
import { EditManageInspectionQuestion } from "../editManageInspectionQuestion/editManageInspectionQuestion";
import { ManageInspectionQuestionValidationSchema } from "./model";
import {
  INSPECTION_QUESTIONS_GRID_NAME,
  TYPE_OPTIONS,
  getColumnDefs,
  defaultColDef,
  paginationPageSize,
} from "./constants";
import { IManageInspectionQuestion } from "./types";
import {
  AddNewQuestionWrapper,
  AddQuestionButtonsWrapper,
  AddQuestionContainer,
  FormControlLabel,
  Radio,
  RadioGroupWrapper,
} from "./styles";

export const ManageInspectionQuestion = ({
  setInspectionQuestions,
  inspectionQuestions,
  setManageInspectionValues,
  hasEdit,
}: IManageInspectionQuestion) => {
  const { t } = useTranslation();
  const { setChangedData } = useAppActions();

  const [showAddQuestions, setShowAddQuestions] = useState(false);

  const {
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      defaultAnswerBool: true,
      numberOfViolationsRequired: false,
      answerRequired: false,
    },
    resolver: yupResolver(ManageInspectionQuestionValidationSchema(t)),
    mode: "onSubmit",
  });

  const numberOfViolationsRequired = watch("numberOfViolationsRequired");
  const answerRequired = watch("answerRequired");
  const typeID = watch("typeID");

  const addQuestion = handleSubmit((data) => {
    setInspectionQuestions(data);
    setShowAddQuestions(false);
    reset({});
    clearErrors();
  });

  const handleChanged = () => {
    setChangedData(true);
  };

  const handleChangeAnswerRequired = () => {
    setValue("answerRequired", !answerRequired);
    handleChanged();
  };

  const handleChangeNumberOfViolations = () => {
    setValue("numberOfViolationsRequired", !numberOfViolationsRequired);
    handleChanged();
  };

  const handleCancelQuestion = () => {
    setShowAddQuestions(!showAddQuestions);
  };

  const detailCellRenderer = useMemo(() => {
    return (data: IDetailCellRendererParams) => {
      return (
        <EditManageInspectionQuestion
          detailCellProps={data}
          setManageInspectionValues={setManageInspectionValues}
          inspectionQuestions={inspectionQuestions}
        />
      );
    };
  }, [inspectionQuestions, setManageInspectionValues]);

  const columnDefs = getColumnDefs(hasEdit, t);

  return (
    <ManageInspectionFormContainer>
      <ManageInspectionFormTitle>
        {t("inspection-questions")}
      </ManageInspectionFormTitle>
      {!showAddQuestions && hasEdit && (
        <AddNewQuestionWrapper>
          <Button
            text={t("add-new-question")}
            onClick={handleCancelQuestion}
            id="manage-inspection-add-new-question"
          />
        </AddNewQuestionWrapper>
      )}
      {showAddQuestions && (
        <AddQuestionContainer>
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("type")}
              <span>*</span>
            </ManageInspectionFormLabel>
            <Select
              register={register("typeID", {
                onChange: handleChanged,
              })}
              options={TYPE_OPTIONS}
              errorMessage={errors.typeID?.message}
              id="manage-inspection-typeID"
            />
          </ManageInspectionFormFieldWrapper>
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("prompt")}
              <span>*</span>
            </ManageInspectionFormLabel>
            <TextArea
              register={register("prompt", {
                onChange: handleChanged,
              })}
              errorMessage={errors.prompt?.message}
              id="manage-inspection-prompt"
            />
          </ManageInspectionFormFieldWrapper>
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("citation")}
            </ManageInspectionFormLabel>
            <Input
              register={register("citation", {
                onChange: handleChanged,
              })}
              id="manage-inspection-citation"
            />
          </ManageInspectionFormFieldWrapper>
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("recommendation")}
            </ManageInspectionFormLabel>
            <TextArea
              register={register("recommendation", {
                onChange: handleChanged,
              })}
              id="manage-inspection-recommendation"
            />
          </ManageInspectionFormFieldWrapper>
          {(typeID === QUESTION_TYPE_ID.TRFL ||
            typeID === QUESTION_TYPE_ID.TEXT) && (
            <ManageInspectionFormFieldWrapper>
              <ManageInspectionFormLabel>
                {t("default-answer")}
              </ManageInspectionFormLabel>
              {typeID === QUESTION_TYPE_ID.TRFL && (
                <RadioGroupWrapper>
                  <Controller
                    control={control}
                    name={"defaultAnswerBool"}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={"true"}
                          control={<Radio icon={<CheckedIcon />} />}
                          label={t("yes")}
                          onChange={handleChanged}
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio icon={<CheckedIcon />} />}
                          label={t("no")}
                          onChange={handleChanged}
                        />
                      </RadioGroup>
                    )}
                  />
                </RadioGroupWrapper>
              )}
              {typeID === QUESTION_TYPE_ID.TEXT && (
                <Input
                  register={{ ...register("defaultAnswerText") }}
                  id="manage-inspection-default-answer-text"
                />
              )}
            </ManageInspectionFormFieldWrapper>
          )}
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("answer-required")}
            </ManageInspectionFormLabel>
            <FormCardCheckbox
              checked={!!answerRequired}
              onChange={handleChangeAnswerRequired}
              id="manage-inspection-answer-required"
            />
          </ManageInspectionFormFieldWrapper>
          <ManageInspectionFormFieldWrapper>
            <ManageInspectionFormLabel>
              {t("number-of-violations-required")}
            </ManageInspectionFormLabel>
            <FormCardCheckbox
              checked={!!numberOfViolationsRequired}
              onChange={handleChangeNumberOfViolations}
              id="manage-inspection-number-of-violations"
            />
          </ManageInspectionFormFieldWrapper>
          <AddQuestionButtonsWrapper>
            <Button
              text={t("add")}
              onClick={addQuestion}
              id="manage-inspection-add"
            />
            <Button
              isGrey
              text={t("cancel")}
              onClick={handleCancelQuestion}
              id="manage-inspection-cancel"
            />
          </AddQuestionButtonsWrapper>
        </AddQuestionContainer>
      )}
      {!!inspectionQuestions?.length && (
        <AgGrid
          title={t("inspection-questions")}
          gridName={INSPECTION_QUESTIONS_GRID_NAME}
          rowData={inspectionQuestions}
          columnDefs={columnDefs}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
          detailCellRenderer={detailCellRenderer}
          masterDetail={true}
          detailRowAutoHeight={true}
          isLoadingData={false}
        />
      )}
    </ManageInspectionFormContainer>
  );
};
