import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const UploadedFilesFormButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  marginTop: 20,
  justifyContent: "center",
  "& > div": {
    width: 180,
    [theme.breakpoints.down("md")]: {
      width: 140,
    },
    "& > button": {
      height: 38,
      [theme.breakpoints.down("md")]: {
        height: 28,
        padding: 8,
      },
      "& > span > svg": {
        width: 24,
        height: 24,
        [theme.breakpoints.down("md")]: {
          width: 16,
          height: 16,
        },
      },
    },
  },
}));

export const UploadedFileWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > svg": {
    cursor: "pointer",
    marginLeft: 10,
    width: 21,
    height: 21,
    [theme.breakpoints.down("md")]: {
      width: 14,
      height: 14,
    },
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
    },
  },
}));
