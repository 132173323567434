import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const EditWrapper = styled(Box)(({ theme }) => ({
  "& > svg": {
    cursor: "pointer",
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
    },
  },
}));

export const ComplianceContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  height: 41,
}));
