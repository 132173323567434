import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import {
  useGetBuildingByOrganizationByIDQuery,
  useGetLocationsOrganizationsByRoomParentIDsQuery,
  useGetRoomByOrganizationByIDQuery,
  useLazyGeneratedPdfWithBarcodeByRoomIDQuery,
} from "src/shared/store/api/organization.api";
import { LocationList } from "src/components/locationList/locationList";
import { Button } from "src/shared/components/button/button";
import { PrintQrCodesButton } from "src/components/printQrCodesButton/printQrCodesButton";
import { BackTo } from "../../shared/components/backTo/backTo";
import { RoomCard } from "../../components/roomCard/roomCard";
import { getRoomConfig } from "./constants";
import {
  RoomInformationBody,
  RoomInformationContainer,
  RoomInformationTitle,
  ButtonWrapper,
  AddLocationWrapper,
} from "./styles";

export const RoomInformation = () => {
  const { t } = useTranslation();
  const { roomID } = useParams();
  const navigate = useNavigate();

  const { data: room, isLoading: isLoadingRoom } =
    useGetRoomByOrganizationByIDQuery(roomID);

  const { data: building, isLoading: isLoadingBuilding } =
    useGetBuildingByOrganizationByIDQuery(room?.parentId, {
      skip: !room?.parentId,
    });

  const { data: locations, isLoading: isLoadingLocations } =
    useGetLocationsOrganizationsByRoomParentIDsQuery(roomID);

  const [
    generatePdfWithBarcode,
    { isFetching: isFetchingGeneratePdfWithBarcode },
  ] = useLazyGeneratedPdfWithBarcodeByRoomIDQuery({});

  const roomConfig = getRoomConfig(room, building, t);

  const backToRoomList = () => {
    if (room?.parentId) {
      navigate(`/building-information/${room?.parentId}`);
    }
  };

  const handleAddLocation = () => {
    if (room?.parentId) {
      navigate(`/add-location/${room?.organizationUnitID}`);
    }
  };

  const handlePrintQrCodes = () => {
    generatePdfWithBarcode(roomID);
  };

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingRoom ||
        isLoadingBuilding ||
        isLoadingLocations ||
        isFetchingGeneratePdfWithBarcode
      }
      loader={<CircularProgress />}
      keepInView
    >
      <RoomInformationContainer>
        <CssBaseline />
        <Header />
        <RoomInformationBody>
          <BackTo text={t("back-to-room-list")} handleBack={backToRoomList} />
          <RoomInformationTitle>
            {t("manage-room-information-here")}
          </RoomInformationTitle>
          <RoomCard roomConfig={roomConfig} />
          <AddLocationWrapper>
            <Button
              text={t("add-location")}
              onClick={handleAddLocation}
              id="room-information-add-location"
            />
            <PrintQrCodesButton handlePrintQrCodes={handlePrintQrCodes} />
          </AddLocationWrapper>
          <LocationList locationItems={locations} roomName={room?.name} />
          <ButtonWrapper>
            <Button
              text={t("back")}
              onClick={backToRoomList}
              isGrey
              id="room-information-back"
            />
          </ButtonWrapper>
        </RoomInformationBody>
      </RoomInformationContainer>
    </BlockUi>
  );
};
