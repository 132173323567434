import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/shared/hooks/redux/redux";

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  const tab = useAppSelector((state) => state.menuReducer.tab);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, tab]);

  return null;
};
