import { useCallback, useEffect, useRef, useState } from "react";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import {
  CellClickedEvent,
  ColDef,
  ColumnState,
  GridApi,
  SelectionChangedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import { useLazyManifestSearchQuery } from "src/shared/store/api/manifest.api";
import { ManifestFilters } from "./manifestFilters/manifestFilters";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { IManifest } from "../editManifestStatusDialog/types";
import { EditManifestStatusDialog } from "../editManifestStatusDialog/editManifestStatusDialog";
import { ManifestCustomTitle } from "./manifestCustomTitle/manifestCustomTitle";
import {
  getFromLocalStorage,
  removeItemFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { Compliance } from "../compliance/compliance";
import { MANIFEST_STORAGE_KEYS } from "./manifestFilters/constants";
import {
  getColumnKeys,
  MANIFEST_GRID_NAME,
  defaultColDef,
  paginationPageSize,
  getContextMenuItems,
  rowGroupPanelShow,
  excelMode,
  rowSelection,
} from "./constants";
import { ComplianceContainer } from "./styles";

export const ManifestTable = () => {
  const [trigger, { data: manifestData, isFetching: isFetchingManifestData }] =
    useLazyManifestSearchQuery();
  const [selectedManifestForEdit, setSelectedManifestForEdit] =
    useState<IManifest>();
  const [selectedManifest, setSelectedManifest] = useState<IManifest>();
  const [updateData, setUpdateData] = useState(false);

  const { tab } = useAppSelector((state) => state.menuReducer);

  const { isAfterClickOnWasteByMonthChart, isAfterClickOnManifestChart } =
    useAppSelector((state) => state.manifestFiltersReducer);

  const today = dayjs(new Date());
  const yearBefore = dayjs(new Date()).subtract(365, "days");

  const {
    saveToLocalStorageManifestFilters,
    setManifestFiltersAccounts,
    setManifestFiltersKeyword,
    setManifestFilterSites,
    setManifestFilterFacilities,
    setManifestFilterStatuses,
    setManifestFilterStartDate,
    setManifestFilterEndDate,
    setManifestFilterCompliances,
    setManifestFilterDiscrepancy,
    setManifestFilterHasAttachment,
    setManifestFiltersSearchOrganization,
    setManifestFilterScroll,
    setManifestFiltersSelectedRow,
    setIsAfterClickOnWasteByMonthChart,
    setIsAfterClickOnManifestChart,
  } = useAppActions();

  const scrollToTop = getFromLocalStorage(MANIFEST_STORAGE_KEYS.SCROLL_ROW);

  const [isOpenEditManifestStatusDialog, setIsOpenEditManifestStatusDialog] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const { mode } = useThemeContext();
  const manifestTableRef = useRef<HTMLDivElement>(null);
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousManifestView =
    previousLocation && previousLocation?.includes("/manifest");

  const { data: userProfile = {}, isSuccess: isSuccessUserProfile } =
    useGetUserProfileByIDQuery(
      {
        id: accounts[0]?.username,
      },
      {
        skip: !accounts[0]?.username,
      }
    );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const newColumnDefs = getColumnKeys(userProfile?.userAccounts?.length, t);

    setColumnDefs(newColumnDefs);
  }, [t, setColumnDefs, userProfile]);

  const onCellClicked = (params: CellClickedEvent) => {
    setManifestFilterScroll(window.scrollY);
    setManifestFiltersSelectedRow(params?.data);
    if (params?.data?.id && params?.data?.id === selectedManifest?.id) {
      navigate(`/manifest/${params?.data?.id}`);
      saveToLocalStorageManifestFilters();
    }
  };

  const handleCloseEditManifestStatusDialog = () => {
    setIsOpenEditManifestStatusDialog(false);
  };

  const customManifestFilters = (gridApi: GridApi | null) => (
    <ManifestFilters
      trigger={trigger}
      gridApi={gridApi}
      updateData={updateData}
      setUpdateData={setUpdateData}
    />
  );

  const contextMenuItems = getContextMenuItems(
    setSelectedManifestForEdit,
    setIsOpenEditManifestStatusDialog,
    t,
    mode === DARK
  );

  const customTitle = () => <ManifestCustomTitle />;

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedManifest(selectedRows[0]);
  }, []);

  useEffect(() => {
    if (previousManifestView) {
      setManifestFiltersKeyword(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.KEYWORD)
      );
      setManifestFiltersAccounts(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.ACCOUNT_ID_LIST)
      );
      setManifestFilterStartDate(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.START_DATE)
          ? dayjs(
              new Date(getFromLocalStorage(MANIFEST_STORAGE_KEYS.START_DATE))
            )
          : yearBefore
      );
      setManifestFilterEndDate(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.END_DATE)
          ? dayjs(new Date(getFromLocalStorage(MANIFEST_STORAGE_KEYS.END_DATE)))
          : today
      );
      setManifestFilterDiscrepancy(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.DISCREPANCY)
      );
      setManifestFilterHasAttachment(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.HAS_ATTACHMENT)
      );
      setManifestFilterStatuses(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.STATUS_LIST)
      );
      setManifestFilterCompliances(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.COMPLIANCE_LIST)
      );
      setManifestFilterFacilities(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.FACILITY_ID_LIST)
      );
      setManifestFilterSites(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.SITE_ID_LIST)
      );
      setManifestFiltersSearchOrganization(
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE)
      );
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST,
        getFromLocalStorage(MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST_ROW)
      );
      setUpdateData(true);
    } else {
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    setManifestFiltersAccounts,
    setManifestFiltersKeyword,
    setManifestFilterSites,
    setManifestFilterFacilities,
    setManifestFilterStatuses,
    setManifestFilterStartDate,
    setManifestFilterEndDate,
    setManifestFilterCompliances,
    setManifestFilterDiscrepancy,
    setManifestFilterHasAttachment,
    setManifestFiltersSearchOrganization,
  ]);

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousManifestView) {
      window.scroll(0, scrollToTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTop, manifestData, previousManifestView]);

  useEffect(() => {
    saveToLocalStorage(MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST, {});
  }, [tab]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST, null);
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST_ROW, null);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (
      (isAfterClickOnManifestChart || isAfterClickOnWasteByMonthChart) &&
      columnDefs?.length &&
      isSuccessUserProfile &&
      userProfile
    ) {
      if (isAfterClickOnManifestChart) {
        setIsAfterClickOnManifestChart(false);
      } else {
        setIsAfterClickOnWasteByMonthChart(false);
      }

      if (manifestTableRef && manifestTableRef.current) {
        manifestTableRef.current.scrollIntoView();
      }

      if (isAfterClickOnManifestChart) {
        setManifestFilterStartDate(dayjs().subtract(3, "years"));
        setManifestFilterCompliances(["yellow", "red"]);
      } else {
        setManifestFilterStartDate(dayjs().subtract(3, "month"));
      }

      setUpdateData(true);

      const columnState =
        getFromLocalStorage(`${MANIFEST_GRID_NAME}_column`) || null;
      removeItemFromLocalStorage(`${MANIFEST_GRID_NAME}_column`);

      const newColumnDefs = [...columnDefs];

      if (columnState) {
        columnState.forEach(({ colId, hide }: ColumnState) => {
          const colIdIndex = newColumnDefs.findIndex(
            ({ field }: ColDef) => field === colId
          );

          if (colIdIndex) {
            newColumnDefs[colIdIndex] = {
              ...newColumnDefs[colIdIndex],
              hide: hide as boolean,
            };
          }
        });
      }

      const complianceIndex = newColumnDefs.findIndex(
        ({ field }) => field === "complianceColor"
      );

      newColumnDefs[complianceIndex] = {
        headerName: t("compliance"),
        field: "complianceColor",
        cellRenderer: (valueFormatterParams: ValueFormatterParams) => {
          return (
            <ComplianceContainer>
              <Compliance color={valueFormatterParams?.value} />
            </ComplianceContainer>
          );
        },
        ...excelMode,
        hide: isAfterClickOnManifestChart ? false : true,
        enableRowGroup: true,
        rowGroup: isAfterClickOnManifestChart ? true : false,
      };

      setColumnDefs(newColumnDefs);
    }
  }, [
    isAfterClickOnManifestChart,
    isAfterClickOnWasteByMonthChart,
    columnDefs,
    setManifestFilterStartDate,
    setIsAfterClickOnManifestChart,
    setIsAfterClickOnWasteByMonthChart,
    isSuccessUserProfile,
    setManifestFilterCompliances,
    userProfile,
    t,
  ]);

  return (
    <div ref={manifestTableRef}>
      {isSuccessUserProfile && (
        <AgGrid
          customFilters={customManifestFilters}
          customTitle={customTitle}
          onCellClicked={onCellClicked}
          gridName={MANIFEST_GRID_NAME}
          rowData={manifestData}
          columnDefs={columnDefs}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
          noRowsText={t("no-manifest-display")}
          isLoadingData={isFetchingManifestData}
          cellSelection={true}
          allowContextMenuWithControlKey={true}
          rowGroupPanelShow={rowGroupPanelShow}
          getContextMenuItems={contextMenuItems}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          withSavingPage={!!previousManifestView}
        />
      )}
      <EditManifestStatusDialog
        open={isOpenEditManifestStatusDialog}
        handleClose={handleCloseEditManifestStatusDialog}
        selectedManifest={selectedManifestForEdit}
      />
    </div>
  );
};
