import { COMPLIANCE_COLOR } from "../compliance/constants";
import { IInspections } from "./types";

export const getInspectionChartStatistics = (
  inspections: IInspections,
  t: (text: string) => string
) => {
  return inspections
    ? [
        {
          value: inspections?.highScore || 0,
          label: t("good"),
          color: COMPLIANCE_COLOR["green"],
        },
        {
          value: inspections?.mediumScore || 0,
          label: t("substandard"),
          color: COMPLIANCE_COLOR["yellow"],
        },
        {
          value: inspections?.lowScore || 0,
          label: t("deficient"),
          color: COMPLIANCE_COLOR["red"],
        },
      ]
    : [];
};

export const inspectionChartHeight = 140;

export const highlightScope = { faded: "global", highlighted: "item" };

export const getInformationInspection = (
  inspections: IInspections,
  t: (text: string) => string
) => {
  return [
    {
      title: t("good"),
      count: inspections?.highScore,
      color: "green",
    },
    {
      title: t("substandard"),
      count: inspections?.mediumScore,
      color: "yellow",
    },
    {
      title: t("deficient"),
      count: inspections?.lowScore,
      color: "red",
    },
  ];
};
