import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { ReactComponent as Trash } from "src/shared/assets/svgs/trash.svg";
import { ReactComponent as TickCircle } from "src/shared/assets/svgs/tick-circle.svg";
import { ReactComponent as GreenTickCircle } from "src/shared/assets/svgs/green-tick-circle.svg";
import {
  useRemoveUserAccountMutation,
  useUpdateDefaultAccountUserMutation,
} from "src/shared/store/api/userProfile.api";
import { AddButton } from "src/shared/components/formCard/addButton/addButton";
import { IAssignedAccount, IAssignedAccountsForm } from "./types";
import { AddButtonWrapper, IconsWrapper, IconWrapper } from "./styles";

export const AssignedAccountsForm = ({
  handleEditAssignedAccounts,
  assignedAccountsConfig,
}: IAssignedAccountsForm) => {
  const { t } = useTranslation();
  const [removeOrganizationByUnitId] = useRemoveUserAccountMutation();
  const [updateDefaultAccount] = useUpdateDefaultAccountUserMutation();
  const { id } = useParams();

  const handleDelete = (organizationUnitID: number) => () => {
    removeOrganizationByUnitId({ id, organizationUnitID });
  };

  const handleUpdateDefaultAccount = (organizationUnitID: number) => () => {
    updateDefaultAccount({ id, organizationUnitID });
  };

  return (
    <FormCard title={t("assigned-accounts")}>
      <React.Fragment>
        <React.Fragment>
          {assignedAccountsConfig?.map(
            (
              assignedAccount: IAssignedAccount,
              assignedAccountIndex: number
            ) => (
              <React.Fragment key={assignedAccount?.val}>
                <IconWrapper>
                  <FieldValue>{assignedAccount?.name}</FieldValue>
                  <FieldWrapper>
                    <IconsWrapper>
                      {!assignedAccount?.isDefault &&
                        handleEditAssignedAccounts && (
                          <TickCircle
                            onClick={handleUpdateDefaultAccount(
                              assignedAccount?.val
                            )}
                          />
                        )}
                      {assignedAccount?.isDefault && <GreenTickCircle />}
                      {!assignedAccount?.isDefault &&
                        handleEditAssignedAccounts && (
                          <Trash onClick={handleDelete(assignedAccount?.val)} />
                        )}
                    </IconsWrapper>
                  </FieldWrapper>
                </IconWrapper>
                {!handleEditAssignedAccounts &&
                assignedAccountIndex ===
                  assignedAccountsConfig?.length - 1 ? null : (
                  <Divider />
                )}
              </React.Fragment>
            )
          )}
        </React.Fragment>
        {handleEditAssignedAccounts && (
          <AddButtonWrapper>
            <AddButton
              text={t("add-account")}
              onClick={handleEditAssignedAccounts}
              id="assigned-accounts-add-account"
            />
          </AddButtonWrapper>
        )}
      </React.Fragment>
    </FormCard>
  );
};
