import { useTranslation } from "react-i18next";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IAdditionalFilesTooltip } from "./types";
import { AdditionalFilesText } from "./styles";

export const AdditionalFilesTooltip = ({
  open,
  setOpen,
}: IAdditionalFilesTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <AdditionalFilesText>
        {t("attach-documents-related-this-waste")}
      </AdditionalFilesText>
    </Tooltip>
  );
};
