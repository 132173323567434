import { Box, Typography, styled } from "@mui/material";

export const UnsavedChangedFieldsContainer = styled(Typography)(
  ({ theme }) => ({
    textAlign: "center",
    width: "80vw",
    maxWidth: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  })
);

export const UnsavedChangesActionsContainer = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  "& > button": {
    width: "fit-content",
  },
}));
