import { SyntheticEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import {
  useGetInspectionWithQuestionsTemplateByIDQuery,
  useLazyNewInspectionTemplateQuery,
  useUpsertInspectionTemplateMutation,
} from "src/shared/store/api/inspection.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { useGetOrganizationBySearchValueQuery } from "src/shared/store/api/organization.api";
import { IOption } from "src/shared/components/select/types";
import { getFieldOption } from "src/components/detailInspectionCard/constants";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import { CopyManageInspectionValidationSchema } from "./model";
import { ICopyManageInspectionDialog } from "./types";
import {
  CopyManageInspectionDialogContentContainer,
  CopyManageInspectionFieldWrapper,
  CopyManageInspectionLabel,
  SendBtnWrapper,
} from "./styles";

export const CopyManageInspectionDialog = ({
  manageInspectionId,
  open,
  handleClose,
}: ICopyManageInspectionDialog) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CopyManageInspectionValidationSchema(t)),
    mode: "all",
  });

  const manageInspectionAccount = getFieldOption(
    watch,
    "accountOrganizationUnitID",
    "accountName"
  );

  const [upsertInspectionTemplate] = useUpsertInspectionTemplateMutation({});

  const [getNewManageInspectionId] = useLazyNewInspectionTemplateQuery();

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: "",
  });

  const { data: inspectionWithQuestions } =
    useGetInspectionWithQuestionsTemplateByIDQuery(manageInspectionId, {
      skip: !manageInspectionId,
    });

  const handleChangeAccount = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    setValue("accountOrganizationUnitID", Number(inputValue?.id), {
      shouldValidate: true,
    });
    setValue("accountName", inputValue?.val, {
      shouldValidate: true,
    });
  };

  const handleCopyManageInspection = handleSubmit((data) => {
    getNewManageInspectionId({}).then(
      ({ data: generateManageInspectionData }) => {
        const generatedInspectionId = generateManageInspectionData?.id;

        upsertInspectionTemplate({
          ...inspectionWithQuestions,
          ...data,
          statusID: STATUS_ID_VALUES.DRAFT,
          inspectionID: generatedInspectionId,
        }).then(() => {
          handleClose();
          navigate(`/manage-inspection/${generatedInspectionId}`);
        });
      }
    );
  });

  const copyManageInspectionDialogActions = () => {
    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="copy-manage-inspection-cancel"
        />
        <Button
          text={t("create")}
          onClick={handleCopyManageInspection}
          id="copy-manage-inspection-create"
        />
      </SendBtnWrapper>
    );
  };

  const renderCopyManageInspectionDialogFields = () => {
    return (
      <CopyManageInspectionDialogContentContainer>
        <CopyManageInspectionFieldWrapper>
          <CopyManageInspectionLabel>
            {t("account")}
            <span>*</span>
          </CopyManageInspectionLabel>
          <Autocomplete
            options={organizationOptions ? organizationOptions : []}
            value={
              manageInspectionAccount?.val ? manageInspectionAccount : null
            }
            register={register("accountName")}
            onChange={handleChangeAccount}
            errorMessage={errors?.accountName?.message}
            id="copy-manage-inspection-account"
          />
        </CopyManageInspectionFieldWrapper>
      </CopyManageInspectionDialogContentContainer>
    );
  };

  useEffect(() => {
    reset({});
    clearErrors();
  }, [clearErrors, open, reset]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("copy-inspection")}
      actions={copyManageInspectionDialogActions}
      renderFields={renderCopyManageInspectionDialogFields}
    />
  );
};
