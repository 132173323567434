import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { FieldName, FieldValue } from "src/shared/components/formCard/styles";
import { FieldWrapper, FieldInformationWrapper } from "./styles";
import { IRoomConfig, IRoomCard } from "./types";

export const RoomCard = ({ roomConfig }: IRoomCard) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("room")}>
      <>
        {roomConfig.map(({ name, value }: IRoomConfig, index: number) => (
          <FieldWrapper key={index}>
            <FieldInformationWrapper>
              <FieldName>{name}</FieldName>
              <FieldValue>{value}</FieldValue>
            </FieldInformationWrapper>
          </FieldWrapper>
        ))}
      </>
    </FormCard>
  );
};
