import { ListOfItems } from "src/shared/components/listOfItems/listOfItems";
import { BuildingsTitle } from "./buildingsTitle/buildingsTitle";
import { BuildingBody } from "./buildingsBody/buildingsBody";
import { IBuildingsList } from "./types";

export const BuildingsList = ({ buildingItems }: IBuildingsList) => {
  return buildingItems?.length > 0 ? (
    <ListOfItems
      title={<BuildingsTitle />}
      body={<BuildingBody buildingItems={buildingItems} />}
    />
  ) : null;
};
