import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import { Header } from "src/components/header/header";
import {
  useGetWasteProfileByIDQuery,
  useUploadWasteProfileAttachmentMutation,
} from "src/shared/store/api/wasteProfile.api";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as AddCircle } from "src/shared/assets/svgs/add-circle.svg";
import { useLazyGetAttachmentByDocumentIDAndFileIDQuery } from "src/shared/store/api/attachments.api";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import {
  MAX_FILE_SIZE,
  getSingeProfileConfig,
  inputFileAccept,
} from "./constants";
import {
  SingleProfileContainer,
  SingleProfileBody,
  SingleProfileTitle,
  BackWrapper,
  BackIcon,
  BackText,
  WasteProfileDetails,
  WasteProfileWrapper,
  WasteProfileDetailsText,
  WasteProfileDetailsTitle,
  Divider,
  SingleProfileButtonWrapper,
} from "./styles";

export const SingleProfile = () => {
  const { t } = useTranslation();
  const { setIsOpenSnackBar, setSnackBarMessages } = useAppActions();
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { profileID } = useParams();
  const { data: wasteProfileData } = useGetWasteProfileByIDQuery({
    id: profileID,
  });

  const [getAttachment] = useLazyGetAttachmentByDocumentIDAndFileIDQuery();
  const [uploadWasteAttachment] = useUploadWasteProfileAttachmentMutation({});

  const SINGLE_PROFILE_CONFIG = getSingeProfileConfig(
    wasteProfileData,
    getAttachment,
    t,
    isMobileView
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file?.size / 1024 > MAX_FILE_SIZE) {
      setSnackBarMessages([t("maximum-allowed-attachment")]);
      setIsOpenSnackBar(true);
    } else {
      const body = new FormData();
      body.append("file", file as File);
      uploadWasteAttachment({
        docID: profileID,
        body,
      });
    }
  };

  const handleClickAttachFile = () => {
    inputRef?.current?.click();
  };

  const handleClickBackToProfileTab = () => {
    navigate(`/dashboard/${TABS_NAMES[4]}`);
  };

  return (
    <SingleProfileContainer>
      <CssBaseline />
      <Header />
      <SingleProfileBody>
        <BackWrapper onClick={handleClickBackToProfileTab}>
          <BackIcon src={backArrow} alt="" />
          <BackText>{t("back-to-profile-tab")}</BackText>
        </BackWrapper>
        <SingleProfileTitle>{t("review-waste-profile")}</SingleProfileTitle>
        <WasteProfileDetails>
          <WasteProfileDetailsTitle>
            {t("waste-profile-details")}
          </WasteProfileDetailsTitle>
          {SINGLE_PROFILE_CONFIG.map(
            ({ isBoldTitle, title, item, customItems }, index: number) => (
              <WasteProfileWrapper key={index}>
                <WasteProfileDetailsText isBold={isBoldTitle} isTitle>{`${t(
                  title
                )}:`}</WasteProfileDetailsText>
                {customItems ? (
                  customItems()
                ) : (
                  <WasteProfileDetailsText>{item}</WasteProfileDetailsText>
                )}
              </WasteProfileWrapper>
            )
          )}
          <Divider />
          <SingleProfileButtonWrapper>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              accept={inputFileAccept}
            />
            <ButtonWithIcon
              text={t("add-attachment")}
              onClick={handleClickAttachFile}
              icon={<AddCircle />}
              id="single-profile-add-attachment"
            />
          </SingleProfileButtonWrapper>
        </WasteProfileDetails>
      </SingleProfileBody>
    </SingleProfileContainer>
  );
};
