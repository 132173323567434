import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { IGeneratorSite, IGeneratorSiteForm } from "./types";

export const GeneratorSiteForm = ({
  generatorSiteConfig,
}: IGeneratorSiteForm) => {
  const { t } = useTranslation();

  return (
    <FormCard title={t("generator-site")} withCollapse>
      <React.Fragment>
        {generatorSiteConfig?.map(
          (generatorSite: IGeneratorSite, index: number) => (
            <React.Fragment key={index}>
              <FieldWrapper>
                <FieldName>{generatorSite.name}</FieldName>
                <FieldValue>{generatorSite.value}</FieldValue>
              </FieldWrapper>
              {index !== generatorSiteConfig?.length - 1 && <Divider />}
            </React.Fragment>
          )
        )}
      </React.Fragment>
    </FormCard>
  );
};
