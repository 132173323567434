import * as yup from "yup";

export const ManifestNotificationFormValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    hazardousManifestDays: yup
      .number()
      .typeError(t("please-enter-a-number-1-45"))
      .required(t("required-field"))
      .integer(t("please-enter-a-number-1-45"))
      .min(1, t("please-enter-a-number-1-45"))
      .max(45, t("please-enter-a-number-1-45")),
    medicalManifestDays: yup
      .number()
      .typeError(t("please-enter-a-number-1-45"))
      .required(t("required-field"))
      .integer(t("please-enter-a-number-1-45"))
      .min(1, t("please-enter-a-number-1-45"))
      .max(45, t("please-enter-a-number-1-45")),
    accountIDList: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          val: yup.string(),
        })
      )
      .required(t("required-field")),
    emailDistributionList: yup.array().of(yup.string()),
  });
