import React from "react";
import { PAGINATION_PAGE_SIZE_OPTIONS } from "src/shared/components/agGrid/customPagination/constants";
import { PaginationButton } from "src/shared/components/agGrid/customPagination/paginationButton/paginationButton";
import { PaginationNumber } from "src/shared/components/agGrid/customPagination/paginationNumber/paginationNumber";
import {
  CustomPaginationContainer,
  PaginationNumberContainer,
  PaginationPageSizeWrapper,
} from "src/shared/components/agGrid/customPagination/styles";
import { Select } from "src/shared/components/select/select";
import { ICustomPagination } from "./types";

export const CustomPagination = ({
  totalPages,
  currentPage,
  paginationPageSize,
  setCurrentPage,
  handleChangePaginationPageSize,
}: ICustomPagination) => {
  const pages = new Array(totalPages || 0).fill(0);

  const handleCurrentPage = (page: number) => () => {
    setCurrentPage(page);
  };

  return (
    <CustomPaginationContainer>
      {!!totalPages && (
        <>
          <PaginationButton
            onClick={handleCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}
          />
          <PaginationNumberContainer>
            {pages?.map((_, index: number) => {
              const indexMinusTotalPages = index + 1 - totalPages;
              const lessThanTwo = index < 2;
              const minNumberForDots = 5;
              const halfOfTotalPages = Math.ceil(totalPages / 2);

              return lessThanTwo ||
                index === currentPage ||
                indexMinusTotalPages > -2 ? (
                <PaginationNumber
                  key={index}
                  isCurrentPage={currentPage === index}
                  number={index + 1}
                  onClick={handleCurrentPage(index)}
                />
              ) : ((index === halfOfTotalPages &&
                  currentPage !== halfOfTotalPages) ||
                  (currentPage === halfOfTotalPages &&
                    index === halfOfTotalPages + 1)) &&
                totalPages >= minNumberForDots ? (
                <React.Fragment key={index}>...</React.Fragment>
              ) : null;
            })}
            <PaginationPageSizeWrapper>
              <Select
                value={paginationPageSize || ""}
                options={PAGINATION_PAGE_SIZE_OPTIONS}
                onChange={handleChangePaginationPageSize}
                id={`site-pagination-size`}
              />
            </PaginationPageSizeWrapper>
          </PaginationNumberContainer>
          <PaginationButton
            isNext
            onClick={handleCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          />
        </>
      )}
    </CustomPaginationContainer>
  );
};
