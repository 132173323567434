import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IFullShippingNameText } from "./types";

export const DOTInformationContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const HazardousTableContainer = styled(Box)(() => ({
  "& > div > div > div": {
    ".ag-root-wrapper": {
      height: 200,
      overflow: "auto",
    },
  },
}));

export const FullShippingNameText = styled(Typography)<IFullShippingNameText>(
  ({ error }) => ({
    marginTop: 24,
    minHeight: 32,
    padding: 8,
    display: "flex",
    alignItems: "center",
    color: "#4FA072",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    borderRadius: 6,
    border: `1px solid ${error ? "#E35D2B" : "#79c4d4"}`,
  })
);

export const DOTTabsWrapper = styled(Box)(({ theme }) => ({
  "& > div": {
    "&:first-of-type": {
      width: 360,
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
    },
  },
}));

export const SectionHeaderName = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode)
    ? theme.palette.secondary.main
    : "#282828",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const FieldsWrapper = styled(Box)(() => ({
  marginTop: 24,
  display: "flex",
  justifyContent: "space-between",
  gap: 32,
}));

export const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  "& > div": {
    maxWidth: "none",
  },
}));

export const ConstituentsWrapper = styled(Box)(() => ({
  "& > div": {
    maxWidth: "none",
    "& > textarea": {
      resize: "none",
    },
  },
}));

export const ShippingNameButtonWrapper = styled(Box)(({ theme }) => ({
  "& > button": {
    width: "fit-content",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 12,
  },
}));

export const DOTInformationWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
    borderRadius: 16,
    border: "1px solid #D9D9D9",
  },
}));

export const CreateShippingName = styled(Typography)(() => ({
  marginTop: 24,
  cursor: "pointer",
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textDecorationLine: "underline",
}));

export const DotInformationDivider = styled(Box)(() => ({
  width: 1,
  backgroundColor: "#D9D9D9",
}));
