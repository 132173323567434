import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import {
  useGetSiteByOrganizationByIDQuery,
  useLazyGeneratedPdfWithBarcodeBySiteIDQuery,
} from "src/shared/store/api/organization.api";
import { Button } from "src/shared/components/button/button";
import { useGetBuildingOrganizationsBySiteParentIDsQuery } from "src/shared/store/api/organization.api";
import { BackTo } from "../../shared/components/backTo/backTo";
import { InventoryAgingCard } from "../../components/inventoryAgingCard/inventoryAgingCard";
import { BuildingsList } from "../../components/buildingsList/buildingsList";
import { SiteCard } from "../../components/siteCard/siteCard";
import { IBuilding } from "../../components/buildingsList/types";
import { getBuildingItems } from "./constants";
import {
  SiteInformationBody,
  SiteInformationContainer,
  SiteInformationTitle,
  ButtonWrapper,
} from "./styles";
import { PrintQrCodesButton } from "src/components/printQrCodesButton/printQrCodesButton";

export const SiteInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteUnitID } = useParams();

  const {
    data: site,
    isLoading: isLoadingSite,
    isFetching: isFetchingSite,
  } = useGetSiteByOrganizationByIDQuery(siteUnitID, {
    skip: !siteUnitID,
  });

  const { data: buildings, isLoading: isLoadingBuildings } =
    useGetBuildingOrganizationsBySiteParentIDsQuery(siteUnitID, {
      skip: !siteUnitID,
    });

  const [
    generatePdfWithBarcode,
    { isFetching: isFetchingGeneratePdfWithBarcode },
  ] = useLazyGeneratedPdfWithBarcodeBySiteIDQuery({});

  const buildingItems = getBuildingItems(buildings);

  const backToAccountInformation = () => {
    if (site?.parentId) {
      navigate(`/account-information/${site?.parentId}`);
    }
  };

  const handlePrintQrCodes = () => {
    generatePdfWithBarcode(siteUnitID);
  };

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingSite ||
        isLoadingBuildings ||
        isFetchingSite ||
        isFetchingGeneratePdfWithBarcode
      }
      loader={<CircularProgress />}
      keepInView
    >
      <SiteInformationContainer>
        <CssBaseline />
        <Header />
        <SiteInformationBody>
          <BackTo
            text={t("back-to-site-list")}
            handleBack={backToAccountInformation}
          />
          <SiteInformationTitle>
            {t("manage-site-information-here")}
          </SiteInformationTitle>
          <SiteCard />
          <PrintQrCodesButton handlePrintQrCodes={handlePrintQrCodes} />
          <InventoryAgingCard id={siteUnitID} />
          <BuildingsList buildingItems={buildingItems as IBuilding[]} />
          <ButtonWrapper>
            <Button
              text={t("back")}
              onClick={backToAccountInformation}
              isGrey
              id="site-information-back"
            />
          </ButtonWrapper>
        </SiteInformationBody>
      </SiteInformationContainer>
    </BlockUi>
  );
};
