import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";
import LightModeSun from "../../shared/assets/svgs/light-mode-sun.svg";
import DarkModeMoon from "../../shared/assets/svgs/dark-mode-moon.svg";
import { ISwitchColorModeButton } from "./types";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const SwitchColorModeButton = styled(Switch)<ISwitchColorModeButton>(
  ({ theme }) => ({
    width: 49,
    height: 26,
    padding: 0,
    [theme.breakpoints.between("md", "lg")]: {
      width: 24,
      height: 24,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(0px)",
      [theme.breakpoints.between("md", "lg")]: {
        transform: "translateX(-1px) translateY(-1px)",
      },
      "&.Mui-checked": {
        transform: "translateX(100%)",
        [theme.breakpoints.between("md", "lg")]: {
          transform: "translateX(-1px) translateY(-1px)",
        },
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${DarkModeMoon})`,
        },
        "& + .MuiSwitch-track": {
          borderRadius: 60,
          background: isDarkMode(theme.palette.mode) ? "#393939" : "#D9D9D9",
          boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 24,
      height: 24,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${LightModeSun})`,
        borderRadius: 60,
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      borderRadius: 60,
      background: "#D9D9D9",
      boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15) inset",
    },
  })
);
