import { ReactComponent as CheckboxIcon } from "src/shared/assets/svgs/checkbox-icon.svg";
import { ReactComponent as CheckedCheckboxIcon } from "src/shared/assets/svgs/checked-checkbox-icon.svg";
import { generatedIdForCheckBox } from "src/shared/helpers/generatedId";
import {
  Checkbox,
  CheckedCheckboxIconWrapper,
  CheckboxIconWrapper,
} from "./styles";
import { IFormCardCheckbox } from "./types";

export const FormCardCheckbox = ({
  checked,
  onChange,
  disabled,
  register,
  id,
}: IFormCardCheckbox) => {
  return (
    <Checkbox
      {...register}
      icon={
        <CheckboxIconWrapper>
          <CheckboxIcon />
        </CheckboxIconWrapper>
      }
      checkedIcon={
        <CheckedCheckboxIconWrapper>
          <CheckedCheckboxIcon />
        </CheckedCheckboxIconWrapper>
      }
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      id={generatedIdForCheckBox(id)}
    />
  );
};
