import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const USER_PROFILE_SUB_URL = "UserProfile";

export const userProfile = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfileByID: builder.query({
      query: ({ id }) => ({
        url: `${USER_PROFILE_SUB_URL}/GetUserProfile/${id}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UserProfile"],
    }),
    upsertGuestUserProfile: builder.mutation({
      query: ({ body }) => ({
        url: `${USER_PROFILE_SUB_URL}/UpsertGuestUserProfile`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    updateAuthenticatedUser: builder.mutation({
      query: ({ id, isDefault = true, isFavorite = false }) => ({
        url: `${USER_PROFILE_SUB_URL}/UpdateAuthenticatedUser`,
        method: HTTP_METHOD.POST,
        body: {
          organizationUnitID: id,
          isDefault,
          isFavorite,
        },
      }),
      invalidatesTags: ["UserProfile", "Organization"],
    }),
    updateUserProfile: builder.mutation({
      query: ({ body }) => ({
        url: `${USER_PROFILE_SUB_URL}/UpdateUserProfile`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UserProfile", "Organization"],
    }),
    removeUserAccount: builder.mutation({
      query: ({ id, organizationUnitID }) => ({
        url: `${USER_PROFILE_SUB_URL}/RemoveUserAccount/${id}/${organizationUnitID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["UserProfile", "Organization"],
    }),
    updateDefaultAccountUser: builder.mutation({
      query: ({ id, organizationUnitID }) => ({
        url: `${USER_PROFILE_SUB_URL}/UpdateDefaultAccountUser?userID=${id}`,
        method: HTTP_METHOD.POST,
        body: {
          organizationUnitID,
          isDefault: true,
          isFavorite: false,
        },
      }),
      invalidatesTags: ["UserProfile"],
    }),
    getUsersAllOrByOrganizationUnitID: builder.query({
      query: ({ organizationUnitID }) => ({
        url: `${USER_PROFILE_SUB_URL}/GetUsersAllOrByOrganizationUnitID${
          organizationUnitID ? `/${organizationUnitID}` : ""
        }`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UserProfile"],
    }),
    deleteUserProfile: builder.mutation({
      query: ({ userID }) => ({
        url: `${USER_PROFILE_SUB_URL}/DeleteUserProfile/${userID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["UserProfile"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserProfileByIDQuery,
  useUpsertGuestUserProfileMutation,
  useUpdateAuthenticatedUserMutation,
  useUpdateUserProfileMutation,
  useRemoveUserAccountMutation,
  useUpdateDefaultAccountUserMutation,
  useDeleteUserProfileMutation,
  useGetUsersAllOrByOrganizationUnitIDQuery,
} = userProfile;
