import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CellClickedEvent,
  GridApi,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useLazyWasteProfileSearchQuery } from "src/shared/store/api/wasteProfile.api";
import { CustomProfilesFilters } from "src/components/customProfilesFilters/customProfilesFilters";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { IWasteProfileData } from "src/pages/singleProfile/types";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { PROFILES_STORAGE_KEYS } from "../customProfilesFilters/constants";
import { ProfilesCustomTitle } from "./profilesCustomTitle/profilesCustomTitle";
import {
  getColumnDefs,
  PROFILES_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  getContextMenuItems,
  rowSelection,
} from "./constants";

export const ProfilesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("/profile");
  const { scroll: scrollToTop } = useAppSelector(
    (state) => state.profileFiltersReducer
  );
  const { tab } = useAppSelector((state) => state.menuReducer);
  const { mode } = useThemeContext();
  const {
    saveToLocalStorageProfileFilters,
    setProfileFilterScroll,
    setProfileFiltersAccounts,
    setProfileFiltersKeyword,
    setProfileFiltersSearchOrganization,
    setProfileFiltersSelectedRow,
  } = useAppActions();

  const [selectedProfile, setSelectedProfile] = useState<IWasteProfileData>();
  const [updateData, setUpdateData] = useState(false);

  const [
    getProfilesData,
    {
      data: profilesData,
      isLoading: isLoadingProfilesData,
      isFetching: isFetchingProfilesData,
    },
  ] = useLazyWasteProfileSearchQuery();

  const customProfilesFilters = (gridApi: GridApi | null) => (
    <CustomProfilesFilters
      gridApi={gridApi}
      getProfilesData={getProfilesData}
      updateData={updateData}
      setUpdateData={setUpdateData}
    />
  );

  const onCellClicked = (params: CellClickedEvent) => {
    setProfileFilterScroll(window.scrollY);
    setProfileFiltersSelectedRow(params?.data);
    if (params?.data?.id === selectedProfile?.id) {
      navigate(`/profile/${params?.data?.id}`);
      saveToLocalStorageProfileFilters();
    }
  };

  const contextMenuItems = getContextMenuItems(t, mode === DARK);

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedProfile(selectedRows[0]);
  }, []);

  const customTitle = () => <ProfilesCustomTitle />;

  const columnDefs = getColumnDefs(t);

  useEffect(() => {
    if (previousProfilesView) {
      setProfileFiltersAccounts(
        getFromLocalStorage(PROFILES_STORAGE_KEYS.ACCOUNT_ID_LIST)
      );
      setProfileFiltersKeyword(
        getFromLocalStorage(PROFILES_STORAGE_KEYS.KEYWORD)
      );
      setProfileFiltersSearchOrganization(
        getFromLocalStorage(PROFILES_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE)
      );
      saveToLocalStorage(
        PROFILES_STORAGE_KEYS.SELECTED_PROFILES,
        getFromLocalStorage(PROFILES_STORAGE_KEYS.SELECTED_PROFILE_ROW)
      );
      setUpdateData(true);

      if (scrollToTop) {
        window.scrollTo(0, scrollToTop);
      }
    } else {
      saveToLocalStorage(PROFILES_STORAGE_KEYS.SELECTED_PROFILES, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    setProfileFiltersAccounts,
    setProfileFiltersKeyword,
    setProfileFiltersSearchOrganization,
  ]);

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousProfilesView) {
      window.scroll(0, scrollToTop);
    }
  }, [scrollToTop, profilesData, previousProfilesView]);

  useEffect(() => {
    saveToLocalStorage(PROFILES_STORAGE_KEYS.SELECTED_PROFILES, {});
  }, [tab]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(PROFILES_STORAGE_KEYS.SELECTED_PROFILES, null);
      saveToLocalStorage(PROFILES_STORAGE_KEYS.SELECTED_PROFILE_ROW, null);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <AgGrid
      customTitle={customTitle}
      title={t("list-of-profiles")}
      onCellClicked={onCellClicked}
      gridName={PROFILES_GRID_NAME}
      rowData={profilesData}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      noRowsText={t("no-profiles-display")}
      isLoadingData={isLoadingProfilesData || isFetchingProfilesData}
      cellSelection={true}
      rowGroupPanelShow={rowGroupPanelShow}
      customFilters={customProfilesFilters}
      getContextMenuItems={contextMenuItems}
      withSavingPage={!!previousProfilesView}
      rowSelection={rowSelection}
      onSelectionChanged={onSelectionChanged}
    />
  );
};
