import { useNavigate } from "react-router-dom";
import AdviseLogo from "src/shared/assets/svgs/advise-logo.svg";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { LogoContainer } from "./styles";

export const Logo = () => {
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate(`/dashboard/${TABS_NAMES[0]}`);
  };

  return <LogoContainer src={AdviseLogo} alt="" onClick={handleClickLogo} />;
};
