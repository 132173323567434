import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../button/button";
import { IInspectionFilesUploader } from "./types";
import {
  InspectionFilesUploaderContainer,
  InspectionFilesUploadedImageWrapper,
} from "./styles";

export const InspectionFilesUploader = ({
  handleFiles,
  files = [],
}: IInspectionFilesUploader) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const filesUploaded = event.target.files || ([] as File[]);
    const renamedFiles = [];

    for (let i = 0; i < filesUploaded?.length; i++) {
      renamedFiles.push(
        new File(
          [filesUploaded[i]],
          `${new Date().getTime()}${filesUploaded[i].name}`,
          { type: filesUploaded[i].type }
        )
      );
    }

    handleFiles(renamedFiles);
  };

  return (
    <InspectionFilesUploaderContainer>
      <Button
        text={t("add-attachment")}
        onClick={handleClick}
        id="inspection-add-attachment"
      />
      <input
        accept="image/png,.jpeg,.jpg"
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        capture="environment"
        multiple={true}
      />
      <InspectionFilesUploadedImageWrapper>
        {[...files]?.map((file: File, index: number) => (
          <img key={index} src={URL.createObjectURL(file)} alt="" />
        ))}
      </InspectionFilesUploadedImageWrapper>
    </InspectionFilesUploaderContainer>
  );
};
