import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const INVENTORY_SUB_URL = "Inventory";

export const inventory = api.injectEndpoints({
  endpoints: (builder) => ({
    allContainerSearch: builder.query({
      query: (body) => ({
        url: `${INVENTORY_SUB_URL}/AllContainerSearch`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyAllContainerSearchQuery } = inventory;
