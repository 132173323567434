import { Box, TextareaAutosize, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { ITextAreaStyles } from "./types";

export const TextAreaContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

export const StyledTextArea = styled(TextareaAutosize)<ITextAreaStyles>(
  ({ theme, error, disabled }) => ({
    borderRadius: 6,
    borderColor: error ? "#E35D2B" : "#B1B1B1",
    width: "100%",
    color:
      disabled && !isDarkMode(theme.palette.mode)
        ? "rgba(0, 0, 0, 0.38)"
        : theme.palette.secondary.main,
    fontFamily: "Roboto",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    padding: 8,
    backgroundColor: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
    "&:hover": {
      borderColor: error
        ? "#E35D2B"
        : isDarkMode(theme.palette.mode)
        ? "rgba(37, 137, 92, 0.5);"
        : "rgba(37, 137, 92, 0.20)",
    },
    "&:focus": {
      outlineColor: error
        ? "#E35D2B"
        : isDarkMode(theme.palette.mode)
        ? "rgba(32, 33, 36, 0.50)"
        : "#25895C",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  })
);

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginLeft: 14,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));
