import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const PERMISSION_SUB_URL = "Permission";

export const permission = api.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: ({ userID }) => ({
        url: `${PERMISSION_SUB_URL}/GetPermissions/${userID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPermissionsQuery } = permission;
