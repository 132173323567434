import React from "react";
import { FormCard } from "src/shared/components/formCard/formCard";
import {
  Divider,
  FieldName,
  FieldValue,
  FieldWrapper,
} from "src/shared/components/formCard/styles";
import { useGetEManifestMutation } from "src/shared/store/api/manifest.api";
import { EManifestCustomTitle } from "../EManifestCustomTitle/EManifestCustomTitle";
import { IEManifest, IEManifestForm } from "./types";

export const EManifestForm = ({
  eManifestConfig,
  manifestId,
}: IEManifestForm) => {
  const [getEManifestAttachment] = useGetEManifestMutation();
  const handleClickLink = () => getEManifestAttachment(manifestId);

  return (
    <FormCard customTitle={() => <EManifestCustomTitle />}>
      <React.Fragment>
        {eManifestConfig?.map((manifest: IEManifest, index: number) => (
          <React.Fragment key={index}>
            <FieldWrapper>
              <FieldName>{manifest.name}</FieldName>
              <FieldValue withLink={!!manifest.link} onClick={handleClickLink}>
                {manifest.value}
              </FieldValue>
            </FieldWrapper>
            {index !== eManifestConfig?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </FormCard>
  );
};
