import { Box, styled } from "@mui/material";

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  marginLeft: 64,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginLeft: 40,
  },
}));

export const PermissionContainer = styled(Box)(() => ({
  position: "relative",
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));

export const PermissionFormCardContainer = styled(Box)(() => ({
  "& > div": {
    zIndex: 100,
    position: "relative",
    "& > div": {
      "&:last-of-type": {
        overflow: "unset",
      },
    },
  },
}));
