import { Box, Typography, styled } from "@mui/material";

export const ProfilesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "40px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "0 16px",
    margin: "26px 0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "12px 0",
  },
}));

export const ProfilesTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const ProfilesDescription = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  color: theme.palette.text.secondary,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  maxWidth: 520,
  marginRight: 16,
  [theme.breakpoints.down("lg")]: {
    maxWidth: "none",
    marginRight: 0,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    marginTop: 8,
    marginRight: 0,
  },
}));

export const ProfilesWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

export const ProfilesCardsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    " & > div": {
      width: "100%",
    },
  },
}));
