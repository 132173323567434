import { FC } from "react";
import { generatedIdForButton } from "src/shared/helpers/generatedId";
import { FilledBtn } from "./styles";
import { IFilledButton } from "./types";

export const FilledButton: FC<IFilledButton> = ({
  text,
  onClick,
  disabled,
  id,
}) => {
  return (
    <FilledBtn
      onClick={onClick}
      disabled={disabled}
      id={generatedIdForButton(id)}
    >
      {text}
    </FilledBtn>
  );
};
