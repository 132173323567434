import { Box, Typography, styled } from "@mui/material";

export const SiteSelectionWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  justifyContent: "space-between",
}));

export const SiteSelectionNameWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
}));

export const SiteSelectionName = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  minWidth: 140,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    minWidth: 110,
  },
}));
