import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEn from "./shared/translations/en/common.json";
import commonFr from "./shared/translations/fr/common.json";
import commonEs from "./shared/translations/es/common.json";

const resources = {
  en: {
    translation: commonEn,
  },
  fr: {
    translation: commonFr,
  },
  es: {
    translation: commonEs,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
