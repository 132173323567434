import { Box, Typography, styled } from "@mui/material";

export const LabelWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    gap: 4,
    flexWrap: "wrap",
  },
}));

export const DialogLabel = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  minWidth: 140,
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));

export const GuestUserProfileDialogContentContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 24,
    [theme.breakpoints.down("md")]: {
      gap: 12,
    },
  })
);

export const SendBtnWrapper = styled(Box)(() => ({
  width: 136,
}));
