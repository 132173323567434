import * as yup from "yup";

export const EditUserProfileValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(t("required-field")),
    lastName: yup.string().required(t("required-field")),
    organizationName: yup.string().required(t("required-field")),
    jobTitle: yup.string().required(t("required-field")),
    phone: yup.string().phone().required(t("required-field")),
    id: yup.string().required(t("required-field")),
    userAccounts: yup.array().of(
      yup.object().shape({
        organizationUnitID: yup.string(),
        isDefault: yup.boolean(),
        isFavorite: yup.boolean(),
      })
    ),
    userPermissions: yup.array().of(
      yup.object().shape({
        permissionID: yup.number(),
      })
    ),
  });
