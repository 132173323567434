import { COMPLIANCE_COLOR } from "../compliance/constants";
import { IInventoryAging } from "./types";

export const inventoryChartHeight = 140;

export const highlightScope = { faded: "global", highlighted: "item" };

export const getInventoryAgingChartStatistics = (
  inventoryAging: IInventoryAging[]
) => {
  return (
    inventoryAging?.map(
      ({
        complianceColor,
        complianceDescription,
        totalContainerQuantity,
      }: IInventoryAging) => ({
        value: totalContainerQuantity || 0,
        label: complianceDescription,
        color: COMPLIANCE_COLOR[complianceColor],
      })
    ) || []
  );
};

export const getInventoryAgingTotalCounts = (
  inventoryAging: IInventoryAging[]
) => {
  return inventoryAging?.reduce((acc, { totalContainerQuantity }) => {
    acc += totalContainerQuantity;

    return acc;
  }, 0);
};
