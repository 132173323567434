import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { SystemMessageForm } from "src/components/systemMessageForm/systemMessageForm";
import {
  NewSystemMessageBody,
  NewSystemMessageContainer,
  NewSystemMessageTitle,
} from "./styles";

export const NewSystemMessage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { setChangedData } = useAppActions();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const handleBackToSiteAdmin = useCallback(() => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      setChangedData(false);
      navigate("/site-admin");
    }
  }, [changedData, navigate, setChangedData, setIsOpenUnsavedChangedDialog]);

  return (
    <NewSystemMessageContainer>
      <CssBaseline />
      <Header />
      <NewSystemMessageBody>
        <BackTo
          text={t("back-to-site-admin")}
          handleBack={handleBackToSiteAdmin}
        />
        <NewSystemMessageTitle>
          {t("create-a-new-system-message")}
        </NewSystemMessageTitle>
        <SystemMessageForm />
      </NewSystemMessageBody>
    </NewSystemMessageContainer>
  );
};
