import { Box, styled } from "@mui/material";

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  justifyContent: "space-between",
}));

export const FieldInformationWrapper = styled(Box)(() => ({
  display: "flex",
}));
