import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const GeneralCharacteristicsContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const FieldsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  " & > div": {
    width: 156,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export const InputLabelWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const InputLabel = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode)
    ? theme.palette.secondary.main
    : "rgba(0, 0, 0, 0.40)",
  width: 60,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
}));

export const CharacteristicsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 19,
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
  "& > div": {
    width: "100%",
    gap: 19,
  },
}));

export const Question = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 16,
    height: 16,
  },
}));

export const HandlingCodeTooltipContainer = styled(Box)(() => ({
  width: "100%",
  position: "relative",
}));

export const GeneralCharacteristicsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
    borderRadius: 16,
    border: "1px solid #D9D9D9",
  },
}));

export const GeneralCharacteristicsHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));
