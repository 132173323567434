import { Box, Typography, styled } from "@mui/material";

export const LabelWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    gap: 4,
    flexWrap: "wrap",
  },
}));

export const DialogLabel = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  minWidth: 100,
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));

export const EditFollowUpDialogContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: 500,
  gap: 24,
  minHeight: 100,
  [theme.breakpoints.down("md")]: {
    gap: 12,
    width: "auto",
  },
}));

export const ActionButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  "& > button": {
    width: 136,
  },
}));

export const CommentsContainer = styled(Box)(() => ({
  width: "100%",
}));
