import { Box, Typography, styled } from "@mui/material";

export const ListWrapper = styled(Box)(() => ({
  "&  > ul > li > span > span": {
    fontWeight: 500,
  },
}));

export const ComplianceContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginLeft: 32,
  marginTop: 8,
}));

export const ComplianceWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

export const ComplianceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
