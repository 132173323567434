import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import {
  IFieldValue,
  IFormCardTitleWithCollapse,
  IFormCardTitleWrapper,
  IFormCardWrapper,
  IFormCardContainer,
} from "./types";

export const FormCardContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isCursorPointer" && prop !== "withCollapse",
})<IFormCardContainer>(({ theme, isCursorPointer, withCollapse }) => ({
  cursor:
    isCursorPointer || withCollapse
      ? "pointer"
      : !isCursorPointer && !withCollapse
      ? "inherit"
      : "default",
  marginTop: 24,
  borderRadius: 16,
  border: "1px solid #D9D9D9",
  padding: 16,
  "&:hover": {
    background:
      withCollapse || isCursorPointer
        ? isDarkMode(theme.palette.mode)
          ? "#3F4044"
          : "#F0F1F2"
        : "",
  },
  [theme.breakpoints.down("md")]: {
    padding: "16px 8px",
  },
}));

export const FormCardWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<IFormCardWrapper>(({ theme, isOpen = true }) => ({
  display: "flex",
  overflow: "hidden",
  transform: isOpen ? "scaleY(1)" : "scaleY(0)",
  transition: "transform 0.4s ease, height 0.4s",
  flexDirection: "column",
  height: isOpen ? "auto" : 0,
  gap: 20,
  [theme.breakpoints.down("md")]: {
    gap: 16,
  },
}));

export const FormCardTitle = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#000",
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  flexWrap: "wrap",
}));

export const FieldName = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  minWidth: 140,
  gap: 8,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    minWidth: 110,
  },
}));

export const FieldValue = styled("a")<IFieldValue>(
  ({ theme, withLink, bold }) => ({
    color: withLink
      ? "#217BDB"
      : isDarkMode(theme.palette.mode)
      ? "#FFF"
      : "rgba(0, 0, 0, 0.80)",
    cursor: withLink ? "pointer" : "inherit",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: bold ? 700 : 400,
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  })
);

export const Divider = styled(Box)(() => ({
  background: "#DDD",
  height: 1,
  width: "100%",
}));

export const EditFormCardIconWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  "& > svg > path": {
    fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
  },
}));

export const FormCardTitleWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<IFormCardTitleWrapper>(({ theme, isOpen }) => ({
  display: "flex",
  cursor: "inherit",
  justifyContent: "space-between",
  marginBottom: isOpen ? 20 : 0,
  [theme.breakpoints.down("md")]: {
    marginBottom: isOpen ? 16 : 0,
  },
}));

export const FormCardTitleWithCollapse = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<IFormCardTitleWithCollapse>(({ theme, isOpen }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > svg": {
    transform: isOpen ? "rotate(180deg)" : "",
    cursor: "pointer",
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "#000",
    },
  },
}));
