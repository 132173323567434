import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  paddingTop: 40,
}));

export const Body = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const EManifestNotificationWrapper = styled(Box)(({ theme }) => ({
  margin: "12px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "0 16px",
  },
}));
