import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { Footer } from "src/shared/components/footer/footer";
import { Notification } from "src/shared/components/notification/notification";
import { Tabs } from "src/shared/components/tabs/tabs";
import { CustomReactRouterPrompt } from "src/shared/components/customReactRouterPrompt/customReactRouterPrompt";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  TABS_IDS,
  TABS_NAMES,
} from "src/shared/store/reducers/menu/menu.slice";
import { ITabsId, ITabsName } from "src/shared/store/reducers/menu/menu.types";
import { useGetActiveBannerQuery } from "src/shared/store/api/banner.api";
import { Home } from "../home/home";
import { Manifest } from "../manifest/manifest";
import { Profiles } from "../profiles/profiles";
import { Requests } from "../requests/requests";
import { Inventory } from "../inventory/inventory";
import { Inspections } from "../inspections/inspections";
import { Body, Container, EManifestNotificationWrapper } from "./styles";

export const TABS_DASHBOARD_CONFIG = [
  {
    label: "home",
    children: <Home />,
  },
  {
    label: "requests",
    children: <Requests />,
  },
  {
    label: "manifests",
    children: <Manifest />,
  },
  {
    label: "inspections",
    children: <Inspections />,
  },
  {
    label: "profiles",
    children: <Profiles />,
  },
  {
    label: "inventory",
    children: <Inventory />,
  },
];

export const DashboardPage = () => {
  const { tabId } = useParams();
  const navigate = useNavigate();
  const {
    setTab,
    setChangedData,
    clearFromLocalStorageManifestFilters,
    clearFromLocalStorageProfileFilters,
    clearFromLocalStorageWasteProfilesFilters,
    clearFromLocalStorageInventoryFilters,
    clearFromLocalStorageInspectionsFilters,
  } = useAppActions();
  const location = useLocation();
  const tab = useAppSelector((state) => state.menuReducer.tab);

  const { data: activeBanner, isSuccess: isSuccessActiveBanner } =
    useGetActiveBannerQuery({});

  useEffect(() => {
    setChangedData(false);
  }, [location, setChangedData]);

  useEffect(() => {
    clearFromLocalStorageManifestFilters();
    clearFromLocalStorageProfileFilters();
    clearFromLocalStorageWasteProfilesFilters();
    clearFromLocalStorageInventoryFilters();
    clearFromLocalStorageInspectionsFilters();
  }, [
    clearFromLocalStorageInventoryFilters,
    clearFromLocalStorageManifestFilters,
    clearFromLocalStorageProfileFilters,
    clearFromLocalStorageWasteProfilesFilters,
    clearFromLocalStorageInspectionsFilters,
    tab,
  ]);

  const setNewValue = useCallback(
    (value: number) => {
      navigate(`/dashboard/${TABS_NAMES[value as ITabsId]}`);
    },
    [navigate]
  );

  useEffect(() => {
    setTab(TABS_IDS[tabId as ITabsName]);

    return () => {
      setTab(undefined);
    };
  }, [setTab, tabId]);

  return (
    <Container>
      <CssBaseline />
      <Header />
      <Body>
        <EManifestNotificationWrapper>
          {isSuccessActiveBanner && activeBanner && (
            <Notification
              title={activeBanner?.title}
              text={activeBanner?.description}
            />
          )}
        </EManifestNotificationWrapper>
        <Tabs
          config={TABS_DASHBOARD_CONFIG}
          value={tab || 0}
          setValue={setNewValue}
        />
      </Body>
      <Footer />
      <CustomReactRouterPrompt />
    </Container>
  );
};
