import { Box, styled, Typography } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const NotificationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 8,
  width: "100%",
  position: "relative",
  padding: "11px 48px 19px 48px",
  backgroundColor: isDarkMode(theme.palette.mode) ? "#217BDB" : "#58C493",
  borderRadius: 8,
  boxShadow: isDarkMode(theme.palette.mode)
    ? ""
    : "0px 4px 4px 0px rgba(217, 217, 217, 0.40)",
  [theme.breakpoints.down("lg")]: {
    padding: "8px 16px 16px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "8px 24px",
    gap: 6,
  },
}));

export const NotificationTitle = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "130%",
  },
}));

export const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  color: "#FFF",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "130%",
    textAlign: "center",
  },
  "& > span": {
    color: isDarkMode(theme.palette.mode) ? "inherit" : "#217BDB",
    fontWeight: 400,
    cursor: "pointer",
  },
}));
