import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CellClickedEvent,
  GridApi,
  SelectionChangedEvent,
} from "ag-grid-community";
import { SelectChangeEvent } from "@mui/material";
import { useLazyGetPendingProfileRequestsQuery } from "src/shared/store/api/wasteProfile.api";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { Tabs } from "src/shared/components/tabs/tabs";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Select } from "src/shared/components/select/select";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { WasteProfilesFilters } from "../../components/wasteProfilesFilters/wasteProfilesFilters";
import { WASTE_PROFILES_STORAGE_KEYS } from "../../components/wasteProfilesFilters/constants";
import {
  getColumnDefs,
  defaultColDef,
  paginationPageSize,
  REQUESTS_GRID_NAME,
  REQUESTS_LABELS,
  getRequestOptions,
  getRequestTabs,
  rowGroupPanelShow,
  rowSelection,
} from "./constants";
import { IRequest } from "./types";
import {
  RequestsContainer,
  RequestsWrapper,
  RequestsPanel,
  RequestsTableWrapper,
  RequestsTabsWrapper,
  MobileTabsWrapper,
} from "./styles";

export const Requests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();
  const { tab } = useAppSelector((state) => state.menuReducer);
  const [selectedTab, setSelectedTab] = useState(0);
  const [updateData, setUpdateData] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<IRequest>();

  const [
    getPendingProfileData,
    {
      data: pendingProfileData,
      isLoading: isLoadingPendingProfileData,
      isFetching: isFetchingPendingProfileData,
    },
  ] = useLazyGetPendingProfileRequestsQuery({});

  const previousLocation = history?.[history.length - 2] as string;
  const previousNewProfilesView =
    previousLocation && previousLocation?.includes("/new-profile");
  const columnDefs = getColumnDefs(t);

  const { scroll: scrollToTop } = useAppSelector(
    (state) => state.wasteProfilesFiltersReducer
  );

  const {
    setWasteProfilesFilterScroll,
    setWasteProfilesFiltersSelectedRow,
    setWasteProfilesFiltersAccounts,
    saveToLocalStorageWasteProfilesFilters,
    setWasteProfilesFiltersKeyword,
  } = useAppActions();

  const onCellClicked = (params: CellClickedEvent) => {
    if (params?.data?.id) {
      setWasteProfilesFilterScroll(window.scrollY);
      setWasteProfilesFiltersSelectedRow(params?.data);
      if (params?.data?.id === selectedRequest?.id) {
        navigate(`/new-profile/${params?.data?.id}`);
        saveToLocalStorageWasteProfilesFilters();
      }
    }
  };

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedRequest(selectedRows[0]);
  }, []);

  useEffect(() => {
    if (previousNewProfilesView) {
      setWasteProfilesFiltersKeyword(
        getFromLocalStorage(WASTE_PROFILES_STORAGE_KEYS.KEYWORD)
      );
      setWasteProfilesFiltersAccounts(
        getFromLocalStorage(WASTE_PROFILES_STORAGE_KEYS.ACCOUNT_ID_LIST)
      );
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES,
        getFromLocalStorage(
          WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES_ROW
        )
      );

      setUpdateData(true);

      if (scrollToTop) {
        window.scrollTo(0, scrollToTop);
      }
    } else {
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES,
        {}
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleChangeTab = (e: SelectChangeEvent<unknown>) => {
    setSelectedTab(REQUESTS_LABELS?.findIndex((el) => el === e?.target?.value));
  };

  const requestOptions = getRequestOptions(t);
  const requestTabs = getRequestTabs();

  useEffect(() => {
    saveToLocalStorage(WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const customRequestsFilters = (gridApi: GridApi | null) => (
    <WasteProfilesFilters
      gridApi={gridApi}
      getPendingProfileData={getPendingProfileData}
      setUpdateData={setUpdateData}
      updateData={updateData}
    />
  );

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousNewProfilesView) {
      window.scroll(0, scrollToTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToTop, pendingProfileData, previousNewProfilesView]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES,
        null
      );
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES_ROW,
        null
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <RequestsContainer>
      <RequestsPanel>
        <RequestsTabsWrapper>
          <Tabs
            orientation="vertical"
            config={requestTabs}
            setValue={setSelectedTab}
            value={selectedTab}
          />
        </RequestsTabsWrapper>
      </RequestsPanel>
      <MobileTabsWrapper>
        <Select
          value={REQUESTS_LABELS[selectedTab]}
          onChange={handleChangeTab}
          options={requestOptions}
          id={`request-${REQUESTS_LABELS[selectedTab]}`}
        />
      </MobileTabsWrapper>
      <RequestsWrapper>
        <RequestsTableWrapper>
          <AgGrid
            title={t(REQUESTS_LABELS[selectedTab])}
            gridName={REQUESTS_GRID_NAME[selectedTab]}
            customFilters={customRequestsFilters}
            rowData={pendingProfileData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            paginationPageSize={paginationPageSize}
            noRowsText={t(
              `no-available-${REQUESTS_LABELS[selectedTab]}-display`
            )}
            cellSelection={true}
            allowContextMenuWithControlKey={true}
            onCellClicked={onCellClicked}
            rowSelection={rowSelection}
            rowGroupPanelShow={rowGroupPanelShow}
            onSelectionChanged={onSelectionChanged}
            withSavingPage={!!previousNewProfilesView}
            isLoadingData={
              isLoadingPendingProfileData || isFetchingPendingProfileData
            }
          />
        </RequestsTableWrapper>
      </RequestsWrapper>
    </RequestsContainer>
  );
};
