import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const FilledBtn = styled(Button)(({ theme }) => ({
  padding: "14px 40px 13px 42px",
  width: "100%",
  height: 40,
  borderRadius: 6,
  background: "linear-gradient(90deg, #25895C 0%, #58C493 100%)",
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "initial",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
  "&:hover": {
    background: "linear-gradient(90deg, #25895C 100%, #58C493 0%)",
  },
  "&:disabled": {
    background: "#D9D9D9",
    color: "#FFF",
  },
}));
