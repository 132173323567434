import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { IStepStyles } from "./types";

export const StepsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 18,
}));

export const Step = styled(Box)(({ selected }: IStepStyles) => ({
  width: 26,
  height: 6,
  borderRadius: 30,
  background: "#25895C",
  opacity: selected ? 1 : 0.2,
  "&:first-of-type": {
    width: 50,
  },
}));
