import React from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { ISignatureForm } from "./types";
import { SignatureText } from "./styles";

export const SignatureForm = ({
  title,
  signature,
  date,
  withCollapse,
}: ISignatureForm) => {
  const { t } = useTranslation();
  const localeDateString = date ? new Date(date).toLocaleDateString() : "";

  return (
    <FormCard title={title} withCollapse={withCollapse}>
      <React.Fragment>
        <SignatureText>
          {signature}
          {localeDateString ? (
            <span>{`, ${t("date")} ${localeDateString}`}</span>
          ) : null}
        </SignatureText>
      </React.Fragment>
    </FormCard>
  );
};
