import { styled } from "@mui/material";

export const LogoContainer = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    width: 112,
    height: 28,
  },
  [theme.breakpoints.down("md")]: {
    width: 73,
    height: 16,
  },
}));
