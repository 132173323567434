import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { DetailInspectionCard } from "../../components/detailInspectionCard/detailInspectionCard";
import {
  NewInspectionBody,
  NewInspectionContainer,
  NewInspectionTitle,
} from "./styles";

export const NewInspection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const handleBackToInspection = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      navigate(`/dashboard/${TABS_NAMES[3]}`);
    }
  };

  return (
    <NewInspectionContainer>
      <CssBaseline />
      <Header />
      <NewInspectionBody>
        <BackTo
          text={t("back-to-inspections")}
          handleBack={handleBackToInspection}
        />
        <NewInspectionTitle>{t("new-inspection-details")}</NewInspectionTitle>
        <DetailInspectionCard />
      </NewInspectionBody>
    </NewInspectionContainer>
  );
};
