import { createSlice } from "@reduxjs/toolkit";
import { IMenuState } from "./menu.types";

export const TABS_IDS = {
  home: 0,
  requests: 1,
  manifest: 2,
  inspections: 3,
  profiles: 4,
  inventory: 5,
};

export const TABS_NAMES = {
  0: "home",
  1: "requests",
  2: "manifest",
  3: "inspections",
  4: "profiles",
  5: "inventory",
};

const initialMenuState: IMenuState = {
  tab: undefined,
  secondMenuTab: undefined,
  adminMenuTab: undefined,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setAdminMenuTab: (state, action) => {
      state.adminMenuTab = action.payload;
    },
    setSecondMenuTab: (state, action) => {
      state.secondMenuTab = action.payload;
    },
  },
});

export const { reducer: menuReducer, actions: menuActions } = menuSlice;
