import { useEffect } from "react";
import { IUsePreselectFormValue } from "./types";

export const usePreselectFormValue = ({
  arrayOfValues,
  setValue,
  fieldID,
  fieldValue,
}: IUsePreselectFormValue) => {
  useEffect(() => {
    if (arrayOfValues?.length === 1) {
      setValue(fieldID, arrayOfValues?.[0]?.id);
      setValue(fieldValue, arrayOfValues?.[0]?.val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayOfValues, setValue]);
};
