import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const NOTIFICATION_SUB_URL = "Notification";

export const notification = api.injectEndpoints({
  endpoints: (builder) => ({
    upsertUserManifestNotification: builder.mutation({
      query: (body) => ({
        url: `${NOTIFICATION_SUB_URL}/UpsertUserManifestNotification`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["ManifestNotification"],
    }),
    getUserManifestNotification: builder.query({
      query: () => ({
        url: `${NOTIFICATION_SUB_URL}/GetUserManifestNotification`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["ManifestNotification"],
    }),
    deleteUserManifestNotification: builder.mutation({
      query: () => ({
        url: `${NOTIFICATION_SUB_URL}/DeleteUserManifestNotification`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["ManifestNotification"],
    }),
    getUserInventoryNotification: builder.query({
      query: () => ({
        url: `${NOTIFICATION_SUB_URL}/GetUserInventoryNotification`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["InventoryNotification"],
    }),
    upsertUserInventoryNotification: builder.mutation({
      query: (body) => ({
        url: `${NOTIFICATION_SUB_URL}/UpsertUserInventoryNotification`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["InventoryNotification"],
    }),
    deleteUserInventoryNotification: builder.mutation({
      query: () => ({
        url: `${NOTIFICATION_SUB_URL}/DeleteUserInventoryNotification`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["InventoryNotification"],
    }),
    processSupportForm: builder.mutation({
      query: (body) => ({
        url: `${NOTIFICATION_SUB_URL}/ProcessSupportForm`,
        method: HTTP_METHOD.POST,
        body,
      }),
    }),
    getUserInspectionNotificationByInspectionID: builder.query({
      query: (inspectionID) => ({
        url: `${NOTIFICATION_SUB_URL}/GetUserInspectionNotificationByInspectionID/${inspectionID}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["InspectionNotification"],
    }),
    upsertUserInspectionNotification: builder.mutation({
      query: (body) => ({
        url: `${NOTIFICATION_SUB_URL}/UpsertUserInspectionNotification`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["InspectionNotification"],
    }),
    deleteUserInspectionNotificationByInspectionID: builder.mutation({
      query: (inspectionID) => ({
        url: `${NOTIFICATION_SUB_URL}/DeleteUserInspectionNotificationByInspectionID/${inspectionID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["InspectionNotification"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserInspectionNotificationByInspectionIDQuery,
  useUpsertUserInspectionNotificationMutation,
  useDeleteUserInspectionNotificationByInspectionIDMutation,
  useProcessSupportFormMutation,
  useUpsertUserManifestNotificationMutation,
  useGetUserManifestNotificationQuery,
  useDeleteUserManifestNotificationMutation,
  useUpsertUserInventoryNotificationMutation,
  useGetUserInventoryNotificationQuery,
  useDeleteUserInventoryNotificationMutation,
} = notification;
