import { ISite } from "src/pages/singleManifest/types";
import { IAging } from "../inventoryAgingCard/types";
import { IInventoryAgingFields, IWasteCode, IWasteCodes } from "./types";

export const agingFields = [
  "stateCodes30Days",
  "stateCodes90Days",
  "stateCodes180Days",
  "stateCodes365Days",
  "stateCodesIndefinite",
];

export const getInventoryAgingParametersFields = (
  site: ISite,
  t: (text: string) => string
) => [
  {
    name: t("name"),
    value: site?.name || "-",
  },
  {
    name: t("epa-id"),
    value: site?.epaNumber || "-",
  },
  {
    name: t("address"),
    value: `${site?.city ? `${site?.city},` : ""} ${
      site?.state ? `${site?.state},` : ""
    } ${site?.postalCode ? site?.postalCode : ""}`,
  },
  {
    name: t("phone"),
    value: site?.phoneNumber || "-",
  },
];

export const getWasteStateCodes = (codes?: Array<IWasteCodes> | undefined) =>
  codes?.reduce((acc, val) => {
    acc += `${val?.val};`;

    return acc;
  }, "");

export const getWasteStateCodeByField = (
  field: string,
  codes?: Array<IWasteCodes> | undefined
) => {
  return {
    ...(codes && codes?.length > 0
      ? { [field]: getWasteStateCodes(codes) }
      : {}),
  };
};

export const getWasteCodesArray = (wasteCode?: string) => {
  return wasteCode
    ?.trim()
    ?.slice(0, -1)
    ?.split(";")
    ?.map((value) => ({ id: value, val: value }));
};

export const getFilteredWasteCodes = (
  stateWasteCodes: Array<IWasteCode>,
  aging: IAging
) => {
  let selectedCodes = "";

  for (const value of Object.keys(aging)) {
    selectedCodes += aging?.[value as IInventoryAgingFields];
  }

  return (
    stateWasteCodes
      ?.filter(({ code }: IWasteCode) => !selectedCodes?.includes(code))
      ?.map(({ code }: IWasteCode) => ({ id: code, val: code })) || []
  );
};
