import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { GuestUserProfileDialog } from "src/components/guestUserProfileDialog/guestUserProfileDialog";
import { TriumvirateUserProfileDialog } from "src/components/triumvirateUserProfileDialog/triumvirateUserProfileDialog";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { IWithAuth } from "./types";

export const WithAuth = ({ children }: IWithAuth) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [isGuest, setIsGuest] = useState<Boolean>(false);
  const [isTriumvirate, setIsTriumvirate] = useState<Boolean>(false);
  const { instance, inProgress } = useMsal();
  const account = instance.getAllAccounts()[0];
  const {
    data: userProfile = {},
    isLoading: isLoadingUserProfile,
    isSuccess: isSuccessUserProfile,
  } = useGetUserProfileByIDQuery(
    {
      id: account?.username,
    },
    {
      skip: !account?.username,
    }
  );

  const hasSystemLoginPermission = hasPermissions(
    userProfile?.userPermissions,
    PERMISSIONS_LIST.SYSTEM_LOGIN
  );

  const handleLogout = useCallback(() => {
    instance.logoutRedirect().catch((error: Error) => console.log(error));
  }, [instance]);

  const isAuthenticatedUserGuest = userProfile?.isAuthenticatedUserGuest;
  const hasSystemLogin =
    !hasSystemLoginPermission &&
    !isLoadingUserProfile &&
    isSuccessUserProfile &&
    isAuthenticatedUserGuest !== false &&
    isAuthenticatedUserGuest !== true;

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      navigate("/sign-in");
    }
  }, [inProgress, isAuthenticated, navigate]);

  useEffect(() => {
    if (hasSystemLogin) {
      handleLogout();
    }
  }, [handleLogout, hasSystemLogin]);

  useEffect(() => {
    if (userProfile.isAuthenticatedUserGuest) {
      setIsGuest(true);
    } else if (userProfile.isAuthenticatedUserGuest === false) {
      setIsTriumvirate(true);
    }
  }, [userProfile.isAuthenticatedUserGuest]);

  const handleCloseGuestUserProfileDialog = () => {
    setIsGuest(false);
  };

  const handleCloseTriumvirateProfileDialog = () => {
    setIsTriumvirate(false);
  };

  return (
    <React.Fragment>
      {children}
      <GuestUserProfileDialog
        open={!!isGuest}
        handleClose={handleCloseGuestUserProfileDialog}
      />
      <TriumvirateUserProfileDialog
        open={!!isTriumvirate}
        handleClose={handleCloseTriumvirateProfileDialog}
      />
    </React.Fragment>
  );
};
