import { useRef, useEffect } from "react";
import { IClickOutside } from "./types";

export const ClickOutside = ({ children, onClick }: IClickOutside) => {
  const wrapperRef = useRef(null) as any;

  const handleClickListener = (event: MouseEvent) => {
    const clickedInside =
      wrapperRef && wrapperRef?.current?.contains?.(event.target);

    if (clickedInside) {
      return;
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);

    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};
