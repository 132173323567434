export const MANIFEST_STORAGE_KEYS = {
  KEYWORD: "MANIFEST_KEYWORD",
  SEARCH_ORGANIZATION_VALUE: "MANIFEST_SEARCH_ORGANIZATION_VALUE",
  ACCOUNT_ID_LIST: "MANIFEST_ACCOUNT_ID_LIST",
  SITE_ID_LIST: "MANIFEST_SITE_ID_LIST",
  FACILITY_ID_LIST: "MANIFEST_FACILITY_ID_LIST",
  STATUS_LIST: "MANIFEST_STATUS_LIST",
  COMPLIANCE_LIST: "MANIFEST_COMPLIANCE_LIST",
  START_DATE: "MANIFEST_START_DATE",
  END_DATE: "MANIFEST_END_DATE",
  DISCREPANCY: "MANIFEST_DISCREPANCY",
  HAS_ATTACHMENT: "MANIFEST_HAS_ATTACHMENT",
  SELECTED_MANIFEST_ROW: "SELECTED_MANIFEST_ROW",
  SELECTED_MANIFEST: "selected_manifest",
  SCROLL_ROW: "MANIFEST_SCROLL_ROW",
};

export const getComplianceData = (complianceList: Array<string>) =>
  complianceList?.reduce((acc: Array<string>, value: string) => {
    if (value === "compliant") {
      acc.push("blue");
      acc.push("green");
    } else {
      acc.push(value);
    }

    return acc;
  }, []);
