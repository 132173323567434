import { Box, Typography, styled } from "@mui/material";
import { IReviewStyles } from "./types";

export const ReviewContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const HeaderSubSection = styled(Typography)(({ theme }) => ({
  marginTop: 24,
  color: theme.palette.secondary.main,
  textAlign: "center",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    marginTop: 12,
  },
}));

export const ReviewInformationWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  [theme.breakpoints.down("md")]: {
    gap: 6,
  },
}));

export const ReviewHeader = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));

export const ReviewDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    gap: 4,
    display: "flex",
    flexDirection: "column",
  },
}));

export const ReviewItemsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "itemsSize",
})<IReviewStyles>(({ theme, itemsSize }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: itemsSize < 3 ? "column" : "row",
    flexWrap: "wrap",
    gap: 4,
  },
}));

export const ReviewValuesWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "itemNumber" && prop !== "itemsSize",
})<IReviewStyles>(({ theme, itemsSize, itemNumber }) => ({
  display: "flex",
  flexDirection: "column",
  width: `${100 / itemsSize}%`,
  alignItems:
    itemNumber === 0
      ? "flex-start"
      : itemNumber === itemsSize - 1
      ? "flex-end"
      : "center",
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    width: "auto",
    gap: 4,
  },
}));
