import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { Button } from "src/shared/components/button/button";
import { getReportTypeByTab } from "../../components/reportsTool/constants";
import { ReportsTool } from "../../components/reportsTool/reportsTool";
import { IReports } from "./types";
import {
  ReportsBody,
  ReportsContainer,
  ReportsTitle,
  ManageScheduledReportsWrapper,
} from "./styles";

export const Reports = ({ tab }: IReports) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reportType = getReportTypeByTab(tab);

  const handleClickBack = () => {
    navigate(`/dashboard/${tab}`);
  };

  const handleManageScheduledReports = () => {
    navigate("/manage-scheduled-reports");
  };

  return (
    <ReportsContainer>
      <CssBaseline />
      <Header />
      <ReportsBody>
        <BackTo
          text={t(`back-to-${getReportTypeByTab(tab)}-tab`)}
          handleBack={handleClickBack}
        />
        <ManageScheduledReportsWrapper>
          <Button
            text={t("manage-scheduled-reports")}
            onClick={handleManageScheduledReports}
            id="reports-manage-scheduled-reports"
          />
          <ReportsTitle>{t(`generate-${reportType}-reports`)}</ReportsTitle>
        </ManageScheduledReportsWrapper>
        <ReportsTool tab={tab} />
      </ReportsBody>
    </ReportsContainer>
  );
};
