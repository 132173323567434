import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: 40,
  marginBottom: 40,
}));

export const Body = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  marginTop: 20,
  borderRadius: 8,
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 32px)",
    marginLeft: 16,
    marginRight: 16,
  },
}));

export const DeleteIconWrapper = styled(Box)(({ theme }) => ({
  "& > svg": {
    cursor: "pointer",
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
    },
  },
}));
