import { IUser } from "src/shared/helpers/types";
import { IUsersInformation } from "../../components/userInformationForm/types";

export const FORM_TYPE = {
  FORM: "form",
  EDIT_USER_INFORMATION: "editUserInformation",
  EDIT_CONTRACT_INFORMATION: "editContactInformation",
  EDIT_ASSIGNED_ACCOUNTS: "editAssignedAccounts",
};

export const getUserInformationConfig = (
  t: (text: string) => string,
  userProfileData: IUser
) =>
  [
    {
      name: t("name"),
      value:
        userProfileData?.firstName || userProfileData?.lastName
          ? `${userProfileData?.firstName} ${userProfileData?.lastName}`
          : "",
    },
    {
      name: t("login"),
      value: userProfileData?.id,
    },
    {
      name: t("organization-name"),
      value: userProfileData?.organizationName,
    },
    {
      name: t("occupation"),
      value: userProfileData?.jobTitle,
    },
  ] as unknown as IUsersInformation;

export const getContactInformationConfig = (
  t: (text: string) => string,
  userProfileData: IUser
) =>
  [
    {
      name: t("email"),
      value: userProfileData?.id,
      link: `mailto:${userProfileData?.id}`,
    },
    {
      name: t("phone"),
      value: userProfileData?.phone,
    },
  ] as unknown as IUsersInformation;
