import { useState } from "react";
import { ListOfItems } from "../../shared/components/listOfItems/listOfItems";
import { SitesBody } from "./sitesBody/sitesBody";
import { SitesTitle } from "./sitesTitle/sitesTitle";
import { ISiteList } from "./types";

export const SitesList = ({ organizationName, siteItems }: ISiteList) => {
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState("0");

  const updateKeyword = (value: string) => {
    setKeyword(value);
    setCurrentPage(0);
  };

  return siteItems?.length > 0 ? (
    <ListOfItems
      withSearch
      keyword={keyword}
      setKeyword={updateKeyword}
      title={<SitesTitle organizationName={organizationName} />}
      body={
        <SitesBody
          siteItems={siteItems}
          keyword={keyword}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          paginationPageSize={paginationPageSize}
          setPaginationPageSize={setPaginationPageSize}
        />
      }
    />
  ) : null;
};
