import { createSlice } from "@reduxjs/toolkit";
import { IUserFilters } from "./userFilters.types";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { USERS_STORAGE_KEYS } from "src/pages/userList/constants";

const initialUserFiltersState: IUserFilters = {
  scroll: undefined,
  selectedRow: undefined,
};

export const userFiltersSlice = createSlice({
  name: "userFilters",
  initialState: initialUserFiltersState,
  reducers: {
    setUserFilterScroll: (state, action) => {
      state.scroll = action.payload;
    },
    setUserFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        USERS_STORAGE_KEYS.SELECTED_USERS_ROW,
        state.selectedRow
      );
    },
  },
});

export const { reducer: userFiltersReducer, actions: userFiltersActions } =
  userFiltersSlice;
