export const generatedIdForInput = (id: string) => {
  return `txt-${id}`;
};

export const generatedIdForAutocomplete = (id: string) => {
  return `txt-auto-${id}`;
};

export const generatedIdForButton = (id: string) => {
  return `btn-${id}`;
};

export const generatedIdForSelect = (id: string) => {
  return `select-${id}`;
};

export const generatedIdForTextArea = (id: string) => {
  return `txt-area-${id}`;
};

export const generatedIdForCheckBox = (id: string) => {
  return `check-box-${id}`;
};

export const generatedIdForDatePicker = (id: string) => {
  return `date-picker-${id}`;
};

export const generatedIdForTimePicker = (id: string) => {
  return `time-picker-${id}`;
};

export const generatedIdForDateTimePicker = (id: string) => {
  return `date-time-picker-${id}`;
};

export const generatedIdForSwitch = (id: string) => {
  return `switch-${id}`;
};

export const generatedIdForMultiEmail = (id: string) => {
  return `multi-email-${id}`;
};
