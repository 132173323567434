import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMsal } from "@azure/msal-react";
import { CircularProgress } from "@mui/material";
import BlockUi from "@availity/block-ui";
import dayjs from "dayjs";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import {
  useGetFollowUpOptionsQuery,
  useGetInspectionTemplatesByAccountQuery,
  useGetInspectionTemplatesForBulkByAccountQuery,
  useGetInspectionWithQuestionsTemplateByIDQuery,
  useUploadInspectionBlobMutation,
  useUpsertInspectionResultBulkMutation,
  useUpsertInspectionResultMutation,
} from "src/shared/store/api/inspection.api";
import { IOption } from "src/shared/components/select/types";
import {
  useGetUserProfileByIDQuery,
  useGetUsersAllOrByOrganizationUnitIDQuery,
} from "src/shared/store/api/userProfile.api";
import { Button } from "src/shared/components/button/button";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { TimePicker } from "src/shared/components/timePicker/timePicker";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  useGetBuildingsBySiteAndLocationTypeIDQuery,
  useGetInspectionStorageByBarcodeQuery,
  useGetLocationsByRoomAndLocationTypeIDQuery,
  useGetRoomsByBuildingAndLocationTypeIDQuery,
  useGetSitesByAccountAndLocationTypeIDQuery,
} from "src/shared/store/api/inspectionLocation.api";
import { usePreselectFormValue } from "src/shared/hooks/usePreselectFormValue/usePreselectFormValue";
import { Alert } from "src/shared/components/alert/alert";
import { Switch } from "src/shared/components/switch/switch";
import { ReactComponent as Camera } from "src/shared/assets/svgs/camera.svg";
import { ScoringDetails } from "../scoringDetails/scoringDetails";
import { DetailNotes } from "../detailNotes/detailNotes";
import { InspectionQuestions } from "../inspectionQuestions/inspectionQuestions";
import { IFollowUp, IQuestion } from "../inspectionQuestions/types";
import {
  DetailInspectionLabel,
  DetailInspectionTitle,
} from "../../pages/newInspection/styles";
import { NewInspectionValidationSchema } from "./model";
import {
  IDetailInspectionCardFields,
  IError,
  IUpsertInspectionResultData,
} from "./types";
import {
  getFieldOption,
  getInspectionOptions,
  getUserOptions,
} from "./constants";
import {
  DetailInspectionCardContainer,
  DetailInspectionCardWrapper,
  DetailInspectionFieldWrapper,
  DetailInspectionButtonWrapper,
  SwitchWrapper,
  CameraIconWrapper,
} from "./styles";

export const DetailInspectionCard = () => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { accountID, barcode } = useParams();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [defaultOrganizationId, setDefaultOrganizationId] = useState();
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);
  const [isOpenSuccessBulkAlert, setIsOpenSuccessBulkAlert] = useState(false);
  const [numberOfSuccessBulk, setNumberOfSuccessBulk] = useState();
  const [isOpenErrorsAlert, setIsOpenErrorsAlert] = useState(false);
  const [errorDetails, setErrorDetails] = useState([]);
  const [inspectionFiles, setInspectionFiles] = useState<File[]>([]);
  const [questionFiles, setQuestionFiles] = useState<{ [key: string]: File[] }>(
    {}
  );
  const [isBulkInspection, setIsBulkInspection] = useState(false);

  const [alertStorageName, setAlertStorageName] = useState("");

  const {
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NewInspectionValidationSchema(t, isBulkInspection)),
    mode: "all",
  });

  const updateFormField = useCallback(
    (
      field: IDetailInspectionCardFields,
      value: string | number | boolean | IOption[] | Date,
      shouldValidate: boolean = false
    ) => {
      setValue(field, value, { shouldValidate: shouldValidate });
    },
    [setValue]
  );

  const backToInspectionsTab = useCallback(() => {
    navigate(`/dashboard/${TABS_NAMES[3]}`);
  }, [navigate]);

  const handleBackToInspection = useCallback(() => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToInspectionsTab();
    }
  }, [changedData, setIsOpenUnsavedChangedDialog, backToInspectionsTab]);

  const inspectionDate = watch("inspectionDate");
  const inspectionTime = watch("inspectionTime");
  const inspector = getFieldOption(watch, "inspectorID", "inspectorName");
  const inspectionAccount = getFieldOption(
    watch,
    "accountOrganizationUnitID",
    "accountName"
  );
  const inspectionSite = getFieldOption(
    watch,
    "siteOrganizationUnitID",
    "siteName"
  );
  const inspectionBuilding = getFieldOption(
    watch,
    "buildingOrganizationUnitID",
    "buildingName"
  );
  const inspectionRoom = getFieldOption(
    watch,
    "roomOrganizationUnitID",
    "roomName"
  );
  const inspectionRooms = watch("roomOrganizationUnitIDList") || [];
  const inspectionLocation = getFieldOption(
    watch,
    "saaCouchbaseStorageID",
    "storageName"
  );
  const inspection = getFieldOption(watch, "inspectionID", "inspectionTitle");
  const followUp = getFieldOption(watch, "followUpOptionID", "followUpOption");
  const questionResults = watch("questionResults") as IQuestion[];
  const comments = watch("comments");
  const storageName = watch("storageName");

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const {
    data: inspectionStorageData,
    isFetching: isFetchingInspectionStorageData,
    isError: isErrorInspectionStorageData,
  } = useGetInspectionStorageByBarcodeQuery(
    {
      accountID,
      barcode,
    },
    { skip: !accountID || !barcode }
  );

  const [
    upsertInspectionResult,
    { isSuccess: isSuccessNewInspection, isLoading: isLoadingNewInspection },
  ] = useUpsertInspectionResultMutation({});

  const [
    upsertInspectionBulkResult,
    {
      data: inspectionBulkResult,
      isSuccess: isSuccessNewBulkInspection,
      isLoading: isLoadingNewBulkInspection,
    },
  ] = useUpsertInspectionResultBulkMutation({});

  const [uploadInspectionBlob] = useUploadInspectionBlobMutation({});

  const { data: userOptions } = useGetUsersAllOrByOrganizationUnitIDQuery(
    { organizationUnitID: inspectionAccount?.id },
    { skip: !inspectionAccount?.id }
  );

  const { data: organizationOptions, isLoading: isLoadingOrganizationOptions } =
    useGetOrganizationBySearchValueQuery({
      searchValue: "",
    });

  const { data: inspectionOptions } = useGetInspectionTemplatesByAccountQuery(
    inspectionAccount?.id,
    {
      skip: !inspectionAccount?.id,
    }
  );
  const { data: inspectionBulkOptions } =
    useGetInspectionTemplatesForBulkByAccountQuery(inspectionAccount?.id, {
      skip: !inspectionAccount?.id,
    });

  const { data: inspectionData } =
    useGetInspectionWithQuestionsTemplateByIDQuery(inspection?.id, {
      skip: !inspection?.id,
    });

  const { data: buildingsOptions, isFetching: isFetchingBuildingOptions } =
    useGetBuildingsBySiteAndLocationTypeIDQuery(
      {
        organizationUnitID: inspectionSite?.id,
        storageTypeID: inspectionData?.storageTypeID,
      },
      {
        skip: !inspectionSite?.id || !inspectionData?.storageTypeID,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: roomsOptions, isFetching: isFetchingRoomsOptions } =
    useGetRoomsByBuildingAndLocationTypeIDQuery(
      {
        organizationUnitID: inspectionBuilding?.id,
        storageTypeID: inspectionData?.storageTypeID,
      },
      {
        skip: !inspectionBuilding?.id || !inspectionData?.storageTypeID,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: locationsOptions, isFetching: isFetchingLocationsOptions } =
    useGetLocationsByRoomAndLocationTypeIDQuery(
      {
        organizationUnitID: inspectionRoom?.id,
        storageTypeID: inspectionData?.storageTypeID,
      },
      {
        skip: !inspectionRoom?.id || !inspectionData?.storageTypeID,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: organizationSites, isFetching: isFetchingOrganizationSites } =
    useGetSitesByAccountAndLocationTypeIDQuery(
      {
        organizationUnitID: inspectionAccount?.id,
        storageTypeID: inspectionData?.storageTypeID,
      },
      {
        skip: !inspectionAccount?.id || !inspectionData?.storageTypeID,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: followUpOptions } = useGetFollowUpOptionsQuery({});

  const handleChanged = () => {
    setChangedData(true);
  };

  const handleChangeInspectionDate = (date: unknown) => {
    if (date) {
      handleChanged();
      updateFormField("inspectionDate", date as Date, true);
    }
  };

  const handleChangeInspectionTime = (time: unknown) => {
    if (time) {
      handleChanged();
      updateFormField("inspectionTime", time as string, true);
    }
  };

  const handleChangeInspector = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("inspectorName", inputValue?.val);
    updateFormField("inspectorID", inputValue?.id);
  };

  const resetBuildingData = () => {
    updateFormField("buildingName", "", false);
    updateFormField("buildingOrganizationUnitID", "", false);
  };

  const resetRoomData = () => {
    updateFormField("roomName", "", false);
    updateFormField("roomOrganizationUnitID", "", false);
    updateFormField("roomOrganizationUnitIDList", []);
  };

  const resetLocationData = useCallback(() => {
    updateFormField("storageName", "", false);
    updateFormField("saaCouchbaseStorageID", "", false);
  }, [updateFormField]);

  const resetSiteData = () => {
    updateFormField("siteOrganizationUnitID", "", false);
    updateFormField("siteName", "", false);
  };

  const resetInspectionData = () => {
    updateFormField("inspectionID", "", false);
    updateFormField("inspectionTitle", "", false);
  };

  const handleChangeAccount = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("accountName", inputValue?.val);
    updateFormField("accountOrganizationUnitID", inputValue?.id);
    resetBuildingData();
    resetRoomData();
    resetLocationData();
    resetSiteData();
    resetInspectionData();
  };

  const handleChangeSite = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("siteName", inputValue?.val);
    updateFormField("siteOrganizationUnitID", inputValue?.id);
    resetBuildingData();
    resetRoomData();
    resetLocationData();
  };

  const handleChangeBuildings = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("buildingName", inputValue?.val);
    updateFormField("buildingOrganizationUnitID", inputValue?.id);
    resetRoomData();
    resetLocationData();
  };

  const handleChangeRoom = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("roomName", inputValue?.val);
    updateFormField("roomOrganizationUnitID", inputValue?.id);
    resetLocationData();
  };

  const handleChangeRooms = (
    _: SyntheticEvent<Element, Event>,
    newInputValues: unknown
  ) => {
    handleChanged();
    updateFormField("roomOrganizationUnitIDList", newInputValues as IOption[]);
  };

  const handleChangeLocations = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("storageName", inputValue?.val);
    updateFormField("saaCouchbaseStorageID", inputValue?.id);
  };

  const handleChangeInspection = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    updateFormField("inspectionID", inputValue?.id);
    updateFormField("inspectionTitle", inputValue?.val);
    resetSiteData();
    resetBuildingData();
    resetRoomData();
    resetLocationData();
  };

  const handleNewInspection = handleSubmit((data) => {
    const modifiedData = {
      inspectorEmail: userProfile?.email,
      inspectionDate: dayjs(data?.inspectionDate).format("YYYY-MM-DD"),
      inspectionTime: data?.inspectionTime
        ? dayjs(data?.inspectionTime).format("hh:mm A")
        : null,
      accountName: data?.accountName,
      accountOrganizationUnitID: data?.accountOrganizationUnitID,
      siteName: data?.siteName,
      siteOrganizationUnitID: data?.siteOrganizationUnitID,
      buildingName: data?.buildingName,
      buildingOrganizationUnitID: data?.buildingOrganizationUnitID,
      roomOrganizationUnitID: data?.roomOrganizationUnitID,
      roomName: data?.roomName,
      saaCouchbaseStorageID: data?.saaCouchbaseStorageID,
      storageName: data?.storageName,
      inspectionDescription: data?.inspectionDescription,
      inspectionID: data?.inspectionID,
      inspectionTitle: data?.inspectionTitle,
      inspectorName: data?.inspectorName,
      comments: data?.comments,
      followUpOption: data?.followUpOption,
      followUpOptionID: data?.followUpOptionID,
      questionResults: [
        ...(data?.questionResults?.map((question) => ({
          ...question,
          followUpOption: followUpOptions?.find(
            (followUpOption: IFollowUp) =>
              followUpOption?.followUpOptionID === question?.followUpOptionID
          )?.followupOption,
        })) || []),
      ],
    };

    upsertInspectionResult(modifiedData).then((resultData) => {
      const formData = new FormData();
      const { data } = resultData as IUpsertInspectionResultData;
      const inspectionResultID = data?.inspectionResultID;

      for (let index = 0; index < inspectionFiles?.length; index++) {
        formData.append("files", inspectionFiles?.[index]);
      }

      const questionFileNames = [];
      for (const value of Object.keys(questionFiles)) {
        questionFileNames?.push({
          questionID: value,
          fileNames: [...questionFiles?.[value]]?.map(
            (file: File) => file?.name
          ),
        });

        for (let index = 0; index < questionFiles?.[value]?.length; index++) {
          formData.append("files", questionFiles?.[value]?.[index]);
        }
      }

      formData.append(
        "inspectionResultFileLink",
        JSON.stringify({
          questionFileNames,
          inspectionFileNames: [...inspectionFiles]?.map(
            (file: File) => file?.name
          ),
        })
      );

      const hasAttachment =
        inspectionFiles?.length || Object.keys(questionFiles)?.length;

      if (hasAttachment) {
        uploadInspectionBlob({
          inspectionResultID,
          body: formData,
        });
      }
    });

    setChangedData(false);
  });

  const handleNewBulkInspection = handleSubmit((data) => {
    upsertInspectionBulkResult({
      inspectorEmail: userProfile?.email,
      inspectionDate: dayjs(data?.inspectionDate).format("YYYY-MM-DD"),
      inspectionTime: data?.inspectionTime
        ? dayjs(data?.inspectionTime).format("hh:mm A")
        : null,
      inspectionID: data?.inspectionID,
      inspectorName: data?.inspectorName,
      accountName: data?.accountName,
      accountOrganizationUnitID: data?.accountOrganizationUnitID,
      siteName: data?.siteName,
      siteOrganizationUnitID: data?.siteOrganizationUnitID,
      buildingName: data?.buildingName,
      buildingOrganizationUnitID: data?.buildingOrganizationUnitID,
      roomOrganizationUnitIDList: data?.roomOrganizationUnitIDList || [],
    });

    setChangedData(false);
  });

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  const handleCloseSuccessBulkAlert = () => {
    setIsOpenSuccessBulkAlert(false);
  };

  const handleCloseErrorsAlert = () => {
    setIsOpenErrorsAlert(false);
  };

  const handleChangeBulkInspection = () => {
    setIsBulkInspection(!isBulkInspection);
  };

  const resetDataAfterSuccess = useCallback(() => {
    setChangedData(false);
    window.scrollTo(0, 0);
    resetLocationData();
    setValue("questionResults", []);
    setInspectionFiles([]);
    setQuestionFiles({});
  }, [resetLocationData, setChangedData, setValue]);

  const handleInspectionBarcodeScan = () => {
    navigate(`/inspection-barcode/${inspectionAccount?.id}`);
  };

  useEffect(() => {
    if (userProfile) {
      updateFormField(
        "inspectorName",
        `${userProfile?.lastName || ""} ${userProfile?.firstName || ""}`
      );
      updateFormField("inspectorID", userProfile?.id);
    }
  }, [updateFormField, userProfile]);

  useEffect(() => {
    if (followUpOptions?.length) {
      updateFormField(
        "followUpOptionID",
        followUpOptions?.[0]?.followUpOptionID
      );
      updateFormField("followUpOption", followUpOptions?.[0]?.followupOption);
    }
  }, [followUpOptions, updateFormField]);

  useEffect(() => {
    if (inspectionData) {
      updateFormField(
        "inspectionTimeRequired",
        inspectionData?.inspectionTimeRequired
      );
      updateFormField(
        "inspectionDescription",
        inspectionData?.description || ""
      );
    }
  }, [inspectionData, updateFormField]);

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  const { data: defaultOrganization, isLoading: isLoadingDefaultAccount } =
    useGetOrganizationsByIDsQuery(defaultOrganizationId, {
      skip: !defaultOrganizationId,
    });

  useEffect(() => {
    if (
      defaultOrganization?.[0] &&
      (!accountID || (accountID && isErrorInspectionStorageData))
    ) {
      updateFormField("accountName", defaultOrganization?.[0]?.name as string);
      updateFormField(
        "accountOrganizationUnitID",
        defaultOrganization?.[0]?.organizationUnitID as number
      );
    }
  }, [
    defaultOrganization,
    defaultOrganizationId,
    updateFormField,
    accountID,
    isErrorInspectionStorageData,
  ]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      backToInspectionsTab();
      clearErrors();
      reset({});
    });
  }, [clearErrors, backToInspectionsTab, reset, setHandleBackUnsavedChanged]);

  useEffect(() => {
    if (isSuccessNewInspection) {
      setIsOpenSuccessAlert(true);
      resetDataAfterSuccess();
    }

    if (isSuccessNewInspection) {
    }
  }, [isSuccessNewInspection, resetDataAfterSuccess]);

  useEffect(() => {
    if (inspectionBulkResult && inspectionBulkResult?.errorDetails) {
      setErrorDetails(inspectionBulkResult?.errorDetails);
    }
    if (
      inspectionBulkResult &&
      typeof inspectionBulkResult?.numberOfInspectionsCreated === "number"
    ) {
      setIsOpenSuccessBulkAlert(true);
      setNumberOfSuccessBulk(inspectionBulkResult?.numberOfInspectionsCreated);
    }

    if (isSuccessNewBulkInspection) {
      setIsOpenSuccessAlert(true);
      resetDataAfterSuccess();
    }
  }, [isSuccessNewBulkInspection, inspectionBulkResult, resetDataAfterSuccess]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => handleNewInspection());
  }, [handleNewInspection, setHandleUpdateUnsavedChanged]);

  usePreselectFormValue({
    arrayOfValues: organizationSites,
    setValue: setValue as unknown as UseFormSetValue<FieldValues>,
    fieldID: "siteOrganizationUnitID",
    fieldValue: "siteName",
  });

  usePreselectFormValue({
    arrayOfValues: buildingsOptions,
    setValue: setValue as unknown as UseFormSetValue<FieldValues>,
    fieldID: "buildingOrganizationUnitID",
    fieldValue: "buildingName",
  });

  usePreselectFormValue({
    arrayOfValues: roomsOptions,
    setValue: setValue as unknown as UseFormSetValue<FieldValues>,
    fieldID: "roomOrganizationUnitID",
    fieldValue: "roomName",
  });

  usePreselectFormValue({
    arrayOfValues: locationsOptions,
    setValue: setValue as unknown as UseFormSetValue<FieldValues>,
    fieldID: "saaCouchbaseStorageID",
    fieldValue: "storageName",
  });

  useEffect(() => {
    if (roomsOptions?.length === 1) {
      setValue("roomOrganizationUnitIDList", roomsOptions);
    }
  }, [roomsOptions, setValue]);

  useEffect(() => {
    if (storageName) {
      setAlertStorageName(storageName);
    }
  }, [storageName]);

  useEffect(() => {
    if (!isOpenSuccessBulkAlert && errorDetails?.length > 0) {
      setIsOpenErrorsAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSuccessBulkAlert]);

  useEffect(() => {
    if (inspectionStorageData) {
      for (const value of Object.keys(inspectionStorageData)) {
        setValue(
          value as IDetailInspectionCardFields,
          inspectionStorageData[value],
          {
            shouldValidate: true,
          }
        );
      }
    }
  }, [inspectionStorageData, setValue]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingNewInspection ||
        isLoadingNewBulkInspection ||
        isFetchingInspectionStorageData ||
        isFetchingBuildingOptions ||
        isFetchingRoomsOptions ||
        isFetchingLocationsOptions ||
        isFetchingOrganizationSites ||
        isLoadingDefaultAccount ||
        isLoadingOrganizationOptions
      }
      loader={<CircularProgress />}
      keepInView
    >
      <DetailInspectionCardContainer>
        <DetailInspectionTitle>{t("new-inspection")}</DetailInspectionTitle>
        <SwitchWrapper>
          <DetailInspectionTitle>{t("single")}</DetailInspectionTitle>
          <Switch
            checked={isBulkInspection}
            onChange={handleChangeBulkInspection}
            id="detail-inspection-build-inspection"
          />
          <DetailInspectionTitle>{t("bulk")}</DetailInspectionTitle>
          <CameraIconWrapper>
            <Camera onClick={handleInspectionBarcodeScan} />
          </CameraIconWrapper>
        </SwitchWrapper>
        <DetailInspectionCardWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>
              {t("inspection-date")}
            </DetailInspectionLabel>
            <DatePicker
              value={inspectionDate ? dayjs(inspectionDate) : null}
              register={register("inspectionDate")}
              onChange={handleChangeInspectionDate}
              maxDate={dayjs()}
              errorMessage={errors?.inspectionDate?.message}
              id="detail-inspection-date"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>
              {t("inspection-time")}
            </DetailInspectionLabel>
            <TimePicker
              value={inspectionTime ? dayjs(inspectionTime) : null}
              register={register("inspectionTime")}
              onChange={handleChangeInspectionTime}
              errorMessage={errors?.inspectionTime?.message}
              id="detail-inspection-time"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>{t("account")}</DetailInspectionLabel>
            <Autocomplete
              options={organizationOptions?.length ? organizationOptions : []}
              value={inspectionAccount?.val ? inspectionAccount : null}
              disabled={!organizationOptions?.length}
              onChange={handleChangeAccount}
              id="detail-inspection-card-account"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>{t("inspector")}</DetailInspectionLabel>
            <Autocomplete
              register={register("inspectorName")}
              value={inspector?.val ? inspector : null}
              options={userOptions?.length ? getUserOptions(userOptions) : []}
              onChange={handleChangeInspector}
              disabled={!userOptions?.length || !inspectionAccount?.id}
              errorMessage={errors?.inspectorName?.message}
              id="detail-inspection-card-inspector"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>{t("inspection")}</DetailInspectionLabel>
            <Autocomplete
              register={register("inspectionTitle")}
              options={
                inspectionOptions?.length && !isBulkInspection
                  ? getInspectionOptions(inspectionOptions)
                  : isBulkInspection && inspectionBulkOptions?.length
                  ? getInspectionOptions(inspectionBulkOptions)
                  : []
              }
              value={inspection?.val ? inspection : null}
              onChange={handleChangeInspection}
              disabled={
                (!inspectionOptions?.length && !isBulkInspection) ||
                (isBulkInspection && !inspectionBulkOptions?.length) ||
                !inspectionAccount
              }
              errorMessage={errors?.inspectionTitle?.message}
              id="detail-inspection-card-inspection"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>{t("site")}</DetailInspectionLabel>
            <Autocomplete
              register={register("siteName")}
              value={inspectionSite?.val ? inspectionSite : null}
              onChange={handleChangeSite}
              options={organizationSites ? organizationSites : []}
              disabled={!organizationSites?.length || !inspectionAccount}
              errorMessage={errors?.siteName?.message}
              id="detail-inspection-card-site"
            />
          </DetailInspectionFieldWrapper>
          <DetailInspectionFieldWrapper>
            <DetailInspectionLabel>{t("building")}</DetailInspectionLabel>
            <Autocomplete
              register={register("buildingName")}
              value={inspectionBuilding?.val ? inspectionBuilding : null}
              onChange={handleChangeBuildings}
              options={buildingsOptions?.length ? buildingsOptions : []}
              disabled={!buildingsOptions?.length || !inspectionSite}
              errorMessage={errors?.buildingName?.message}
              id="detail-inspection-card-building"
            />
          </DetailInspectionFieldWrapper>
          {!isBulkInspection && (
            <DetailInspectionFieldWrapper>
              <DetailInspectionLabel>{t("room")}</DetailInspectionLabel>
              <Autocomplete
                register={register("roomName")}
                value={inspectionRoom?.val ? inspectionRoom : null}
                onChange={handleChangeRoom}
                options={roomsOptions?.length ? roomsOptions : []}
                disabled={!roomsOptions?.length || !inspectionBuilding}
                errorMessage={errors?.roomName?.message}
                id="detail-inspection-card-room"
              />
            </DetailInspectionFieldWrapper>
          )}
          {!isBulkInspection && (
            <DetailInspectionFieldWrapper>
              <DetailInspectionLabel>{t("location")}</DetailInspectionLabel>
              <Autocomplete
                register={register("storageName")}
                value={inspectionLocation?.val ? inspectionLocation : null}
                onChange={handleChangeLocations}
                options={locationsOptions?.length ? locationsOptions : []}
                disabled={!locationsOptions?.length || !inspectionRoom}
                errorMessage={errors?.storageName?.message}
                id="detail-inspection-card-location"
              />
            </DetailInspectionFieldWrapper>
          )}
          {isBulkInspection && (
            <DetailInspectionFieldWrapper>
              <DetailInspectionLabel>{t("rooms")}</DetailInspectionLabel>
              <Autocomplete
                register={register("roomOrganizationUnitIDList")}
                value={inspectionRooms?.length ? inspectionRooms : []}
                onChange={handleChangeRooms}
                options={roomsOptions?.length ? roomsOptions : []}
                disabled={!roomsOptions?.length || !inspectionBuilding}
                errorMessage={errors?.roomOrganizationUnitIDList?.message}
                multiple
                id="detail-inspection-card-rooms"
              />
            </DetailInspectionFieldWrapper>
          )}
        </DetailInspectionCardWrapper>
      </DetailInspectionCardContainer>
      {inspectionLocation?.id && inspection?.id && !isBulkInspection && (
        <>
          <ScoringDetails description={inspectionData?.description} />
          <InspectionQuestions
            inspectionId={inspection?.id}
            updateFormField={updateFormField}
            questionResults={questionResults}
            errors={errors}
            register={register as unknown as UseFormRegister<FieldValues>}
            watch={watch}
            control={control as unknown as Control<FieldValues>}
            questionFiles={questionFiles}
            setQuestionFiles={setQuestionFiles}
          />
          <DetailNotes
            followUp={followUp}
            accountId={inspectionAccount?.id}
            comments={comments}
            updateFormField={updateFormField}
            inspectionFiles={inspectionFiles}
            setInspectionFiles={setInspectionFiles}
          />
        </>
      )}
      {((inspectionRooms?.length > 0 && inspection?.id && isBulkInspection) ||
        (inspectionLocation?.id && inspection?.id && !isBulkInspection)) && (
        <DetailInspectionButtonWrapper>
          <Button
            isGrey
            text={t("back")}
            onClick={handleBackToInspection}
            id="detail-inspection-card-back"
          />
          <Button
            text={t("finish")}
            onClick={
              isBulkInspection ? handleNewBulkInspection : handleNewInspection
            }
            id="detail-inspection-card-finish"
          />
        </DetailInspectionButtonWrapper>
      )}
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[
          t("successfully-submitted", {
            inspection: watch("inspectionTitle"),
            location: alertStorageName,
          }),
        ]}
        severity="success"
      />
      <Alert
        open={isOpenSuccessBulkAlert}
        handleClose={handleCloseSuccessBulkAlert}
        messages={[
          t("success-submitted-bulk-inspection", {
            numberOfSuccessBulk,
          }),
        ]}
        severity="success"
      />
      <Alert
        open={isOpenErrorsAlert}
        handleClose={handleCloseErrorsAlert}
        messages={[
          `${t(
            "inspection-has-already-created-on-this-date"
          )}: ${errorDetails?.map((error: IError) => error?.message)}`,
        ]}
        severity="error"
      />
    </BlockUi>
  );
};
