import * as yup from "yup";

export const InventoryAgingParametersValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    stateCodes30Days: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        val: yup.string(),
      })
    ),
    stateCodes90Days: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        val: yup.string(),
      })
    ),
    stateCodes180Days: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        val: yup.string(),
      })
    ),
    stateCodes365Days: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        val: yup.string(),
      })
    ),
    stateCodesIndefinite: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        val: yup.string(),
      })
    ),
  });
