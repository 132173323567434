import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IStyledButton } from "./types";

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isGrey" && prop !== "isError",
})<IStyledButton>(({ theme, isGrey, startIcon, isError }) => ({
  padding: "8px 12px",
  width: "100%",
  height: 35,
  borderRadius: 19,
  border: !!startIcon ? "1px solid #DDD" : "none",
  background: !!startIcon
    ? "transparent"
    : isGrey
    ? "#F0F1F2"
    : isError
    ? "#E35D2B"
    : "#217BDB",
  color:
    (!!startIcon && !isDarkMode(theme.palette.mode)) || isGrey
      ? "#3A3A3A"
      : "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "initial",
  [theme.breakpoints.down("md")]: {
    height: 28,
    fontSize: 12,
  },
  "&:hover": {
    background: isError
      ? "#bf2424"
      : !!startIcon && !isDarkMode(theme.palette.mode)
      ? "#F0F1F2"
      : !!startIcon && isDarkMode(theme.palette.mode)
      ? "#35363A"
      : isGrey
      ? "#c2c3c4"
      : "#0D67C7",
  },
  "&:disabled": {
    background: isDarkMode(theme.palette.mode) ? "#282828" : "#D9D9D9",
  },
}));
