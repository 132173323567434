import { useTranslation } from "react-i18next";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { ICreateChemicalTooltip } from "./types";
import { ChemicalTooltipText } from "./styles";

export const CreateChemicalTooltip = ({
  open,
  setOpen,
}: ICreateChemicalTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <ChemicalTooltipText>
        {t("if-a-chemical-constituent-of-this-stream")}
      </ChemicalTooltipText>
    </Tooltip>
  );
};
