import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Active } from "src/shared/assets/svgs/green-tick-circle.svg";
import { ReactComponent as NotActive } from "src/shared/assets/svgs/close-red-icon.svg";
import { ReactComponent as Arrow } from "src/shared/assets/svgs/edit-form-arrow.svg";
import { FieldName } from "src/shared/components/formCard/styles";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import { ILocation, ILocationBody } from "./types";
import {
  ArrowWrapper,
  Divider,
  FieldInformationWrapper,
  FieldWrapper,
  IconWrapperWithArrow,
  FieldsWrapper,
  FieldValue,
  RoomFieldWrapper,
  LocationItemContainer,
} from "./styles";

export const LocationBody = ({ locationItems }: ILocationBody) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickLocation = (id: string, docType: string) => () => {
    navigate(`/edit-location/${id}/${docType}`);
  };

  return (
    <React.Fragment>
      {locationItems?.map(
        (
          {
            locationType,
            departmentName,
            id,
            piContactFullName,
            statusID,
            doc_type,
            name,
          }: ILocation,
          index: number
        ) => (
          <React.Fragment key={id}>
            <LocationItemContainer
              key={id}
              onClick={handleClickLocation(id, doc_type)}
            >
              <RoomFieldWrapper>
                <FieldInformationWrapper>
                  <FieldName>{t("name")}</FieldName>
                  <FieldsWrapper>
                    <FieldValue>{name}</FieldValue>
                  </FieldsWrapper>
                </FieldInformationWrapper>
                <IconWrapperWithArrow>
                  {statusID === STATUS_ID_VALUES.ACTIVE ? (
                    <Active />
                  ) : (
                    <NotActive />
                  )}
                  <ArrowWrapper>
                    <Arrow />
                  </ArrowWrapper>
                </IconWrapperWithArrow>
              </RoomFieldWrapper>
              <FieldWrapper>
                <FieldName>{t("type")}</FieldName>
                <FieldsWrapper>
                  <FieldValue>{locationType}</FieldValue>
                </FieldsWrapper>
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("department")}</FieldName>
                <FieldsWrapper>
                  <FieldValue>{departmentName}</FieldValue>
                </FieldsWrapper>
              </FieldWrapper>
              <FieldWrapper>
                <FieldName>{t("pi")}</FieldName>
                <FieldValue>{piContactFullName}</FieldValue>
              </FieldWrapper>
            </LocationItemContainer>
            {index !== locationItems?.length - 1 && <Divider />}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};
