import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import NewInspection from "src/shared/assets/svgs/new-inspection.svg";
import { AccountCard } from "src/components/accountCard/accountCard";
import { useLazyNewInspectionTemplateQuery } from "src/shared/store/api/inspection.api";
import { ManageInspectionsTable } from "../../components/manageInspectionsTable/manageInspectionsTable";
import { ManageInspectionFromTemplateDialog } from "../../components/manageInspectionFromTemplateDialog/manageInspectionFromTemplateDialog";
import {
  ManageInspectionsContainer,
  ManageInspectionsBody,
  ManageInspectionsTitle,
  ManageInspectionsWrapper,
  ManageInspectionsDescription,
  ManageInspectionsCardsWrapper,
} from "./styles";

export const ManageInspections = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenCreateFromTemplateDialog, setIsOpenCreateFromTemplateDialog] =
    useState(false);

  const [getNewManageInspectionId] = useLazyNewInspectionTemplateQuery();

  const handleBackToInspection = () => {
    navigate(`/dashboard/${TABS_NAMES[3]}`);
  };

  const handleNewManageInspection = () => {
    getNewManageInspectionId({}).then(({ data }) => {
      const generatedInspectionId = data?.id;

      if (generatedInspectionId) {
        navigate(`/new-manage-inspection/${generatedInspectionId}`);
      }
    });
  };

  const handleOpenCreateFromTemplateDialog = () => {
    setIsOpenCreateFromTemplateDialog(true);
  };

  const handleCloseCreateFromTemplateDialog = () => {
    setIsOpenCreateFromTemplateDialog(false);
  };

  return (
    <ManageInspectionsContainer>
      <CssBaseline />
      <Header />
      <ManageInspectionsBody>
        <BackTo
          text={t("back-to-inspections")}
          handleBack={handleBackToInspection}
        />
        <ManageInspectionsTitle>
          {t("manage-inspections")}
        </ManageInspectionsTitle>
        <ManageInspectionsWrapper>
          <ManageInspectionsDescription>
            {t("manage-inspections-description")}
          </ManageInspectionsDescription>
          <ManageInspectionsCardsWrapper>
            <AccountCard
              title={t("create-new-inspection")}
              buttonText={t("from-template")}
              img={NewInspection}
              onClick={handleOpenCreateFromTemplateDialog}
              id="manage-inspections-create-new-inspection-from-template"
            />
            <AccountCard
              title={t("create-new-inspection")}
              buttonText={t("from-scratch")}
              img={NewInspection}
              onClick={handleNewManageInspection}
              id="manage-inspections-create-new-inspection-from-scratch"
            />
          </ManageInspectionsCardsWrapper>
        </ManageInspectionsWrapper>
        <ManageInspectionsTable />
      </ManageInspectionsBody>
      <ManageInspectionFromTemplateDialog
        open={isOpenCreateFromTemplateDialog}
        handleClose={handleCloseCreateFromTemplateDialog}
      />
    </ManageInspectionsContainer>
  );
};
