import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const REPORT_SUB_URL = "Report";

export const report = api.injectEndpoints({
  endpoints: (builder) => ({
    getReportItems: builder.query({
      query: (reportModule) => ({
        url: `${REPORT_SUB_URL}/GetReportItems/${reportModule}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSubscriptionsByReportPath: builder.query({
      query: ({ reportModule, reportName }) => ({
        url: `${REPORT_SUB_URL}/GetSubscriptionsByReportPath/${reportModule}/${reportName}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSubscriptionsByCurrentUser: builder.query({
      query: () => ({
        url: `${REPORT_SUB_URL}/getSubscriptionsByCurrentUser`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["Subscription"],
    }),
    upsertSubscription: builder.mutation({
      query: (body) => ({
        url: `${REPORT_SUB_URL}/UpsertSubscription`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["Subscription"],
    }),
    getSubscriptionByID: builder.query({
      query: (subscriptionID) => ({
        url: `${REPORT_SUB_URL}/GetSubscriptionByID/${subscriptionID}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["Subscription"],
    }),
    getReportParameters: builder.query({
      query: ({ reportModule, reportName }) => ({
        url: `${REPORT_SUB_URL}/getReportParameters/${reportModule}/${reportName}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getReportParametersBySubscriptionID: builder.query({
      query: (subscriptionID) => ({
        url: `${REPORT_SUB_URL}/GetReportParametersBySubscriptionID/${subscriptionID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    deleteSubscription: builder.mutation({
      query: (subscriptionID) => ({
        url: `${REPORT_SUB_URL}/DeleteSubscription/${subscriptionID}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["Subscription"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetReportParametersBySubscriptionIDQuery,
  useGetReportParametersQuery,
  useDeleteSubscriptionMutation,
  useGetSubscriptionByIDQuery,
  useUpsertSubscriptionMutation,
  useGetReportItemsQuery,
  useGetSubscriptionsByReportPathQuery,
  useGetSubscriptionsByCurrentUserQuery,
} = report;
