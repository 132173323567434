import { useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import { CircularProgress } from "@mui/material";
import { useLazyDownloadQuery } from "src/shared/store/api/attachments.api";
import { ReactComponent as Download } from "src/shared/assets/svgs/import.svg";
import { IZoomedImageWidthDownload } from "./types";
import {
  ImageWrapper,
  ZoomedImageWidthDownloadContainer,
  DownloadIconWrapper,
} from "./styles";

export const ZoomedImageWidthDownload = ({
  folder,
  fileName,
  containerName,
}: IZoomedImageWidthDownload) => {
  const [
    downloadFileByName,
    { data: downloadedFile, isSuccess: isSuccessDownloadFile },
  ] = useLazyDownloadQuery({});

  useEffect(() => {
    if (!downloadedFile) {
      downloadFileByName({ containerName, folder, fileName });
    }
  }, [containerName, folder, fileName, downloadFileByName, downloadedFile]);

  const downloadedFileObjectUrl = downloadedFile
    ? URL.createObjectURL(downloadedFile)
    : "";

  const handleDownload = () => {
    const url = window.URL || window.webkitURL;
    const blob = url.createObjectURL(downloadedFile);

    const hiddenElement = document.createElement("a");
    hiddenElement.href = blob;
    hiddenElement.target = "_blank";
    hiddenElement.download = fileName;
    hiddenElement.click();
  };

  return (
    <ZoomedImageWidthDownloadContainer
      tag="div"
      blocking={!isSuccessDownloadFile}
      loader={<CircularProgress />}
      keepInView
    >
      <Zoom>
        <ImageWrapper>
          <img src={downloadedFileObjectUrl} alt="" />
        </ImageWrapper>
      </Zoom>
      {isSuccessDownloadFile && (
        <DownloadIconWrapper>
          <Download onClick={handleDownload} />
        </DownloadIconWrapper>
      )}
    </ZoomedImageWidthDownloadContainer>
  );
};
