import { Box, styled } from "@mui/material";

export const FieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  justifyContent: "space-between",
}));

export const FieldInformationWrapper = styled(Box)(() => ({
  display: "flex",
}));

export const Divider = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#D9D9D9",
  height: 1,
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    gap: 8,
  },
}));
