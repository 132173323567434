import { Input } from "../../input/input";
import { IInput } from "../../input/types";
import { FormCardInputContainer } from "./styles";

export const FormCardInput = (props: IInput) => {
  return (
    <FormCardInputContainer>
      <Input {...props} />
    </FormCardInputContainer>
  );
};
