import {
  GetContextMenuItemsParams,
  GroupCellRendererParams,
  IDetailCellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  AllSeriesType,
  ChartsTooltip,
  PiePlot,
  ResponsiveChartContainer,
  pieArcLabelClasses,
} from "@mui/x-charts";
import { getSortedByMonthFormat } from "src/components/manifestTable/constants";
import { ResponsiveWrapper } from "src/components/manifestChart/styles";
import { IComplianceColor } from "src/components/compliance/types";
import { COMPLIANCE_COLOR } from "src/components/compliance/constants";
import TrashContextMenu from "src/shared/assets/svgs/trash-context-menu.svg";
import TrashContextMenuWhite from "src/shared/assets/svgs/trash-context-menu-white.svg";
import { IInspection } from "src/components/detailInspectionCard/types";
import { InspectionInformation } from "./inspectionInformation/inspectionInformation";
import { getScoreRating } from "./inspectionInformation/constants";
import { IScore } from "./types";

export const INSPECTIONS_GRID_NAME = "inspections";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

export const getLocaleDateValue = (date?: string) => {
  return date ? new Date(date).toLocaleDateString() : "";
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    cellRenderer: "agGroupCellRenderer",
    field: "",
    width: 50,
    suppressMovable: true,
    resizable: false,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: t("inspection"),
    field: "inspectionTitle",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("date"),
    field: "inspectionDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    useValueFormatterForExport: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.inspectionDate);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.inspectionDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("account"),
    field: "accountName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("department"),
    field: "departmentName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("building"),
    field: "buildingName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
  },
  {
    headerName: t("site"),
    field: "siteName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("room"),
    field: "roomName",
    ...excelMode,
    enableRowGroup: true,
    flex: 1,
  },
  {
    headerName: t("location"),
    field: "storageName",
    ...excelMode,
    enableRowGroup: true,
    flex: 1,
  },
  {
    headerName: t("pi"),
    field: "piContactFullName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("score"),
    field: "score",
    cellRenderer: ({ data }: GroupCellRendererParams) => {
      const series = [
        {
          type: "pie",
          arcLabel: (item) => (item?.index === 0 ? `${item.value} %` : ""),
          data: [
            {
              value: data?.score?.value,
              color: COMPLIANCE_COLOR[data?.score?.color as IComplianceColor],
            },
            {
              value: 100 - data?.score?.value,
              color: COMPLIANCE_COLOR["grey"],
            },
          ],
          innerRadius: 30,
          outerRadius: 20,
        },
      ] as AllSeriesType[];

      return data ? (
        <ResponsiveWrapper>
          <ResponsiveChartContainer
            series={series}
            height={100}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                transform: "none !important",
                color: "#282828",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: 10,
                fontStyle: "normal",
                fontweight: 300,
                lineHeight: "normal",
              },
            }}
          >
            <PiePlot />
            <ChartsTooltip trigger="item" />
          </ResponsiveChartContainer>
        </ResponsiveWrapper>
      ) : null;
    },
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return data?.score;
    },
    sortable: true,
    width: 120,
    comparator: (firstScore: IScore, secondScore: IScore) => {
      if (firstScore?.value === secondScore?.value) {
        return 0;
      }

      return firstScore?.value > secondScore?.value ? 1 : -1;
    },
    cellRendererParams: {
      suppressPadding: true,
    },
    ...excelMode,
    enableRowGroup: true,
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return t(`${getScoreRating(data?.score?.color) || ""}`);
      },
    },
  },
];

export const DetailCellRenderer = ({ data }: IDetailCellRendererParams) => {
  return <InspectionInformation id={data?.id} />;
};

export const getContextMenuItems =
  (
    setDeleteInspectionSelected: (inspectionID: IInspection) => void,
    setOpenDeleteInspectionDialog: (status: boolean) => void,

    t: (value: string) => string,
    isDark: boolean,
    hasDeleteInspectionsPermission: boolean
  ) =>
  (params: GetContextMenuItemsParams) =>
    [
      hasDeleteInspectionsPermission
        ? {
            name: t("delete-inspection"),
            action: () => {
              setDeleteInspectionSelected(params?.node?.data);
              setOpenDeleteInspectionDialog(true);
            },
            icon: `<img src="${
              isDark ? TrashContextMenuWhite : TrashContextMenu
            }"/>`,
          }
        : "",
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];
