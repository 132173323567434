import { DialogActions } from "@mui/material";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { IDialog } from "./types";
import {
  StyledDialog,
  DialogTitle,
  CloseIconWrapper,
  DialogContent,
} from "./styles";

export const Dialog = ({
  open,
  handleClose,
  title,
  actions,
  renderFields,
}: IDialog) => {
  return (
    <StyledDialog open={open} onClose={handleClose}>
      {title && (
        <DialogTitle>
          {title}
          {handleClose && (
            <CloseIconWrapper onClick={handleClose}>
              <Close />
            </CloseIconWrapper>
          )}
        </DialogTitle>
      )}
      <DialogContent>{renderFields()}</DialogContent>
      <DialogActions>{actions()}</DialogActions>
    </StyledDialog>
  );
};
