import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import i18n from "src/i18n";
import { FooterLink } from "../footerLink/footerLink";
import { FooterLinksContainer } from "./styles";
import { getFooterLinks, getFooterLanguageConfig } from "./constants";

export const FooterLinks = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const FOOTER_LANGUAGE_CONFIG = getFooterLanguageConfig(handleChangeLanguage);
  const FOOTER_LINKS_CONFIG = getFooterLinks(navigate);

  return (
    <FooterLinksContainer>
      {lessThanLarge &&
        FOOTER_LANGUAGE_CONFIG.map((config, index) => (
          <FooterLink config={config} key={index} />
        ))}
      {FOOTER_LINKS_CONFIG.map((config, index) => (
        <FooterLink config={config} key={index} />
      ))}
    </FooterLinksContainer>
  );
};
