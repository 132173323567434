import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InventoryInformationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12px 20px 24px 84px",
  gap: 8,
  zIndex: 0,
  userSelect: "text",
  cursor: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "12px 20px 16px 24px",
  },
}));

export const InventoryInformationTitle = styled(Typography)(({ theme }) => ({
  color: "#25895C",
  textAlign: "center",
  fontSize: 16,
  marginLeft: -84,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const InventoryWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export const InventoryDescription = styled(Typography)(({ theme }) => ({
  color: isDarkMode(theme.palette.mode) ? "#FFF" : "#2F2F2F",
  width: "50%",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 400,
  },
}));
