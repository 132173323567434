import { Autocomplete } from "../../autocomplete/autocomplete";
import { IAutocomplete } from "../../autocomplete/types";
import { FormCardAutocompleteContainer } from "./styles";

export const FormCardAutocomplete = ({
  options,
  value,
  updateData,
  onChange,
  disabled,
  errorMessage,
  multiple,
  id,
}: IAutocomplete) => {
  return (
    <FormCardAutocompleteContainer>
      <Autocomplete
        multiple={multiple}
        options={options}
        value={value}
        updateData={updateData}
        onChange={onChange}
        disabled={disabled}
        errorMessage={errorMessage}
        id={id}
      />
    </FormCardAutocompleteContainer>
  );
};
