import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { Button } from "src/shared/components/button/button";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useGetSiteOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { OrganizationCard } from "../../components/organizationCard/organizationCard";
import { SitesList } from "../../components/sitesList/sitesList";
import { BackTo } from "../../shared/components/backTo/backTo";
import { getSitesItems } from "./constants";
import {
  AccountInformationBody,
  AccountInformationContainer,
  AccountInformationTitle,
  ButtonsWrapper,
} from "./styles";

export const AccountInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationUnitID } = useParams();

  const { data: organizationData, isLoading: isLoadingOrganizationData } =
    useGetOrganizationsByIDsQuery(organizationUnitID, {
      skip: !organizationUnitID,
    });

  const { data: organizationSites, isLoading: isLoadingOrganizationSites } =
    useGetSiteOrganizationsByIDsQuery(organizationUnitID, {
      skip: !organizationUnitID,
    });

  const organization = organizationData?.[0];

  const siteItems = getSitesItems(organizationSites);

  const backToHomeTab = () => {
    navigate(`/dashboard/${TABS_NAMES[0]}`);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingOrganizationData || isLoadingOrganizationSites}
      loader={<CircularProgress />}
      keepInView
    >
      <AccountInformationContainer>
        <CssBaseline />
        <Header />
        <AccountInformationBody>
          <BackTo text={t("back-to-home-tab")} handleBack={backToHomeTab} />
          <AccountInformationTitle>
            {t("manage-site-information-here")}
          </AccountInformationTitle>
          <OrganizationCard
            organizationName={organization?.name}
            totalSites={organization?.totalSites}
          />
          <SitesList
            organizationName={organization?.name}
            siteItems={siteItems}
          />
          <ButtonsWrapper>
            <Button
              text={t("back")}
              onClick={backToHomeTab}
              isGrey
              id="account-information-back"
            />
            <Button
              text={t("back-to-top")}
              onClick={scrollToTop}
              id="account-information-back-to-top"
            />
          </ButtonsWrapper>
        </AccountInformationBody>
      </AccountInformationContainer>
    </BlockUi>
  );
};
