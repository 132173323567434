import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IManifestFilters } from "./manifestFilters.types";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { MANIFEST_STORAGE_KEYS } from "src/components/manifestTable/manifestFilters/constants";
import { HAS_VALUES_OPTIONS } from "src/components/inspectionsTable/inspectionsFilters/constants";

const initialManifestFiltersState: IManifestFilters = {
  keyword: "",
  accounts: [],
  sites: [],
  facilities: [],
  statuses: [],
  compliances: [],
  startDate: dayjs(new Date()).subtract(365, "days"),
  endDate: dayjs(new Date()),
  discrepancy: false,
  isAfterClickOnWasteByMonthChart: false,
  isAfterClickOnManifestChart: false,
  hasAttachment: HAS_VALUES_OPTIONS.ALL,
  searchOrganization: "",
  selectedRow: undefined,
};

export const manifestFiltersSlice = createSlice({
  name: "manifestFilters",
  initialState: initialManifestFiltersState,
  reducers: {
    setManifestFiltersKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setManifestFiltersAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setManifestFiltersSearchOrganization: (state, action) => {
      state.searchOrganization = action.payload;
    },
    setManifestFilterScroll: (_, action) => {
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.SCROLL_ROW, action.payload);
    },
    setManifestFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST_ROW,
        state.selectedRow
      );
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.SELECTED_MANIFEST,
        state.selectedRow
      );
    },
    setManifestFilterSites: (state, action) => {
      state.sites = action.payload;
    },
    setManifestFilterFacilities: (state, action) => {
      state.facilities = action.payload;
    },
    setManifestFilterStatuses: (state, action) => {
      state.statuses = action.payload;
    },
    setManifestFilterCompliances: (state, action) => {
      state.compliances = action.payload;
    },
    setIsAfterClickOnWasteByMonthChart: (state, action) => {
      state.isAfterClickOnWasteByMonthChart = action.payload;
    },
    setIsAfterClickOnManifestChart: (state, action) => {
      state.isAfterClickOnManifestChart = action.payload;
    },
    setManifestFilterStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setManifestFilterEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setManifestFilterDiscrepancy: (state, action) => {
      state.discrepancy = action.payload;
    },
    setManifestFilterHasAttachment: (state, action) => {
      state.hasAttachment = action.payload;
    },
    saveToLocalStorageManifestFilters: (state) => {
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.KEYWORD, state.keyword);
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.ACCOUNT_ID_LIST, state.accounts);
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE,
        state.searchOrganization
      );
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.SITE_ID_LIST, state.sites);
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.FACILITY_ID_LIST,
        state.facilities
      );
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.STATUS_LIST, state.statuses);
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.COMPLIANCE_LIST,
        state.compliances
      );
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.START_DATE, state.startDate);
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.END_DATE, state.endDate);
      saveToLocalStorage(MANIFEST_STORAGE_KEYS.DISCREPANCY, state.discrepancy);
      saveToLocalStorage(
        MANIFEST_STORAGE_KEYS.HAS_ATTACHMENT,
        state.hasAttachment
      );
    },
    clearFromLocalStorageManifestFilters: (state) => {
      state.keyword = "";
      state.sites = [];
      state.facilities = [];
      state.accounts = [];
      state.statuses = [];
      state.compliances = [];
      state.startDate = dayjs(new Date()).subtract(365, "days");
      state.endDate = dayjs(new Date());
      state.discrepancy = false;
      state.hasAttachment = HAS_VALUES_OPTIONS.ALL;
      state.searchOrganization = "";
      state.selectedRow = undefined;
    },
  },
});

export const {
  reducer: manifestFiltersReducer,
  actions: manifestFiltersActions,
} = manifestFiltersSlice;
