import { IContact, IDepartment, ILocation } from "./types";

export const getLocationTypesOptions = (locationTypes: Array<ILocation>) => {
  return (
    locationTypes?.map(({ typeId, storageType }: ILocation) => ({
      id: typeId,
      val: storageType,
    })) || []
  );
};

export const getDepartmentOptions = (departments: Array<IDepartment>) => {
  return (
    departments?.map(({ id, departmentName }: IDepartment) => ({
      id,
      val: departmentName,
    })) || []
  );
};

export const getContactOptions = (contacts: Array<IContact>) => {
  return (
    contacts?.map(({ contactID, firstName, lastName }: IContact) => ({
      id: contactID,
      val: `${firstName} ${lastName}`,
    })) || []
  );
};

export const EditLocationFieldsWithoutIds = [
  "name",
  "barcode",
  "contactID",
  "piContactFullName",
  "departmentID",
  "departmentName",
  "locationType",
  "locationTypeID",
];

export const ADVISE_LOCATION = "advise_location";
