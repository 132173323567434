import BlockUi from "@availity/block-ui";
import { Box, styled } from "@mui/material";

export const ImageWrapper = styled(Box)(() => ({
  "& > img": {
    width: "100%",
  },
}));

export const ZoomedImageWidthDownloadContainer = styled(BlockUi)(() => ({
  width: "100%",
  minHeight: 100,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const DownloadIconWrapper = styled(Box)(() => ({
  cursor: "pointer",
  "& > svg > path": {
    fill: "#217BDB",
  },
}));
