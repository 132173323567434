import { Box, styled } from "@mui/material";

export const CustomPaginationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 16,
  [theme.breakpoints.down("md")]: {
    padding: 8,
  },
}));

export const PaginationNumberContainer = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

export const PaginationPageSizeWrapper = styled(Box)(() => ({
  "& > div": {
    width: 60,
  },
}));
