import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as ArrowDown } from "../../shared/assets/svgs/arrow-down.svg";
import question from "../../shared/assets/svgs/question.svg";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { List } from "src/shared/components/list/list";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  UserProfileArrowWrapper,
  UserProfileContainer,
  UserProfileEdit,
  UserProfileJobTitle,
  UserProfileLink,
  UserProfileName,
  UserProfileQuestion,
  UserProfileStatus,
  UserProfileWrapper,
  UserProfileMainWrapper,
  OrganizationName,
  OrganizationText,
  OrganizationContainer,
} from "./styles";

export const UserProfile = () => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const toggleOrganization = () => {
    setShowOrganization(!showOrganization);
  };

  const handleClickEdit = () => {
    navigate(`/edit-user/${accounts[0].username}`);
  };

  const {
    firstName = "",
    lastName = "",
    userName = "",
    jobTitle = "",
    organizationName = "",
    phone = "",
  } = userProfile;

  return (
    <UserProfileContainer>
      <Tooltip open={isOpenTooltip} setOpen={setIsOpenTooltip}>
        <List
          items={[
            t("access-to-more-account"),
            t("have-multiple-accounts"),
            t("selecting-favorite-options"),
          ]}
        />
      </Tooltip>
      <UserProfileMainWrapper>
        <UserProfileWrapper>
          <UserProfileName>
            {firstName && lastName && `${firstName} ${lastName}`}
          </UserProfileName>
          <UserProfileStatus>{userName && t("active-user")}</UserProfileStatus>
        </UserProfileWrapper>
        <UserProfileWrapper>
          <UserProfileQuestion
            src={question}
            alt=""
            onClick={() => setIsOpenTooltip(true)}
          />
          <UserProfileEdit onClick={handleClickEdit}>
            {t("edit")}
          </UserProfileEdit>
        </UserProfileWrapper>
      </UserProfileMainWrapper>
      <UserProfileLink>{userName}</UserProfileLink>
      <UserProfileJobTitle>{jobTitle}</UserProfileJobTitle>
      {showOrganization && (
        <OrganizationContainer>
          <OrganizationName>{organizationName}</OrganizationName>
          {/* <OrganizationText>25 Shattuck St</OrganizationText>
          <OrganizationText>Boston, MA 02115</OrganizationText> */}
          <OrganizationText>{phone}</OrganizationText>
        </OrganizationContainer>
      )}
      <UserProfileArrowWrapper open={showOrganization}>
        <ArrowDown onClick={toggleOrganization} />
      </UserProfileArrowWrapper>
    </UserProfileContainer>
  );
};
