import { useMemo } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { userActions } from "../../store/reducers/user/user.slice";
import { menuActions } from "../../store/reducers/menu/menu.slice";
import { snackBarActions } from "../../store/reducers/snackBar/snackBar.slice";
import { tokenActions } from "src/shared/store/reducers/token/token.slice";
import { unsavedChangesActions } from "src/shared/store/reducers/unsavedChanges/unsavedChanges.slice";
import { profileFiltersActions } from "src/shared/store/reducers/profileFilters/profileFilters.slice";
import { userFiltersActions } from "src/shared/store/reducers/userFilters/userFilters.slice";
import { manifestFiltersActions } from "src/shared/store/reducers/manifestFilters/manifestFilters.slice";
import { activeSectionActions } from "../../store/reducers/activeSection/activeSection.slice";
import { wasteProfilesFiltersActions } from "../../store/reducers/wasteProfilesFilters/wasteProfilesFilters.slice";
import { inventoryFiltersActions } from "../../store/reducers/inventoryFIlters/inventoryFilters.slice";
import { inspectionsFiltersActions } from "../../store/reducers/inspectionsFilters/inspectionsFilters.slice";
import { manageInspectionsFiltersActions } from "../../store/reducers/manageInspectionsFilters/manageInspectionsFilters.slice";
import { manageScheduledReportsFiltersActions } from "../../store/reducers/manageScheduledReportsFilters/manageScheduledReportsFilters.slice";
import { errorHandlingActions } from "../../store/reducers/errorHandling/errorHandling.slice";
import { inspectionNotificationFiltersActions } from "../../store/reducers/inspectionNotificationFilters/inspectionNotificationFilters.slice";
import { systemMessagesFiltersActions } from "../../store/reducers/systemMessagesFilters/systemMessagesFilters.slice";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const rootActions = {
  ...userActions,
  ...menuActions,
  ...tokenActions,
  ...snackBarActions,
  ...unsavedChangesActions,
  ...profileFiltersActions,
  ...userFiltersActions,
  ...manifestFiltersActions,
  ...activeSectionActions,
  ...wasteProfilesFiltersActions,
  ...inventoryFiltersActions,
  ...inspectionsFiltersActions,
  ...manageInspectionsFiltersActions,
  ...manageScheduledReportsFiltersActions,
  ...errorHandlingActions,
  ...inspectionNotificationFiltersActions,
  ...systemMessagesFiltersActions,
};

export const useAppActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
