import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { AccountCardContainer, AccountCardTitle } from "./styles";
import { IAccountCard } from "./types";

export const AccountCard = ({
  title,
  buttonText,
  img,
  onClick,
  id,
  disabled,
}: IAccountCard) => {
  return (
    <AccountCardContainer img={img}>
      <AccountCardTitle>{title}</AccountCardTitle>
      <FilledButton
        text={buttonText}
        onClick={onClick}
        id={id}
        disabled={disabled}
      />
    </AccountCardContainer>
  );
};
