import { Box, styled } from "@mui/material";

export const InspectionFilesUploaderContainer = styled(Box)(() => ({
  marginTop: 12,
  "& > a,button": {
    width: "fit-content",
  },
  "& > input": {
    display: "none",
  },
}));

export const InspectionFilesUploadedImageWrapper = styled(Box)(({ theme }) => ({
  marginTop: 12,
  display: "flex",
  flexDirection: "column",
  gap: 8,
  "& > img": {
    width: 75,
    height: 75,
    [theme.breakpoints.down("md")]: {
      width: 50,
      height: 50,
    },
  },
}));
