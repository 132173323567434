import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { FormCard } from "src/shared/components/formCard/formCard";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import FAQData from "../../faq.json";
import { Answer, FAQBody, FAQContainer, FAQTitle } from "./styles";

export const FAQ = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSecondMenuTab } = useAppActions();
  const userName = accounts[0]?.username;

  const { data: userProfileData } = useGetUserProfileByIDQuery(
    { id: userName },
    { skip: !userName }
  );

  const permissions = userProfileData?.userPermissions;

  const hasUserAdminPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.USER_ADMIN
  );

  const backToPreviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    setSecondMenuTab(1);

    return () => {
      setSecondMenuTab(undefined);
    };
  }, [setSecondMenuTab, hasUserAdminPermission]);

  return (
    <FAQContainer>
      <CssBaseline />
      <Header />
      <FAQBody>
        <BackTo
          text={t("back-to-previous-page")}
          handleBack={backToPreviousPage}
        />
        <FAQTitle>{t("faq-header")}</FAQTitle>
        <FAQTitle>{t("faq-title")}</FAQTitle>
        {FAQData?.map(({ question, answer }, index) => (
          <FormCard key={index} title={question} withCollapse>
            <Answer>{answer}</Answer>
          </FormCard>
        ))}
      </FAQBody>
    </FAQContainer>
  );
};
