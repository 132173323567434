import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { getFieldOption } from "src/components/detailInspectionCard/constants";
import { Input } from "src/shared/components/input/input";
import { TextArea } from "src/shared/components/textArea/textArea";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import {
  useGetScheduleOptionsQuery,
  useLazyNewInspectionTemplateQuery,
  useUpdateInspectionStatusMutation,
  useUpsertInspectionTemplateMutation,
} from "src/shared/store/api/inspection.api";
import { getLocationTypesOptions } from "src/components/editLocationParameters/constants";
import { COMPLIANCE_COLOR } from "src/components/compliance/constants";
import { Button } from "src/shared/components/button/button";
import { IOption } from "src/shared/components/select/types";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import { Alert } from "src/shared/components/alert/alert";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useGetAllLocationTypesQuery } from "src/shared/store/api/inspectionLocation.api";
import { useGetInspectionWithQuestionsTemplateByIDQuery } from "src/shared/store/api/inspection.api";
import { IQuestion } from "src/components/inspectionQuestions/types";
import { DeleteManageInspectionDialog } from "../deleteManageInspectionDialog/deleteInspectionDialog";
import { IManageInspectionQuestionFields } from "../manageInspectionQuestion/types";
import { ManageInspectionQuestion } from "../manageInspectionQuestion/manageInspectionQuestion";
import { ManageInspectionChangeStatusDialog } from "../manageInspectionChangeStatusDialog/manageInspectionChangeStatusDialog";
import { CopyManageInspectionDialog } from "../copyManageInspectionDialog/copyManageInspectionDialog";
import { ManageInspectionValidationSchema } from "./model";
import { getScheduleOptions } from "./constants";
import { IManageInspectionForm, IManageInspectionFormFields } from "./types";
import {
  ManageInspectionFormContainer,
  ManageInspectionFormFieldWrapper,
  ManageInspectionFormLabel,
  ManageInspectionFormTitle,
  ManageInspectionRatingWrapper,
  RatingWrapper,
  RatingDescription,
  Percent,
  ManageInspectionButtonWrapper,
  IndicatesRequiredField,
} from "./styles";

export const ManageInspectionForm = ({
  isEdit,
  isTemplate,
}: IManageInspectionForm) => {
  const { manageInspectionID } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { accounts } = useMsal();

  const [
    upsertInspectionTemplate,
    {
      data: upsertInspectionTemplateData,
      isSuccess: isSuccessUpsertInspectionTemplate,
      isLoading: isLoadingUpserInspectionTemplate,
    },
  ] = useUpsertInspectionTemplateMutation({});

  const {
    data: inspectionWithQuestions,
    isLoading: isLoadingInspectionsWithQuestions,
  } = useGetInspectionWithQuestionsTemplateByIDQuery(manageInspectionID);

  const [defaultOrganizationId, setDefaultOrganizationId] = useState();
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);
  const [
    resetInspectionWithQuestionsData,
    setResetInspectionWithQuestionsData,
  ] = useState(false);
  const [
    openDeleteManageInspectionDialog,
    setOpenDeleteManageInspectionDialog,
  ] = useState<boolean>(false);
  const [
    openManageInspectionChangeStatusDialog,
    setOpenManageInspectionChangeStatusDialog,
  ] = useState<boolean>(false);
  const [openCopyManageInspectionDialog, setOpenCopyManageInspectionDialog] =
    useState<boolean>(false);

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [getNewManageInspectionId] = useLazyNewInspectionTemplateQuery();

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inspectionTimeRequired: false,
    },
    resolver: yupResolver(ManageInspectionValidationSchema(t)),
    mode: "all",
  });

  const inspectionTimeRequired = watch("inspectionTimeRequired");
  const inspectionQuestions = watch("inspectionQuestions");
  const scheduleID = watch("scheduleID");
  const storageTypeID = watch("storageTypeID");

  const handleBackToManageInspections = useCallback(() => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      navigate("/manage-inspections");
      setChangedData(false);
    }
  }, [changedData, navigate, setChangedData, setIsOpenUnsavedChangedDialog]);

  const manageInspectionAccount = getFieldOption(
    watch,
    "accountOrganizationUnitID",
    "accountName"
  );

  const [
    updateInspectionStatus,
    {
      isLoading: isLoadingUpdateInspectionStatus,
      isSuccess: isSuccessUpdateInspectionStatus,
    },
  ] = useUpdateInspectionStatusMutation({});

  const { data: scheduleOptions } = useGetScheduleOptionsQuery({});

  const { data: locationTypes } = useGetAllLocationTypesQuery({});

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const { data: organizationOptions, isLoading: isLoadingOrganizationOptions } =
    useGetOrganizationBySearchValueQuery({
      searchValue: "",
    });

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const scheduleIDValue = [
    ...(scheduleOptions ? getScheduleOptions(scheduleOptions) : []),
  ]?.find((option) => option?.id === scheduleID) || { id: "", val: "" };

  const locationTypesValue = [
    ...(locationTypes ? getLocationTypesOptions(locationTypes) : []),
  ]?.find((option) => option?.id === storageTypeID) || { id: "", val: "" };

  const isDraftStatus =
    inspectionWithQuestions?.statusID === STATUS_ID_VALUES.DRAFT;
  const isPublishStatus =
    inspectionWithQuestions?.statusID === STATUS_ID_VALUES.PUBLISH;
  const isArchiveStatus =
    inspectionWithQuestions?.statusID === STATUS_ID_VALUES.ARCHIVE;

  const handleSaveManageInspection = handleSubmit((data) => {
    const statusID = data?.statusID || STATUS_ID_VALUES.DRAFT;

    if (isTemplate) {
      getNewManageInspectionId({}).then(
        ({ data: generateManageInspectionData }) => {
          const generatedInspectionId = generateManageInspectionData?.id;

          upsertInspectionTemplate({
            ...data,
            statusID: STATUS_ID_VALUES.DRAFT,
            inspectionID: generatedInspectionId,
          });
        }
      );
    } else {
      upsertInspectionTemplate({
        ...data,
        statusID,
        inspectionID: manageInspectionID,
      });
    }
  });

  const handleChanged = useCallback(() => {
    setChangedData(true);
  }, [setChangedData]);

  const handleChangeAccount = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    setValue("accountOrganizationUnitID", Number(inputValue?.id), {
      shouldValidate: true,
    });
    setValue("accountName", inputValue?.val, {
      shouldValidate: true,
    });
  };

  const handleChangeSchedule = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    setValue("scheduleID", inputValue?.id, {
      shouldValidate: true,
    });
  };

  const handleChangeInspectionTime = () => {
    handleChanged();
    setValue("inspectionTimeRequired", !inspectionTimeRequired);
  };

  const handleChangeLocationType = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    const inputValue = newInputValue as IOption;

    handleChanged();
    setValue("storageTypeID", inputValue?.id, {
      shouldValidate: true,
    });
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  const setInspectionQuestions = useCallback(
    (data: IManageInspectionQuestionFields) => {
      handleChanged();
      const newInspectionQuestions = [...(inspectionQuestions || []), data].map(
        (question: IManageInspectionQuestionFields, index: number) => ({
          ...question,
          questionNumber: index + 1,
        })
      );

      setValue("inspectionQuestions", newInspectionQuestions);
    },
    [setValue, handleChanged, inspectionQuestions]
  );

  const setManageInspectionValues = useCallback(
    (inspections: IManageInspectionQuestionFields[]) => {
      setValue("inspectionQuestions", inspections);
    },
    [setValue]
  );

  const handleUpdateStatusWithCheckChanges = (statusID: number) => () => {
    if (changedData) {
      setOpenManageInspectionChangeStatusDialog(true);
    } else {
      updateInspectionStatus({ inspectionID: manageInspectionID, statusID });
    }
  };

  const handleUpdateInspectionStatus = (statusID: number) => () => {
    updateInspectionStatus({ inspectionID: manageInspectionID, statusID });
  };

  const handleDeleteManageInspection = () => {
    setOpenDeleteManageInspectionDialog(true);
  };

  const handleCloseDeleteManageInspectionDialog = () => {
    setOpenDeleteManageInspectionDialog(false);
  };

  const handleCloseManageInspectionChangeStatusDialog = () => {
    setOpenManageInspectionChangeStatusDialog(false);
  };

  const handleManageInspectionDiscardChanges = () => {
    setResetInspectionWithQuestionsData(true);
  };

  const handleManageInspectionSaveAndPublish = () => {
    handleSaveManageInspection().then(() => {
      handleUpdateInspectionStatus(STATUS_ID_VALUES.PUBLISH)();
    });
  };

  const handleCloseCopyManageInspectionDialog = () => {
    setOpenCopyManageInspectionDialog(false);
  };

  const handleCopyInspection = () => {
    setOpenCopyManageInspectionDialog(true);
  };

  useEffect(() => {
    if (defaultOrganization?.[0]) {
      setValue("accountName", defaultOrganization?.[0]?.name as string);
      setValue(
        "accountOrganizationUnitID",
        defaultOrganization?.[0]?.organizationUnitID as number
      );
    }
  }, [defaultOrganization, defaultOrganizationId, setValue]);

  useEffect(() => {
    if (userProfile?.userAccounts && !isEdit) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [isEdit, userProfile]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      navigate("/manage-inspections");
      setChangedData(false);
      clearErrors();
      reset({});
    });
  }, [
    clearErrors,
    navigate,
    reset,
    setChangedData,
    setHandleBackUnsavedChanged,
  ]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => handleSaveManageInspection());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSaveManageInspection]);

  useEffect(() => {
    if (isSuccessUpsertInspectionTemplate || isSuccessUpdateInspectionStatus) {
      setChangedData(false);
      clearErrors();
      setIsOpenSuccessAlert(true);
    }
  }, [
    clearErrors,
    isSuccessUpsertInspectionTemplate,
    isSuccessUpdateInspectionStatus,
    reset,
    setChangedData,
  ]);

  useEffect(() => {
    if (inspectionWithQuestions) {
      for (const value of Object.keys(inspectionWithQuestions)) {
        if (value !== "accountOrganizationUnitID") {
          setValue(
            value as IManageInspectionFormFields,
            inspectionWithQuestions[value],
            {
              shouldValidate: true,
            }
          );
        }
      }

      if (inspectionWithQuestions?.accountOrganizationUnitID !== 0) {
        setDefaultOrganizationId(
          inspectionWithQuestions?.accountOrganizationUnitID
        );
      }

      setValue(
        "inspectionQuestions",
        inspectionWithQuestions?.inspectionQuestions?.map(
          (question: IQuestion) => ({
            ...question,
          })
        )
      );
      setResetInspectionWithQuestionsData(false);
    }
  }, [inspectionWithQuestions, setValue, resetInspectionWithQuestionsData]);

  useEffect(() => {
    if (upsertInspectionTemplateData) {
      navigate(
        `/manage-inspection/${upsertInspectionTemplateData?.inspectionID}`
      );
    }
  }, [upsertInspectionTemplateData, navigate]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingUpserInspectionTemplate ||
        isLoadingUpdateInspectionStatus ||
        isLoadingInspectionsWithQuestions ||
        isLoadingOrganizationOptions
      }
      loader={<CircularProgress />}
      keepInView
    >
      <ManageInspectionFormContainer>
        <ManageInspectionFormTitle>
          {t("manage-inspection")}
        </ManageInspectionFormTitle>
        <IndicatesRequiredField>
          <span>*</span> {t("indicates-a-required-field")}
        </IndicatesRequiredField>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("account")}
            <span>*</span>
          </ManageInspectionFormLabel>
          <Autocomplete
            options={organizationOptions ? organizationOptions : []}
            value={
              manageInspectionAccount?.val ? manageInspectionAccount : null
            }
            register={register("accountName", {
              onChange: handleChanged,
            })}
            onChange={handleChangeAccount}
            errorMessage={errors?.accountName?.message}
            disabled={(isPublishStatus || isArchiveStatus) && !isTemplate}
            id="manage-inspection-account-name"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("title")}
            <span>*</span>
          </ManageInspectionFormLabel>
          <Input
            register={register("title", { onChange: handleChanged })}
            errorMessage={errors?.title?.message}
            id="manage-inspection-title"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("description")}
          </ManageInspectionFormLabel>
          <TextArea
            register={register("description", { onChange: handleChanged })}
            id="manage-inspection-description"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("schedule")}
            <span>*</span>
          </ManageInspectionFormLabel>
          <Autocomplete
            options={scheduleOptions ? getScheduleOptions(scheduleOptions) : []}
            register={register("scheduleID", { onChange: handleChanged })}
            onChange={handleChangeSchedule}
            value={scheduleIDValue?.val ? scheduleIDValue : null}
            errorMessage={errors?.scheduleID?.message}
            id="manage-inspection-scheduleID"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("rating")}
            <span>*</span>
          </ManageInspectionFormLabel>
          <ManageInspectionRatingWrapper>
            <RatingWrapper>
              <RatingDescription color={COMPLIANCE_COLOR.green}>
                {`${t("good")}:`}
              </RatingDescription>
              <Input
                type="number"
                register={register("tresholdGood", { onChange: handleChanged })}
                errorMessage={errors?.tresholdGood?.message}
                id="manage-inspection-treshold-good"
              />
              <Percent>%</Percent>
            </RatingWrapper>
            <RatingWrapper>
              <RatingDescription color={COMPLIANCE_COLOR.yellow}>
                {`${t("substandard")}:`}
              </RatingDescription>
              <Input
                type="number"
                register={register("tresholdSubstandard", {
                  onChange: handleChanged,
                })}
                errorMessage={errors.tresholdSubstandard?.message}
                id="manage-inspection-treshold-substandard"
              />
              <Percent>%</Percent>
            </RatingWrapper>
            <RatingDescription color={COMPLIANCE_COLOR.red}>
              <span>{t("otherwise-inspection-is")}</span>
              {t("deficient")}
            </RatingDescription>
          </ManageInspectionRatingWrapper>
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("inspection-time-required")}
          </ManageInspectionFormLabel>
          <FormCardCheckbox
            checked={!!inspectionTimeRequired}
            onChange={handleChangeInspectionTime}
            id="manage-inspection-time-required"
          />
        </ManageInspectionFormFieldWrapper>
        <ManageInspectionFormFieldWrapper>
          <ManageInspectionFormLabel>
            {t("location-type")}
            <span>*</span>
          </ManageInspectionFormLabel>
          <Autocomplete
            options={
              locationTypes ? getLocationTypesOptions(locationTypes) : []
            }
            register={register("storageTypeID", { onChange: handleChanged })}
            onChange={handleChangeLocationType}
            value={locationTypesValue?.val ? locationTypesValue : null}
            errorMessage={errors?.storageTypeID?.message}
            id="manage-inspection-location-type"
          />
        </ManageInspectionFormFieldWrapper>
      </ManageInspectionFormContainer>
      <ManageInspectionQuestion
        inspectionQuestions={
          inspectionQuestions as IManageInspectionQuestionFields[]
        }
        setInspectionQuestions={setInspectionQuestions}
        setManageInspectionValues={setManageInspectionValues}
        hasEdit={
          isDraftStatus ||
          (!isEdit && !isPublishStatus && !isArchiveStatus) ||
          !!isTemplate
        }
      />
      <ManageInspectionButtonWrapper>
        {!isArchiveStatus && !isLoadingInspectionsWithQuestions && (
          <Button
            text={t("save")}
            onClick={handleSaveManageInspection}
            id="manage-inspection-save"
          />
        )}
        {isDraftStatus && !isTemplate && (
          <Button
            text={t("publish")}
            onClick={handleUpdateStatusWithCheckChanges(
              STATUS_ID_VALUES.PUBLISH
            )}
            id="manage-inspection-publish"
          />
        )}
        {isPublishStatus && !isTemplate && (
          <Button
            text={t("archive")}
            onClick={handleUpdateInspectionStatus(STATUS_ID_VALUES.ARCHIVE)}
            id="manage-inspection-archive"
          />
        )}
        {manageInspectionID && (
          <Button
            text={t("copy-inspection")}
            onClick={handleCopyInspection}
            id="manage-inspection-copy-inspection"
          />
        )}
        {isDraftStatus && !isTemplate && (
          <Button
            text={t("delete")}
            onClick={handleDeleteManageInspection}
            isError
            id="manage-inspection-delete"
          />
        )}
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleBackToManageInspections}
          id="manage-inspection-cancel"
        />
      </ManageInspectionButtonWrapper>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("successfully-saving")]}
        severity="success"
      />
      <DeleteManageInspectionDialog
        inspectionID={inspectionWithQuestions?.inspectionID}
        open={openDeleteManageInspectionDialog}
        handleClose={handleCloseDeleteManageInspectionDialog}
      />
      <ManageInspectionChangeStatusDialog
        open={openManageInspectionChangeStatusDialog}
        handleClose={handleCloseManageInspectionChangeStatusDialog}
        handleDiscardChanges={handleManageInspectionDiscardChanges}
        handleSaveAndPublish={handleManageInspectionSaveAndPublish}
      />
      <CopyManageInspectionDialog
        manageInspectionId={manageInspectionID}
        open={openCopyManageInspectionDialog}
        handleClose={handleCloseCopyManageInspectionDialog}
      />
    </BlockUi>
  );
};
