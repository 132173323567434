import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { CellClickedEvent } from "ag-grid-community";
import { Input } from "src/shared/components/input/input";
import { Select } from "src/shared/components/select/select";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { Button } from "src/shared/components/button/button";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useLazyGetChemicalsByKeywordQuery } from "src/shared/store/api/regulatory.api";
import { IOption } from "src/shared/components/select/types";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { IColumn } from "src/pages/userList/types";
import question from "src/shared/assets/svgs/question.svg";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { CreateChemicalDialog } from "../createChemicalDialog/createChemicalDialog";
import { ChemicalNameTooltip } from "../chemicalNameTooltip/chemicalNameTooltip";
import {
  ButtonWrapper,
  FieldWrapper,
  IndicatesRequiredField,
  SectionHeader,
  TextField,
} from "../../pages/newProfile/styles";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import { CreateChemicalTooltip } from "../createChemicalTooltip/createChemicalTooltip";
import {
  AVAILABLE_CONSTITUENTS_GRID_NAME,
  UNITS_OPTIONS,
  getColumnDefs,
  defaultColDef,
  getTotalValuesByField,
  paginationPageSize,
  PERCENT,
} from "./constants";
import { ChemicalCompositionValidationSchema } from "./model";
import { IChemical, IProfileWaste } from "./types";
import {
  AddButtonWrapper,
  ChemicalCompositionContainer,
  ChemicalFieldsContainer,
  ChemicalNameWrapper,
  CreateChemical,
  CreateChemicalWrapper,
  FieldsWrapper,
  Question,
  TotalText,
  ChemicalCompositionHeaderWrapper,
  ChemicalCompositionWrapper,
} from "./styles";

export const ChemicalComposition = () => {
  const { t } = useTranslation();
  const { profileID } = useParams();
  const navigate = useNavigate();
  const { history } = useHistory();
  const chemicalNameRef = useRef<HTMLInputElement>(null);
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { units: PERCENT },
    resolver: yupResolver(ChemicalCompositionValidationSchema(t)),
    mode: "onSubmit",
  });

  const units = watch("units");
  const chemical = watch("chemical");

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [chemicalSearch, setChemicalSearch] = useState("");
  const [profileWastes, setProfileWastes] = useState<Array<IProfileWaste>>([]);
  const [isOpenChemicalTooltip, setIsOpenChemicalTooltip] = useState(false);
  const [isCreateChemicalDialogOpen, setIsCreateChemicalDialogOpen] =
    useState(false);
  const [isOpenChemicalNameTooltip, setIsOpenChemicalNameTooltip] =
    useState(false);

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const [
    getChemicalByKeyword,
    { data: chemicals, isFetching: isFetchingChemical },
  ] = useLazyGetChemicalsByKeywordQuery({});

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  const handleChangedData = () => {
    setChangedData(true);
  };

  const updateChemicalData = (value: string) => {
    setChemicalSearch(value);
    setValue("chemical", undefined as unknown as IOption);
    getChemicalByKeyword({
      keyword: value,
    });
  };

  const handleChangeChemical = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setValue("chemical", newInputValue as IOption, { shouldValidate: true });
    handleChangedData();
  };

  const handleAdd = handleSubmit(
    ({ minimumValue, maximumValue, chemical, units }) => {
      setProfileWastes([
        {
          chemicalUniqueID: chemical?.id,
          savedChemicalName: chemical?.val,
          maximumValue,
          minimumValue,
          units,
        },
        ...profileWastes,
      ]);
      setValue("maximumValue", undefined as unknown as number);
      setValue("minimumValue", undefined as unknown as number);
      reset({
        chemical: {},
        units: "%",
      });
      setChemicalSearch("");

      if (chemicalNameRef?.current) {
        chemicalNameRef?.current?.focus();
      }
    }
  );

  const handleDelete = (data: IProfileWaste) => {
    const newProfiles = profileWastes?.filter(
      (el: IProfileWaste) => el !== data
    );
    setProfileWastes(newProfiles);
    setChangedData(true);
  };

  const onCellClicked = (params: CellClickedEvent) => {
    const target = params?.event?.target as HTMLButtonElement;
    const column = params.column as unknown as IColumn;

    if (column?.colId === "action" && target?.dataset?.action) {
      const action = target?.dataset?.action;
      if (action === "delete" && params?.data) {
        handleDelete(params?.data);
      }
    }
  };

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  const handleContinue = useCallback(() => {
    upsertProfileRequest({
      id: profileID,
      profileWastes: profileWastes?.map(
        ({
          minimumValue,
          maximumValue,
          chemicalUniqueID,
          units,
        }: IProfileWaste) => ({
          minimumValue: String(minimumValue),
          maximumValue: String(maximumValue),
          chemicalUniqueID,
          units,
        })
      ),
      sectionStep: SECTION.GENERAL_CHARACTERISTICS,
    });
    setChangedData(false);
  }, [profileID, profileWastes, setChangedData, upsertProfileRequest]);

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToDashboard();
      });
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  const handleOpenCreateChemicalDialog = () => {
    setIsCreateChemicalDialogOpen(true);
  };

  const handleCloseCreateChemicalDialog = () => {
    setIsCreateChemicalDialogOpen(false);
  };

  const handleOpenChemicalTooltip = () => {
    setIsOpenChemicalTooltip(true);
  };

  const handleOpenChemicalNameTooltip = () => {
    setIsOpenChemicalNameTooltip(true);
  };

  const totalMin = getTotalValuesByField(
    profileWastes as Array<{ [key: string]: string }>,
    "minimumValue"
  );
  const totalMax = getTotalValuesByField(
    profileWastes as Array<{ [key: string]: string }>,
    "maximumValue"
  );

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    setProfileWastes(wasteProfileData?.profileWastes);
  }, [setValue, wasteProfileData]);

  const setChemicalValue = (id: string, val: string) => {
    setValue("chemical", { id, val }, { shouldValidate: true });
    setChemicalSearch(val);
    getChemicalByKeyword({
      keyword: val,
    });
  };

  const columnDefs = getColumnDefs(isActiveWasteProfile, t);

  const chemicalsOptions = useMemo(() => {
    return (
      chemicals?.map((chemical: IChemical) => ({
        id: chemical?.id,
        val: chemical?.chemicalName,
      })) || []
    );
  }, [chemicals]);

  return (
    <ChemicalCompositionContainer>
      <ChemicalCompositionWrapper>
        <ChemicalCompositionHeaderWrapper>
          <SectionHeader>{t("chemical-composition-section")}</SectionHeader>
          <IndicatesRequiredField>
            <span>*</span> {t("indicates-a-required-field")}
          </IndicatesRequiredField>
        </ChemicalCompositionHeaderWrapper>
        {!isActiveWasteProfile && (
          <ChemicalFieldsContainer>
            <FieldWrapper>
              <TextField>
                {`${t("chemical-name-or-cas")}`}
                <span> *</span>
                <Question
                  src={question}
                  alt=""
                  onClick={handleOpenChemicalNameTooltip}
                />
                <ChemicalNameTooltip
                  open={isOpenChemicalNameTooltip}
                  setOpen={setIsOpenChemicalNameTooltip}
                />
              </TextField>
              <ChemicalNameWrapper>
                <Autocomplete
                  inputRef={chemicalNameRef}
                  placeholder={t("chemical-name-or-cas")}
                  options={chemicalsOptions}
                  value={chemical || null}
                  updateData={updateChemicalData}
                  onChange={handleChangeChemical}
                  clearOnBlur={false}
                  errorMessage={
                    errors?.chemical?.id?.message || errors?.chemical?.message
                  }
                  filterOptions={(options) => options}
                  noOptionText={
                    !chemicalSearch
                      ? t("start-typing")
                      : isFetchingChemical
                      ? ""
                      : undefined
                  }
                  id="chemical-composition-name-or-cas"
                />
              </ChemicalNameWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("min")}`}
                <span> *</span>
              </TextField>
              <FieldsWrapper>
                <Input
                  placeholder={t("min")}
                  errorMessage={errors?.minimumValue?.message}
                  register={{
                    ...register("minimumValue", {
                      onChange: handleChangedData,
                    }),
                  }}
                  id="chemical-composition-min"
                />
              </FieldsWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("max")}`}
                <span> *</span>
              </TextField>
              <FieldsWrapper>
                <Input
                  placeholder={t("max")}
                  errorMessage={errors?.maximumValue?.message}
                  register={{
                    ...register("maximumValue", {
                      onChange: handleChangedData,
                    }),
                  }}
                  id="chemical-composition-max"
                />
              </FieldsWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("unit")}`}
                <span> *</span>
              </TextField>
              <FieldsWrapper>
                <Select
                  value={units || ""}
                  errorMessage={errors?.units?.message}
                  options={UNITS_OPTIONS}
                  register={{
                    ...register("units", { onChange: handleChangedData }),
                  }}
                  id="chemical-composition-units"
                />
              </FieldsWrapper>
            </FieldWrapper>
            <AddButtonWrapper>
              <Button
                text={t("add")}
                onClick={handleAdd}
                id="chemical-composition-add"
              />
            </AddButtonWrapper>
          </ChemicalFieldsContainer>
        )}
        <Box>
          {!isActiveWasteProfile && (
            <CreateChemicalWrapper>
              <CreateChemical onClick={handleOpenCreateChemicalDialog}>
                {t("create-chemical")}
              </CreateChemical>

              <Question
                src={question}
                alt=""
                onClick={handleOpenChemicalTooltip}
              />
              <CreateChemicalTooltip
                open={isOpenChemicalTooltip}
                setOpen={setIsOpenChemicalTooltip}
              />
            </CreateChemicalWrapper>
          )}
          <AgGrid
            title={t("available-constituents")}
            onCellClicked={onCellClicked}
            gridName={AVAILABLE_CONSTITUENTS_GRID_NAME}
            rowData={profileWastes}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            paginationPageSize={paginationPageSize}
            noRowsText={t("no-available-constituents-display")}
            enableRangeSelection={true}
            allowContextMenuWithControlKey={true}
            isLoadingData={false}
          />
          {profileWastes?.length > 0 && (
            <TotalText>
              {t("total-chemical", { min: totalMin, max: totalMax })}
            </TotalText>
          )}
        </Box>
      </ChemicalCompositionWrapper>
      <ButtonWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCancel}
          id="chemical-composition-cancel"
        />
        {!isActiveWasteProfile && (
          <Button
            text={t("continue")}
            onClick={handleContinue}
            id="chemical-composition-continue"
          />
        )}
      </ButtonWrapper>
      <CreateChemicalDialog
        open={isCreateChemicalDialogOpen}
        handleClose={handleCloseCreateChemicalDialog}
        chemicalName={chemicalSearch}
        setChemicalValue={setChemicalValue}
      />
    </ChemicalCompositionContainer>
  );
};
