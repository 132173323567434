import React from "react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { Header } from "src/components/header/header";
import { CircularProgress, CssBaseline } from "@mui/material";
import { Button } from "src/shared/components/button/button";
import { useGetManifestByIDQuery } from "src/shared/store/api/manifest.api";
import { useNavigate, useParams } from "react-router-dom";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { HazardousWasteForm } from "../../components/hazardousWasteForm/hazardousWasteForm";
import { FormCard } from "src/shared/components/formCard/formCard";
import { GeneratorForm } from "../../components/generatorForm/generatorForm";
import { TransporterForm } from "../../components/transporterForm/transporterForm";
import { FacilityForm } from "../../components/facilityForm/facilityForm";
import { GeneratorSiteForm } from "../../components/generatorSiteForm/generatorSiteForm";
import { EManifestForm } from "../../components/EManifestForm/EManifestForm";
import { SignatureForm } from "../../components/signatureForm/signatureForm";
import { DiscrepancyForm } from "../../components/discrepancyForm/discrepancyForm";
import { ManifestLinesTable } from "../../components/manifestLinesTable/manifestLinesTable";
import { ITransporter } from "../../components/transporterForm/types";
import { UploadedFilesForm } from "../../components/uploadedFilesForm/uploadedFilesForm";
import { ITransporter as ITransporterConfig } from "./types";
import {
  getHazardousWasteConfig,
  getGeneratorConfig,
  getGeneratorSiteConfig,
  getTransportersConfig,
  getFacilityConfig,
  getEManifestConfig,
  getTransportersSignatureConfig,
} from "./constants";
import {
  BackIcon,
  BackText,
  BackWrapper,
  ButtonsWrapper,
  SingleManifestBody,
  SingleManifestContainer,
  SingleManifestTitle,
} from "./styles";

export const SingleManifest = () => {
  const { t } = useTranslation();
  const { manifestID } = useParams();
  const navigate = useNavigate();
  const { data: manifestData, isFetching: isFetchingManifestByID } =
    useGetManifestByIDQuery({ manifestID });

  const hazardousWasteConfig = getHazardousWasteConfig(t, manifestData);
  const eManifestConfig = getEManifestConfig(t, manifestData?.hasEmanifest);
  const generatorConfig = getGeneratorConfig(t, manifestData?.account);
  const generatorSiteConfig = getGeneratorSiteConfig(t, manifestData?.site);
  const transportersConfig = getTransportersConfig(
    t,
    manifestData?.transporters
  );
  const facilityConfig = getFacilityConfig(t, manifestData?.facility);
  const transportersSignatureConfig = getTransportersSignatureConfig(
    manifestData?.transporters
  );

  const handleClickBackToManifestTab = () => {
    navigate(`/dashboard/${TABS_NAMES[2]}`);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <SingleManifestContainer>
      <CssBaseline />
      <Header />
      <SingleManifestBody>
        <BlockUi
          blocking={isFetchingManifestByID}
          tag="div"
          loader={<CircularProgress />}
          keepInView
        >
          <BackWrapper onClick={handleClickBackToManifestTab}>
            <BackIcon src={backArrow} alt="" />
            <BackText>{t("back-to-manifest-tab")}</BackText>
          </BackWrapper>
          <SingleManifestTitle>
            {t("review-manifest-details")}
          </SingleManifestTitle>
          <HazardousWasteForm
            hazardousWasteConfig={hazardousWasteConfig}
            manifest={manifestData}
          />
          <EManifestForm
            eManifestConfig={eManifestConfig}
            manifestId={manifestData?.id}
          />
          <GeneratorForm generatorConfig={generatorConfig} />
          <GeneratorSiteForm generatorSiteConfig={generatorSiteConfig} />
          <FormCard title={t("transporters")} withCollapse>
            <React.Fragment>
              {transportersConfig?.map(
                (transporterConfig: Array<ITransporter>, index) => (
                  <TransporterForm
                    key={`${index}-transporters`}
                    transporterConfig={transporterConfig}
                  />
                )
              )}
            </React.Fragment>
          </FormCard>
          <FacilityForm facilityConfig={facilityConfig} />
          <ManifestLinesTable manifestLines={manifestData?.manifestLines} />
          <SignatureForm
            title={t("generators-signature")}
            signature={manifestData?.authorizedSignature}
            date={manifestData?.dateSigned}
            withCollapse
          />
          <FormCard title={t("transporter-signatures")} withCollapse>
            <React.Fragment>
              {transportersSignatureConfig?.map(
                (
                  { signature, date, sequenceNumber }: ITransporterConfig,
                  index
                ) => (
                  <SignatureForm
                    key={`${index}-transporter-signatures`}
                    title={t("transporter-index-signature", {
                      index: sequenceNumber,
                    })}
                    signature={signature}
                    date={date}
                  />
                )
              )}
            </React.Fragment>
          </FormCard>
          <DiscrepancyForm discrepancy={manifestData?.discrepancyDescription} />
          <UploadedFilesForm blobFilePaths={manifestData?.blobFilePaths} />
          <ButtonsWrapper>
            <Button
              text={t("back")}
              onClick={handleClickBack}
              isGrey
              id="single-manifest-back"
            />
          </ButtonsWrapper>
        </BlockUi>
      </SingleManifestBody>
    </SingleManifestContainer>
  );
};
