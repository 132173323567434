import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/redux";
import { useHistory } from "./historyProvider";
import { TABS_DASHBOARD_CONFIG } from "src/pages/dashboard/dashboard";

export const HistoryContainer = () => {
  const { push, history } = useHistory();
  const location = useLocation();
  const tab = useAppSelector((state) => state.menuReducer.tab);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const updateHistoryData = (value: string) => {
    if (!isFirstRender || !history?.length) {
      push(value);
    } else {
      setIsFirstRender(false);
    }
  };

  useEffect(() => {
    updateHistoryData(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (tab) {
      updateHistoryData(TABS_DASHBOARD_CONFIG?.[tab as number]?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return <Outlet />;
};
