import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/shared/components/button/button";
import { useGetWasteProfileRequestByIDQuery } from "src/shared/store/api/wasteProfile.api";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useLazyGetAttachmentByDocumentIDAndFileIDQuery } from "src/shared/store/api/attachments.api";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import {
  ButtonWrapper,
  SectionDivider,
  SectionHeader,
} from "../../pages/newProfile/styles";
import { getProfileReviewConfig } from "./constants";
import { IReview } from "./types";
import {
  HeaderSubSection,
  ReviewContainer,
  ReviewInformationWrapper,
  ReviewDescription,
  ReviewHeader,
  ReviewItemsWrapper,
  ReviewValuesWrapper,
} from "./styles";

export const Review = ({
  upsertProfileRequest,
  isLoadingUpsertProfile,
}: IReview) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profileID } = useParams();
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");

  const {
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  } = useAppActions();

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const {
    data: wasteProfileData,
    isLoading: isLoadingWasteProfile,
    isFetching: isFetchingWasteProfile,
  } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isDraftProfile = wasteProfileData?.statusID === STATUS_ID_VALUES.DRAFT;

  const [getAttachment] = useLazyGetAttachmentByDocumentIDAndFileIDQuery();

  const handleGetAttachment = (fileName: string, fileID: string) => () =>
    getAttachment({
      fileName,
      docID: profileID,
      fileID,
    });

  const profileReviewConfig = getProfileReviewConfig(
    wasteProfileData,
    t,
    handleGetAttachment
  );

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  const handleContinue = useCallback(() => {
    upsertProfileRequest({
      id: profileID,
      sectionStep: SECTION.SUBMIT,
    });
  }, [profileID, upsertProfileRequest]);

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(backToDashboard);
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  return (
    <ReviewContainer>
      <SectionHeader>{t("review-section")}</SectionHeader>
      {profileReviewConfig.map((config, indexProfileReview) => (
        <React.Fragment key={`${indexProfileReview}-profileReview`}>
          <SectionDivider />
          <HeaderSubSection>{config?.header}</HeaderSubSection>
          <ReviewInformationWrapper>
            {config?.items?.map(({ values }, indexConfig) => (
              <ReviewItemsWrapper
                itemsSize={values?.length}
                key={`${indexConfig}-config`}
              >
                {values?.map(({ values: items, title }, indexValue) => (
                  <ReviewValuesWrapper
                    itemsSize={values?.length}
                    itemNumber={indexValue}
                    key={`${indexValue}-value`}
                  >
                    <ReviewHeader key={`${indexValue}-review-header`}>
                      {title}
                    </ReviewHeader>
                    {items?.map((value, indexItem) => (
                      <ReviewDescription key={`${indexItem}-item-value`}>
                        {value}
                      </ReviewDescription>
                    ))}
                  </ReviewValuesWrapper>
                ))}
              </ReviewItemsWrapper>
            ))}
          </ReviewInformationWrapper>
        </React.Fragment>
      ))}
      <ButtonWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCancel}
          id="review-cancel"
        />
        {isDraftProfile &&
          !isLoadingWasteProfile &&
          !isFetchingWasteProfile && (
            <Button
              text={t("submit")}
              onClick={handleContinue}
              disabled={isLoadingUpsertProfile}
              id="review-submit"
            />
          )}
      </ButtonWrapper>
    </ReviewContainer>
  );
};
