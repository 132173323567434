import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { debounce } from "lodash";
import { Input } from "src/shared/components/input/input";
import { ReactComponent as FilterIcon } from "src/shared/assets/svgs/filter-icon.svg";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  useGetBuildingOrganizationsBySiteParentIDsQuery,
  useGetInventoryLocationsQuery,
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationSitesQuery,
  useGetOrganizationsByIDsQuery,
  useGetRoomsOrganizationsBySiteParentIDsQuery,
} from "src/shared/store/api/organization.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { Button } from "src/shared/components/button/button";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IOrganization } from "src/components/quickOverview/types";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { IOption } from "src/shared/components/select/types";
import { getListOfIdsFromObject } from "src/components/inspectionsTable/inspectionsFilters/constants";
import { IBuilding } from "src/components/buildingsList/types";
import { IRoom } from "src/pages/buildingInformation/types";
import { ILocation } from "src/components/locationList/locationBody/types";
import { ICustomInventoryFilters, IListOfOrganizationUnitID } from "./types";
import { getListOfOrganizationUnitIDsFromObject } from "./constants";
import {
  ActionsWrapper,
  FilterIconWrapper,
  FilterWrapper,
  FiltersContainer,
  FiltersWrapper,
  Label,
  InventoryFiltersContainer,
  InventoryFiltersWrapper,
  InventorySearchWrapper,
} from "./styles";

export const CustomInventoryFilters = ({
  gridApi,
  getInventoryData,
}: ICustomInventoryFilters) => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const {
    setInventoryFiltersAccounts,
    setInventoryFiltersKeyword,
    setInventoryFiltersSearchOrganization,
    setInventoryPickupStartDate,
    setInventoryPickupEndDate,
    setInventoryFilterSites,
    setInventoryFilterBuildings,
    setInventoryFilterRooms,
    setInventoryFilterLocations,
  } = useAppActions();
  const {
    accounts: inventoryAccounts,
    keyword,
    searchOrganization,
    pickupStartDate,
    pickupEndDate,
    sites,
    buildings,
    rooms,
    locations,
  } = useAppSelector((state) => state.inventoryFiltersReducer);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();
  const accountIds = useMemo(
    () => inventoryAccounts?.map((organization) => organization?.id),
    [inventoryAccounts]
  );
  const buildingIds = useMemo(
    () => buildings?.map((building) => building?.id),
    [buildings]
  );
  const roomIds = useMemo(() => rooms?.map((room) => room?.id), [rooms]);
  const locationIds = useMemo(
    () => locations?.map((location) => location?.id),
    [locations]
  );
  const sitesIds = useMemo(() => sites?.map((site) => site?.id), [sites]);

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const { data: organizationSites, isFetching: isFetchingSiteOptions } =
    useGetOrganizationSitesQuery(
      {
        organizationId: inventoryAccounts?.map(
          (organization: IOrganization) => organization?.id
        ),
      },
      {
        skip: !inventoryAccounts?.length,
      }
    );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const getInventoryByKeyword = useCallback(
    (newKeyword: string) => {
      getInventoryData({
        accountIDList: inventoryAccounts?.length ? accountIds : [],
        siteIDList: sitesIds,
        keyword: newKeyword,
        pickupStartDate,
        pickupEndDate,
        buildingIDList: buildingIds?.length ? buildingIds : [],
        roomIDList: roomIds?.length ? roomIds : [],
        storageIDList: locationIds?.length ? locationIds : [],
      });
      gridApi?.paginationGoToPage(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getInventoryData,
      accountIds,
      sitesIds,
      pickupStartDate,
      pickupEndDate,
      buildingIds,
      roomIds,
      locationIds,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTrigger = useCallback(
    debounce((newKeyword) => getInventoryByKeyword(newKeyword), 5000),
    [getInventoryByKeyword]
  );

  const onChangeSearchInventory = (e: ChangeEvent<HTMLInputElement>) => {
    const searchInventoryValue = e?.target?.value;
    setInventoryFiltersKeyword(searchInventoryValue);
    debouncedTrigger(searchInventoryValue);
  };

  const handleFilters = () => setIsOpenFilters(!isOpenFilters);

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInventoryFiltersAccounts(newInputValue as Array<IOrganization>);
  };

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const { data: buildingsOptions, isFetching: isFetchingBuildingOptions } =
    useGetBuildingOrganizationsBySiteParentIDsQuery(
      getListOfIdsFromObject(sites),
      {
        skip: !sites?.length,
      }
    );

  const { data: roomsOptions, isFetching: isFetchingRoomOptions } =
    useGetRoomsOrganizationsBySiteParentIDsQuery(
      getListOfOrganizationUnitIDsFromObject(
        buildings as unknown as IListOfOrganizationUnitID[]
      ),
      {
        skip: !buildings?.length,
      }
    );

  const { data: locationsOptions, isFetching: isFetchingLocationOptions } =
    useGetInventoryLocationsQuery(
      getListOfOrganizationUnitIDsFromObject(
        rooms as unknown as IListOfOrganizationUnitID[]
      ),
      {
        skip: !rooms?.length,
      }
    );

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (defaultOrganization && !inventoryAccounts?.length) {
      const defaultAccountList = [
        {
          id: defaultOrganization?.[0]?.organizationUnitID,
          val: defaultOrganization?.[0]?.name,
        },
      ];
      setInventoryFiltersAccounts(defaultAccountList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  const handleClearFilters = () => {
    gridApi?.setFilterModel(null);
    setInventoryFiltersKeyword("");
    setInventoryFiltersSearchOrganization("");
    setInventoryFiltersAccounts([
      {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      },
    ]);
    setInventoryFilterSites([]);
    setInventoryFilterBuildings([]);
    setInventoryFilterRooms([]);
    setInventoryFilterLocations([]);
    getInventoryData({
      accountIDList: [defaultOrganization?.[0]?.organizationUnitID],
      keyword,
      pickupStartDate,
      pickupEndDate,
      buildingIDList: [],
      roomIDList: [],
      storageIDList: [],
    });
  };

  const handleApplyFilters = () => {
    const body = {
      accountIDList: inventoryAccounts?.length ? accountIds : [],
      siteIDList: sitesIds,
      keyword,
      pickupStartDate,
      pickupEndDate,
      buildingIDList: buildingIds?.length ? buildingIds : [],
      roomIDList: roomIds?.length ? roomIds : [],
      storageIDList: locationIds?.length ? locationIds : [],
    };
    getInventoryData(body);
    gridApi?.paginationGoToPage(0);
  };

  useEffect(() => {
    if (defaultOrganizationId) {
      getInventoryData({
        accountIDList: inventoryAccounts?.length
          ? accountIds
          : defaultOrganizationId
          ? [defaultOrganizationId]
          : [],
        siteIDList: sitesIds,
        keyword,
        pickupStartDate,
        pickupEndDate,
        buildingIDList: buildingIds?.length ? buildingIds : [],
        roomIDList: roomIds?.length ? roomIds : [],
        storageIDList: locationIds?.length ? locationIds : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganizationId]);

  const handleChangePickupStartDate = (date: unknown) => {
    if (date) {
      setInventoryPickupStartDate(date as Date);
    }
  };

  const handleChangePickupEndDate = (date: unknown) => {
    if (date) {
      setInventoryPickupEndDate(date as Date);
    }
  };

  const handleChangeSite = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInventoryFilterSites(newInputValue as Array<IOption>);
    setInventoryFilterBuildings([]);
    setInventoryFilterRooms([]);
    setInventoryFilterLocations([]);
  };

  const handleChangeBuildings = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInventoryFilterBuildings(newInputValue as Array<IOption>);
    setInventoryFilterRooms([]);
    setInventoryFilterLocations([]);
  };

  const handleChangeRooms = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInventoryFilterRooms(newInputValue as Array<IOption>);
    setInventoryFilterLocations([]);
  };

  const handleChangeLocations = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInventoryFilterLocations(newInputValue as Array<IOption>);
  };

  return (
    <BlockUi
      tag="div"
      blocking={
        isFetchingSiteOptions ||
        isFetchingBuildingOptions ||
        isFetchingRoomOptions ||
        isFetchingLocationOptions
      }
      loader={<CircularProgress />}
      keepInView
    >
      <InventoryFiltersContainer>
        <InventorySearchWrapper>
          <Input
            value={keyword}
            placeholder={t("search-in-inventory")}
            onChange={onChangeSearchInventory}
            InputProps={{
              endAdornment: (
                <FilterIconWrapper position="end">
                  <FilterIcon onClick={handleFilters} />
                </FilterIconWrapper>
              ),
            }}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                getInventoryData({
                  accountIDList: inventoryAccounts?.length
                    ? accountIds
                    : defaultOrganizationId
                    ? [defaultOrganizationId]
                    : [],
                  siteIDList: sitesIds,
                  keyword,
                  pickupStartDate,
                  pickupEndDate,
                  buildingIDList: buildingIds?.length ? buildingIds : [],
                  roomIDList: roomIds?.length ? roomIds : [],
                  storageIDList: locationIds?.length ? locationIds : [],
                });
              }
            }}
            id="search-in-inventory"
          />
        </InventorySearchWrapper>
        {isOpenFilters && (
          <>
            <InventoryFiltersWrapper>
              {t("please-use-all-filters")}
            </InventoryFiltersWrapper>
            <FiltersContainer>
              <FiltersWrapper>
                {userProfile?.userAccounts?.length > 1 && (
                  <FilterWrapper>
                    <Label>{t("account")}</Label>
                    <Autocomplete
                      options={organizationOptions ? organizationOptions : []}
                      value={inventoryAccounts || ""}
                      updateData={(value: string) => {
                        setInventoryFiltersSearchOrganization(value);
                      }}
                      onChange={handleChangeOrganization}
                      multiple
                      id="custom-inventory-filters-account"
                    />
                  </FilterWrapper>
                )}
                <FilterWrapper>
                  <Label>{t("site")}</Label>
                  <Autocomplete
                    value={sites || ""}
                    onChange={handleChangeSite}
                    options={organizationSites ? organizationSites : []}
                    disabled={!organizationSites}
                    multiple
                    id="custom-inventory-filters-site"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("pickup-date-from")}</Label>
                  <DatePicker
                    value={pickupStartDate}
                    onChange={handleChangePickupStartDate}
                    maxDate={pickupEndDate}
                    id="custom-inventory-filters-pickup-date-from"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("pickup-date-to")}</Label>
                  <DatePicker
                    value={pickupEndDate}
                    onChange={handleChangePickupEndDate}
                    minDate={pickupStartDate}
                    id="custom-inventory-filters-pickup-date-to"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("building")}</Label>
                  <Autocomplete
                    value={buildings || null}
                    onChange={handleChangeBuildings}
                    options={
                      buildingsOptions?.length
                        ? buildingsOptions?.map(
                            ({ organizationUnitID, id, name }: IBuilding) => ({
                              id,
                              val: name,
                              organizationUnitID,
                            })
                          )
                        : []
                    }
                    disabled={!buildingsOptions?.length || !sites?.length}
                    multiple
                    id="custom-inventory-filters-buildings"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("room")}</Label>
                  <Autocomplete
                    value={rooms || null}
                    onChange={handleChangeRooms}
                    options={
                      roomsOptions?.length
                        ? roomsOptions?.map(
                            ({ organizationUnitID, id, name }: IRoom) => ({
                              id,
                              val: name,
                              organizationUnitID,
                            })
                          )
                        : []
                    }
                    disabled={!roomsOptions?.length || !buildings?.length}
                    multiple
                    id="custom-inventory-filters-room"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("location")}</Label>
                  <Autocomplete
                    value={locations || null}
                    onChange={handleChangeLocations}
                    options={
                      locationsOptions?.length
                        ? locationsOptions?.map(
                            ({ organizationUnitID, id, name }: ILocation) => ({
                              id,
                              val: name,
                              organizationUnitID,
                            })
                          )
                        : []
                    }
                    disabled={!locationsOptions?.length || !rooms?.length}
                    multiple
                    id="custom-inventory-filters-location"
                  />
                </FilterWrapper>
              </FiltersWrapper>
            </FiltersContainer>
            <ActionsWrapper>
              <Button
                isGrey
                text={t("clear-filters")}
                onClick={handleClearFilters}
                id="custom-inventory-filters-clear-filters"
              />
              <Button
                isGrey
                text={t("cancel")}
                onClick={handleFilters}
                id="custom-inventory-filters-cancel"
              />
              <Button
                text={t("apply")}
                onClick={handleApplyFilters}
                id="custom-inventory-filters-apply"
              />
            </ActionsWrapper>
          </>
        )}
      </InventoryFiltersContainer>
    </BlockUi>
  );
};
