import { ListOfItems } from "src/shared/components/listOfItems/listOfItems";
import { RoomsTitle } from "./roomsTitle/roomsTitle";
import { RoomsBody } from "./roomsBody/roomsBody";
import { IRoomList } from "./types";

export const RoomsList = ({ roomsItems }: IRoomList) => {
  return roomsItems?.length > 0 ? (
    <ListOfItems
      title={<RoomsTitle />}
      body={<RoomsBody roomsItems={roomsItems} />}
    />
  ) : null;
};
