import { Box, Typography, styled } from "@mui/material";
import { IRatingDescription } from "./types";

export const ManageInspectionFormContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  border: "1px solid #D9D9D9",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  width: "100%",
  borderRadius: 16,
  padding: 16,
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
  },
}));

export const ManageInspectionFormFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  "& > div,textArea": {
    display: "flex",
    maxWidth: 400,
    width: "100%",
    flexWrap: "wrap",
    "& .MuiFormHelperText-root": {
      textWrap: "nowrap",
      top: 24,
    },
    "& > div,textArea": {
      "& .MuiFormHelperText-root": {
        textWrap: "nowrap",
        top: 24,
      },
      height: 24,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ManageInspectionFormLabel = styled(Typography)(({ theme }) => ({
  minWidth: 140,
  color: "#B1B1B1",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  display: "flex",
  gap: 8,
  "& > span": {
    color: "#F41808",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    minWidth: 120,
  },
}));

export const ManageInspectionFormTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontWeight: 500,
  lineHeight: "23px",
  letterSpacing: "0em",
  [theme.breakpoints.down("md")]: {
    fontSize: 18,
  },
}));

export const ManageInspectionRatingWrapper = styled(Box)(() => ({
  display: "flex",
  height: "auto !important",
  gap: 16,
  width: "100%",
  maxWidth: "none !important",
}));

export const RatingWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  "& > div > div": {
    height: 24,
    width: 50,
  },
}));

export const RatingDescription = styled(Typography)<IRatingDescription>(
  ({ theme, color }) => ({
    color: color,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    "& > span": {
      color: "#B1B1B1",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  })
);

export const Percent = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const ManageInspectionButtonWrapper = styled(Box)(() => ({
  marginTop: 24,
  display: "flex",
  justifyContent: "end",
  gap: 8,
  "& > button": {
    width: "fit-content",
  },
}));

export const IndicatesRequiredField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  "& > span": {
    color: "#F41808",
  },
}));
