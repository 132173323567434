import { Box, Typography, styled } from "@mui/material";

export const LabelWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  gap: 6,
}));

export const DialogLabel = styled(Typography)(({ theme }) => ({
  color: "#B1B1B1",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginLeft: 4,
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));

export const CreateChemicalDialogContentContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: 500,
    [theme.breakpoints.down("md")]: {
      gap: 12,
      width: "auto",
    },
  })
);

export const SendBtnWrapper = styled(Box)(() => ({
  width: 300,
  display: "flex",
  gap: 16,
}));
