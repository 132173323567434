import { createSlice } from "@reduxjs/toolkit";
import { IErrorHandlingState } from "./errorHandling.types";

const initialErrorHandlingState: IErrorHandlingState = {
  body: "",
};

export const errorHandlingSlice = createSlice({
  name: "errorHandling",
  initialState: initialErrorHandlingState,
  reducers: {
    setErrorHandlingBody: (state, action) => {
      state.body = action.payload;
    },
  },
});

export const { reducer: errorHandlingReducer, actions: errorHandlingActions } =
  errorHandlingSlice;
