import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useGetSubscriptionsByCurrentUserQuery } from "src/shared/store/api/report.api";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { DeleteSubscriptionDialog } from "../deleteSubscriptionDialog/deleteSubscriptionDialog";
import { ISubscription } from "./types";
import {
  getColumnDefs,
  MANAGE_SCHEDULED_REPORTS_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS,
  getContextMenuItems,
  rowSelection,
} from "./constants";

export const ManageScheduledReportsTable = () => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const navigate = useNavigate();
  const { history } = useHistory();

  const {
    setManageScheduledReportsFiltersSelectedRow,
    setManageScheduledReportsFilterScroll,
  } = useAppActions();

  const [selectedSchedule, setSelectedSchedule] = useState<ISubscription>();
  const [deleteSubscriptionSelected, setDeleteSubscriptionSelected] =
    useState<ISubscription>();
  const [openDeleteSubscriptionDialog, setOpenDeleteSubscriptionDialog] =
    useState<boolean>(false);

  const previousLocation = history?.[history.length - 2] as string;
  const previousEditSchedule =
    previousLocation && previousLocation?.includes("/edit-schedule");

  const contextMenuItems = getContextMenuItems(
    setDeleteSubscriptionSelected,
    setOpenDeleteSubscriptionDialog,
    t,
    mode === DARK
  );

  const scrollToTop = getFromLocalStorage(
    MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SCROLL_ROW
  );

  const { data: subscriptionsReports } = useGetSubscriptionsByCurrentUserQuery(
    {}
  );

  const columnDefs = getColumnDefs(t);

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedSchedule(selectedRows[0]);
  }, []);

  const onCellClicked = (params: CellClickedEvent) => {
    setManageScheduledReportsFilterScroll(window.scrollY);
    setManageScheduledReportsFiltersSelectedRow(params?.data);
    if (
      params?.data?.subscriptionIDField ===
      selectedSchedule?.subscriptionIDField
    ) {
      navigate(`/edit-schedule/${params?.data?.subscriptionIDField}`);
    }
  };

  const modifiedSubscriptionReports = useMemo(
    () =>
      subscriptionsReports?.map((subscription: ISubscription) => ({
        id: subscription?.subscriptionIDField,
        ...subscription,
      })),
    [subscriptionsReports]
  );

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousEditSchedule) {
      window.scroll(0, scrollToTop);
    }
  }, [scrollToTop, subscriptionsReports, previousEditSchedule]);

  useEffect(() => {
    if (previousEditSchedule) {
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS,
        getFromLocalStorage(
          MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS_ROW
        )
      );
    } else {
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS,
        {}
      );
    }
  }, [previousEditSchedule]);

  const handleCloseDeleteSubscriptionDialog = () => {
    setOpenDeleteSubscriptionDialog(false);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS,
        null
      );
      saveToLocalStorage(
        MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS.SELECTED_MANAGE_SCHEDULED_REPORTS_ROW,
        null
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <AgGrid
        title={t("manage-scheduled-reports")}
        gridName={MANAGE_SCHEDULED_REPORTS_GRID_NAME}
        rowData={modifiedSubscriptionReports}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        noRowsText={t("no-manage-scheduled-reports-display")}
        cellSelection={true}
        rowGroupPanelShow={rowGroupPanelShow}
        rowSelection={rowSelection}
        isLoadingData={false}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        withSavingPage={!!previousEditSchedule}
        withSavingSearch={!!previousEditSchedule}
        getContextMenuItems={contextMenuItems}
      />
      <DeleteSubscriptionDialog
        subscriptionID={deleteSubscriptionSelected?.subscriptionIDField}
        open={openDeleteSubscriptionDialog}
        handleClose={handleCloseDeleteSubscriptionDialog}
      />
    </>
  );
};
