import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "src/shared/components/list/list";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { Compliance } from "../compliance/compliance";
import { COMPLIANCE_COLOR_TEXT_ARRAY } from "../compliance/constants";
import { IComplianceArray, IManifestTooltip } from "./types";
import {
  ListWrapper,
  ComplianceWrapper,
  ComplianceText,
  ComplianceContainer,
} from "./styles";

export const ManifestTooltip = ({ open, setOpen }: IManifestTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <React.Fragment>
        <ListWrapper>
          <List items={[t("type-a-keyword-manifest")]} />
        </ListWrapper>
        <ListWrapper>
          <List
            items={[
              t("use-as-many-filters-as-needed"),
              t("default-date-range-is-365"),
              t("export-manifest-list-tool"),
            ]}
          />
        </ListWrapper>
        <ComplianceContainer>
          {COMPLIANCE_COLOR_TEXT_ARRAY.map(
            ({ color, text }: IComplianceArray, index) => (
              <ComplianceWrapper key={`${index}-compliance-tooltip`}>
                <Compliance color={color} />
                <ComplianceText>{t(text)}</ComplianceText>
              </ComplianceWrapper>
            )
          )}
        </ComplianceContainer>
      </React.Fragment>
    </Tooltip>
  );
};
