import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useGetLocationByIdAndDocTypeQuery } from "src/shared/store/api/organization.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { EditLocationParameters } from "../editLocationParameters/editLocationParameters";
import { IEditLocation } from "./types";
import {
  EditLocationContainer,
  EditLocationBody,
  EditLocationTitle,
} from "./styles";

export const EditLocation = ({ isEdit }: IEditLocation) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locationID, roomID, docType } = useParams();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const { data: location } = useGetLocationByIdAndDocTypeQuery(
    { locationID, docType },
    {
      skip: !locationID || !isEdit,
    }
  );

  const handleCancel = () => {
    if (location?.parentID) {
      navigate(`/room-information/${location?.parentID}`);
    } else if (roomID) {
      navigate(`/room-information/${roomID}`);
    }
  };

  const backToRoom = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCancel();
    }
  };

  return (
    <EditLocationContainer>
      <CssBaseline />
      <Header />
      <EditLocationBody>
        <BackTo text={t("back-to-room")} handleBack={backToRoom} />
        <EditLocationTitle>
          {t(isEdit ? "manage-the-location-here" : "create-a-new-location")}
        </EditLocationTitle>
        <EditLocationParameters isEdit={isEdit} />
      </EditLocationBody>
    </EditLocationContainer>
  );
};
