import { useTranslation } from "react-i18next";
import { FollowUs } from "src/components/followUs/followUs";
import { FooterLinks } from "src/components/footerLinks/footerLinks";
import { TermsOfUsePdf } from "../termsOfUse/termsOfUse";
import { Logo } from "../logo/logo";
import {
  FooterContainer,
  FooterCopyright,
  FooterText,
  Wrapper,
  LogoWrapper,
  FooterMainWrapper,
} from "./styles";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <Wrapper>
        <FooterMainWrapper>
          <Wrapper>
            <LogoWrapper>
              <Logo />
              <FooterText>{t("complete-online-waste")}</FooterText>
              <TermsOfUsePdf />
            </LogoWrapper>
          </Wrapper>
          <FooterLinks />
          <FollowUs />
        </FooterMainWrapper>
        <FooterCopyright>{t("copyright")}</FooterCopyright>
      </Wrapper>
    </FooterContainer>
  );
};
