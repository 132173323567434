import { Box, Typography, styled } from "@mui/material";

export const CustomerInformationContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const ContactInformationTitle = styled(Typography)(({ theme }) => ({
  marginTop: 40,
  marginBottom: -16,
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginTop: 0,
  },
}));

export const CustomerInformationWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    borderRadius: 16,
    border: "1px solid #D9D9D9",
    padding: "16px 8px",
  },
}));

export const ContactInformationWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
    borderRadius: 16,
    border: "1px solid #D9D9D9",
    padding: "16px 8px",
  },
}));

export const CustomerInformationHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

export const CustomerInformationFieldWrapper = styled(Box)(({ theme }) => ({
  marginTop: 16,
  maxWidth: 368,
  display: "flex",
  flexDirection: "column",
  gap: 8,
  "& .MuiFormHelperText-root": {
    top: "32px !important",
    marginLeft: 0,
    [theme.breakpoints.down("md")]: {
      top: "28px !important",
    },
  },
  "& > div > div": {
    height: 32,
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
    "& > div": {
      height: 32,
      [theme.breakpoints.down("md")]: {
        height: 28,
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
    flexDirection: "row",
  },
}));

export const CustomerInformationTextField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  alignItems: "center",
  display: "flex",
  gap: 8,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "& > span": {
    color: "#F41808",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontWeight: 400,
    width: 96,
  },
}));
