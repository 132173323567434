import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const WasteStreamDescriptionContainer = styled(Box)(() => ({
  marginTop: 70,
}));

export const WasteStreamRadioGroup = styled(RadioGroup)(({ theme }) => ({
  borderRadius: 6,
  background: isDarkMode(theme.palette.mode) ? "#35363A" : "#F0F1F2",
  padding: 16,
}));

export const WasteStreamFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    width: "fit-content",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
  })
);

export const WasteStreamRadio = styled(Radio)(() => ({
  color: "#DDD",
  "&.Mui-checked": {
    color: "#217BDB",
  },
  "& > span > svg": {
    width: 16,
    height: 16,
  },
  "& > input": {
    backgroundColor: "#FFF",
  },
}));

export const WasteStreamDescriptionWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    borderRadius: 16,
    border: "1px solid #D9D9D9",
    padding: "16px 8px",
  },
}));

export const WasteStreamDescriptionHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));
