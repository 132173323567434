import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "src/shared/components/input/input";
import { IListOfItems } from "./types";
import { ListOfItemsContainer } from "./styles";

export const ListOfItems = ({
  title,
  body,
  keyword,
  setKeyword,
  withSearch,
  id,
}: IListOfItems) => {
  const { t } = useTranslation();

  const onChangeSearchItems = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedKeyword = e?.target?.value;

    if (setKeyword) {
      setKeyword(updatedKeyword);
    }
  };

  return (
    <ListOfItemsContainer>
      {title}
      {withSearch && (
        <Input
          value={keyword}
          placeholder={t("search-in-sites")}
          onChange={onChangeSearchItems}
          id={`search-in-sites-${id}`}
        />
      )}
      {body}
    </ListOfItemsContainer>
  );
};
