import { Box, ImageList as MuiImageList, styled } from "@mui/material";

export const QuestionPromptWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

export const ImageList = styled(MuiImageList)(() => ({
  width: "100%",
}));
