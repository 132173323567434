import { createSlice } from "@reduxjs/toolkit";
import { IActiveSectionState } from "./activeSection.types";

const initialMenuState: IActiveSectionState = {
  activeSection: 0,
  setActiveSection: () => {},
  lastSectionStep: 0,
  setLastSectionStep: () => {},
};

export const activeSectionSlice = createSlice({
  name: "activeSection",
  initialState: initialMenuState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setLastSectionStep: (state, action) => {
      state.lastSectionStep = action.payload;
    },
  },
});

export const { reducer: activeSectionReducer, actions: activeSectionActions } =
  activeSectionSlice;
