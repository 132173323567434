import { Box, styled } from "@mui/material";

export const PrintToEXcelButtonWrapper = styled(Box)(() => ({
  width: 160,
}));

export const PrintToExcelButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));
