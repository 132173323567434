import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IInspectionNotificationInformationText } from "./types";

export const InspectionNotificationFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
}));

export const InspectionNotificationFormLabel = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "23.44px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  })
);

export const InspectionNotificationFormFieldWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  flexDirection: "column",
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const InspectionNotificationCheckboxFieldWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    gap: 8,
    alignItems: "center",
    "& > div,textArea": {
      display: "flex",
      maxWidth: 400,
      width: "100%",
      flexWrap: "wrap",
      "& .MuiFormHelperText-root": {
        textWrap: "nowrap",
        top: 24,
      },
      "& > div,textArea": {
        "& .MuiFormHelperText-root": {
          textWrap: "nowrap",
          top: 24,
        },
        height: 24,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })
);

export const InspectionNotificationCheckboxLabel = styled(Typography)(
  ({ theme }) => ({
    minWidth: 140,
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    display: "flex",
    gap: 8,
    "& > span": {
      color: "#F41808",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      minWidth: 120,
    },
  })
);

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginLeft: 14,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const InspectionNotificationInformationWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    gap: 16,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 0,
    },
  })
);

export const InspectionNotificationInformationText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isBold",
})<IInspectionNotificationInformationText>(({ theme, isBold }) => ({
  color: theme.palette.text.secondary,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: isBold ? 700 : 400,
  lineHeight: "140%",
  width: "50%",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    width: "100%",
    lineHeight: "140%",
  },
}));

export const InspectionNotificationScheduleWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      gap: 8,
    },
  })
);
