import * as yup from "yup";

export const GuestUserProfileValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    firstName: yup.string().required(t("required-field")),
    lastName: yup.string().required(t("required-field")),
    organizationName: yup.string().required(t("required-field")),
    jobTitle: yup.string().required(t("required-field")),
    phone: yup.string().phone().required(t("required-field")),
  });
