import React from "react";
import { useTranslation } from "react-i18next";
import { TabPanel } from "./tabPanel/tabPanel";
import { StyledTabs, Tab } from "./styles";
import { IConfigTabs, ITabs } from "./types";

export const Tabs = ({ config, orientation, value, setValue }: ITabs) => {
  const { t } = useTranslation();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <StyledTabs
        onChange={handleChange}
        value={value}
        orientation={orientation}
        TabIndicatorProps={{
          style: { transition: "none" },
        }}
      >
        {config?.map(({ label }: IConfigTabs, index: number) => (
          <Tab
            label={t(label)}
            value={index}
            key={index}
            orientation={orientation}
            disableRipple
          />
        ))}
      </StyledTabs>
      {orientation !== "vertical" &&
        config?.map(({ children }: IConfigTabs, index: number) => (
          <TabPanel index={index} value={value} key={index}>
            {children}
          </TabPanel>
        ))}
    </React.Fragment>
  );
};
