import { styled } from "@mui/system";

export const Ul = styled("ul")(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const LiItem = styled("li")(() => ({
  fontFamily: "Roboto",
}));
