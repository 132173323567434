import { Box, Button, Typography, styled } from "@mui/material";

export const InputWrapper = styled(Box)(({ theme }) => ({
  padding: "16px 8px",
  [theme.breakpoints.down("md")]: {
    padding: "20px 8px",
  },
  "& > div > div > input": {
    [theme.breakpoints.up("md")]: {
      height: 32,
    },
  },
}));

export const AgGridTitleWrapper = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #25895C",
  background: "rgba(37, 137, 92, 0.20)",
  padding: "8px 24px 8px 16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "12.5px 8px",
  },
}));

export const AgGridTitle = styled(Typography)(({ theme }) => ({
  color: "#25895C",
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const AgGridWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "10px 0 0",
}));

export const AgGridContainer = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  border: "1px solid #25895C",
  marginBottom: 80,
  marginTop: 24,
  [theme.breakpoints.down("lg")]: {
    marginBottom: 40,
  },
  ".ag-cell-wrapper": {
    width: "100%",
  },
}));

export const PrintToExcelButton = styled(Button)(() => ({
  height: 28,
  width: 110,
  border: "1px solid #25895C",
  borderRadius: 14,
  color: "#25895C",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  textTransform: "initial",
}));
