import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { ScheduledReportConfiguration } from "../../components/scheduledReportConfiguration/scheduledReportConfiguration";
import { ISetSchedule } from "./types";
import {
  SetScheduleBody,
  SetScheduleContainer,
  SetScheduleTitle,
} from "./styles";

export const SetSchedule = ({ isEdit }: ISetSchedule) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const handleClickBack = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <SetScheduleContainer>
      <CssBaseline />
      <Header />
      <SetScheduleBody>
        <BackTo text={t("back-to-reports")} handleBack={handleClickBack} />
        <SetScheduleTitle>
          {t("generate-schedule-report-here")}
        </SetScheduleTitle>
        <ScheduledReportConfiguration isEdit={isEdit} />
      </SetScheduleBody>
    </SetScheduleContainer>
  );
};
