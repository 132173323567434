import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormCardTitle } from "src/shared/components/formCard/styles";
import question from "src/shared/assets/svgs/question.svg";
import { EManifestTooltip } from "../EManifestTooltip/EManifestTooltip";
import { EManifestCustomTitleWrapper, Question } from "./styles";

export const EManifestCustomTitle = () => {
  const { t } = useTranslation();
  const [isOpenEManifestTooltip, setIsOpenEManifestTooltip] = useState(false);

  const handleOpenEManifestTooltip = () => {
    setIsOpenEManifestTooltip(true);
  };

  return (
    <EManifestCustomTitleWrapper>
      <FormCardTitle>{t("e-manifest")}</FormCardTitle>
      <Question src={question} alt="" onClick={handleOpenEManifestTooltip} />
      <EManifestTooltip
        open={isOpenEManifestTooltip}
        setOpen={setIsOpenEManifestTooltip}
      />
    </EManifestCustomTitleWrapper>
  );
};
