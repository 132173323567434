import { Box, styled } from "@mui/material";
import { IFavorite } from "./types";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const FacilitiesAndAccountsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
    marginRight: 16,
  },
}));

export const FavoriteContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFavorite",
})<IFavorite>(({ theme, isFavorite }) => ({
  display: "flex",
  alignItems: "center",
  gap: 10,
  "& > svg": {
    width: 18,
    height: 18,
    flexShrink: 0,
    cursor: "pointer",
    "& > path": {
      fill: isDarkMode(theme.palette.mode) && !isFavorite ? "#FFF" : "",
    },
  },
}));
