import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { ManifestNotificationForm } from "../../components/manifestNotificationForm/manifestNotificationForm";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  WasteNotificationsBody,
  WasteNotificationsContainer,
  WasteNotificationsTitle,
} from "./styles";

export const ManifestNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const backToManifestTab = () => {
    navigate(`/dashboard/${TABS_NAMES[2]}`);
  };

  const handleClickBackToManifestTab = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToManifestTab();
    }
  };

  return (
    <WasteNotificationsContainer>
      <CssBaseline />
      <Header />
      <WasteNotificationsBody>
        <BackTo
          text={t("back-to-manifest-tab")}
          handleBack={handleClickBackToManifestTab}
        />
        <WasteNotificationsTitle>
          {t("configure-manifest-return-notification-here")}
        </WasteNotificationsTitle>
        <WasteNotificationsTitle>
          {t("notification-form-description")}
        </WasteNotificationsTitle>
        <ManifestNotificationForm />
      </WasteNotificationsBody>
    </WasteNotificationsContainer>
  );
};
