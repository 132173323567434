import { ITabPanel } from "./types";

export const TabPanel = (props: ITabPanel) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};
