import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { InspectionNotificationForm } from "src/components/inspectionNotificationForm/inspectionNotificationForm";
import {
  InspectionNotificationBody,
  InspectionNotificationContainer,
  InspectionNotificationTitle,
} from "./styles";

export const InspectionNotification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const backToInspectionsNotificationsTab = () => {
    navigate(`/inspections-notifications`);
  };

  const handleClickBackToInspectionsNotificationsTab = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToInspectionsNotificationsTab();
    }
  };

  return (
    <InspectionNotificationContainer>
      <CssBaseline />
      <Header />
      <InspectionNotificationBody>
        <BackTo
          text={t("back-to-inspections-notifications")}
          handleBack={handleClickBackToInspectionsNotificationsTab}
        />
        <InspectionNotificationTitle>
          {t("configure-inspection-notification-here")}
        </InspectionNotificationTitle>
        <InspectionNotificationTitle>
          {t("inspection-notification-description")}
        </InspectionNotificationTitle>
        <InspectionNotificationForm />
      </InspectionNotificationBody>
    </InspectionNotificationContainer>
  );
};
