import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AllSeriesType,
  ChartsTooltip,
  PiePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { CircularProgress } from "@mui/material";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { ReactComponent as Close } from "src/shared/assets/svgs/close.svg";
import { ChartLegend } from "src/shared/components/chartLegend/chartLegend";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import {
  inventoryChartHeight,
  getInventoryAgingChartStatistics,
  getInventoryAgingTotalCounts,
  highlightScope,
} from "./constants";
import { IInventoryAging, IInventoryAgingChart } from "./types";
import {
  CloseIconWrapper,
  InventoryAgingContainer,
  ResponsiveWrapper,
} from "./styles";

export const InventoryAgingChart = ({
  inventoryAgingData,
  isLoadingInventoryAgingData,
  handleDelete,
}: IInventoryAgingChart) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsAfterClickOnInventoryChart } = useAppActions();

  const totalInspectionCount = getInventoryAgingTotalCounts(inventoryAgingData);

  const series = [
    {
      type: "pie",
      data: getInventoryAgingChartStatistics(inventoryAgingData),
      highlightScope: highlightScope,
      innerRadius: 30,
      outerRadius: 70,
    },
  ] as AllSeriesType[];

  const inventoryAgingChartLegendConfig = inventoryAgingData?.map(
    ({
      complianceColor,
      complianceDescription,
      totalContainerQuantity,
    }: IInventoryAging) => ({
      description: complianceDescription,
      value: `${totalContainerQuantity}`,
      color: complianceColor,
    })
  );

  const handleClickInventoryAgingChart = () => {
    setIsAfterClickOnInventoryChart(true);
    navigate(`/dashboard/${TABS_NAMES[5]}`);
  };

  return (
    <InventoryAgingContainer
      tag="div"
      blocking={isLoadingInventoryAgingData}
      loader={<CircularProgress />}
      keepInView
      onClick={handleClickInventoryAgingChart}
    >
      <CloseIconWrapper onClick={handleDelete}>
        <Close />
      </CloseIconWrapper>
      <ResponsiveWrapper>
        <ResponsiveChartContainer series={series} height={inventoryChartHeight}>
          <PiePlot />
          <ChartsTooltip trigger="item" />
        </ResponsiveChartContainer>
      </ResponsiveWrapper>
      <ChartLegend
        title={t("inventory")}
        secondTitle={totalInspectionCount ? ` (${totalInspectionCount})` : ""}
        showLegend={!!inventoryAgingData}
        chartLegendInformationConfig={inventoryAgingChartLegendConfig}
      />
    </InventoryAgingContainer>
  );
};
