import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, RadioGroup } from "@mui/material";
import dayjs from "dayjs";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Input } from "src/shared/components/input/input";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { TimePicker } from "src/shared/components/timePicker/timePicker";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { MultiEmail } from "src/shared/components/multiEmail/multiEmail";
import { TextArea } from "src/shared/components/textArea/textArea";
import { Select } from "src/shared/components/select/select";
import { Button } from "src/shared/components/button/button";
import {
  useGetReportParametersBySubscriptionIDQuery,
  useGetReportParametersQuery,
  useGetSubscriptionByIDQuery,
  useUpsertSubscriptionMutation,
} from "src/shared/store/api/report.api";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { DeleteSubscriptionDialog } from "src/components/deleteSubscriptionDialog/deleteSubscriptionDialog";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import {
  useGetBuildingOrganizationsBySiteParentIDsQuery,
  useGetLocationsOrganizationsByRoomParentIDsQuery,
  useGetRoomsOrganizationsBySiteParentIDsQuery,
  useGetSiteOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { Alert } from "src/shared/components/alert/alert";
import {
  DATE_OPTION_CUSTOM,
  DAYS_OF_WEEK_PARAMS,
  DYNAMIC_VALUES,
  MONTH_OF_YEAR,
  ON_WEEK_OF_MONTH_OPTIONS,
  PARAMETER_TYPE,
  RENDER_FORMAT_OPTIONS,
  SCHEDULE_TYPE,
  TYPE_OBJECT,
  getNextRunDateRangeEndDate,
  getNextRunDateRangeStartDate,
  getNextScheduledMonthRun,
  getNextScheduledRun,
  getNextScheduledWeekRun,
  REPORT_PARAMS_FIELDS,
} from "./constants";
import { ScheduledReportConfigurationValidationSchema } from "./model";
import {
  IDynamicOptions,
  IReportParameter,
  IReportParamsErrorsMessages,
  IScheduleReportConfigurationFields,
  IScheduledReportConfiguration,
  IScheduledReportConfigurationFields,
  ISubscriptionData,
  IValidValues,
  IWeekDaysType,
} from "./types";
import {
  FormControlLabel,
  Radio,
  RadioGroupWrapper,
  ScheduleReportConfigurationFieldWrapper,
  ScheduleReportConfigurationMonthScheduleWrapper,
  ScheduleReportConfigurationLabel,
  ScheduledReportConfigurationContainer,
  DailyRadioGroupWrapper,
  CheckboxWrapper,
  Label,
  InputWrapper,
  FrequencyContainer,
  FollowingDaysContainer,
  ActionsWrapper,
  SelectWrapper,
  ScheduleReportFieldText,
  ReportParametersWrapper,
  WeekDaysPickerWrapper,
  ErrorText,
} from "./styles";

export const ScheduledReportConfiguration = ({
  isEdit,
}: IScheduledReportConfiguration) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const { accountIDs, reportType, subscriptionID } = useParams();

  const [openDeleteSubscriptionDialog, setOpenDeleteSubscriptionDialog] =
    useState<boolean>(false);
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const reportPathArray = decodeURIComponent(reportType as string)?.split("/");
  const reportModule = reportPathArray?.[reportPathArray?.length - 2];
  const reportName = reportPathArray?.[reportPathArray?.length - 1];

  const {
    data: reportParametersByModuleAndName,
    isLoading: isLoadingReportParameters,
    isFetching: isFetchingReportParameters,
  } = useGetReportParametersQuery(
    {
      reportModule,
      reportName,
    },
    { skip: isEdit }
  );

  const {
    data: reportParametersById,
    isLoading: isLoadingReportParametersById,
    isFetching: isFetchingReportParametersById,
  } = useGetReportParametersBySubscriptionIDQuery(subscriptionID, {
    skip: !subscriptionID,
  });

  const reportParameters = isEdit
    ? reportParametersById
    : reportParametersByModuleAndName;

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<IScheduleReportConfigurationFields>({
    defaultValues: {
      type: SCHEDULE_TYPE.DAY,
      subject: "@ReportName was executed at @ExecutionTime",
      body: "See attached report automatically generated by Triumvirate Environmental’s ADVISE system.",
      renderFormat: "PDF",
      dayType: "following",
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      endDate: null,
    },
    resolver: yupResolver(
      ScheduledReportConfigurationValidationSchema(t, reportParameters)
    ),
    mode: "onSubmit",
  });

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const [
    upsertSubscription,
    {
      isLoading: isLoadingUpsertSubscriptions,
      isSuccess: isSuccessUpsertSubscriptions,
      isError: isErrorUpsertSubscription,
    },
  ] = useUpsertSubscriptionMutation({});

  const [
    upsertSubscriptionAfterRequest,
    { isSuccess: isSuccessUpsertSubscriptionsAfterRequest },
  ] = useUpsertSubscriptionMutation({});

  const { data: subscriptionData, isLoading: isLoadingSubscriptionData } =
    useGetSubscriptionByIDQuery(subscriptionID, {
      skip: !isEdit,
    });

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const type = watch("type") || "";
  const description = watch("description") || "";
  const startDateTime = watch("startDateTime");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const renderFormat = watch("renderFormat");
  const onWeekOfMonth = watch("onWeekOfMonth");
  const emails = watch("emailDistributionList") || [];
  const reportParams = watch("reportParams");
  const siteList = watch("reportParams.siteID");
  const buildingList = watch("reportParams.buildingID");
  const roomList = watch("reportParams.roomID");
  const isMonthType = type === SCHEDULE_TYPE.MONTH;
  const isDayType = type === SCHEDULE_TYPE.DAY;
  const dateOption = watch("reportParams.dateOption");
  const paramsStartDate = watch("reportParams.startDate");
  const paramsEndDate = watch("reportParams.endDate");

  const startDateTimeFromRequest =
    subscriptionData?.scheduleDefinition?.startDateTime;
  const daysOfWeekFromRequest =
    subscriptionData?.scheduleDefinition?.item?.daysOfWeek;
  const whichWeekFromRequest =
    subscriptionData?.scheduleDefinition?.item?.whichWeek;
  const typeFromRequest = subscriptionData?.scheduleDefinition?.item?.$type;
  const weeksIntervalFromRequest =
    subscriptionData?.scheduleDefinition?.item?.weeksInterval;
  const dateOptionFromRequest = subscriptionData?.reportParams?.find(
    (el: IReportParameter) => el?.name === REPORT_PARAMS_FIELDS.DATE_OPTION
  )?.value;
  const endDateSpecifiedFromRequest =
    subscriptionData?.scheduleDefinition?.endDateSpecified;
  const endDateFromRequest = subscriptionData?.scheduleDefinition?.endDate;

  const reportParamsErrors =
    errors?.reportParams as IReportParamsErrorsMessages;

  const startDateToCalculateNextRun = getNextScheduledRun(
    startDateTimeFromRequest,
    daysOfWeekFromRequest
  );

  const calculatedNextScheduledRun =
    typeFromRequest === 3
      ? getNextScheduledMonthRun(
          dayjs(startDateToCalculateNextRun),
          whichWeekFromRequest || 0,
          daysOfWeekFromRequest
        )
      : typeFromRequest === 1 && weeksIntervalFromRequest === 1
      ? startDateToCalculateNextRun
      : getNextScheduledWeekRun(
          startDateTimeFromRequest,
          weeksIntervalFromRequest,
          daysOfWeekFromRequest
        );

  const nextScheduledRun =
    endDateSpecifiedFromRequest &&
    dayjs(calculatedNextScheduledRun).isSameOrBefore(
      dayjs(endDateFromRequest).add(1, "day")
    )
      ? calculatedNextScheduledRun
      : !endDateSpecifiedFromRequest
      ? calculatedNextScheduledRun
      : undefined;

  const nextRunDateRangeStartDate = getNextRunDateRangeStartDate(
    dateOptionFromRequest,
    paramsStartDate,
    nextScheduledRun
  );

  const nextRunDateRangeEndDate = getNextRunDateRangeEndDate(
    dateOptionFromRequest,
    paramsEndDate,
    nextScheduledRun
  );

  const { data: siteOptions } = useGetSiteOrganizationsByIDsQuery(
    isEdit ? subscriptionData?.accountIDs : accountIDs,
    {
      skip: !subscriptionData?.accountIDs,
    }
  );

  const { data: buildingOptions } =
    useGetBuildingOrganizationsBySiteParentIDsQuery(siteList, {
      skip: !siteList,
    });

  const { data: roomsOptions } = useGetRoomsOrganizationsBySiteParentIDsQuery(
    buildingList,
    { skip: !buildingList }
  );

  const { data: locationOptions } =
    useGetLocationsOrganizationsByRoomParentIDsQuery(roomList, {
      skip: !roomList,
    });

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleChangedDynamicData = (name: string) => () => {
    handleChangedData();
    if (name === DYNAMIC_VALUES.SITE) {
      setValue(`reportParams.${DYNAMIC_VALUES.BUILDING}`, undefined);
      setValue(`reportParams.${DYNAMIC_VALUES.ROOM}`, undefined);
    } else if (name === DYNAMIC_VALUES.BUILDING) {
      setValue(`reportParams.${DYNAMIC_VALUES.ROOM}`, undefined);
    }
    setValue(`reportParams.${DYNAMIC_VALUES.LOCATION}`, undefined);
  };

  const handleChangeStartTime = (time: unknown) => {
    if (time) {
      setValue("startDateTime", time as string, { shouldValidate: true });
      handleChangedData();
    }
  };

  const handleChangeStartDate = (date: unknown) => {
    if (date) {
      setValue("startDate", date as Date, { shouldValidate: true });
      handleChangedData();
    }
  };

  const handleChangeEndDate = (date: unknown) => {
    if (date) {
      setValue("endDate", date as Date, { shouldValidate: true });
      handleChangedData();
    }
  };

  const handleClearEndDate = () => {
    setValue("endDate", null);
    handleChangedData();
  };

  const handleChangeDate = (field: string) => (date: unknown) => {
    if (date) {
      setValue(`reportParams.${field}`, date as Date, {
        shouldValidate: true,
      });
      handleChangedData();
    }
  };

  const handleChangeEmails = (_emails: string[]) => {
    setValue("emailDistributionList", _emails);
    handleChangedData();
  };

  const handleCloseSetSchedule = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleClickCancel = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCloseSetSchedule();
    }
  };

  const onChangeWeekDaysCheckbox = (day: string) => () => {
    setValue(day as IWeekDaysType, !watch(day as IWeekDaysType));
    setValue("daysOfWeek", undefined, { shouldValidate: true });
    setValue("dayType", "following");
    handleChangedData();
  };

  const onChangeEveryWeekday = () => {
    DAYS_OF_WEEK_PARAMS?.forEach(({ day, defaultValue }) => {
      setValue(day as IWeekDaysType, defaultValue);
    });
    handleChangedData();
  };

  const handleUpsertSubscription = handleSubmit((data) => {
    const reportParamsData = data?.reportParams || {};
    const reportParams = [];

    for (const value of Object.keys(reportParamsData)) {
      reportParams.push({
        name: value,
        value:
          value === REPORT_PARAMS_FIELDS.START_DATE
            ? getNextRunDateRangeStartDate(
                reportParamsData?.dateOption,
                reportParamsData[value]
              )
            : value === REPORT_PARAMS_FIELDS.END_DATE
            ? getNextRunDateRangeEndDate(
                reportParamsData?.dateOption,
                reportParamsData[value]
              )
            : typeof reportParamsData[value] === TYPE_OBJECT
            ? reportParamsData[value]
            : String(reportParamsData[value]),
      });
    }

    upsertSubscription({
      description: data?.description,
      emailDistributionList: data?.emailDistributionList,
      subject: data?.subject,
      body: data?.body,
      renderFormat: data?.renderFormat,
      accountIDs: isEdit
        ? subscriptionData?.accountIDs
        : accountIDs?.split(","),
      reportModule: !isEdit ? reportModule : null,
      reportName: !isEdit ? reportName : null,
      ...(isEdit ? { subscriptionID: subscriptionID } : {}),
      scheduleDefinition: {
        startDateTime: dayjs(startDateTime)
          .set("year", dayjs(data?.startDate).get("year"))
          .set("month", dayjs(data?.startDate).get("month"))
          .set("date", dayjs(data?.startDate).get("date"))
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        endDate: data?.endDate ? data?.endDate : dayjs(),
        endDateSpecified: data?.endDate ? true : false,
        item: {
          $type: isMonthType ? 3 : 1,
          ...(isMonthType
            ? {
                whichWeekSpecified: true,
                whichWeek: Number(data?.onWeekOfMonth),
              }
            : {
                weeksIntervalSpecified: true,
                weeksInterval: isDayType ? 1 : data?.repeatAfterWeeks,
              }),
          daysOfWeek: {
            sunday: data?.sunday,
            monday: data?.monday,
            tuesday: data?.tuesday,
            wednesday: data?.wednesday,
            thursday: data?.thursday,
            friday: data?.friday,
            saturday: data?.saturday,
          },
          monthsOfYear: MONTH_OF_YEAR,
        },
      },
      reportParams,
    });
  });

  const handleCloseDeleteSubscriptionDialog = () => {
    setOpenDeleteSubscriptionDialog(false);
    navigate(-1);
  };

  const handleOpenDeleteSubscriptionDialog = () => {
    setOpenDeleteSubscriptionDialog(true);
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  const renderWeekDaysPicker = () => (
    <WeekDaysPickerWrapper>
      <CheckboxWrapper>
        {DAYS_OF_WEEK_PARAMS?.map(({ day, label }) => (
          <React.Fragment key={day}>
            <FormCardCheckbox
              checked={watch(day as IWeekDaysType)}
              onChange={onChangeWeekDaysCheckbox(day)}
              id={`scheduled-report-configuration-${label}`}
            />
            <Label>{t(label)}</Label>
          </React.Fragment>
        ))}
      </CheckboxWrapper>
      <ErrorText>{errors?.daysOfWeek?.message as string}</ErrorText>
    </WeekDaysPickerWrapper>
  );

  useEffect(() => {
    if (userProfile?.email && !isEdit) {
      setValue("emailDistributionList", [userProfile?.email]);
    }
  }, [isEdit, setValue, userProfile?.email]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCloseSetSchedule();
      clearErrors();
      reset();
    });
  }, [clearErrors, handleCloseSetSchedule, reset, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => handleUpsertSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpsertSubscription]);

  useEffect(() => {
    if (subscriptionData) {
      for (const value of Object.keys(subscriptionData)) {
        if (value === "reportParams") {
          subscriptionData?.[value]?.forEach(
            (subscription: ISubscriptionData) => {
              setValue(
                `reportParams.${subscription?.name}`,
                subscription?.value
              );
            }
          );
        } else {
          setValue(
            value as IScheduledReportConfigurationFields,
            subscriptionData[value],
            {
              shouldValidate: true,
            }
          );
        }
      }
      setValue(
        "emailDistributionList",
        subscriptionData?.emailDistributionList
      );
      setValue("startDate", dayjs(startDateTimeFromRequest) as unknown as Date);
      setValue(
        "startDateTime",
        dayjs(startDateTimeFromRequest) as unknown as string
      );
      if (endDateSpecifiedFromRequest) {
        setValue("endDate", endDateFromRequest);
      }
      const item = subscriptionData?.scheduleDefinition?.item;
      const daysOfWeek = item?.daysOfWeek;
      for (const value of Object.keys(daysOfWeek)) {
        setValue(
          value as IScheduledReportConfigurationFields,
          daysOfWeek[value],
          {
            shouldValidate: true,
          }
        );
      }
      if (typeof item?.weeksInterval === "number") {
        setValue("repeatAfterWeeks", item?.weeksInterval);
      }
      if (item?.$type === 3) {
        setValue("type", SCHEDULE_TYPE.MONTH);
        setValue("onWeekOfMonth", item?.whichWeek);
      } else if (item?.$type === 1 && item?.weeksInterval > 1) {
        setValue("type", SCHEDULE_TYPE.WEEK);
      } else {
        setValue("type", SCHEDULE_TYPE.DAY);
      }
    }
  }, [
    subscriptionData,
    setValue,
    startDateTimeFromRequest,
    endDateSpecifiedFromRequest,
    endDateFromRequest,
    isSuccessUpsertSubscriptionsAfterRequest,
  ]);

  useEffect(() => {
    if (reportParameters?.length && !isEdit) {
      reportParameters?.forEach((reportParameter: IReportParameter) => {
        const value = reportParameter?.defaultValues?.length
          ? reportParameter?.defaultValues?.[0]
          : reportParameter?.defaultValues;

        setValue(
          `reportParams.${reportParameter?.name}`,
          value === "False" ? false : value === "True" ? true : value
        );
      });
    }
  }, [isEdit, reportParameters, setValue]);

  useEffect(() => {
    if (isSuccessUpsertSubscriptions) {
      setChangedData(false);
      setIsOpenSuccessAlert(true);
    }
  }, [isSuccessUpsertSubscriptions, setChangedData]);

  useEffect(() => {
    if (isErrorUpsertSubscription && subscriptionData) {
      upsertSubscriptionAfterRequest(subscriptionData);
      setChangedData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorUpsertSubscription]);

  return (
    <ScheduledReportConfigurationContainer
      tag="div"
      blocking={
        isLoadingUpsertSubscriptions ||
        isLoadingSubscriptionData ||
        isLoadingReportParameters ||
        isFetchingReportParameters ||
        isLoadingReportParametersById ||
        isFetchingReportParametersById
      }
      loader={<CircularProgress />}
      keepInView
    >
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("schedule-name")}
        </ScheduleReportConfigurationLabel>
        <Input
          errorMessage={errors?.description?.message as string}
          register={{
            ...register("description", { onChange: handleChangedData }),
          }}
          id="scheduled-report-configuration-description"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ReportParametersWrapper>
        <ScheduleReportConfigurationLabel>
          {t("report-parameters")}
        </ScheduleReportConfigurationLabel>
        {reportParameters?.map(
          ({
            prompt,
            validValues,
            parameterTypeName,
            parameterStateName,
            name,
          }: IReportParameter) => {
            const field = `reportParams.${name}`;
            const fieldValue = watch(field);
            const dynamicOptions =
              name === DYNAMIC_VALUES.SITE && siteOptions?.length
                ? siteOptions
                : name === DYNAMIC_VALUES.BUILDING && buildingOptions?.length
                ? buildingOptions
                : name === DYNAMIC_VALUES.ROOM && roomsOptions?.length
                ? roomsOptions
                : name === DYNAMIC_VALUES.LOCATION && locationOptions?.length
                ? locationOptions
                : [];

            const modifiedDynamicOptions = dynamicOptions?.map(
              ({ name, organizationUnitID }: IDynamicOptions) => ({
                id: organizationUnitID,
                val: name,
              })
            );

            return parameterTypeName !== PARAMETER_TYPE.DATE_TIME ||
              dateOption === DATE_OPTION_CUSTOM ? (
              <ScheduleReportConfigurationFieldWrapper key={name}>
                <ScheduleReportConfigurationLabel>
                  {name === REPORT_PARAMS_FIELDS.DATE_OPTION
                    ? "Reporting Period"
                    : parameterTypeName === PARAMETER_TYPE.DATE_TIME &&
                      dateOption !== DATE_OPTION_CUSTOM
                    ? ""
                    : prompt}
                </ScheduleReportConfigurationLabel>
                {validValues && validValues?.length ? (
                  <Select
                    value={reportParams?.[name] || ""}
                    register={{
                      ...register(field, {
                        onChange: handleChangedData,
                      }),
                    }}
                    options={validValues?.map(
                      ({ label, value }: IValidValues) => ({
                        id: value,
                        val: label,
                      })
                    )}
                    errorMessage={reportParamsErrors?.[name]?.message}
                    id={`scheduled-report-configuration-${field}`}
                  />
                ) : parameterTypeName === PARAMETER_TYPE.DATE_TIME &&
                  dateOption === DATE_OPTION_CUSTOM ? (
                  <DatePicker
                    value={fieldValue ? dayjs(fieldValue) : null}
                    register={{
                      ...register(field, {
                        onChange: handleChangedData,
                      }),
                    }}
                    onChange={handleChangeDate(name)}
                    errorMessage={reportParamsErrors?.[name]?.message}
                    disabled={reportParams?.dateOption !== DATE_OPTION_CUSTOM}
                    id={`scheduled-report-configuration-${field}`}
                  />
                ) : parameterTypeName === PARAMETER_TYPE.BOOLEAN ? (
                  <FormCardCheckbox
                    checked={fieldValue || null}
                    onChange={() => {
                      setValue(field, !fieldValue);
                    }}
                    register={{
                      ...register(field, {
                        onChange: handleChangedData,
                      }),
                    }}
                    id={`scheduled-report-configuration-${field}`}
                  />
                ) : parameterTypeName === PARAMETER_TYPE.STRING &&
                  parameterStateName !== "DynamicValuesUnavailable" ? (
                  <Input
                    register={{
                      ...register(field, {
                        onChange: handleChangedData,
                      }),
                    }}
                    errorMessage={reportParamsErrors?.[name]?.message}
                    id={`scheduled-report-configuration-${field}`}
                  />
                ) : parameterStateName === "DynamicValuesUnavailable" ? (
                  <Select
                    value={watch(`reportParams.${name}`) || ""}
                    register={{
                      ...register(field, {
                        onChange: handleChangedDynamicData(name),
                      }),
                    }}
                    options={modifiedDynamicOptions}
                    disabled={!modifiedDynamicOptions?.length}
                    id={`scheduled-report-configuration-${field}`}
                  />
                ) : null}
              </ScheduleReportConfigurationFieldWrapper>
            ) : null;
          }
        )}
      </ReportParametersWrapper>
      {isEdit && (
        <ScheduleReportFieldText>
          {t("next-scheduled-run")}
          {nextScheduledRun
            ? nextScheduledRun.format("ddd, MMM D, YYYY h:mmA")
            : "-"}
          <br />
          {t("next-run-date-range")}
          {nextScheduledRun &&
          nextRunDateRangeStartDate &&
          nextRunDateRangeEndDate ? (
            <React.Fragment>
              <React.Fragment>
                {dayjs(nextRunDateRangeStartDate).format("MMM D, YYYY")}
              </React.Fragment>
              <React.Fragment> {t("to")} </React.Fragment>
              <React.Fragment>
                {dayjs(nextRunDateRangeEndDate).format("MMM D, YYYY")}
              </React.Fragment>
            </React.Fragment>
          ) : (
            "-"
          )}
        </ScheduleReportFieldText>
      )}
      <ScheduleReportConfigurationLabel>
        {t("email-frequency")}
      </ScheduleReportConfigurationLabel>
      <RadioGroupWrapper>
        <Controller
          control={control}
          name={"type"}
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                value={SCHEDULE_TYPE.DAY}
                control={<Radio icon={<CheckedIcon />} />}
                label={t(SCHEDULE_TYPE.DAY)}
                onChange={handleChangedData}
              />
              <FormControlLabel
                value={SCHEDULE_TYPE.WEEK}
                control={<Radio icon={<CheckedIcon />} />}
                label={t(SCHEDULE_TYPE.WEEK)}
                onChange={handleChangedData}
              />
              <FormControlLabel
                value={SCHEDULE_TYPE.MONTH}
                control={<Radio icon={<CheckedIcon />} />}
                label={t(SCHEDULE_TYPE.MONTH)}
                onChange={handleChangedData}
              />
            </RadioGroup>
          )}
        />
      </RadioGroupWrapper>
      {type === SCHEDULE_TYPE.DAY && (
        <FrequencyContainer>
          <ScheduleReportConfigurationLabel>
            {t("daily-schedule")}
          </ScheduleReportConfigurationLabel>
          <DailyRadioGroupWrapper>
            <Controller
              control={control}
              name={"dayType"}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FollowingDaysContainer>
                    <FormControlLabel
                      value={"following"}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("on-the-following-days")}
                      onChange={handleChangedData}
                    />
                  </FollowingDaysContainer>
                  {renderWeekDaysPicker()}
                  <FormControlLabel
                    value={"weekday"}
                    control={<Radio icon={<CheckedIcon />} />}
                    label={t("every-weekday")}
                    onChange={onChangeEveryWeekday}
                  />
                </RadioGroup>
              )}
            />
          </DailyRadioGroupWrapper>
        </FrequencyContainer>
      )}
      {type === SCHEDULE_TYPE.WEEK && (
        <FrequencyContainer>
          <ScheduleReportConfigurationLabel>
            {t("weekly-schedule")}
          </ScheduleReportConfigurationLabel>
          <ScheduleReportConfigurationFieldWrapper>
            <ScheduleReportFieldText>
              {t("repeat-every")}
            </ScheduleReportFieldText>
            <InputWrapper>
              <Input
                type="number"
                register={{
                  ...register("repeatAfterWeeks", {
                    onChange: handleChangedData,
                  }),
                }}
                errorMessage={errors?.repeatAfterWeeks?.message as string}
                id="scheduled-report-configuration-repeat-after-weeks"
              />
            </InputWrapper>
            <ScheduleReportFieldText>{t("week-s")}</ScheduleReportFieldText>
          </ScheduleReportConfigurationFieldWrapper>
          <FollowingDaysContainer>
            <ScheduleReportFieldText>
              {t("day-of-the-week")}
            </ScheduleReportFieldText>
            {renderWeekDaysPicker()}
          </FollowingDaysContainer>
        </FrequencyContainer>
      )}
      {type === SCHEDULE_TYPE.MONTH && (
        <FrequencyContainer>
          <ScheduleReportConfigurationLabel>
            {t("monthly-schedule")}
          </ScheduleReportConfigurationLabel>
          <ScheduleReportConfigurationMonthScheduleWrapper>
            <ScheduleReportFieldText>{t("on-the")}</ScheduleReportFieldText>
            <SelectWrapper>
              <Select
                value={onWeekOfMonth || ""}
                register={{
                  ...register("onWeekOfMonth", {
                    onChange: handleChangedData,
                  }),
                }}
                options={ON_WEEK_OF_MONTH_OPTIONS}
                errorMessage={errors?.onWeekOfMonth?.message as string}
                id="scheduled-report-configuration-on-week-of-month"
              />
            </SelectWrapper>
            <ScheduleReportFieldText>
              {t("week-of-month")}
            </ScheduleReportFieldText>
          </ScheduleReportConfigurationMonthScheduleWrapper>
          <ScheduleReportFieldText>
            {t("on-day-of-week")}
          </ScheduleReportFieldText>
          {renderWeekDaysPicker()}
        </FrequencyContainer>
      )}
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("run-time")}
        </ScheduleReportConfigurationLabel>
        <TimePicker
          value={startDateTime ? dayjs(startDateTime) : null}
          register={register("startDateTime")}
          onChange={handleChangeStartTime}
          errorMessage={errors?.startDateTime?.message as string}
          id="scheduled-report-configuration-start-date-time"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("start-date")}
        </ScheduleReportConfigurationLabel>
        <DatePicker
          value={startDate ? dayjs(startDate) : null}
          register={register("startDate")}
          onChange={handleChangeStartDate}
          errorMessage={errors?.startDate?.message as string}
          maxDate={dayjs(endDate)}
          id="scheduled-report-configuration-start-date"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("end-date")}
        </ScheduleReportConfigurationLabel>
        <DatePicker
          withClear
          onClear={handleClearEndDate}
          value={endDate ? dayjs(endDate) : null}
          register={register("endDate")}
          onChange={handleChangeEndDate}
          errorMessage={errors?.endDate?.message as string}
          minDate={dayjs(startDate)}
          id="scheduled-report-configuration-end-date"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationLabel>
        {t("email-details")}
      </ScheduleReportConfigurationLabel>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("distribution-list")}
        </ScheduleReportConfigurationLabel>
        <MultiEmail
          values={emails as string[]}
          onChange={handleChangeEmails}
          id="scheduled-report-configuration-distribution-list"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("subject")}
        </ScheduleReportConfigurationLabel>
        <Input
          errorMessage={errors?.subject?.message as string}
          register={{
            ...register("subject", { onChange: handleChangedData }),
          }}
          id="scheduled-report-configuration-subject"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("body")}
        </ScheduleReportConfigurationLabel>
        <TextArea
          errorMessage={errors?.body?.message as string}
          register={{
            ...register("body", { onChange: handleChangedData }),
          }}
          id="scheduled-report-configuration-body"
        />
      </ScheduleReportConfigurationFieldWrapper>
      <ScheduleReportConfigurationFieldWrapper>
        <ScheduleReportConfigurationLabel>
          {t("attachment-type")}
        </ScheduleReportConfigurationLabel>
        <SelectWrapper>
          <Select
            value={renderFormat}
            register={{
              ...register("renderFormat", { onChange: handleChangedData }),
            }}
            options={RENDER_FORMAT_OPTIONS}
            errorMessage={errors?.renderFormat?.message as string}
            id="scheduled-report-configuration-render-format"
          />
        </SelectWrapper>
      </ScheduleReportConfigurationFieldWrapper>
      <ActionsWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClickCancel}
          id="scheduled-report-configuration-cancel"
        />
        {isEdit && (
          <Button
            text={t("delete")}
            onClick={handleOpenDeleteSubscriptionDialog}
            isError
            id="scheduled-report-configuration-delete"
          />
        )}
        <Button
          text={t("apply")}
          onClick={handleUpsertSubscription}
          disabled={isLoadingUpsertSubscriptions || isOpenSuccessAlert}
          id="scheduled-report-configuration-apply"
        />
      </ActionsWrapper>
      <DeleteSubscriptionDialog
        subscriptionID={subscriptionID}
        open={openDeleteSubscriptionDialog}
        handleClose={handleCloseDeleteSubscriptionDialog}
      />
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[
          t("scheduled-report-successfully", { scheduleName: description }),
        ]}
        severity="success"
      />
    </ScheduledReportConfigurationContainer>
  );
};
