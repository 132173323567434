import { Box, Typography, styled } from "@mui/material";

export const FollowUsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  alignItems: "flex-end",
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
  },
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
}));

export const FollowUsTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  color: "#F5F5F5",
  lineHeight: "normal",
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const FollowUsLinksWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
}));

export const FollowUsLink = styled("img")(({ theme }) => ({
  width: 24,
  height: 24,
  [theme.breakpoints.down("lg")]: {
    width: 20,
    height: 20,
  },
  "&:hover": {
    opacity: 0.7,
  },
}));

export const FollowUsBody = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  color: "#F5F5F5",
  lineHeight: "140%",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    lineHeight: "130%",
  },
}));
