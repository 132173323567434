import { useTranslation } from "react-i18next";
import { Box, OutlinedTextFieldProps } from "@mui/material";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/select-arrow.svg";
import { generatedIdForAutocomplete } from "src/shared/helpers/generatedId";
import { StyledAutocomplete, Input, IconWrapper } from "./styles";
import { IAutocomplete, IAutocompleteOption } from "./types";

export const Autocomplete = ({
  options,
  value,
  updateData,
  onChange,
  disabled,
  errorMessage,
  defaultValue,
  placeholder,
  multiple,
  clearOnBlur,
  getOptionDisabled,
  inputValue,
  register,
  filterOptions,
  inputRef,
  noOptionText,
  id,
}: IAutocomplete) => {
  const { t } = useTranslation();
  const onInputChange = (_: unknown, newInputValue: string) => {
    if (updateData) {
      updateData(newInputValue);
    }
  };

  return (
    <StyledAutocomplete
      {...register}
      noOptionsText={noOptionText}
      options={options}
      value={value}
      multiple={multiple}
      inputValue={inputValue}
      popupIcon={
        <IconWrapper position="end">
          <ArrowDown />
        </IconWrapper>
      }
      onChange={onChange}
      getOptionLabel={(option: unknown) => {
        const { val } = option as IAutocompleteOption;
        return val ? val : "";
      }}
      clearOnBlur={clearOnBlur}
      defaultValue={defaultValue}
      onInputChange={onInputChange}
      renderInput={(params: unknown) => (
        <Input
          {...(params as OutlinedTextFieldProps)}
          autoComplete="off"
          error={Boolean(errorMessage)}
          helperText={errorMessage && t(`${errorMessage}`)}
          placeholder={placeholder}
          inputRef={inputRef}
          id={generatedIdForAutocomplete(id)}
        />
      )}
      renderOption={(props, option: unknown) => {
        const { id, val } = option as IAutocompleteOption;

        return (
          <Box component="li" {...props} key={id}>
            {val}
          </Box>
        );
      }}
      disabled={disabled}
      isOptionEqualToValue={(option: unknown, value: unknown) => {
        const { val: optionVal } = option as IAutocompleteOption;
        const { val: valueVal } = value as IAutocompleteOption;

        return optionVal === valueVal;
      }}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      id={generatedIdForAutocomplete(id)}
    />
  );
};
