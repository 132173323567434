import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IArrowDownWrapper } from "./types";

export const ArrowDownWrapper = styled(Box)<IArrowDownWrapper>(({ open }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 8,
  "& > svg": {
    transform: open ? "rotate(180deg)" : "",
    "& > path": {
      fill: "#25895C",
    },
  },
}));

export const PopoverItem = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  padding: "6px 16px",
  color: theme.palette.text.secondary,
  fontSize: 16,
  minWidth: 150,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
  "&:hover": {
    background: isDarkMode(theme.palette.mode)
      ? "rgba(37, 137, 92, 0.5);"
      : "rgba(37, 137, 92, 0.20)",
  },
}));

export const ExportDescription = styled(Typography)(({ theme }) => ({
  color: "#25895C",
  fontSize: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));
