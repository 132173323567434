import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IMultiEmailWrapper } from "./types";

export const MultiEmailWrapper = styled(Box)<IMultiEmailWrapper>(
  ({ theme, error }) => ({
    ".react-multi-email.focused": {
      background: isDarkMode(theme.palette.mode) ? "#35363A" : "none",
      border: "none",
      outline: error
        ? "1px solid #E35D2B"
        : isDarkMode(theme.palette.mode)
        ? "2px solid rgba(32, 33, 36, 0.50)"
        : "2px solid #25895C",
    },
    "& > div ": {
      borderRadius: 8,
      "&:hover": {
        border: "none",
        outline: error
          ? "1px solid #E35D2B"
          : isDarkMode(theme.palette.mode)
          ? "1px solid rgba(37, 137, 92, 0.5)"
          : "1px solid rgba(37, 137, 92, 0.20)",
      },
      outline: error ? "1px solid #E35D2B" : "1px solid #B1B1B1",
      border: "none",
      background: isDarkMode(theme.palette.mode) ? "#35363A" : "none",

      "& > input": {
        background: "none",
        color: theme.palette.secondary.main,
        padding: "4px 8px",
        borderRadius: 8,
        fontFamily: "Roboto",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        width: "100%",
        [theme.breakpoints.down("lg")]: {
          fontSize: 14,
        },
      },
    },
  })
);

export const ErrorHelperText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  margin: 2,
  marginLeft: 14,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));
