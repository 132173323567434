import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "src/shared/components/list/list";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IProfilesTooltip } from "./types";
import { ListWrapper } from "./styles";

export const ProfilesTooltip = ({ open, setOpen }: IProfilesTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <React.Fragment>
        <ListWrapper>
          <List items={[t("keyword-entry-query-profiles")]} />
          <List items={[t("site-entry-profiles")]} />
          <List items={[t("click-profiles-tab")]} />
        </ListWrapper>
      </React.Fragment>
    </Tooltip>
  );
};
