import { IQuestion } from "./types";

export const QUESTION_TYPE_ID = {
  TRFL: "TRFL",
  NMBR: "NMBR",
  TEXT: "TEXT",
};

export const getInspectionQuestionAnswer = (question: IQuestion) => {
  switch (question?.typeID) {
    case QUESTION_TYPE_ID.TRFL:
      return question?.answerBool?.toString();
    case QUESTION_TYPE_ID.NMBR:
      return question?.answerNumeric;
    case QUESTION_TYPE_ID.TEXT:
      return question?.answerText;

    default: {
      return question?.answerBool?.toString();
    }
  }
};
