import { Box, Drawer, styled } from "@mui/material";

export const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 40,
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  position: "fixed",
  top: 0,
  zIndex: 1000,
  [theme.breakpoints.down("lg")]: {
    padding: "0 16px",
  },
}));

export const BurgerMenu = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  width: 1088,
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    gap: 16,
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    gap: 16,
  },
}));

export const MenuDrawer = styled(Drawer)(() => ({
  zIndex: 999,
}));

export const MobileColorModeWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  "& > span": {
    display: "flex",
  },
}));

export const MobileTabsWrapper = styled(Box)(() => ({
  "& > div": {
    display: "flex",
  },
}));

export const AdminMobileTabsWrapper = styled(Box)(() => ({
  paddingBottom: 8,
  "& > div": {
    display: "flex",
    minHeight: "auto",
  },
}));

export const Divider = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#D9D9D9",
  height: 2,
  margin: "38.5px 0 32px 0",
}));
