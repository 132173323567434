import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { useDeactivateLocationMutation } from "src/shared/store/api/organization.api";
import { IDeleteLocationDialog } from "./types";
import {
  DialogBody,
  DeleteLocationDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const DeleteLocationDialog = ({
  locationId,
  open,
  handleClose,
  successCallBack,
}: IDeleteLocationDialog) => {
  const { t } = useTranslation();

  const [
    deactivateLocation,
    { isSuccess: isSuccessDeleteLocation, isLoading: isDeleteLocationLoading },
  ] = useDeactivateLocationMutation();

  useEffect(() => {
    if (isSuccessDeleteLocation && !isDeleteLocationLoading) {
      handleClose();
      successCallBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteLocationLoading]);

  const deleteDialogActions = () => {
    const handleDeleteLocation = locationId
      ? () => deactivateLocation(locationId)
      : undefined;

    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="delete-location-cancel"
        />
        <Button
          text={t("continue")}
          onClick={handleDeleteLocation}
          id="delete-location-continue"
        />
      </SendBtnWrapper>
    );
  };

  const renderDeleteLocationDialogFields = () => {
    return (
      <DeleteLocationDialogContentContainer>
        <DialogBody>{t("are-you-sure-delete-location")}</DialogBody>
      </DeleteLocationDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("delete-location")}
      actions={deleteDialogActions}
      renderFields={renderDeleteLocationDialogFields}
    />
  );
};
