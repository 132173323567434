import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const DetailInspectionCardContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  border: "1px solid #D9D9D9",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  width: "100%",
  borderRadius: 16,
  padding: 16,
  [theme.breakpoints.down("md")]: {
    gap: 8,
    marginTop: 16,
  },
}));

export const DetailInspectionCardWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const DetailInspectionFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  "& > div,textArea": {
    display: "flex",
    maxWidth: 400,
    justifyContent: "end",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 108px)",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const DetailInspectionButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: 48,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: 24,
  "& > button": {
    width: "fit-content",
    minWidth: 150,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
    "& > button": {
      width: "fit-content",
      minWidth: 100,
    },
  },
}));

export const SwitchWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  alignItems: "center",
}));

export const CameraIconWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 20,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& > svg > g > path": {
    fill: isDarkMode(theme.palette.mode) ? "#FFF" : "",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 10,
    width: 28,
    height: 28,
  },
}));
