import { ListOfItems } from "src/shared/components/listOfItems/listOfItems";
import { LocationTitle } from "./locationTitle/locationTitle";
import { LocationBody } from "./locationBody/locationBody";
import { ILocationList } from "./types";

export const LocationList = ({ locationItems, roomName }: ILocationList) => {
  return locationItems ? (
    <ListOfItems
      title={<LocationTitle />}
      body={<LocationBody locationItems={locationItems} />}
    />
  ) : null;
};
