import { Compliance } from "src/components/compliance/compliance";
import { getLocaleDateValue } from "src/components/manifestTable/constants";
import { FieldValue } from "src/shared/components/formCard/styles";
import { ManifestComplianceContainer } from "./styles";
import {
  IAccount,
  IFacility,
  ISite,
  ITransporter,
  ITransporters,
  IManifestData,
} from "./types";

export const getHazardousWasteConfig = (
  t: (text: string) => string,
  manifestData: IManifestData
) => [
  {
    name: t("status"),
    customRenderValue: () => (
      <ManifestComplianceContainer>
        <FieldValue>{manifestData?.status}</FieldValue>
        <Compliance color={manifestData?.complianceColor} />
      </ManifestComplianceContainer>
    ),
    isStatus: true,
  },
  {
    name: t("shipped-date"),
    value: getLocaleDateValue(manifestData?.dateShipped),
  },
  {
    name: t("return-date"),
    value: getLocaleDateValue(manifestData?.dateReturned),
  },
];

export const getEManifestConfig = (
  t: (text: string) => string,
  hasEManifest: string
) => [
  {
    name: "1. File",
    value: hasEManifest
      ? t("completed-manifest-copy-RCRA")
      : t("check-RCRA-for-copy"),
    link: "true",
  },
];

export const getGeneratorConfig = (
  t: (text: string) => string,
  account: IAccount
) => [
  {
    name: t("name"),
    value: account?.name,
  },
  {
    name: t("address-1"),
    value: account?.street1,
  },
  {
    name: t("address-2"),
    value: account
      ? `${account?.city}, ${account?.state} ${account?.postalCode} ${account?.country}`
      : "",
  },
  {
    name: t("phone"),
    value: account?.phoneNumber,
  },
];

export const getGeneratorSiteConfig = (
  t: (text: string) => string,
  site: ISite
) => [
  {
    name: t("name"),
    value: site?.name,
  },
  {
    name: t("epa-id"),
    value: site?.epaIDNumber,
  },
  {
    name: t("address-1"),
    value: site?.address1,
  },
  {
    name: t("address-2"),
    value: site
      ? `${site?.city}, ${site?.state} ${site?.postalCode} ${site?.country}`
      : "",
  },
  {
    name: t("phone"),
    value: site?.phoneNumber,
  },
];

export const getTransportersConfig = (
  t: (text: string) => string,
  transporters: ITransporters
) =>
  transporters?.map((transporter: ITransporter) => [
    {
      name: `${transporter?.sequenceNumber}.`,
      value: transporter?.name,
    },
    {
      name: t("epa-id"),
      value: transporter?.epaNumber,
    },
  ]);

export const getFacilityConfig = (
  t: (text: string) => string,
  facility: IFacility
) => [
  {
    name: t("name"),
    value: facility?.name,
  },
  {
    name: t("epa-id"),
    value: facility?.epaIDNumber,
  },
  {
    name: t("address-1"),
    value: facility?.address1,
  },
  {
    name: t("address-2"),
    value: facility
      ? `${facility?.city}, ${facility?.state} ${facility?.postalCode} ${facility?.country}`
      : "",
  },
  {
    name: t("phone"),
    value: facility?.phone,
  },
];

export const getTransportersSignatureConfig = (transporters: ITransporters) =>
  transporters?.map((transporter: ITransporter) => ({
    signature: `${transporter?.signature}.`,
    date: transporter?.dateSigned,
    sequenceNumber: transporter?.sequenceNumber,
  }));
