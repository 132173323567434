import { useTranslation } from "react-i18next";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import { IHandlingCodeTooltip } from "./types";
import { HandlingCodeText } from "./styles";

export const HandlingCodeTooltip = ({
  open,
  setOpen,
}: IHandlingCodeTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <HandlingCodeText>{t("new-york-only")}</HandlingCodeText>
    </Tooltip>
  );
};
