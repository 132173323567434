import facebook from "src/shared/assets/svgs/facebook.svg";
import twitter from "src/shared/assets/svgs/twitter.svg";
import instagram from "src/shared/assets/svgs/instagram.svg";
import linkedIn from "src/shared/assets/svgs/linkedIn.svg";

export const FOLLOW_US_LINKS_CONFIG = [
  {
    link: "https://www.facebook.com/TriumvirateEnvironmental",
    icon: facebook,
  },
  {
    link: "https://twitter.com/Triumvirate",
    icon: twitter,
  },
  {
    link: "https://www.instagram.com/triumvirateenvironmental",
    icon: instagram,
  },
  {
    link: "https://www.linkedin.com/company/triumvirate-environmental",
    icon: linkedIn,
  },
];
