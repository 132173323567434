import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import {
  useGetHazardousMaterialsQuery,
  useGetNonDotShippingNamesQuery,
  useGetWasteCharacteristicsQuery,
} from "src/shared/store/api/regulatory.api";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { SelectShippingNameDialog } from "../selectShippingNameDialog/selectShippingNameDialog";
import { Tabs } from "src/shared/components/tabs/tabs";
import { Button } from "src/shared/components/button/button";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { Select } from "src/shared/components/select/select";
import { Input } from "src/shared/components/input/input";
import { TextArea } from "src/shared/components/textArea/textArea";
import {
  useGetWasteProfileRequestByIDQuery,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import { IItems, IWasteCharacteristics } from "../generalCharacteristics/types";
import {
  ButtonWrapper,
  ErrorText,
  FieldWrapper,
  IndicatesRequiredField,
  SectionDivider,
  SectionHeader,
  TextField,
} from "../../pages/newProfile/styles";
import { DTOInformationValidationSchema } from "./model";
import { IDOTInformationFields, IHazardous, INonHazardous } from "./types";
import {
  HAZARDOUS_GRID_NAME,
  NON_HAZARDOUS_GRID_NAME,
  getColumnDefsHazardous,
  getColumnDefsNonHazardous,
  defaultColDef,
  paginationPageSize,
  TIME_FRAME_OPTIONS,
  DOT_INFORMATION_KEYS,
  rowSelection,
} from "./constants";
import {
  DOTInformationContainer,
  FullShippingNameText,
  HazardousTableContainer,
  DOTTabsWrapper,
  SectionHeaderName,
  FieldsWrapper,
  SectionWrapper,
  ConstituentsWrapper,
  ShippingNameButtonWrapper,
  DOTInformationWrapper,
  CreateShippingName,
  DotInformationDivider,
} from "./styles";

export const DOTInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const {
    setHandleBackUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
    setChangedData,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DTOInformationValidationSchema(t)),
    mode: "onSubmit",
  });

  const commonShippingName = watch("commonShippingName");
  const fullShippingName = watch("fullShippingName");
  const lineConstituents = watch("lineConstituents") || "";

  const {
    data: hazardousMaterials,
    isLoading: isLoadingHazardousMaterials,
    isFetching: isFetchingHazardousMaterials,
  } = useGetHazardousMaterialsQuery({});
  const {
    data: nonHazardousMaterials,
    isLoading: isLoadingNonHazardousMaterials,
    isFetching: isFetchingNonHazardousMaterials,
  } = useGetNonDotShippingNamesQuery({});
  const [shippingNameOptions, setShippingNameOptions] = useState([]);
  const { data: wasteCharacteristics } = useGetWasteCharacteristicsQuery({});

  const [isOpenSelectShippingNameDialog, setIsOpenSelectShippingNameDialog] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isOpenShippingNameTables, setIsOpenShippingNameTables] =
    useState(false);
  const [selectedHazardous, setSelectedHazardous] = useState<IHazardous>();
  const [selectedHazardousRow, setSelectedHazardousRow] =
    useState<IHazardous>();
  const [selectedNonHazardousRow, setSelectedNonHazardousRow] =
    useState<INonHazardous>();

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const columnDefsHazardous = getColumnDefsHazardous(t);
  const columnDefsNonHazardous = getColumnDefsNonHazardous(t);

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  const shippingName = fullShippingName
    ? fullShippingName
    : selectedHazardous
    ? `${selectedHazardous?.identificationNumbers}, ${commonShippingName}, (${lineConstituents}), ${selectedHazardous?.labelCodes},${selectedHazardous?.packingGroup}`
    : commonShippingName;

  const onCellClickedHazardous = (params: CellClickedEvent) => {
    if (
      selectedHazardousRow?.hazardousMaterialID ===
      params?.data?.hazardousMaterialID
    ) {
      const hazardousMaterialsValues =
        params?.data?.hazardousMaterials?.split("[or]");
      setValue("hazardousMaterial_id", params?.data?.hazardousMaterialID);
      setValue("nonDotShippingName_id", "");
      setValue("fullShippingName", "");
      setChangedData(true);
      if (hazardousMaterialsValues?.length === 1) {
        setValue("commonShippingName", hazardousMaterialsValues[0], {
          shouldValidate: true,
        });
        setSelectedHazardous(params?.data);
        setIsOpenShippingNameTables(false);
        setSelectedHazardousRow(undefined);
      } else {
        setSelectedHazardous(params?.data);
        setShippingNameOptions(
          hazardousMaterialsValues?.map((el: string) => ({
            id: el.replace(/\[.+\]/, ""),
            val: el.replace(/\[.+\]/, ""),
          }))
        );
        setIsOpenSelectShippingNameDialog(true);
      }
    }
  };

  const onCellClickedNonHazardous = (params: CellClickedEvent) => {
    if (selectedNonHazardousRow?.id === params?.data?.id) {
      setChangedData(true);
      setValue("nonDotShippingName_id", params?.data?.hazardousMaterialID);
      setValue("hazardousMaterial_id", "");
      setValue("commonShippingName", params?.data?.shippingName, {
        shouldValidate: true,
      });
      setValue("fullShippingName", "");
      setIsOpenShippingNameTables(false);
      setSelectedHazardous(undefined);
      setSelectedNonHazardousRow(undefined);
    }
  };

  const handleCloseSelectShippingNameDialog = () => {
    setIsOpenSelectShippingNameDialog(false);
  };

  const handleSelectShippingName = (shippingName: string) => {
    setValue("commonShippingName", shippingName, {
      shouldValidate: true,
    });
    setIsOpenShippingNameTables(false);
    setSelectedHazardousRow(undefined);
  };

  const onSelectionHazardousChanged = useCallback(
    (props: SelectionChangedEvent) => {
      const selectedRows = props?.api?.getSelectedRows();
      setSelectedHazardousRow(selectedRows[0]);
    },
    []
  );

  const onSelectionNonHazardousChanged = useCallback(
    (props: SelectionChangedEvent) => {
      const selectedRows = props?.api?.getSelectedRows();
      setSelectedNonHazardousRow(selectedRows[0]);
    },
    []
  );

  const quickFilterMatcher = useCallback(
    (quickFilterParts: string[], rowQuickFilterAggregateText: string) => {
      return quickFilterParts.every((part) =>
        rowQuickFilterAggregateText.startsWith(part)
      );
    },
    []
  );

  const TABS_DOT_INFORMATION_CONFIG = [
    {
      label: "hazardous",
      children: (
        <HazardousTableContainer>
          <AgGrid
            title={t("hazardous")}
            onCellClicked={onCellClickedHazardous}
            onSelectionChanged={onSelectionHazardousChanged}
            quickFilterMatcher={quickFilterMatcher}
            rowSelection={rowSelection}
            gridName={HAZARDOUS_GRID_NAME}
            rowData={hazardousMaterials}
            columnDefs={columnDefsHazardous}
            defaultColDef={defaultColDef}
            paginationPageSize={paginationPageSize}
            noRowsText={t("no-available-hazardous-display")}
            enableRangeSelection={true}
            allowContextMenuWithControlKey={true}
            isLoadingData={
              isLoadingHazardousMaterials || isFetchingHazardousMaterials
            }
          />
        </HazardousTableContainer>
      ),
    },
    {
      label: "Non-Hazardous",
      children: (
        <HazardousTableContainer>
          <AgGrid
            title={t("non-hazardous")}
            onCellClicked={onCellClickedNonHazardous}
            onSelectionChanged={onSelectionNonHazardousChanged}
            quickFilterMatcher={quickFilterMatcher}
            rowSelection={rowSelection}
            gridName={NON_HAZARDOUS_GRID_NAME}
            rowData={nonHazardousMaterials}
            columnDefs={columnDefsNonHazardous}
            defaultColDef={defaultColDef}
            paginationPageSize={paginationPageSize}
            noRowsText={t("no-available-non-hazardous-display")}
            enableRangeSelection={true}
            allowContextMenuWithControlKey={true}
            isLoadingData={
              isLoadingNonHazardousMaterials || isFetchingNonHazardousMaterials
            }
          />
        </HazardousTableContainer>
      ),
    },
  ];

  const backToProfilesTab = useCallback(() => {
    navigate(`/dashboard/${TABS_NAMES[4]}`);
  }, [navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContinue = useCallback(
    handleSubmit((data) => {
      upsertProfileRequest({
        id: profileID,
        ...data,
        fullShippingName: shippingName,
        sectionStep: SECTION.ADDITIONAL_INFORMATION,
      });
      setChangedData(false);
    }),
    [
      handleSubmit,
      profileID,
      setChangedData,
      shippingName,
      upsertProfileRequest,
    ]
  );

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToProfilesTab();
      });
      setHandleUpdateUnsavedChanged(() => {
        handleContinue();
        backToProfilesTab();
      });
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToProfilesTab();
    }
  }, [
    backToProfilesTab,
    changedData,
    handleContinue,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleChangedConstituents = () => {
    setValue("fullShippingName", "");
    handleChangedData();
  };

  const characteristics = wasteCharacteristics?.reduce(
    (
      acc: { [key: string]: IWasteCharacteristics },
      elem: IWasteCharacteristics
    ) => {
      acc[elem?.key] = elem;

      return acc;
    },
    {}
  );

  const handleOpenShippingNameTables = () => {
    setIsOpenShippingNameTables(!isOpenShippingNameTables);
  };

  useEffect(() => {
    DOT_INFORMATION_KEYS.map((key: string) =>
      setValue(key as IDOTInformationFields, wasteProfileData?.[key])
    );
  }, [setValue, wasteProfileData]);

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  useEffect(() => {
    if (wasteProfileData?.hazardousMaterial_id) {
      const selectedHazardous = hazardousMaterials?.find(
        ({ hazardousMaterialID }: IHazardous) =>
          String(hazardousMaterialID) ===
          wasteProfileData?.hazardousMaterial_id?.split("|")?.[1]
      );
      if (selectedHazardous) {
        setSelectedHazardous(selectedHazardous);
        setValue(
          "hazardousMaterial_id",
          selectedHazardous?.hazardousMaterialID
        );
      }
    }
  }, [hazardousMaterials, setValue, wasteProfileData?.hazardousMaterial_id]);

  const handleChangeTab = (tab: number) => {
    setSelectedTab(tab);
    setSelectedNonHazardousRow(undefined);
    setSelectedHazardousRow(undefined);
  };

  return (
    <DOTInformationContainer>
      <DOTInformationWrapper>
        <SectionHeader>{t("dot-information-section")}</SectionHeader>
        <IndicatesRequiredField>
          <span>*</span> {t("indicates-a-required-field")}
        </IndicatesRequiredField>
        {!isActiveWasteProfile && (
          <ShippingNameButtonWrapper>
            <CreateShippingName onClick={handleOpenShippingNameTables}>
              {t("search-and-select-shipping-name")}
            </CreateShippingName>
          </ShippingNameButtonWrapper>
        )}
        <FullShippingNameText error={errors?.commonShippingName?.message}>
          {shippingName}
        </FullShippingNameText>
        <ErrorText>{errors?.commonShippingName?.message}</ErrorText>
        {isOpenShippingNameTables && (
          <DOTTabsWrapper>
            <Tabs
              config={TABS_DOT_INFORMATION_CONFIG}
              setValue={handleChangeTab}
              value={selectedTab}
            />
          </DOTTabsWrapper>
        )}
        <SelectShippingNameDialog
          shippingNameOptions={shippingNameOptions}
          handleSelectShippingName={handleSelectShippingName}
          open={isOpenSelectShippingNameDialog}
          handleClose={handleCloseSelectShippingNameDialog}
        />
        <SectionDivider />
        <ConstituentsWrapper>
          <FieldWrapper>
            <TextField>{t("constituents")}</TextField>
            <TextArea
              errorMessage={errors?.lineConstituents?.message}
              register={{
                ...register("lineConstituents", {
                  onChange: handleChangedConstituents,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="DOT-information-anticipated-line-constituents"
            />
          </FieldWrapper>
        </ConstituentsWrapper>
        <SectionDivider />
        <FieldsWrapper>
          <SectionWrapper>
            <SectionHeaderName>
              {t("shipping-container-details")}
            </SectionHeaderName>
            <FieldWrapper>
              <TextField>
                {`${t("size")}`}
                <span> *</span>
              </TextField>
              <Select
                value={watch("containerSize") || ""}
                errorMessage={errors?.containerSize?.message}
                options={characteristics?.containerSize?.items || []}
                register={{
                  ...register("containerSize", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-container-size"
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("weight-oum")}`}
                <span> *</span>
              </TextField>
              <Select
                value={watch("containerWeightUom") || ""}
                errorMessage={errors?.containerWeightUom?.message}
                options={
                  characteristics?.containerUom?.items?.map(
                    ({ val }: IItems) => ({
                      id: val,
                      val,
                    })
                  ) || []
                }
                register={{
                  ...register("containerWeightUom", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-container-weight-uom"
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("type")}`}
                <span> *</span>
              </TextField>
              <Select
                value={watch("containerType") || ""}
                errorMessage={errors?.containerType?.message}
                options={characteristics?.containerType?.items || []}
                register={{
                  ...register("containerType", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-container-type"
              />
            </FieldWrapper>
          </SectionWrapper>
          <DotInformationDivider />
          <SectionWrapper>
            <SectionHeaderName>
              {t("anticipated-shipment-volume")}
            </SectionHeaderName>
            <FieldWrapper>
              <TextField>
                {`${t("quantity")}`}
                <span> *</span>
              </TextField>
              <Input
                errorMessage={errors?.anticipatedQuantity?.message}
                register={{
                  ...register("anticipatedQuantity", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-quantity"
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("size")}`}
                <span> *</span>
              </TextField>
              <Input
                errorMessage={errors?.anticipatedSize?.message}
                register={{
                  ...register("anticipatedSize", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-size"
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField>
                {`${t("time-frame")}`}
                <span> *</span>
              </TextField>
              <Select
                value={watch("anticipatedTimeframe") || ""}
                errorMessage={errors?.anticipatedTimeframe?.message}
                options={TIME_FRAME_OPTIONS}
                register={{
                  ...register("anticipatedTimeframe", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isActiveWasteProfile}
                id="DOT-information-anticipated-time-frame"
              />
            </FieldWrapper>
          </SectionWrapper>
        </FieldsWrapper>
        <ButtonWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCancel}
            id="DOT-information-cancel"
          />
          {!isActiveWasteProfile && (
            <Button
              text={t("continue")}
              onClick={handleContinue}
              id="DOT-information-continue"
            />
          )}
        </ButtonWrapper>
      </DOTInformationWrapper>
    </DOTInformationContainer>
  );
};
