import { Box, Typography, styled } from "@mui/material";

export const ItemsTitleWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const ItemsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));
