import { CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/header/header";
import { BackTo } from "src/shared/components/backTo/backTo";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { InspectionsNotificationsTable } from "src/components/inspectionsNotificationsTable/inspectionsNotificationsTable";
import {
  InspectionsNotificationsBody,
  InspectionsNotificationsContainer,
  InspectionsNotificationsTitle,
} from "./styles";

export const InspectionsNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsOpenUnsavedChangedDialog } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const backToInspectionsTab = () => {
    navigate(`/dashboard/${TABS_NAMES[3]}`);
  };

  const handleClickBackToInspectionTab = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToInspectionsTab();
    }
  };

  return (
    <InspectionsNotificationsContainer>
      <CssBaseline />
      <Header />
      <InspectionsNotificationsBody>
        <BackTo
          text={t("back-to-inspection-tab")}
          handleBack={handleClickBackToInspectionTab}
        />
        <InspectionsNotificationsTitle>
          {t("manage-inspection-reminder-notifications")}
        </InspectionsNotificationsTitle>
        <InspectionsNotificationsTitle>
          {t("inspections-notifications-description")}
        </InspectionsNotificationsTitle>
        <InspectionsNotificationsTable />
      </InspectionsNotificationsBody>
    </InspectionsNotificationsContainer>
  );
};
