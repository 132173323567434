import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "@mui/material";
import {
  useGetFollowUpOptionsQuery,
  useGetInspectionWithQuestionsTemplateByIDQuery,
} from "src/shared/store/api/inspection.api";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { Input } from "src/shared/components/input/input";
import { QUESTION_TYPE_ID } from "src/components/inspectionsTable/inspectionInformation/inspectionQuestions/constants";
import { TextArea } from "src/shared/components/textArea/textArea";
import { useAppActions } from "src/shared/hooks/redux/redux";
import { DetailInspectionFieldWrapper } from "../detailInspectionCard/styles";
import { Select } from "src/shared/components/select/select";
import { getFollowUpOptions } from "src/components/inspectionsTable/inspectionsFilters/constants";
import { InspectionFilesUploader } from "src/shared/components/inspectionFilesUploader/inspectionFilesUploader";
import { IOption } from "src/shared/components/select/types";
import {
  DetailInspectionDescription,
  DetailInspectionLabel,
  DetailInspectionTitle,
} from "../../pages/newInspection/styles";
import { IInspectionQuestions, IQuestion, IQuestionErrors } from "./types";
import {
  InspectionQuestionsContainer,
  QuestionContainer,
  Divider,
  QuestionNumberFieldWrapper,
  QuestionTextFieldWrapper,
  FormControlLabel,
  RadioGroupWrapper,
  Radio,
  InspectionQuestionsTitleWrapper,
  NumberOfQuestions,
} from "./styles";

export const InspectionQuestions = ({
  inspectionId,
  updateFormField,
  questionResults,
  errors,
  register,
  watch,
  control,
  questionFiles,
  setQuestionFiles,
}: IInspectionQuestions) => {
  const { t } = useTranslation();
  const { setChangedData } = useAppActions();

  const questionErrors = errors?.questionResults as unknown as IQuestionErrors;

  const { data: inspection } = useGetInspectionWithQuestionsTemplateByIDQuery(
    inspectionId,
    {
      skip: !inspectionId,
    }
  );

  const { data: followUpOptions } = useGetFollowUpOptionsQuery({});

  const handleChanged = () => {
    setChangedData(true);
  };

  const questions = inspection?.inspectionQuestions;

  const mappedFollowUpOptions = followUpOptions?.length
    ? getFollowUpOptions(followUpOptions)
    : [];

  useEffect(() => {
    updateFormField(
      "questionResults",
      questions?.map((question: IQuestion) => ({
        ...question,
        answerBool: question?.defaultAnswerBool,
        answerText: question?.defaultAnswerText,
        followUpOptionID: -1,
      })),
      false
    );
  }, [updateFormField, questions]);

  const handleFileChange = (files: File[], questionID: string) => {
    setQuestionFiles({
      ...questionFiles,
      [questionID]: files,
    });
  };

  return (
    <InspectionQuestionsContainer>
      <InspectionQuestionsTitleWrapper>
        <DetailInspectionTitle>
          {t("inspection-questions")}
        </DetailInspectionTitle>
        <NumberOfQuestions>{`(${questions?.[0]?.questionNumber || 0}-${
          questions?.[questions?.length - 1]?.questionNumber || 0
        })`}</NumberOfQuestions>
      </InspectionQuestionsTitleWrapper>
      {questionResults?.map((question: IQuestion, index: number) => {
        const defaultAnswerBoolString = question?.defaultAnswerBool?.toString();
        const answerBoolString = question?.answerBool?.toString();

        return (
          <QuestionContainer key={index}>
            <DetailInspectionFieldWrapper>
              <DetailInspectionLabel>
                {question?.questionNumber}
              </DetailInspectionLabel>
              <DetailInspectionDescription>
                {question?.prompt}
              </DetailInspectionDescription>
            </DetailInspectionFieldWrapper>
            <DetailInspectionFieldWrapper>
              <DetailInspectionLabel>{t("answer")}</DetailInspectionLabel>
              {question?.typeID === QUESTION_TYPE_ID.TRFL && (
                <RadioGroupWrapper key={index}>
                  <Controller
                    control={control}
                    name={`questionResults.${index}.answerBool`}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={"true"}
                          control={<Radio icon={<CheckedIcon />} />}
                          label={t("yes")}
                          onChange={handleChanged}
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio icon={<CheckedIcon />} />}
                          label={t("no")}
                          onChange={handleChanged}
                        />
                      </RadioGroup>
                    )}
                  />
                </RadioGroupWrapper>
              )}
              {question?.typeID === QUESTION_TYPE_ID.NMBR && (
                <QuestionNumberFieldWrapper>
                  <Input
                    register={register(
                      `questionResults.${index}.answerNumeric`,
                      {
                        onChange: handleChanged,
                      }
                    )}
                    type="number"
                    placeholder="0"
                    errorMessage={
                      questionErrors?.[index]?.answerNumeric?.message
                    }
                    id={`inspection-question-results-${index}-answer-numeric`}
                  />
                </QuestionNumberFieldWrapper>
              )}
              {question?.typeID === QUESTION_TYPE_ID.TEXT && (
                <QuestionTextFieldWrapper>
                  <TextArea
                    register={register(`questionResults.${index}.answerText`, {
                      onChange: handleChanged,
                    })}
                    placeholder={t("add-details-here")}
                    errorMessage={questionErrors?.[index]?.answerText?.message}
                    id={`inspection-question-results-${index}-answer-text`}
                  />
                </QuestionTextFieldWrapper>
              )}
            </DetailInspectionFieldWrapper>
            {(question?.typeID !== QUESTION_TYPE_ID.TRFL ||
              (question?.typeID === QUESTION_TYPE_ID.TRFL &&
                defaultAnswerBoolString !== answerBoolString)) && (
              <React.Fragment>
                {question?.typeID === QUESTION_TYPE_ID.TRFL && (
                  <DetailInspectionFieldWrapper>
                    <DetailInspectionLabel>
                      {t("number-of-violations")}
                    </DetailInspectionLabel>
                    <QuestionNumberFieldWrapper>
                      <Input
                        register={register(
                          `questionResults.${index}.numberOfViolations`,
                          {
                            onChange: handleChanged,
                          }
                        )}
                        type="number"
                        placeholder="0"
                        errorMessage={
                          questionErrors?.[index]?.numberOfViolations?.message
                        }
                        id={`inspection-question-results-${index}-number-of-violations`}
                      />
                    </QuestionNumberFieldWrapper>
                  </DetailInspectionFieldWrapper>
                )}
                <DetailInspectionFieldWrapper>
                  <DetailInspectionLabel>{t("comments")}</DetailInspectionLabel>
                  <QuestionTextFieldWrapper>
                    <TextArea
                      register={register(`questionResults.${index}.comments`, {
                        onChange: handleChanged,
                      })}
                      placeholder={t("comments")}
                      id={`inspection-question-results-${index}-number-of-comments`}
                    />
                  </QuestionTextFieldWrapper>
                </DetailInspectionFieldWrapper>
                <DetailInspectionFieldWrapper key={index}>
                  <DetailInspectionLabel>
                    {t("follow-up")}
                  </DetailInspectionLabel>
                  <Select
                    register={register(
                      `questionResults.${index}.followUpOptionID`,
                      {
                        onChange: handleChanged,
                      }
                    )}
                    value={watch(`questionResults.${index}.followUpOptionID`)}
                    options={mappedFollowUpOptions as IOption[]}
                    disabled={!mappedFollowUpOptions?.length}
                    errorMessage={
                      questionErrors?.[index]?.followUpOptionID?.message
                    }
                    id={`inspection-question-results-${index}-follow-up`}
                  />
                </DetailInspectionFieldWrapper>
                <InspectionFilesUploader
                  handleFiles={(files: File[]) =>
                    handleFileChange(files, question?.questionID)
                  }
                  files={questionFiles?.[question?.questionID]}
                />
              </React.Fragment>
            )}
            <Divider />
          </QuestionContainer>
        );
      })}
    </InspectionQuestionsContainer>
  );
};
