import { IPermission } from "./types";

export const PERMISSIONS_LIST = {
  SYSTEM_LOGIN: 13,
  USER_ADMIN: 10,
  EDIT_ACCOUNT_ADMIN: 9,
  INVITE_USER: 8,
  EDIT_USER_PERMISSION_LIST: 2,
  EDIT_USER_PERMISSION_FOR_SELF: 3,
  ASSIGN_ACCOUNTS_ACCESS: 53,
  USER_ACCOUNT_ADMIN: 7,
  CREATE_PROFILE: 25,
  ACCESS_INSPECTIONS: 14,
  DELETE_INSPECTIONS: 12,
  EDIT_BANNER: 27,
};

export const hasPermissions = (
  permissions: Array<IPermission>,
  permissionNumber: number
) => {
  return !!permissions?.find(
    (permission: IPermission) => permission.permissionID === permissionNumber
  );
};
