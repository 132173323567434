import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { SelectChangeEvent, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as ArrowDown } from "../../shared/assets/svgs/arrow-down.svg";
import {
  ChangeLanguageContainer,
  ChangeLanguageMenuItem,
  ChangeLanguageSelect,
  ChangeLanguageText,
} from "./styles";
import { CHANGE_LANGUAGE_CONFIG } from "./constants";

export const ChangeLanguage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanLarge = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChangeLanguage = (event: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(event.target.value as string);
  };

  const renderSelectValue = (value: unknown) => (
    <ChangeLanguageText>
      {lessThanLarge
        ? t(`${value}-small`)
        : `${t("language")}: ${t(value as string)}`}
    </ChangeLanguageText>
  );

  return (
    <ChangeLanguageContainer>
      <ChangeLanguageSelect
        onChange={handleChangeLanguage}
        value={i18n.language}
        renderValue={renderSelectValue}
        IconComponent={ArrowDown}
      >
        {CHANGE_LANGUAGE_CONFIG.map(
          (language: string, languageIndex: number) => (
            <ChangeLanguageMenuItem value={language} key={languageIndex}>
              {t(language)}
            </ChangeLanguageMenuItem>
          )
        )}
      </ChangeLanguageSelect>
    </ChangeLanguageContainer>
  );
};
