import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "src/shared/components/list/list";
import { Tooltip } from "src/shared/components/tooltip/tooltip";
import filterIcon from "src/shared/assets/svgs/filter-icon.svg";
import { IInspectionsTooltip } from "./types";
import { ListWrapper } from "./styles";

export const InspectionsTooltip = ({ open, setOpen }: IInspectionsTooltip) => {
  const { t } = useTranslation();

  return (
    <Tooltip open={open} setOpen={setOpen}>
      <React.Fragment>
        <ListWrapper>
          <List items={[t("type-a-keyword-into-search")]} />
          <List
            items={[
              t("click-filter-icon-more-filters", { filterIcon: filterIcon }),
            ]}
          />
          <List items={[t("use-as-many-filters-as-needed")]} />
          <List items={[t("default-date-range-is-365")]} />
          <List items={[t("export-inspection-list-with-tool")]} />
        </ListWrapper>
      </React.Fragment>
    </Tooltip>
  );
};
