export const saveToLocalStorage = (key: string, data: unknown) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getFromLocalStorage = (key: string) => {
  const stringData = localStorage.getItem(key);

  return stringData ? JSON.parse(stringData) : stringData;
};

export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
