import { SyntheticEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { Input } from "src/shared/components/input/input";
import {
  useGetWasteCodesQuery,
  useNewChemicalMutation,
} from "src/shared/store/api/regulatory.api";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { IOption } from "src/shared/components/select/types";
import { CreateChemicalValidationSchema } from "./model";
import { ICreateChemicalDialog, IWasteCode } from "./types";
import {
  INITIAL_CREATE_CHEMICAL_VALUES,
  MAX_WASTE_CODES_VALUE,
} from "./constants";
import {
  DialogLabel,
  LabelWrapper,
  CreateChemicalDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const CreateChemicalDialog = ({
  open,
  handleClose,
  chemicalName,
  setChemicalValue,
}: ICreateChemicalDialog) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateChemicalValidationSchema(t)),
    mode: "onSubmit",
  });

  const [
    createChemical,
    {
      isSuccess: isSuccessCreateChemical,
      isLoading: isLoadingCreateChemical,
      data: createChemicalData,
    },
  ] = useNewChemicalMutation();

  const { data: wasteCodes } = useGetWasteCodesQuery({});

  const resetCreateChemicalData = useCallback(() => {
    reset(INITIAL_CREATE_CHEMICAL_VALUES);
  }, [reset]);

  const handleCloseCreateChemical = () => {
    resetCreateChemicalData();
    handleClose();
  };

  useEffect(() => {
    if (isSuccessCreateChemical && !isLoadingCreateChemical) {
      setChemicalValue(
        createChemicalData?.chemicalName,
        createChemicalData?.chemicalName
      );
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreateChemical]);

  useEffect(() => {
    if (open) {
      setValue("chemicalName", chemicalName);
    }
  }, [chemicalName, setValue, open]);

  useEffect(() => {
    if (isSuccessCreateChemical) {
      resetCreateChemicalData();
    }
  }, [isSuccessCreateChemical, resetCreateChemicalData]);

  const handleCreateChemical = handleSubmit(
    ({ cas, chemicalName, uhc1, uhc2, wasteCodes }) => {
      createChemical({
        cas,
        chemicalName,
        uhc1,
        uhc2,
        wc1: wasteCodes?.[0]?.id,
        wc2: wasteCodes?.[1]?.id,
        wc3: wasteCodes?.[2]?.id,
        wc4: wasteCodes?.[3]?.id,
        wc5: wasteCodes?.[4]?.id,
        wc6: wasteCodes?.[5]?.id,
      });
    }
  );

  const createChemicalDialogActions = () => {
    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCloseCreateChemical}
          id="create-chemical-cancel"
        />
        <Button
          text={t("save")}
          onClick={handleCreateChemical}
          id="create-chemical-save"
        />
      </SendBtnWrapper>
    );
  };
  const wasteCodesValues = watch("wasteCodes");

  const onChangeWastCodes = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setValue("wasteCodes", newInputValue as Array<IOption>);
  };

  const renderCreateChemicalDialogFields = () => {
    return (
      <CreateChemicalDialogContentContainer>
        <LabelWrapper>
          <DialogLabel>{t("chemical-name")}</DialogLabel>
          <Input
            placeholder={t("chemical-name")}
            errorMessage={errors?.chemicalName?.message}
            register={{
              ...register("chemicalName"),
            }}
            id="create-chemical-name"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("waste-codes")}</DialogLabel>
          <Autocomplete
            options={
              wasteCodes
                ? wasteCodes?.map(({ id, description }: IWasteCode) => ({
                    id,
                    val: description,
                  }))
                : []
            }
            value={wasteCodesValues || []}
            onChange={onChangeWastCodes}
            multiple
            getOptionDisabled={() =>
              wasteCodesValues
                ? wasteCodesValues?.length > MAX_WASTE_CODES_VALUE
                : false
            }
            id="create-chemical-waste-codes"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("uhc1")}</DialogLabel>
          <Input
            placeholder={t("uhc1")}
            register={{
              ...register("uhc1"),
            }}
            id="create-chemical-uhc1"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("uhc2")}</DialogLabel>
          <Input
            placeholder={t("uhc2")}
            register={{
              ...register("uhc2"),
            }}
            id="create-chemical-uhc2"
          />
        </LabelWrapper>
        <LabelWrapper>
          <DialogLabel>{t("cas")}</DialogLabel>
          <Input
            placeholder={t("cas")}
            register={{
              ...register("cas"),
            }}
            errorMessage={errors?.cas?.message}
            id="create-chemical-cas"
          />
        </LabelWrapper>
      </CreateChemicalDialogContentContainer>
    );
  };

  return (
    <Dialog
      open={open}
      title={t("create-chemical")}
      actions={createChemicalDialogActions}
      renderFields={renderCreateChemicalDialogFields}
      handleClose={handleCloseCreateChemical}
    />
  );
};
