import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { Input } from "src/shared/components/input/input";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { useGetOrganizationsByIDsQuery } from "src/shared/store/api/organization.api";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { MultiEmail } from "src/shared/components/multiEmail/multiEmail";
import { ISite } from "src/pages/accountInformation/types";
import {
  useDeleteUserInventoryNotificationMutation,
  useGetUserInventoryNotificationQuery,
  useUpsertUserInventoryNotificationMutation,
} from "src/shared/store/api/notification.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { AccountCardWithSiteSelection } from "../accountCardWithSiteSelection/accountCardWithSiteSelection";
import { Alert } from "src/shared/components/alert/alert";
import { InventoryNotificationFormValidationSchema } from "./model";
import { IInventoryNotificationFields } from "./types";
import {
  InventoryNotificationFormContainer,
  InventoryNotificationFormFieldDaysWrapper,
  InventoryNotificationFormFieldWrapper,
  InventoryNotificationFormLabel,
  AccountCardWithSiteSelectionWrapper,
  ActionsWrapper,
  ErrorHelperText,
} from "./styles";

export const InventoryNotificationForm = () => {
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InventoryNotificationFormValidationSchema(t)),
    mode: "onSubmit",
  });

  const selectedSiteList = watch("siteIDList") || [];
  const emails = watch("emailDistributionList") || [];

  const [
    upsertUserInventoryNotification,
    {
      isLoading: isLoadingUpsertInventoryNotification,
      isSuccess: isSuccessUpsertInventoryNotification,
    },
  ] = useUpsertUserInventoryNotificationMutation({});

  const {
    data: inventoryNotificationData,
    isFetching: isFetchingInventoryNotificationData,
    isSuccess: isSuccessInventoryNotificationData,
  } = useGetUserInventoryNotificationQuery({});

  const [
    deleteInventoryNotification,
    {
      isLoading: isLoadingDeleteInventoryNotification,
      isSuccess: isSuccessDeleteInventoryNotification,
    },
  ] = useDeleteUserInventoryNotificationMutation({});

  const { data: userProfile = {}, isLoading: isLoadingUserProfile } =
    useGetUserProfileByIDQuery(
      {
        id: accounts[0]?.username,
      },
      {
        skip: !accounts[0]?.username,
      }
    );

  const organizationIds = userProfile?.userAccounts?.map(
    (account: IUserAccount) => account?.organizationUnitID
  );

  const { data: organizationData, isLoading: isLoadingOrganizationData } =
    useGetOrganizationsByIDsQuery(organizationIds, {
      skip: !organizationIds,
    });

  const handleChangedData = () => {
    setChangedData(true);
  };

  const backToManifestTab = useCallback(() => {
    navigate(`/dashboard/${TABS_NAMES[5]}`);
  }, [navigate]);

  const handleCloseInventoryNotificationForm = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToManifestTab();
    }
  };

  const setSelectedSiteList = (values: number[]) => {
    setValue(
      "siteIDList",
      values?.length === 0 ? (undefined as unknown as number[]) : values,
      {
        shouldValidate: errors?.siteIDList?.message ? true : false,
      }
    );
    handleChangedData();
  };

  const handleChangeEmails = (_emails: string[]) => {
    setValue("emailDistributionList", _emails);
    handleChangedData();
  };

  const handleCreateNotification = handleSubmit((data) => {
    upsertUserInventoryNotification(data);
  });

  const handleDeleteInventoryNotification = () => {
    deleteInventoryNotification({});
  };

  const handleCloseSuccessAlert = () => {
    setIsOpenSuccessAlert(false);
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      backToManifestTab();
      clearErrors();
      reset({});
    });
  }, [clearErrors, backToManifestTab, reset, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleCreateNotification().then(() => {
        backToManifestTab();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCreateNotification]);

  useEffect(() => {
    if (inventoryNotificationData) {
      for (const value of Object.keys(inventoryNotificationData || {})) {
        setValue(
          value as IInventoryNotificationFields,
          inventoryNotificationData[value],
          {
            shouldValidate: true,
          }
        );
      }
    } else {
      if (isSuccessInventoryNotificationData) {
        if (userProfile?.email) {
          setValue("emailDistributionList", [userProfile?.email]);
        }

        setValue("daysUntilNonCompliant", 14);
      }
    }
  }, [
    inventoryNotificationData,
    isSuccessInventoryNotificationData,
    setValue,
    userProfile?.email,
  ]);

  useEffect(() => {
    if (isSuccessUpsertInventoryNotification) {
      setChangedData(false);
      setIsOpenSuccessAlert(true);
    }
  }, [isSuccessUpsertInventoryNotification, setChangedData]);

  useEffect(() => {
    if (isSuccessDeleteInventoryNotification) {
      reset();
      clearErrors();
      setChangedData(false);
    }
  }, [
    clearErrors,
    isSuccessDeleteInventoryNotification,
    reset,
    setChangedData,
  ]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingUserProfile ||
        isLoadingOrganizationData ||
        isLoadingUpsertInventoryNotification ||
        isLoadingDeleteInventoryNotification
      }
      loader={<CircularProgress />}
      keepInView
    >
      <InventoryNotificationFormContainer>
        <InventoryNotificationFormFieldDaysWrapper>
          <InventoryNotificationFormLabel>
            {t("number-of-days-until-non-compliant")}
          </InventoryNotificationFormLabel>
          <Input
            register={{
              ...register("daysUntilNonCompliant", {
                onChange: handleChangedData,
              }),
            }}
            errorMessage={errors?.daysUntilNonCompliant?.message}
            id="inventory-notification-days-until-non-compliant"
          />
        </InventoryNotificationFormFieldDaysWrapper>
        <AccountCardWithSiteSelectionWrapper>
          <InventoryNotificationFormLabel>
            {t("select-generator-sites")}
          </InventoryNotificationFormLabel>
          <ErrorHelperText>{errors?.siteIDList?.message}</ErrorHelperText>
          {organizationData?.map(({ organizationUnitID, name }: ISite) => (
            <AccountCardWithSiteSelection
              key={organizationUnitID}
              id={organizationUnitID}
              accountName={name}
              selectedSiteList={selectedSiteList}
              setSelectedSiteList={setSelectedSiteList}
            />
          ))}
        </AccountCardWithSiteSelectionWrapper>
        <InventoryNotificationFormFieldWrapper>
          <InventoryNotificationFormLabel>
            {t("distribution-list")}
          </InventoryNotificationFormLabel>
          <MultiEmail
            values={emails as string[]}
            onChange={handleChangeEmails}
            id="inventory-notification-distribution-list"
            errorMessage={errors.emailDistributionList?.message}
          />
        </InventoryNotificationFormFieldWrapper>
        <ActionsWrapper>
          <Button
            isGrey
            text={t("back")}
            onClick={handleCloseInventoryNotificationForm}
            id="inventory-notification-back"
          />
          {inventoryNotificationData && (
            <Button
              text={t("delete")}
              isError
              onClick={handleDeleteInventoryNotification}
              disabled={
                !!isLoadingUpsertInventoryNotification ||
                !!isFetchingInventoryNotificationData
              }
              id="inventory-notification-delete"
            />
          )}
          <Button
            text={t("save-and-finish")}
            onClick={handleCreateNotification}
            id="inventory-notification-save-and-finish"
          />
        </ActionsWrapper>
      </InventoryNotificationFormContainer>
      <Alert
        open={isOpenSuccessAlert}
        handleClose={handleCloseSuccessAlert}
        messages={[t("inventory-notification-distribution-saved-successfully")]}
        severity="success"
      />
    </BlockUi>
  );
};
