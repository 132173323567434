import * as yup from "yup";
import dayjs from "dayjs";
import { QUESTION_TYPE_ID } from "src/components/inspectionsTable/inspectionInformation/inspectionQuestions/constants";

export const NewInspectionValidationSchema = (
  t: (value: string) => string,
  isBulkInspection: boolean
) =>
  yup.object().shape({
    inspectionDate: yup.date().required(t("required-field")),
    inspectionTimeRequired: yup.boolean(),
    inspectionTime: yup
      .string()
      .when(["inspectionTimeRequired"], (inspectionTimeRequired, schema) => {
        return inspectionTimeRequired?.[0]
          ? schema.required(t("required-field"))
          : schema;
      })
      .when(["inspectionDate"], (data, schema, { value }) => {
        const inspectionDate = data?.[0];
        const isToday = dayjs(inspectionDate).isSame(dayjs(), "day");

        return isToday && dayjs().isBefore(value)
          ? schema.test({
              message: t("select-a-valid-inspection-time"),
              test: () => false,
            })
          : schema;
      }),
    inspectorName: yup.string().required(t("required-field")),
    inspectorID: yup.string().required(t("required-field")),
    accountName: yup.string().required(t("required-field")),
    accountOrganizationUnitID: yup.number().required(t("required-field")),
    siteName: yup.string().required(t("required-field")),
    siteOrganizationUnitID: yup.string().required(t("required-field")),
    buildingName: yup.string().required(t("required-field")),
    buildingOrganizationUnitID: yup.string().required(t("required-field")),
    roomOrganizationUnitID: isBulkInspection
      ? yup.string()
      : yup.string().required(t("required-field")),
    roomName: isBulkInspection
      ? yup.string()
      : yup.string().required(t("required-field")),
    roomOrganizationUnitIDList: isBulkInspection
      ? yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string(),
              val: yup.string(),
            })
          )
          .required()
      : yup.array(),
    saaCouchbaseStorageID: isBulkInspection
      ? yup.string()
      : yup.string().required(t("required-field")),
    storageName: isBulkInspection
      ? yup.string()
      : yup.string().nullable().required(t("required-field")),
    inspectionID: yup.string().required(t("required-field")),
    inspectionTitle: yup.string().required(t("required-field")),
    inspectionDescription: yup.string(),
    followUpOptionID: yup.string().required(t("required-field")),
    followUpOption: yup.string().required(t("required-field")),
    comments: yup.string(),
    questionResults: isBulkInspection
      ? yup.array()
      : yup.array().of(
          yup.object().shape({
            answerNumeric: yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .when(
                ["answerRequired", "typeID"],
                ([answerRequired, typeID], schema) => {
                  return answerRequired && typeID === QUESTION_TYPE_ID.NMBR
                    ? schema.required(t("required-field"))
                    : schema;
                }
              )
              .nullable()
              .integer()
              .min(0),
            questionID: yup.string(),
            typeID: yup.string(),
            answerBool: yup.boolean().nullable(),
            numberOfViolations: yup
              .number()
              .transform((v, o) => (o === "" ? null : v))
              .nullable()
              .integer()
              .positive()
              .when(
                [
                  "numberOfViolationsRequired",
                  "answerBool",
                  "defaultAnswerBool",
                ],
                (
                  [numberOfViolationsRequired, answerBool, defaultAnswerBool],
                  schema
                ) => {
                  return defaultAnswerBool !== answerBool &&
                    numberOfViolationsRequired
                    ? schema.required(t("required-field"))
                    : schema;
                }
              ),
            answerText: yup
              .string()
              .when(
                ["answerRequired", "typeID"],
                ([answerRequired, typeID], schema) => {
                  return answerRequired && typeID === QUESTION_TYPE_ID.TEXT
                    ? schema.required(t("required-field"))
                    : schema;
                }
              )
              .nullable(),
            comments: yup.string(),
            questionNumber: yup.number(),
            prompt: yup.string(),
            followUpChangedByName: yup.string(),
            followUpOption: yup.string(),
            followUpOptionID: yup.number(),
          })
        ),
  });
