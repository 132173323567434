export const POSITION_OF_CHARTS = {
  MANIFEST: 0,
  WASTE: 1,
  INSPECTIONS: 2,
  INVENTORY: 3,
  INSPECTIONS_DUE: 4,
};

export const DEFAULT_CHARTS_POSITION = [
  POSITION_OF_CHARTS.MANIFEST,
  POSITION_OF_CHARTS.WASTE,
  POSITION_OF_CHARTS.INSPECTIONS,
  POSITION_OF_CHARTS.INVENTORY,
  POSITION_OF_CHARTS.INSPECTIONS_DUE,
];

export const DASHBOARD_CHART_IDS = "DASHBOARD_CHART_IDS";
