import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import question from "src/shared/assets/svgs/question.svg";
import { TextArea } from "src/shared/components/textArea/textArea";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  MAX_FILE_SIZE,
  inputFileAccept,
} from "src/components/uploadedFilesForm/constants";
import {
  useGetWasteProfileRequestByIDQuery,
  useUploadWasteProfileRequestAttachmentMutation,
  useUpsertProfileRequestMutation,
} from "src/shared/store/api/wasteProfile.api";
import { TABS_NAMES } from "src/shared/store/reducers/menu/menu.slice";
import { useLazyGetAttachmentByDocumentIDAndFileIDQuery } from "src/shared/store/api/attachments.api";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as AddCircle } from "src/shared/assets/svgs/add-circle.svg";
import { AdditionalFilesTooltip } from "../additionalFilesTooltip/additionalFilesTooltip";
import { STATUS_ID_VALUES, SECTION } from "../../pages/newProfile/constants";
import {
  ButtonWrapper,
  FieldWrapper,
  IndicatesRequiredField,
  SectionHeader,
  TextField,
} from "../../pages/newProfile/styles";
import { AdditionalInformationValidationSchema } from "./model";
import { IAttachment } from "./types";
import {
  AdditionalInformationContainer,
  NotesWrapper,
  UploadedFile,
  AdditionalInformationWrapper,
  Question,
  QuestionWrapper,
  AdditionalInformationHeaderWrapper,
  AdditionalFilesWrapper,
  AddAttachmentButtonWrapper,
} from "./styles";

export const AdditionalInformation = () => {
  const { t } = useTranslation();
  const { profileID } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { history } = useHistory();
  const previousLocation = history?.[history.length - 2] as string;
  const previousProfilesView =
    previousLocation && previousLocation?.includes("profiles");
  const [isOpenAdditionalFilesTooltip, setIsOpenAdditionalFilesTooltip] =
    useState(false);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AdditionalInformationValidationSchema(t)),
    mode: "onSubmit",
  });

  const {
    setChangedData,
    setSnackBarMessages,
    setIsOpenSnackBar,
    setHandleUpdateUnsavedChanged,
    setHandleBackUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  } = useAppActions();

  const { changedData, isOpenUnsavedChangedDialog } = useAppSelector(
    (state) => state.unsavedChangesReducer
  );

  const { data: wasteProfileData } = useGetWasteProfileRequestByIDQuery({
    id: profileID,
  });

  const isActiveWasteProfile =
    wasteProfileData?.statusID === STATUS_ID_VALUES.ACTIVE;

  const [getAttachment] = useLazyGetAttachmentByDocumentIDAndFileIDQuery();

  const [upsertProfileRequest] = useUpsertProfileRequestMutation({});

  const [uploadWasteProfileAttachment] =
    useUploadWasteProfileRequestAttachmentMutation({});

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleClickAttachFile = () => {
    inputRef?.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file?.size / 1024 > MAX_FILE_SIZE) {
      setSnackBarMessages([t("maximum-allowed-attachment")]);
      setIsOpenSnackBar(true);
    } else {
      const body = new FormData();
      body.append("file", file as File);
      uploadWasteProfileAttachment({
        docID: profileID,
        body,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContinue = useCallback(
    handleSubmit(({ note }) => {
      upsertProfileRequest({
        id: profileID,
        note,
        sectionStep: SECTION.REVIEW,
      });
      setChangedData(false);
    }),
    []
  );

  useEffect(() => {
    const note = wasteProfileData?.note;
    if (note) {
      setValue("note", note);
    }
  }, [setValue, wasteProfileData]);

  const backToDashboard = useCallback(() => {
    navigate(
      `/dashboard/${previousProfilesView ? TABS_NAMES[4] : TABS_NAMES[1]}`
    );
  }, [navigate, previousProfilesView]);

  const handleContinueWithBackToProfileTab = useCallback(() => {
    handleContinue();
    backToDashboard();
  }, [backToDashboard, handleContinue]);

  const handleCancel = useCallback(() => {
    if (changedData) {
      setHandleBackUnsavedChanged(() => {
        backToDashboard();
      });
      setHandleUpdateUnsavedChanged(handleContinueWithBackToProfileTab);
      setIsOpenUnsavedChangedDialog(true);
    } else {
      backToDashboard();
    }
  }, [
    backToDashboard,
    changedData,
    handleContinueWithBackToProfileTab,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
    setIsOpenUnsavedChangedDialog,
  ]);

  const handleGetAttachment = (fileName: string, fileID: string) => () =>
    getAttachment({
      fileName,
      docID: profileID,
      fileID,
    });

  const handleOpenAdditionalFilesTooltip = () => {
    setIsOpenAdditionalFilesTooltip(true);
  };

  useEffect(() => {
    if (!isOpenUnsavedChangedDialog) {
      setHandleUpdateUnsavedChanged(handleContinue);
    }
  }, [
    handleContinue,
    setHandleUpdateUnsavedChanged,
    isOpenUnsavedChangedDialog,
  ]);

  return (
    <AdditionalInformationContainer>
      <AdditionalInformationWrapper>
        <AdditionalInformationHeaderWrapper>
          <SectionHeader>{t("additional-information-section")}</SectionHeader>
          <IndicatesRequiredField>
            <span>*</span> {t("indicates-a-required-field")}
          </IndicatesRequiredField>
        </AdditionalInformationHeaderWrapper>
        <NotesWrapper>
          <FieldWrapper>
            <TextField>{t("notes")}</TextField>
            <TextArea
              errorMessage={errors?.note?.message}
              register={{
                ...register("note", {
                  onChange: handleChangedData,
                }),
              }}
              disabled={isActiveWasteProfile}
              id="additional-information-note"
            />
          </FieldWrapper>
        </NotesWrapper>
        <AdditionalFilesWrapper>
          {!isActiveWasteProfile && (
            <FieldWrapper>
              <QuestionWrapper>
                <TextField>{`${t("additional-files")}`}</TextField>
                <Question
                  src={question}
                  alt=""
                  onClick={handleOpenAdditionalFilesTooltip}
                />
                <AdditionalFilesTooltip
                  open={isOpenAdditionalFilesTooltip}
                  setOpen={setIsOpenAdditionalFilesTooltip}
                />
              </QuestionWrapper>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                accept={inputFileAccept}
              />
              <AddAttachmentButtonWrapper>
                <ButtonWithIcon
                  text={t("add-attachment")}
                  onClick={handleClickAttachFile}
                  icon={<AddCircle />}
                  id="additional-information-add-attachment"
                />
              </AddAttachmentButtonWrapper>
            </FieldWrapper>
          )}
        </AdditionalFilesWrapper>
        <FieldWrapper>
          <TextField>{`${t("uploaded-files")}`}</TextField>
          {wasteProfileData?.attachments?.map(
            ({ fileName, fileID }: IAttachment) => (
              <UploadedFile
                key={fileID}
                onClick={handleGetAttachment(fileName, fileID)}
              >
                {fileName}
              </UploadedFile>
            )
          )}
        </FieldWrapper>
        <ButtonWrapper>
          <Button
            isGrey
            text={t("cancel")}
            onClick={handleCancel}
            id="additional-information-cancel"
          />
          {!isActiveWasteProfile && (
            <Button
              text={t("continue")}
              onClick={handleContinue}
              id="additional-information-continue"
            />
          )}
        </ButtonWrapper>
      </AdditionalInformationWrapper>
    </AdditionalInformationContainer>
  );
};
