import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const NewProfileContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  paddingTop: 40,
}));

export const NewProfileBody = styled(Box)(({ theme }) => ({
  flex: "1 0 auto",
  width: 1088,
  background: isDarkMode(theme.palette.mode) ? "#202124" : "#FFF",
  boxShadow: isDarkMode(theme.palette.mode)
    ? "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
    : "0px 10px 15px 4px #D9D9D9",
  padding: "44px 90px 22px 90px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: 16,
  },
}));

export const NewProfileTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "left",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginTop: 24,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    marginTop: 20,
  },
}));

export const BackWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  gap: 8,
}));

export const BackIcon = styled("img")(() => ({
  width: 24,
  height: 24,
}));

export const BackText = styled(Typography)(({ theme }) => ({
  color: "#217BDB",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textDecorationLine: "underline",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const SectionHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: "center",
  fontSize: 24,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 26,
  },
}));

export const IndicatesRequiredField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginTop: 24,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  "& > span": {
    color: "#F41808",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));

export const SectionDivider = styled(Box)(() => ({
  marginTop: 24,
  width: "100%",
  height: 1,
  backgroundColor: "#D9D9D9",
}));

export const TextField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  alignItems: "center",
  display: "flex",
  gap: 8,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "& > span": {
    color: "#F41808",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontWeight: 400,
    color: "#B1B1B1",
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: 24,
  marginTop: 24,
  " & > button": {
    width: 111,
    height: 38,
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  marginTop: 16,
  maxWidth: 368,
  display: "flex",
  flexDirection: "column",
  gap: 8,
  "& .MuiFormHelperText-root": {
    top: "32px !important",
    marginLeft: 0,
    [theme.breakpoints.down("md")]: {
      top: "28px !important",
    },
  },
  "& > div > div": {
    height: 32,
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
    "& > div": {
      height: 32,
      [theme.breakpoints.down("md")]: {
        height: 28,
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
  },
}));

export const ErrorText = styled(Typography)(() => ({
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
}));
