import { Box, Typography, styled } from "@mui/material";
import checkedSection from "src/shared/assets/svgs/checked-section.svg";
import currentSection from "src/shared/assets/svgs/current-section.svg";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { INavigationCircleStyles, INavigationLineStyles } from "./types";

export const NavigationLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isPassedSection",
})<INavigationLineStyles>(({ isPassedSection }) => ({
  width: "100%",
  backgroundColor: isPassedSection ? "#217BDB" : "#B1B1B1",
  height: 2,
}));

export const NavigationSectionsContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  padding: "24px 20px 0 20px",
}));

export const NavigationCircle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isPassedSection" &&
    prop !== "isActiveSection" &&
    prop !== "isClickable",
})<INavigationCircleStyles>(
  ({ theme, isPassedSection, isActiveSection, isClickable }) => ({
    minWidth: 18,
    minHeight: 18,
    borderRadius: "50%",
    backgroundColor: isPassedSection || isActiveSection ? "#217BDB" : "#B1B1B1",
    backgroundImage: isActiveSection
      ? `url(${currentSection})`
      : isPassedSection
      ? `url(${checkedSection})`
      : "",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: isClickable ? "pointer" : "default",
    [theme.breakpoints.down("md")]: {
      minWidth: 12,
      minHeight: 12,
      backgroundSize: isActiveSection ? 5 : 8,
    },
  })
);

export const SectionText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 34,
  left: -15,
  color: isDarkMode(theme.palette.mode)
    ? theme.palette.secondary.main
    : "#282828",
  fontSize: 14,
  fontWeight: 300,
  width: "max-content",
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
    top: 20,
    left: 3,
  },
}));

export const SectionContainer = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));
