import { generatedIdForSwitch } from "src/shared/helpers/generatedId";
import { ISwitch } from "./types";
import { StyledSwitch } from "./styles";

export const Switch = ({ checked, onChange, disabled, id }: ISwitch) => {
  return (
    <StyledSwitch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      id={generatedIdForSwitch(id)}
    />
  );
};
