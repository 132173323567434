import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/shared/components/button/button";
import { Input } from "src/shared/components/input/input";
import { TextArea } from "src/shared/components/textArea/textArea";
import { DateTimePicker } from "src/shared/components/dateTimePicker/dateTimePicker";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import {
  useDeleteBannerByIdMutation,
  useGetBannerByIdQuery,
  useUpsertBannerMutation,
} from "src/shared/store/api/banner.api";
import { Notification } from "src/shared/components/notification/notification";
import { SystemMessageValidationSchema } from "./model";
import {
  SystemMessageFormContainer,
  SystemMessageFormFieldWrapper,
  SystemMessageFormLabel,
  SystemMessageFormTitle,
  SystemMessageButtonWrapper,
  IndicatesRequiredField,
  SystemMessageFormPreviewWrapper,
} from "./styles";

export const SystemMessageForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bannerId } = useParams();
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );
  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const [
    upsertBanner,
    { isLoading: isLoadingUpsertBanner, isSuccess: isSuccessUpsertBanner },
  ] = useUpsertBannerMutation({});

  const { data: bannerData, isFetching: isFetchingBannerData } =
    useGetBannerByIdQuery(bannerId, {
      skip: !bannerId,
    });

  const [
    deleteBanner,
    { isLoading: isLoadingDeleteBanned, isSuccess: isSuccessDeleteBanner },
  ] = useDeleteBannerByIdMutation({});

  const {
    setValue,
    watch,
    handleSubmit,
    register,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SystemMessageValidationSchema(t)),
    mode: "all",
  });

  const title = watch("title");
  const description = watch("description");
  const startDisplayDate = watch("startDisplayDate");
  const stopDisplayDate = watch("stopDisplayDate");

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleBackToSiteAdmin = useCallback(() => {
    navigate("/site-admin");
  }, [navigate]);

  const handleCancel = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      setChangedData(false);
      handleBackToSiteAdmin();
    }
  };

  const handleChangeStartDisplayDate = (date: unknown) => {
    if (date) {
      handleChangedData();
      setValue("startDisplayDate", date as Date, { shouldValidate: true });
    }
  };

  const handleChangeStopDisplayDate = (date: unknown) => {
    if (date) {
      handleChangedData();
      setValue("stopDisplayDate", date as Date, { shouldValidate: true });
    }
  };

  const handleUpsertBanner = handleSubmit((data) => {
    upsertBanner(data);
  });

  const handleDeleteBanner = () => {
    deleteBanner(bannerId);
  };

  useEffect(() => {
    if (bannerData) {
      for (const value of Object.keys(bannerData)) {
        setValue(value as any, bannerData[value], {
          shouldValidate: true,
        });
      }
    }
  }, [bannerData, setValue]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => handleUpsertBanner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleUpsertBanner]);

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleBackToSiteAdmin();
      clearErrors();
      reset();
    });
  }, [clearErrors, handleBackToSiteAdmin, reset, setHandleBackUnsavedChanged]);

  useEffect(() => {
    if (isSuccessUpsertBanner) {
      setChangedData(false);
      handleBackToSiteAdmin();
    }
  }, [isSuccessUpsertBanner, setChangedData, handleBackToSiteAdmin]);

  useEffect(() => {
    if (isSuccessDeleteBanner) {
      setChangedData(false);
      handleBackToSiteAdmin();
    }
  }, [isSuccessDeleteBanner, setChangedData, handleBackToSiteAdmin]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingUpsertBanner || isLoadingDeleteBanned || isFetchingBannerData
      }
      loader={<CircularProgress />}
      keepInView
    >
      <SystemMessageFormContainer>
        <SystemMessageFormTitle>{t("system-message")}</SystemMessageFormTitle>
        <IndicatesRequiredField>
          <span>*</span> {t("indicates-a-required-field")}
        </IndicatesRequiredField>
        <SystemMessageFormFieldWrapper>
          <SystemMessageFormLabel>
            {t("title")}
            <span> *</span>
          </SystemMessageFormLabel>
          <Input
            errorMessage={errors?.title?.message}
            register={{
              ...register("title", {
                onChange: handleChangedData,
              }),
            }}
            id="system-message-title"
          />
        </SystemMessageFormFieldWrapper>
        <SystemMessageFormFieldWrapper>
          <SystemMessageFormLabel>
            {t("description")}
            <span> *</span>
          </SystemMessageFormLabel>
          <TextArea
            errorMessage={errors?.description?.message}
            register={{
              ...register("description", {
                onChange: handleChangedData,
              }),
            }}
            id="system-message-description"
          />
        </SystemMessageFormFieldWrapper>
        <SystemMessageFormFieldWrapper>
          <SystemMessageFormLabel>
            {t("start-displaying")}
            <span> *</span>
          </SystemMessageFormLabel>
          <DateTimePicker
            value={startDisplayDate ? dayjs(startDisplayDate) : null}
            register={register("startDisplayDate")}
            onChange={handleChangeStartDisplayDate}
            errorMessage={errors?.startDisplayDate?.message}
            id="system-message-start-display-date"
          />
        </SystemMessageFormFieldWrapper>
        <SystemMessageFormFieldWrapper>
          <SystemMessageFormLabel>
            {t("stop-displaying")}
            <span> *</span>
          </SystemMessageFormLabel>
          <DateTimePicker
            value={stopDisplayDate ? dayjs(stopDisplayDate) : null}
            register={register("stopDisplayDate")}
            onChange={handleChangeStopDisplayDate}
            errorMessage={errors?.stopDisplayDate?.message}
            id="system-message-stop-display-date"
          />
        </SystemMessageFormFieldWrapper>
        <SystemMessageFormPreviewWrapper>
          <SystemMessageFormLabel>{t("preview")}</SystemMessageFormLabel>
          {(title || description) && (
            <Notification title={title} text={description} />
          )}
        </SystemMessageFormPreviewWrapper>
      </SystemMessageFormContainer>
      <SystemMessageButtonWrapper>
        <Button
          text={t("save")}
          onClick={handleUpsertBanner}
          id="system-message-save"
        />
        {bannerId && (
          <Button
            text={t("delete")}
            onClick={handleDeleteBanner}
            isError
            id="system-message-delete"
          />
        )}
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleCancel}
          id="system-message-cancel"
        />
      </SystemMessageButtonWrapper>
    </BlockUi>
  );
};
