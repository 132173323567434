import {
  Box,
  styled,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  Typography,
} from "@mui/material";

export const InspectionQuestionsContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  borderRadius: 16,
  padding: 16,
  border: "1px solid #DDDDDD",
  "& > div > div": {
    "&:not(:first-of-type)": {
      justifyContent: "space-between",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
    gap: 8,
  },
}));

export const QuestionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 6,
  "& > div > div": {
    maxWidth: "none",
  },
}));

export const Divider = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#D9D9D9",
  height: 1,
  margin: "8px 0",
}));

export const QuestionNumberFieldWrapper = styled(Box)(() => ({
  "& > div": {
    width: 70,
    "& .MuiFormHelperText-root": {
      top: "32px !important",
      wordWrap: "break-word",
      margin: 0,
      width: 70,
      position: "unset",
    },
    "& > div": {
      height: 32,
    },
  },
}));

export const QuestionTextFieldWrapper = styled(Box)(() => ({
  "& > div,textArea": {
    maxWidth: "none",
    width: "100%",
    "& > div": {
      height: 32,
    },
  },
}));

export const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "end",
  },
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: "fit-content",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
}));

export const Radio = styled(MuiRadio)(() => ({
  color: "#DDD",
  "&.Mui-checked": {
    color: "#217BDB",
  },
  "& > span > svg": {
    width: 16,
    height: 16,
  },
  "& > input": {
    backgroundColor: "#FFF",
  },
}));

export const InspectionQuestionsTitleWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const NumberOfQuestions = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "16px",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
