import {
  Box,
  FormControlLabel as MuiFormControlLabel,
  InputAdornment,
  Radio as MuiRadio,
  Typography,
  styled,
} from "@mui/material";

export const InspectionsFiltersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px 8px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "20px 8px",
  },
}));

export const InspectionsSearchWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& > div > div > input": {
    [theme.breakpoints.up("md")]: {
      height: 32,
    },
  },
  "& > img": {
    marginLeft: 16,
  },
}));

export const FilterIconWrapper = styled(InputAdornment)(() => ({
  cursor: "pointer",
}));

export const InspectionsFiltersWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "27px 0 24px 0",
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  minWidth: 108,
}));

export const FilterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    gap: 8,
    flexWrap: "wrap",
  },
}));

export const FiltersWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  minWidth: 400,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minWidth: "auto",
  },
}));

export const CheckboxContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 108,
    width: "fit-content",
  },
}));

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    gap: 8,
  },
}));

export const Radio = styled(MuiRadio)(() => ({
  color: "#DDD",
  "&.Mui-checked": {
    color: "#217BDB",
  },
  "& > span > svg": {
    width: 16,
    height: 16,
  },
  "& > input": {
    backgroundColor: "#FFF",
  },
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: "fit-content",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%",
}));

export const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "row",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ScoreWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  gap: 8,
  "& > div > div": {
    height: 24,
  },
}));
