import * as yup from "yup";

export const SystemMessageValidationSchema = (t: (value: string) => string) =>
  yup.object().shape({
    title: yup.string().required(t("required-field")),
    description: yup.string().required(t("required-field")),
    startDisplayDate: yup
      .date()
      .required(t("required-field"))
      .test({
        name: "validator-time",
        test: function () {
          const { from } = this;
          const formValue = from?.[0].value;
          const startDisplayDate = formValue.startDisplayDate;
          const stopDisplayDate = formValue.stopDisplayDate;
          if (
            typeof stopDisplayDate === "object" &&
            startDisplayDate > stopDisplayDate
          ) {
            return this.createError({
              message: t("stop-displaying-date-before-start-date"),
              path: "stopDisplayDate",
            });
          } else {
            return true;
          }
        },
      }),
    stopDisplayDate: yup.date().required(t("required-field")),
  });
