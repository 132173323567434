import * as yup from "yup";

export const ChemicalCompositionValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    chemical: yup
      .object({
        id: yup.string().required(t("required-field")),
        val: yup.string().required(t("required-field")),
      })
      .required(t("required-field")),
    minimumValue: yup
      .number()
      .typeError(t("not-a-number"))
      .required(t("required-field")),
    maximumValue: yup
      .number()
      .typeError(t("not-a-number"))
      .required(t("required-field"))
      .min(yup.ref("minimumValue"), t("must-be-greater-min")),
    units: yup.string().required(t("required-field")),
  });
