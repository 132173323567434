import { HighlightScope } from "@mui/x-charts";
import { COMPLIANCE_COLOR } from "../compliance/constants";
import {
  IModifiedInspectionsDue,
  IInspectionDue,
  IInspectionsDueInformation,
} from "./types";

export const inspectionsDueChartHeight = 180;

export const highlightScope = { faded: "global" };

export const inspectionDueChartMargin = {
  left: -20,
  right: -30,
  top: 20,
  bottom: 0,
};

export const getModifiedInspectionDue = (inspectionsDue: IInspectionDue[]) => {
  return inspectionsDue?.reduce(
    (acc: IModifiedInspectionsDue, inspectionDue: IInspectionDue) => {
      if (acc[inspectionDue?.inspectionReminderType]) {
        acc[inspectionDue?.inspectionReminderType]?.push(inspectionDue);
      } else {
        acc[inspectionDue?.inspectionReminderType] = [inspectionDue];
      }

      return acc;
    },
    {}
  );
};

export const getPercentageByTotal = (count: number, totalCount: number) => {
  return Math.round((count * 100) / totalCount);
};

export const getInspectionsDueInformation = (
  inspectionsDue: IModifiedInspectionsDue = {}
) => {
  const inspectionsDueArray = Object.keys(inspectionsDue).map((key) => ({
    ...inspectionsDue[key],
  }));

  return inspectionsDueArray?.map((inspectionDue: IInspectionDue[]) => ({
    complianceDescription: inspectionDue?.[0]?.inspectionReminderType,
    complianceColor: inspectionDue?.[0]?.color || "red",
  }));
};
[
  { complianceDescription: "Hazardous", complianceColor: "red" },
  { complianceDescription: "Non-Hazardous", complianceColor: "blue" },
] as IInspectionsDueInformation[];

export const getInspectionsDueSeries = (
  modifiedInspectionsDue: IModifiedInspectionsDue = {}
) => {
  const modifiedInspectionsDueArray = Object.keys(modifiedInspectionsDue).map(
    (key) => [...modifiedInspectionsDue[key]]
  );

  return modifiedInspectionsDueArray?.map((inspectionDue) => ({
    type: "bar",
    data:
      inspectionDue?.length > 0
        ? inspectionDue?.map(({ locations }: IInspectionDue) => locations)
        : [0],
    color: COMPLIANCE_COLOR[inspectionDue?.[0]?.color || "red"],
    highlightScope: highlightScope as Partial<HighlightScope>,
    layout: "horizontal",
  }));
};

export const getTotalInspectionsDue = (
  modifiedInspectionsDue: IModifiedInspectionsDue,
  complianceDescription: string
) => {
  return modifiedInspectionsDue?.[complianceDescription]?.reduce(
    (acc: number, { locations }: IInspectionDue) => {
      acc += locations;

      return acc;
    },
    0
  );
};

export const getYAxisInspectionsDue = () => {
  return [
    {
      scaleType: "band",
      id: "y-axis-id",
      data: [""],
    },
  ];
};
