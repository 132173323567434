export const SECTION = {
  CLEAR: -1,
  CUSTOMER_INFORMATION: 0,
  WASTE_STREAM_DESCRIPTION: 1,
  CHEMICAL_COMPOSITION: 2,
  GENERAL_CHARACTERISTICS: 3,
  DOT_INFORMATION: 4,
  ADDITIONAL_INFORMATION: 5,
  REVIEW: 6,
  SUBMIT: 7,
};

export const SECTION_SIZE = 7;

export const STATUS_ID_VALUES = {
  ACTIVE: 1,
  INACTIVE: 2,
  FAIL: 3,
  SENT: 4,
  OUT_OF_BUSINESS: 5,
  PENDING: 6,
  SUCCESS: 7,
  DRAFT: 8,
  SUSPENDED: 9,
  ARCHIVE: 16,
  PUBLISH: 17,
};
