import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const DASHBOARD_SUB_URL = "Dashboard";

export const dashboard = api.injectEndpoints({
  endpoints: (builder) => ({
    getManifestComplianceStatistics: builder.query({
      query: () => ({
        url: `${DASHBOARD_SUB_URL}/GetManifestComplianceStatistics`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getWasteShippedByMonth: builder.query({
      query: () => ({
        url: `${DASHBOARD_SUB_URL}/GetWasteShippedByMonth`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInspections: builder.query({
      query: () => ({
        url: `${DASHBOARD_SUB_URL}/GetInspections`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInventoryAging: builder.query({
      query: () => ({
        url: `${DASHBOARD_SUB_URL}/GetInventoryAging`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getInspectionsDue: builder.query({
      query: () => ({
        url: `${DASHBOARD_SUB_URL}/GetInspectionsDue`,
        method: HTTP_METHOD.GET,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetInspectionsDueQuery,
  useLazyGetManifestComplianceStatisticsQuery,
  useLazyGetWasteShippedByMonthQuery,
  useLazyGetInspectionsQuery,
  useLazyGetInventoryAgingQuery,
} = dashboard;
