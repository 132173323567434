import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "src/shared/components/button/button";
import { Switch } from "src/shared/components/switch/switch";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import {
  useGetUserProfileByIDQuery,
  useUpdateAuthenticatedUserMutation,
} from "src/shared/store/api/userProfile.api";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
} from "src/shared/store/api/organization.api";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import {
  useLazyGetInspectionsDueQuery,
  useLazyGetInspectionsQuery,
  useLazyGetInventoryAgingQuery,
  useLazyGetManifestComplianceStatisticsQuery,
  useLazyGetWasteShippedByMonthQuery,
} from "src/shared/store/api/dashboard.api";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { ManifestChart } from "../manifestChart/manifestChart";
import { InspectionChart } from "../InspectionChart/inspectionChart";
import { InventoryAgingChart } from "../inventoryAgingChart/inventoryAgingChart";
import { WasteShippedChart } from "../wasteShippedChart/wasteShippedChart";
import { InviteDialog } from "../inviteDialog/inviteDialog";
import { InspectionsDueChart } from "../inspectionsDueChart/inspectionsDueChart";
import { IOrganization } from "./types";
import {
  DASHBOARD_CHART_IDS,
  DEFAULT_CHARTS_POSITION,
  POSITION_OF_CHARTS,
} from "./constants";
import {
  QuickOverviewContainer,
  QuickOverviewTitle,
  AssumeAccountWrapper,
  AssumeAccountTitle,
  QuickOverviewWrapper,
  QuickOverviewSelectWrapper,
  InviteButtonWrapper,
  ResetButtonWrapper,
  ChartsContainer,
  ChartsWrapper,
} from "./styles";

export const QuickOverview = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const dragChart = useRef<number>(0);
  const draggedOverChart = useRef<number>(0);

  const [isOpenInviteDialog, setIsOpenInviteDialog] = useState(false);
  const [isAssumeAccount, setIsAssumeAccount] = useState(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState();
  const [chartIds, setChartIds] = useState(
    getFromLocalStorage(DASHBOARD_CHART_IDS) || DEFAULT_CHARTS_POSITION
  );

  const [
    updateAuthenticatedUser,
    { isSuccess: isSuccessUpdateAuthenticatedUser },
  ] = useUpdateAuthenticatedUserMutation();

  const [
    getManifestComplianceStatisticsData,
    {
      data: manifestComplianceStatistics,
      isLoading: isLoadingManifestComplianceStatistics,
      isFetching: isFetchingManifestComplianceStatistics,
    },
  ] = useLazyGetManifestComplianceStatisticsQuery({});

  const [
    getInspectionDue,
    {
      data: inspectionsDue,
      isLoading: isLoadingInspectionsDue,
      isFetching: isFetchingInspectionsDue,
    },
  ] = useLazyGetInspectionsDueQuery();

  const [
    getWasteShippedDate,
    {
      data: wasteShippedByMonth,
      isLoading: isLoadingWasteShippedByMonth,
      isFetching: isFetchingWasteShippedByMonth,
    },
  ] = useLazyGetWasteShippedByMonthQuery({});

  const [
    getInspections,
    {
      data: inspections,
      isLoading: isLoadingInspections,
      isFetching: isFetchingInspections,
    },
  ] = useLazyGetInspectionsQuery({});

  const [
    getInventoryAging,
    {
      data: inventoryAging,
      isLoading: isLoadingInventoryAging,
      isFetching: isFetchingInventoryAging,
    },
  ] = useLazyGetInventoryAgingQuery({});

  const getChartsData = useCallback(() => {
    getManifestComplianceStatisticsData({});
    getWasteShippedDate({});
    getInspections({});
    getInventoryAging({});
    getInspectionDue({});
  }, [
    getInspections,
    getInventoryAging,
    getManifestComplianceStatisticsData,
    getWasteShippedDate,
    getInspectionDue,
  ]);

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const permissions = userProfile?.userPermissions;

  const hasInviteUserPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.INVITE_USER
  );

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const [organizationOption, setOrganizationOption] = useState<IOrganization>();

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );

      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  const [searchValue, setSearchValue] = useState("");

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery(
    {
      searchValue,
    },
    {
      skip: !isAuthenticated,
    }
  );

  const { t } = useTranslation();

  const handleOpenInviteDialog = () => {
    setIsOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    setIsOpenInviteDialog(false);
  };

  const onChangeSwitch = () => {
    if (organizationOption?.id) {
      setIsAssumeAccount(!isAssumeAccount);
      updateAuthenticatedUser({ id: organizationOption?.id });
    }
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setOrganizationOption(newInputValue as IOrganization);
  };

  const handleChangeChartIds = (chartIds: number[]) => {
    saveToLocalStorage(DASHBOARD_CHART_IDS, chartIds);
    setChartIds(chartIds);
  };

  const handleResetCharts = () => {
    handleChangeChartIds(DEFAULT_CHARTS_POSITION);
  };

  const handleSort = () => {
    const chartsClone = [...chartIds];
    const temp = chartsClone[dragChart.current];
    chartsClone[dragChart.current] = chartsClone[draggedOverChart.current];
    chartsClone[draggedOverChart.current] = temp;

    handleChangeChartIds(chartsClone);
  };

  const handleDelete = (deletedId: number) => {
    const newCharts = chartIds?.filter((id: number) => id !== deletedId);

    handleChangeChartIds(newCharts);
  };

  useEffect(() => {
    if (defaultOrganization) {
      setOrganizationOption({
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      });
    }
  }, [defaultOrganization]);

  useEffect(() => {
    getChartsData();
  }, [getChartsData, defaultOrganizationId]);

  useEffect(() => {
    if (isSuccessUpdateAuthenticatedUser) {
      setIsAssumeAccount(false);
      getChartsData();
    }
  }, [getChartsData, isSuccessUpdateAuthenticatedUser]);

  return (
    <QuickOverviewContainer>
      <QuickOverviewWrapper>
        <QuickOverviewTitle color="secondary">
          {t("quick-overview")}
        </QuickOverviewTitle>
        <AssumeAccountWrapper>
          <InviteButtonWrapper>
            {hasInviteUserPermission && (
              <Button
                text={t("invite")}
                onClick={handleOpenInviteDialog}
                disabled={!userProfile?.userAccounts || !organizationOption}
                id="quick-overview-invite"
              />
            )}
          </InviteButtonWrapper>
          <AssumeAccountTitle color="secondary">
            {t("default-account")}
          </AssumeAccountTitle>
          <Switch
            checked={isAssumeAccount}
            onChange={onChangeSwitch}
            disabled={!userProfile?.userAccounts || !organizationOption}
            id="quick-overview-is-assume-account"
          />
        </AssumeAccountWrapper>
        <QuickOverviewSelectWrapper>
          <Autocomplete
            options={
              organizationOptions
                ? organizationOptions
                : organizationOption
                ? [organizationOption]
                : []
            }
            value={organizationOption || null}
            updateData={(value: string) => {
              setSearchValue(value);
            }}
            onChange={handleChangeOrganization}
            disabled={!userProfile?.userAccounts?.length}
            id="quick-overview-account"
          />
        </QuickOverviewSelectWrapper>
        <InviteDialog
          organizationOption={organizationOption}
          open={isOpenInviteDialog}
          handleClose={handleCloseInviteDialog}
        />
      </QuickOverviewWrapper>
      <ResetButtonWrapper>
        <Button
          text={t("reset")}
          onClick={handleResetCharts}
          id="quick-overview-reset"
        />
      </ResetButtonWrapper>
      <ChartsContainer>
        {chartIds?.map((id: number, index: number) => (
          <ChartsWrapper
            key={id}
            draggable
            onDragStart={() => (dragChart.current = index)}
            onDragEnter={() => (draggedOverChart.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            {id === POSITION_OF_CHARTS.MANIFEST && (
              <ManifestChart
                manifestData={manifestComplianceStatistics}
                isLoadingManifestData={
                  isLoadingManifestComplianceStatistics ||
                  isFetchingManifestComplianceStatistics
                }
                handleDelete={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  handleDelete(id);
                }}
              />
            )}
            {id === POSITION_OF_CHARTS.WASTE && (
              <WasteShippedChart
                wasteData={wasteShippedByMonth}
                isLoadingWasteData={
                  isLoadingWasteShippedByMonth || isFetchingWasteShippedByMonth
                }
                handleDelete={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  handleDelete(id);
                }}
              />
            )}
            {id === POSITION_OF_CHARTS.INSPECTIONS && (
              <InspectionChart
                inspectionsData={inspections}
                isLoadingInspectionsData={
                  isLoadingInspections || isFetchingInspections
                }
                handleDelete={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  handleDelete(id);
                }}
              />
            )}
            {id === POSITION_OF_CHARTS.INVENTORY && (
              <InventoryAgingChart
                inventoryAgingData={inventoryAging}
                isLoadingInventoryAgingData={
                  isLoadingInventoryAging || isFetchingInventoryAging
                }
                handleDelete={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  handleDelete(id);
                }}
              />
            )}
            {id === POSITION_OF_CHARTS.INSPECTIONS_DUE && (
              <InspectionsDueChart
                inspectionsDue={inspectionsDue}
                isLoadingInspectionsDue={
                  isLoadingInspectionsDue || isFetchingInspectionsDue
                }
                handleDelete={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  handleDelete(id);
                }}
              />
            )}
          </ChartsWrapper>
        ))}
      </ChartsContainer>
    </QuickOverviewContainer>
  );
};
