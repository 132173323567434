import React, { useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { PaginationNumber } from "./paginationNumber/paginationNumber";
import { PaginationButton } from "./paginationButton/paginationButton";
import { PAGINATION_PAGE_SIZE_OPTIONS } from "./constants";
import { PAGINATION } from "../constants";
import { Select } from "../../select/select";
import { ICustomPagination } from "./types";
import {
  CustomPaginationContainer,
  PaginationNumberContainer,
  PaginationPageSizeWrapper,
} from "./styles";

export const CustomPagination = ({
  gridName,
  gridApi,
  rowDataProps,
  currentPage,
  setCurrentPage,
  searchValue,
  paginationPageSize,
  sortModel,
  setPaginationPageSize,
  changedColumnState,
  setChangedColumnState,
}: ICustomPagination) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const totalPages = gridApi?.paginationGetTotalPages();
    setTotalPages(totalPages || 0);
    setChangedColumnState(false);
  }, [
    gridApi,
    rowDataProps,
    searchValue,
    paginationPageSize,
    sortModel,
    changedColumnState,
    setChangedColumnState,
  ]);
  const pages = new Array(totalPages).fill(0);

  const handleChangePaginationPageSize = (e: SelectChangeEvent<unknown>) => {
    setPaginationPageSize(e?.target?.value as string);
    saveToLocalStorage(`${gridName}-${PAGINATION}`, e?.target?.value);
  };

  useEffect(() => {
    if (totalPages === 1) {
      gridApi?.paginationGoToPage(0);
      setCurrentPage(0);
    }
  }, [totalPages, setCurrentPage, gridApi]);

  return (
    <CustomPaginationContainer>
      {!!totalPages && (
        <>
          <PaginationButton
            onClick={() => gridApi?.paginationGoToPreviousPage()}
            disabled={currentPage === 0}
          />
          <PaginationNumberContainer>
            {pages?.map((_, index) => {
              const indexMinusTotalPages = index + 1 - totalPages;
              const lessThanTwo = index < 2;
              const minNumberForDots = 5;
              const halfOfTotalPages = Math.ceil(totalPages / 2);

              return lessThanTwo ||
                index === currentPage ||
                indexMinusTotalPages > -2 ? (
                <PaginationNumber
                  key={index}
                  isCurrentPage={currentPage === index}
                  number={index + 1}
                  onClick={() => gridApi?.paginationGoToPage(index)}
                />
              ) : ((index === halfOfTotalPages &&
                  currentPage !== halfOfTotalPages) ||
                  (currentPage === halfOfTotalPages &&
                    index === halfOfTotalPages + 1)) &&
                totalPages >= minNumberForDots ? (
                <React.Fragment key={index}>...</React.Fragment>
              ) : null;
            })}
            <PaginationPageSizeWrapper>
              <Select
                value={paginationPageSize || ""}
                options={PAGINATION_PAGE_SIZE_OPTIONS}
                onChange={handleChangePaginationPageSize}
                id={`pagination-${gridName}`}
              />
            </PaginationPageSizeWrapper>
          </PaginationNumberContainer>
          <PaginationButton
            isNext
            onClick={() => gridApi?.paginationGoToNextPage()}
            disabled={currentPage === totalPages - 1}
          />
        </>
      )}
    </CustomPaginationContainer>
  );
};
