import * as yup from "yup";
import { MANIFEST_STATUSES } from "./constants";
import { Dayjs } from "dayjs";

export const EditManifestStatusValidationSchema = (
  t: (value: string) => string,
  dateShipped: Dayjs,
  today: Dayjs
) =>
  yup.object().shape({
    status: yup.string().required(t("required-field")),
    returnDate: yup
      .date()
      .nullable()
      .typeError("")
      .when(["status"], (status, schema) => {
        const statusValue = status?.[0];
        return statusValue === MANIFEST_STATUSES.RETURNED ||
          statusValue === MANIFEST_STATUSES.ARCHIVED
          ? schema
              .required(t("required-field"))
              .min(
                dateShipped.subtract(1, "day"),
                t("must-be-between-shipped-and-today")
              )
              .max(today, t("must-be-between-shipped-and-today"))
          : schema;
      }),
  });
