import { createSlice } from "@reduxjs/toolkit";
import { IWasteProfileFilters } from "./wasteProfilesFilters.types";
import { saveToLocalStorage } from "src/shared/helpers/localStorage";
import { WASTE_PROFILES_STORAGE_KEYS } from "src/components/wasteProfilesFilters/constants";

const initialWasteProfilesFiltersState: IWasteProfileFilters = {
  keyword: "",
  accounts: [],
  searchOrganization: "",
  scroll: undefined,
  selectedRow: undefined,
};

export const wasteProfilesFiltersSlice = createSlice({
  name: "wasteProfileFilters",
  initialState: initialWasteProfilesFiltersState,
  reducers: {
    setWasteProfilesFiltersKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setWasteProfilesFiltersAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setWasteProfilesFiltersSearchOrganization: (state, action) => {
      state.searchOrganization = action.payload;
    },
    setWasteProfilesFilterScroll: (state, action) => {
      state.scroll = action.payload;
    },
    setWasteProfilesFiltersSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SELECTED_WASTE_PROFILES_ROW,
        state.selectedRow
      );
    },
    saveToLocalStorageWasteProfilesFilters: (state) => {
      saveToLocalStorage(WASTE_PROFILES_STORAGE_KEYS.KEYWORD, state.keyword);
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.ACCOUNT_ID_LIST,
        state.accounts
      );
      saveToLocalStorage(
        WASTE_PROFILES_STORAGE_KEYS.SEARCH_ORGANIZATION_VALUE,
        state.searchOrganization
      );
    },
    clearFromLocalStorageWasteProfilesFilters: (state) => {
      state.keyword = "";
      state.accounts = [];
      state.selectedRow = undefined;
    },
  },
});

export const {
  reducer: wasteProfilesFiltersReducer,
  actions: wasteProfilesFiltersActions,
} = wasteProfilesFiltersSlice;
