import { Box, Typography, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const InspectionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "40px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "0 16px",
    margin: "26px 0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "12px 0",
  },
}));

export const InspectionsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 20,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export const InspectionsDescription = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  color: theme.palette.text.secondary,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginRight: 16,
  [theme.breakpoints.down("lg")]: {
    maxWidth: "none",
    marginRight: 0,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
    marginTop: 8,
    marginRight: 0,
  },
}));

export const InspectionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));

export const InspectionsCardsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 16,
  width: "100%",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
  [theme.breakpoints.down("md")]: {
    " & > div": {
      "&:nth-of-type(1)": {
        order: 1,
      },
      "&:nth-of-type(2)": {
        order: 0,
      },
      "&:nth-of-type(3)": {
        order: 2,
      },
      width: "100%",
    },
  },
}));

export const PermissionAlertWrapper = styled(Box)(({ theme }) => ({
  marginTop: 12,
  "& > div": {
    backgroundColor: isDarkMode(theme.palette.mode)
      ? "#217BDB"
      : "rgb(229, 246, 253)",
    border: "none",
    "& > div > svg": {
      fill: isDarkMode(theme.palette.mode) ? "" : "#29b6f6",
    },
  },
}));
