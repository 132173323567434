import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { useMsal } from "@azure/msal-react";
import { RadioGroup, SelectChangeEvent } from "@mui/material";
import { Input } from "src/shared/components/input/input";
import question from "src/shared/assets/svgs/question.svg";
import { ReactComponent as FilterIcon } from "src/shared/assets/svgs/filter-icon.svg";
import { Question } from "src/components/permissitonsForm/styles";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import {
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationSitesQuery,
  useGetOrganizationsByIDsQuery,
  useLazyGetAllOrganizationFacilitiesQuery,
} from "src/shared/store/api/organization.api";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { IOrganization } from "src/components/quickOverview/types";
import { Select } from "src/shared/components/select/select";
import { MANIFEST_STATUS_OPTIONS } from "src/components/editManifestStatusDialog/constants";
import { COMPLIANCE_COLOR_OPTIONS } from "src/components/compliance/constants";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import { Button } from "src/shared/components/button/button";
import { IOption } from "src/shared/components/select/types";
import { ManifestTooltip } from "src/components/manifestTooltip/manifestTooltip";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import {
  HAS_VALUES_OPTIONS,
  getHasValue,
} from "src/components/inspectionsTable/inspectionsFilters/constants";
import {
  ManifestFiltersContainer,
  ManifestSearchWrapper,
  FilterIconWrapper,
  ManifestFiltersWrapper,
  Label,
  FilterWrapper,
  FiltersContainer,
  FiltersWrapper,
  CheckboxContainer,
  ActionsWrapper,
  FormControlLabel,
  RadioGroupWrapper,
  Radio,
  CheckboxWrapper,
} from "./styles";
import { IManifestFilters } from "./types";
import { getComplianceData } from "./constants";

export const ManifestFilters = ({
  trigger,
  gridApi,
  updateData,
  setUpdateData,
}: IManifestFilters) => {
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const {
    keyword,
    searchOrganization,
    accounts: accountsIds,
    sites,
    facilities,
    statuses,
    compliances,
    startDate,
    endDate,
    discrepancy,
    hasAttachment,
    isAfterClickOnManifestChart,
    isAfterClickOnWasteByMonthChart,
  } = useAppSelector((state) => state.manifestFiltersReducer);
  const {
    setManifestFiltersKeyword,
    setManifestFiltersSearchOrganization,
    setManifestFiltersAccounts,
    setManifestFilterSites,
    setManifestFilterFacilities,
    setManifestFilterStatuses,
    setManifestFilterCompliances,
    setManifestFilterStartDate,
    setManifestFilterEndDate,
    setManifestFilterDiscrepancy,
    setManifestFilterHasAttachment,
  } = useAppActions();

  const today = dayjs(new Date());
  const yearBefore = dayjs(new Date()).subtract(365, "days");

  const [updateAfterCancel, setUpdateAfterCancel] = useState<boolean>(false);
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [isOpenManifestTooltip, setIsOpenManifestTooltip] =
    useState<boolean>(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const getManifestList = useCallback(
    (newKeyword: string) => {
      trigger({
        keyword: newKeyword,
        startDate,
        endDate,
        hasAttachment: getHasValue(hasAttachment),
        discrepancy,
        statusList: statuses,
        complianceList: getComplianceData(compliances),
        accountIDList: accountsIds?.map((organization) => organization?.id),
        siteIDList: sites?.map((site) => site?.id),
        facilityIDList: facilities?.map((facility) => facility?.id),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      trigger,
      startDate,
      endDate,
      hasAttachment,
      discrepancy,
      statuses,
      compliances,
      accountsIds,
      sites,
      facilities,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTrigger = useCallback(
    debounce((keyword) => getManifestList(keyword), 2000),
    [getManifestList]
  );

  const onChangeSearchManifest = (e: ChangeEvent<HTMLInputElement>) => {
    const searchManifestValue = e?.target?.value;
    setManifestFiltersKeyword(searchManifestValue);
    debouncedTrigger(searchManifestValue);
  };

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  useEffect(() => {
    if (defaultOrganization && !accountsIds?.length) {
      const defaultAccountList = [
        {
          id: defaultOrganization?.[0]?.organizationUnitID,
          val: defaultOrganization?.[0]?.name,
        },
      ];
      setManifestFiltersAccounts(defaultAccountList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  const [getAllOrganizationFacilities, { data: organizationFacilities }] =
    useLazyGetAllOrganizationFacilitiesQuery();

  const { data: organizationSites } = useGetOrganizationSitesQuery(
    {
      organizationId: accountsIds?.map(
        (organization: IOrganization) => organization?.id
      ),
    },
    {
      skip: !accountsIds?.length,
    }
  );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const handleFilters = () => setIsOpenFilters(!isOpenFilters);

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setManifestFiltersAccounts(newInputValue as Array<IOrganization>);
    setManifestFilterFacilities([]);
  };

  const handleChangeStartDate = (date: unknown) => {
    if (date) {
      setManifestFilterStartDate(date as Date);
    }
  };

  const handleChangeEndDate = (date: unknown) => {
    if (date) {
      setManifestFilterEndDate(date as Date);
    }
  };

  const handleChangeDiscrepancy = () => {
    setManifestFilterDiscrepancy(!discrepancy);
  };

  const handleChangeHasAttachment = (event: ChangeEvent<HTMLInputElement>) => {
    setManifestFilterHasAttachment(event?.target?.value);
  };

  const handleChangeStatus = (e: SelectChangeEvent<unknown>) => {
    const statusValue = e?.target?.value;
    setManifestFilterStatuses(statusValue as Array<IOption>);
  };

  const handleChangeCompliance = (e: SelectChangeEvent<unknown>) => {
    const complianceValue = e?.target?.value;
    setManifestFilterCompliances(complianceValue as Array<string>);
  };

  const handleChangeFacility = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setManifestFilterFacilities(newInputValue as Array<IOption>);
  };

  const handleChangeSite = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setManifestFilterSites(newInputValue as Array<IOption>);
    setManifestFilterFacilities([]);
  };

  useEffect(() => {
    if (sites?.length || accountsIds?.length) {
      getAllOrganizationFacilities({
        isSiteRequest: !!sites?.length,
        siteIDList: sites?.map((site) => site?.id),
        accountIDList: accountsIds?.map((account) => account?.id),
      });
    }
  }, [sites, accountsIds, getAllOrganizationFacilities]);

  const handleApplyFilters = () => {
    getManifestList(keyword);
    gridApi?.paginationGoToPage(0);
  };

  useEffect(() => {
    if (updateAfterCancel) {
      getManifestList(keyword);
      setUpdateAfterCancel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getManifestList, updateAfterCancel]);

  const handleOpenManifestTooltip = () => {
    setIsOpenManifestTooltip(true);
  };

  const handleClearFilters = () => {
    gridApi?.setFilterModel(null);
    setManifestFiltersKeyword("");
    setManifestFiltersSearchOrganization("");
    setManifestFiltersAccounts([
      {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      },
    ]);
    setManifestFilterSites([]);
    setManifestFilterFacilities([]);
    setManifestFilterStatuses([]);
    setManifestFilterCompliances([]);
    setManifestFilterStartDate(yearBefore);
    setManifestFilterEndDate(today);
    setManifestFilterDiscrepancy(false);
    setManifestFilterHasAttachment(false);
    setUpdateAfterCancel(true);
  };

  useEffect(() => {
    if (
      (accountsIds?.length || defaultOrganizationId) &&
      ((!updateData &&
        !isAfterClickOnManifestChart &&
        !isAfterClickOnWasteByMonthChart) ||
        updateData)
    ) {
      trigger({
        keyword,
        startDate: startDate,
        endDate: endDate,
        hasAttachment: getHasValue(hasAttachment),
        discrepancy,
        statusList: statuses,
        complianceList: getComplianceData(compliances),
        accountIDList: accountsIds?.length
          ? accountsIds?.map((organization) => organization?.id)
          : defaultOrganizationId
          ? [defaultOrganizationId]
          : [],
        siteIDList: sites?.map((site) => site?.id),
        facilityIDList: facilities?.map((facility) => facility?.id),
      });
      if (updateData) {
        setUpdateData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganizationId, updateData]);

  return (
    <ManifestFiltersContainer>
      <ManifestSearchWrapper>
        <Input
          value={keyword}
          placeholder={t("search-in-manifests")}
          onChange={onChangeSearchManifest}
          InputProps={{
            endAdornment: (
              <FilterIconWrapper position="end">
                <FilterIcon onClick={handleFilters} />
              </FilterIconWrapper>
            ),
          }}
          id="search-in-manifests"
        />
        <Question src={question} alt="" onClick={handleOpenManifestTooltip} />
        <ManifestTooltip
          open={isOpenManifestTooltip}
          setOpen={setIsOpenManifestTooltip}
        />
      </ManifestSearchWrapper>
      {isOpenFilters && (
        <>
          <ManifestFiltersWrapper>
            {t("please-use-all-filters")}
          </ManifestFiltersWrapper>
          <FiltersContainer>
            <FiltersWrapper>
              {userProfile?.userAccounts?.length > 1 && (
                <FilterWrapper>
                  <Label>{t("account")}</Label>
                  <Autocomplete
                    options={organizationOptions ? organizationOptions : []}
                    value={accountsIds || ""}
                    updateData={(value: string) => {
                      setManifestFiltersSearchOrganization(value);
                    }}
                    onChange={handleChangeOrganization}
                    multiple
                    id="manifest-filters-account"
                  />
                </FilterWrapper>
              )}
              <FilterWrapper>
                <Label>{t("site")}</Label>
                <Autocomplete
                  value={sites || ""}
                  onChange={handleChangeSite}
                  options={organizationSites ? organizationSites : []}
                  disabled={!organizationSites}
                  multiple
                  id="manifest-filters-site"
                />
              </FilterWrapper>
              <FilterWrapper>
                <Label>{t("tsdf")}</Label>
                <Autocomplete
                  value={facilities || ""}
                  onChange={handleChangeFacility}
                  options={organizationFacilities ? organizationFacilities : []}
                  disabled={!organizationFacilities}
                  multiple
                  id="manifest-filters-facilities"
                />
              </FilterWrapper>
              <FilterWrapper>
                <Label>{t("status")}</Label>
                <Select
                  value={statuses}
                  options={MANIFEST_STATUS_OPTIONS}
                  onChange={handleChangeStatus}
                  multiple
                  id="manifest-filters-status"
                />
              </FilterWrapper>
              <FilterWrapper>
                <Label>{t("compliance")}</Label>
                <Select
                  value={compliances}
                  options={COMPLIANCE_COLOR_OPTIONS}
                  onChange={handleChangeCompliance}
                  multiple
                  id="manifest-filters-compliance"
                />
              </FilterWrapper>
              <FilterWrapper>
                <Label>{t("shipped-date-from")}</Label>
                <DatePicker
                  value={startDate}
                  onChange={handleChangeStartDate}
                  maxDate={endDate}
                  id="manifest-filters-shipped-date-from"
                />
              </FilterWrapper>
              <FilterWrapper>
                <Label>{t("shipped-date-to")}</Label>
                <DatePicker
                  value={endDate}
                  onChange={handleChangeEndDate}
                  minDate={startDate}
                  id="manifest-filters-shipped-date-to"
                />
              </FilterWrapper>
            </FiltersWrapper>
            <CheckboxContainer>
              <CheckboxWrapper>
                <FormCardCheckbox
                  checked={discrepancy}
                  onChange={handleChangeDiscrepancy}
                  id="manifest-filters-discrepancy"
                />
                <Label>{t("discrepancy")}</Label>
              </CheckboxWrapper>
              <RadioGroupWrapper>
                <Label>{`${t("has-attachments")}?`}</Label>
                <RadioGroup
                  value={hasAttachment || ""}
                  onChange={handleChangeHasAttachment}
                >
                  <FormControlLabel
                    value={HAS_VALUES_OPTIONS.ALL}
                    control={<Radio icon={<CheckedIcon />} />}
                    label={t("all")}
                  />
                  <FormControlLabel
                    value={HAS_VALUES_OPTIONS.HAS}
                    control={<Radio icon={<CheckedIcon />} />}
                    label={t("yes")}
                  />
                  <FormControlLabel
                    value={HAS_VALUES_OPTIONS.HAS_NOT}
                    control={<Radio icon={<CheckedIcon />} />}
                    label={t("no")}
                  />
                </RadioGroup>
              </RadioGroupWrapper>
            </CheckboxContainer>
          </FiltersContainer>
          <ActionsWrapper>
            <Button
              isGrey
              text={t("clear-filters")}
              onClick={handleClearFilters}
              id="manifest-filters-clear-filters"
            />
            <Button
              isGrey
              text={t("cancel")}
              onClick={handleFilters}
              id="manifest-filters-cancel"
            />
            <Button
              text={t("apply")}
              onClick={handleApplyFilters}
              id="manifest-filters-apply"
            />
          </ActionsWrapper>
        </>
      )}
    </ManifestFiltersContainer>
  );
};
