import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { BackIcon, BackText, BackWrapper } from "./styles";
import { IBackTo } from "./types";

export const BackTo = ({ text, handleBack }: IBackTo) => {
  return (
    <BackWrapper onClick={handleBack}>
      <BackIcon src={backArrow} alt="" />
      <BackText>{text}</BackText>
    </BackWrapper>
  );
};
