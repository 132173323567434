import { Box, styled } from "@mui/material";

export const FormCardInputContainer = styled(Box)(({ theme }) => ({
  "& > div": {
    "& .MuiFormHelperText-root": {
      position: "absolute",
      top: 42,
      marginTop: 2,
      fontFamily: "Roboto",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: 10,
        top: 28,
      },
    },
  },
  "& > div > div": {
    height: 42,
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
    "& > input": {
      fontSize: 18,
      height: 34,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
        height: 16,
      },
    },
  },
}));
