import { useTranslation } from "react-i18next";
import { TitleContainer, Title } from "./styles";

export const SiteCardTitle = () => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <Title>{t("site")}</Title>
    </TitleContainer>
  );
};
