import ReactRouterPrompt from "react-router-prompt";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/shared/hooks/redux/redux";
import { Button } from "../button/button";
import {
  UnsavedChangedFieldsContainer,
  UnsavedChangesActionsContainer,
} from "../unsavedChangesDialog/styles";
import { Dialog } from "../dialog/dialog";

export const CustomReactRouterPrompt = () => {
  const { t } = useTranslation();
  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const renderUnsavedChangesDialogFields = () => (
    <UnsavedChangedFieldsContainer>
      {t("all-unsaved-changes-lost")}
    </UnsavedChangedFieldsContainer>
  );

  return (
    <ReactRouterPrompt when={changedData}>
      {({ onConfirm, onCancel }) => {
        const renderUnsavedChangesDialogActions = () => (
          <UnsavedChangesActionsContainer>
            <Button
              text={t("cancel")}
              isGrey
              onClick={onCancel}
              id="prompt-cancel"
            />
            <Button
              text={t("confirm")}
              onClick={onConfirm}
              id="prompt-confirm"
            />
          </UnsavedChangesActionsContainer>
        );

        return (
          <Dialog
            open={changedData}
            renderFields={renderUnsavedChangesDialogFields}
            actions={renderUnsavedChangesDialogActions}
            title={t("unsaved-changes")}
            handleClose={onCancel}
          />
        );
      }}
    </ReactRouterPrompt>
  );
};
