import { Box, Typography, styled } from "@mui/material";

export const CreateNewProfileWrapper = styled(Box)(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const CreateNewProfileDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
  },
}));

export const MailToLink = styled("a")(() => ({
  display: "inline",
}));
