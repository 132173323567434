import React, { useState } from "react";
import { ImageListItem } from "@mui/material";
import { ReactComponent as PhotoAlbum } from "src/shared/assets/svgs/photo-album.svg";
import { ZoomedImageWidthDownload } from "../../../../../shared/components/zoomedImageWithDownload/zoomedImageWithDownload";
import { IBlobFile } from "../../types";
import { FolderOpenWrapper, InspectionText } from "../styles";
import { IQuestionPromptWithImages } from "./types";
import { QuestionPromptWrapper, ImageList } from "./styles";

export const QuestionPromptWithImages = ({
  prompt,
  blobFilePaths = [],
}: IQuestionPromptWithImages) => {
  const [isOpenImages, setIsOpenImages] = useState(false);

  const handleOpenFolder = () => {
    setIsOpenImages(!isOpenImages);
  };

  return (
    <React.Fragment>
      <QuestionPromptWrapper>
        <InspectionText>{prompt}</InspectionText>
        {!!blobFilePaths?.length && (
          <FolderOpenWrapper>
            <PhotoAlbum onClick={handleOpenFolder} />
          </FolderOpenWrapper>
        )}
      </QuestionPromptWrapper>
      {isOpenImages && (
        <ImageList cols={4}>
          {blobFilePaths?.map(({ containerName, file, folder }: IBlobFile) => {
            return (
              <ImageListItem key={file}>
                <ZoomedImageWidthDownload
                  containerName={containerName}
                  fileName={file}
                  folder={folder}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      )}
    </React.Fragment>
  );
};
