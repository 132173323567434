import { Box, FormHelperText, Select } from "@mui/material";
import { styled } from "@mui/system";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const SelectContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  "& .MuiFormHelperText-root": {
    top: 24,
    marginTop: 2,
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: 24,
  borderRadius: 8,
  background: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
  color: theme.palette.secondary.main,
  fontFamily: "Roboto",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  width: "100%",
  "& .MuiSelect-select": {
    padding: "4px 8px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B1B1B1",
    },
    "&:hover fieldset": {
      borderColor: isDarkMode(theme.palette.mode)
        ? "rgba(37, 137, 92, 0.5);"
        : "rgba(37, 137, 92, 0.20)",
    },
    "&.Mui-focused fieldset": {
      borderColor: isDarkMode(theme.palette.mode)
        ? "rgba(32, 33, 36, 0.50)"
        : "#25895C",
    },
    "&.Mui-error fieldset": {
      borderColor: "#E35D2B",
    },
  },
}));

export const SelectFormHelperText = styled(FormHelperText)(() => ({
  position: "absolute",
  marginTop: 0,
  marginLeft: 14,
}));
