import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormCard } from "src/shared/components/formCard/formCard";
import { Button } from "src/shared/components/button/button";
import backArrow from "src/shared/assets/svgs/back-arrow.svg";
import { useGetOrganizationBySearchValueQuery } from "src/shared/store/api/organization.api";
import { IOrganization } from "src/components/quickOverview/types";
import { FormCardAutocomplete } from "src/shared/components/formCard/formCardAutocomplete/formCardAutocomplete";
import {
  BackIcon,
  BackText,
  BackWrapper,
  ButtonsWrapper,
} from "../../pages/editUser/styles";
import { IEditAssignedAccountsForm } from "./types";

export const EditAssignedAccountsForm = ({
  errors,
  handleCancel,
  updateUserData,
  disabledSaveButton,
  userAccounts,
  setUserAccounts,
  handleChangedData,
}: IEditAssignedAccountsForm) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [organizationOption, setOrganizationOption] = useState<
    Array<IOrganization>
  >([]);

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue,
  });

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    handleChangedData();
    setOrganizationOption(newInputValue as Array<IOrganization>);
  };

  const updateData = (value: string) => {
    setSearchValue(value);
  };

  const updateAssignedAccountForm = () => {
    const newValues = organizationOption?.map((organization) => ({
      organizationUnitID: String(organization?.id),
      isDefault: false,
      isFavorite: false,
    }));

    setUserAccounts([...userAccounts, ...newValues]);
    updateUserData();
  };

  return (
    <div>
      <BackWrapper onClick={handleCancel}>
        <BackIcon src={backArrow} alt="" />
        <BackText>{t("edit-assigned-accounts")}</BackText>
      </BackWrapper>
      <FormCard title={t("assigned-accounts")}>
        <React.Fragment>
          <FormCardAutocomplete
            multiple
            options={
              organizationOptions
                ? organizationOptions
                : organizationOption
                ? [organizationOption]
                : []
            }
            value={organizationOption || ""}
            updateData={updateData}
            onChange={handleChangeOrganization}
            errorMessage={errors?.userAccounts?.message}
            id="edit-assigned-accounts-assigned-accounts"
          />
          <ButtonsWrapper>
            <Button
              text={t("cancel")}
              onClick={handleCancel}
              isGrey
              id="edit-assigned-accounts-cancel"
            />
            <Button
              text={t("save")}
              onClick={updateAssignedAccountForm}
              disabled={disabledSaveButton || !organizationOption}
              id="edit-assigned-accounts-save"
            />
          </ButtonsWrapper>
        </React.Fragment>
      </FormCard>
    </div>
  );
};
