import { useTranslation } from "react-i18next";
import {
  AgGridTitle,
  AgGridTitleWrapper,
  PrintToExcelButton,
} from "src/shared/components/agGrid/styled";
import { useAppSelector } from "src/shared/hooks/redux/redux";
import { useProfileDetailExportFileMutation } from "src/shared/store/api/wasteProfile.api";

export const ProfilesCustomTitle = () => {
  const { t } = useTranslation();
  const [profileDetailExportFile] = useProfileDetailExportFileMutation();
  const { keyword, accounts } = useAppSelector(
    (state) => state.profileFiltersReducer
  );

  const handlePrintToExcel = () => {
    profileDetailExportFile({
      keyword,
      accountIDList: accounts?.map((account) => account.id),
    });
  };

  return (
    <AgGridTitleWrapper>
      <AgGridTitle>{t("list-of-profiles")}</AgGridTitle>
      <PrintToExcelButton onClick={handlePrintToExcel} id="print-to-excel">
        {t("print-to-excel")}
      </PrintToExcelButton>
    </AgGridTitleWrapper>
  );
};
